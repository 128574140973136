import React, { FC, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@material-ui/core";

import GitRepoInformation from "components/GitRepoInformation/GitRepoInformation";
import PageHeader from "components/PageHeader";
import { AssessmentDetailsContext } from "pages/Assessments/pages/AssessessmentDetails/context/AssessmentDetailsContext";

interface DetailHeaderProps {
  gitRepo: string;
}

const ConsolidatedAssessmentDetailHeader: FC<DetailHeaderProps> = ({
  gitRepo,
}) => {
  const project =
    gitRepo.split("/").pop()?.toUpperCase() ?? gitRepo.toUpperCase();
  const { assessmentDetail } = useContext(AssessmentDetailsContext);

  return (
    <Fragment>
      <PageHeader mb={0}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          {`${project} Assessments`}
        </Box>
      </PageHeader>
      <Box
        my={1}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <GitRepoInformation {...assessmentDetail} />
        <Breadcrumbs separator={"/"} aria-label="breadcrumb">
          <Link color="inherit" to="/repos/">
            Repositories
          </Link>
          <Link color="inherit" to={`/repos/details/${gitRepo}/overview`}>
            {gitRepo}
          </Link>
          <Typography color="textPrimary">Assessments</Typography>
        </Breadcrumbs>
      </Box>
    </Fragment>
  );
};

export default ConsolidatedAssessmentDetailHeader;
