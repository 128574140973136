import React, { FC, useContext } from "react";
import { Box, Card, Grid } from "@material-ui/core";

import { AssessmentDetailsContext } from "../context/AssessmentDetailsContext";
import ReactMarkdown from "components/ReactMarkdown/ReactMarkdown";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";

const DetailSummary: FC = () => {
  const { assessmentDetail } = useContext(AssessmentDetailsContext);
  return (
    <Box component={Card} p={2} mb={2}>
      <Grid container component="dl">
        <Grid item xs={12}>
          <dd>
            <ReactMarkdown linkTarget="_blank">
              {assessmentDetail?.markdown}
            </ReactMarkdown>
          </dd>
        </Grid>
      </Grid>
      <Grid container component="dl">
        {assessmentDetail?.target && (
          <Grid item xs={6} md={4}>
            <dt>Target</dt>
            <dd>{assessmentDetail.target}</dd>
          </Grid>
        )}
        {assessmentDetail?.provider && (
          <Grid item xs={6} md={4}>
            <dt>Provider</dt>
            <dd>{assessmentDetail.provider}</dd>
          </Grid>
        )}
        {assessmentDetail?.gitRepo && (
          <Grid item xs={6} md={4}>
            <dt>Repo</dt>
            <dd>
              <a
                href={assessmentDetail?.gitRepoUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {assessmentDetail.gitRepoName ?? "-"}
              </a>
            </dd>
          </Grid>
        )}
        {assessmentDetail?.assessmentTs && (
          <Grid item xs={6} md={4}>
            <dt>Created</dt>
            <dd>
              <RelativeTimeFormatter dateTs={assessmentDetail?.assessmentTs} />
            </dd>
          </Grid>
        )}
        {assessmentDetail?.gitBranch && (
          <Grid item xs={6} md={4}>
            <dt>Branch</dt>
            <dd>{assessmentDetail.gitBranch}</dd>
          </Grid>
        )}
        {assessmentDetail?.gitTag && (
          <Grid item xs={6} md={4}>
            <dt>Tag</dt>
            <dd>{assessmentDetail.gitTag}</dd>
          </Grid>
        )}
        {assessmentDetail?.host && (
          <Grid item xs={6} md={4}>
            <dt>Host</dt>
            <dd>{assessmentDetail.host}</dd>
          </Grid>
        )}
        {assessmentDetail?.gitCommit && (
          <Grid item xs={6} md={4}>
            <dt>Commit</dt>
            <dd>
              <a
                href={assessmentDetail?.gitCommitUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {assessmentDetail?.gitCommit?.slice(0, 7) ?? "-"}
              </a>
            </dd>
          </Grid>
        )}
        {assessmentDetail?.ciBuildUrl && (
          <Grid item xs={6} md={4}>
            <dt>Job</dt>
            <dd>
              <a
                href={assessmentDetail?.ciBuildUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {assessmentDetail?.ciBuildId ?? "-"}
              </a>
            </dd>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DetailSummary;
