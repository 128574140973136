import React, { FC, useMemo } from "react";
import {
  Box,
  Chip,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { capitalize } from "lodash";
import useCommonStyles from "theme/useCommonStyles";

import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import SeverityComponent, {
  Severity,
} from "components/SeverityComponent/SeverityComponent";
import { Finding } from "types/assessments";
import { Violation } from "types/violations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dList: {
      margin: 0,
    },
    dTitle: {
      margin: 0,
      marginRight: theme.spacing(1),
    },
    resource: {
      backgroundColor: "#336be4",
    },
    repo: {
      backgroundColor: "#565970",
    },
  }),
);
interface SuppressedFindingsRowProps {
  finding: Finding | Violation;
  gitRepo?: string;
  showResource: boolean;
}

const SuppressedFindingRow: FC<SuppressedFindingsRowProps> = ({
  finding,
  gitRepo,
  showResource,
}) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.up("xl"));
  const lines =
    finding.line === finding.lineRangeEnd
      ? ` ${finding.line}`
      : `s ${finding.line} - ${finding.lineRangeEnd}`;
  const filePath = useMemo(() => {
    const str =
      finding.isModuleReference && finding.callerFilePath
        ? finding.callerFilePath
        : finding.filePath ?? "";

    return str?.length >= (matchesLg ? 47 : 23)
      ? `~${str.substring(str.length - (matchesLg ? 45 : 22))}`
      : str;
  }, [
    finding.callerFilePath,
    finding.filePath,
    finding.isModuleReference,
    matchesLg,
  ]);

  return (
    <Box>
      <Box>{finding.title}</Box>
      <Box className={classes.dTitle} component="dl">
        {finding.resource && showResource && (
          <div>
            <Typography
              display="inline"
              className={classes.dTitle}
              variant="h6"
              component="dt"
            >
              Resource
            </Typography>
            <Typography
              display="inline"
              className={commonStyles.wordBreakAll}
              variant="body2"
              component="dd"
            >
              {finding.resource}
            </Typography>
          </div>
        )}

        <div>
          <Typography
            display="inline"
            className={classes.dTitle}
            variant="h6"
            component="dt"
          >
            Suppression Level
          </Typography>
          <Typography display="inline" variant="body2" component="dd">
            <Chip
              size="small"
              style={{ width: 90 }}
              className={classNames({
                [classes.resource]: finding.suppressionLevel === "resource",
                [classes.repo]: finding.suppressionLevel === "repository",
              })}
              color={
                finding.suppressionLevel === "assessment"
                  ? "secondary"
                  : "primary"
              }
              label={capitalize(
                finding.suppressionLevel === "repository"
                  ? "resource"
                  : finding.suppressionLevel,
              )}
            />
          </Typography>
        </div>
        <div>
          <Typography
            display="inline"
            className={classes.dTitle}
            variant="h6"
            component="dt"
          >
            Suppressed
          </Typography>
          <Typography display="inline" variant="body2" component="dd">
            <RelativeTimeFormatter dateTs={finding.suppressedTs} />
          </Typography>
        </div>
        <div>
          <Typography
            display="inline"
            className={classes.dTitle}
            variant="h6"
            component="dt"
          >
            Severity
          </Typography>
          <Typography display="inline" variant="body2" component="dd">
            <SeverityComponent severity={Severity[finding.severity]} />
          </Typography>
        </div>
        {gitRepo && (
          <div>
            <Typography
              display="inline"
              className={classes.dTitle}
              variant="h6"
              component="dt"
            >
              Repository
            </Typography>
            <Typography
              className={commonStyles.wordBreakAll}
              display="inline"
              variant="body2"
              component="dd"
            >
              {gitRepo}
            </Typography>
          </div>
        )}
        {finding.filePath && (
          <Box display="flex" alignItems="flex-start">
            <Box className={classes.dTitle} component="dt">
              <DescriptionOutlined fontSize="small" />
            </Box>
            {finding.gitFilePermaLink ? (
              <Box className={commonStyles.wordBreakAll}>
                Found in &#8204;
                <a
                  href={finding.gitFilePermaLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {`${
                    finding.isModuleReference && finding.callerFilePath
                      ? finding.callerFilePath
                      : finding.filePath
                  } : `}
                  <span style={{ wordBreak: "initial" }}>{`Line${lines}`}</span>
                </a>
              </Box>
            ) : (
              <Typography
                className={commonStyles.wordBreakAll}
                variant="body2"
                component="dd"
              >
                {`Found in ${filePath}`}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SuppressedFindingRow;
