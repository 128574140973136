import { createContext, useContext } from "react";

export interface QueryStateContextState {
  getValue(key: string): Record<string, any> | undefined;
  setValue(key: string, value: Record<string, any>): void;
}

const defaultContext: QueryStateContextState = {
  getValue() {
    return void 0;
  },
  setValue() {},
};

export const QueryStateContext =
  createContext<QueryStateContextState>(defaultContext);

export const useQueryStateContext = () => useContext(QueryStateContext);
