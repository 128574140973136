import React, { Fragment, useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  ClickAwayListener,
  createStyles,
  Grow,
  Hidden,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  useTheme,
} from "@material-ui/core";
import { BuildRounded, Business } from "@material-ui/icons";

import {
  useChangeOrg,
  useCurrentOrg,
  useOrganizations,
  useUser,
} from "providers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: "none",
      fontSize: 11.5,
      color: "rgba(0,0,0,.75)",
      [theme.breakpoints.up("md")]: {
        borderRadius: theme.spacing(1),
      },
    },
    root: {
      display: "flex",
    },
    item: {
      fontSize: 11.5,
    },
    link: {
      color: "inherit",
      "&:hover": {
        textDecoration: "none",
      },
    },
    caption: {
      fontSize: 11,
      fontWeight: 600,
      letterSpacing: ".04em",
      padding: "4px 8px 4px 8px",
    },
    icon: {
      marginLeft: 8,
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }),
);

const ChangeOrgDropdown = () => {
  const classes = useStyles();
  const theme = useTheme();
  const currentOrg = useCurrentOrg();
  const organizations = useOrganizations();
  const changeOrg = useChangeOrg();
  const navigate = useNavigate();
  const { provider } = useUser();
  const isGitHubProvider = provider?.toLocaleLowerCase() === "github";

  const handleChangeOrg = useCallback(
    async (orgId: string) => {
      await changeOrg(orgId);
      navigate("/", { replace: true });
    },
    [changeOrg, navigate],
  );
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        size="small"
        className={classes.button}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Hidden smDown implementation="js">
          {currentOrg.displayName}
          <FontAwesomeIcon className={classes.icon} icon={faCaretDown} />
        </Hidden>
        <Hidden mdUp implementation="js">
          <Business style={{ color: "#757575" }} />
        </Hidden>
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    disabled
                    style={{ opacity: 1 }}
                    className={classes.caption}
                  >
                    CHANGE ORGANIZATION
                  </MenuItem>
                  {organizations.map((org) => (
                    <MenuItem
                      key={org.orgId}
                      disabled={org.orgId === currentOrg.orgId}
                      className={classes.item}
                      onClick={() => handleChangeOrg(org.orgId)}
                      style={
                        org.orgId === currentOrg.orgId
                          ? {
                              opacity: 0.8,
                              backgroundColor: theme.palette.grey[300],
                            }
                          : {}
                      }
                    >
                      {org.displayName}
                    </MenuItem>
                  ))}
                  {isGitHubProvider && (
                    <a
                      className={classes.link}
                      href="https://docs.lacework.com/iac/iac-security-faqs#organization-not-visible"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleClose}
                    >
                      <MenuItem className={classes.item}>
                        <Box fontSize={10} style={{ opacity: 0.7 }} mr={0.5}>
                          <BuildRounded fontSize="inherit" />
                        </Box>
                        Configure
                      </MenuItem>
                    </a>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default ChangeOrgDropdown;
