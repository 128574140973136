import React, { FC, Fragment, useCallback, useMemo, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import { Column } from "react-table";

import { useCurrentOrgId, useHasScope } from "providers";

import ConfirmationModal, {
  ConfirmationModalRef,
} from "components/ConfirmationModal/ConfirmationModal";
import DataTable from "components/DataTable";
import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import IdTruncator from "components/IdTruncator";
import NoDataComponent from "components/NoDataComponent";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import StatusBadge from "components/StatusBadge";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
  optimisticDatasetUpdater,
} from "helpers/queryHelpers";
import tokenService from "services/tokenService";
import { AgentToken } from "types";
import { Permissions, Resources } from "types/auth-roles";
import { TokensTabsRowActions } from "types/tabs";

const columnsDef = (
  { onRevokeToken, onDisableToken, onEnableToken }: TokensTabsRowActions,
  hasClusterWriteScope: boolean,
): Column<AgentToken>[] =>
  [
    {
      Header: "Created",
      accessor: "createTs",
      Cell: ({ row: { original: row } }) => (
        <RelativeTimeFormatter dateTs={row.createTs}></RelativeTimeFormatter>
      ),
    } as Column<AgentToken>,
    {
      Header: "Expires",
      accessor: "expirationTs",
      Cell: ({ row: { original: row } }) => (
        <RelativeTimeFormatter
          dateTs={row.expirationTs}
        ></RelativeTimeFormatter>
      ),
    } as Column<AgentToken>,
    {
      Header: "Last Usage",
      accessor: "lastUseTs",
      Cell: ({ row: { original: row } }) => (
        <RelativeTimeFormatter dateTs={row.lastUseTs}></RelativeTimeFormatter>
      ),
    } as Column<AgentToken>,
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row: { original: row } }) => (
        <StatusBadge status={row.status}></StatusBadge>
      ),
    } as Column<AgentToken>,
    hasClusterWriteScope &&
      ({
        Header: "Actions",
        accessor: "id",
        collapse: true,
        disableSortBy: true,
        Cell: ({ row: { original: row } }) => {
          if (row.status === "revoked") {
            return <DropdownActions items={[]}></DropdownActions>;
          }

          const dropdownActions: DropdownItem[] = [
            {
              label: "Revoke Token",
              action: () => onRevokeToken(row),
            },
          ];

          const enableTokenAction: DropdownItem = {
            label: "Enable Token",
            action: () => onEnableToken(row),
          };

          const disableTokenAction: DropdownItem = {
            label: "Disable Token",
            action: () => onDisableToken(row),
          };

          row.status === "active" &&
            dropdownActions.unshift(disableTokenAction);
          row.status === "disabled" &&
            dropdownActions.unshift(enableTokenAction);

          return <DropdownActions items={dropdownActions} />;
        },
      } as Column<AgentToken>),
  ].filter((column): column is Column<AgentToken> => !!column);

interface AgentTokensDataTableProps {
  isActive: boolean;
}

const AgentTokensDataTable: FC<AgentTokensDataTableProps> = ({ isActive }) => {
  const orgId = useCurrentOrgId();
  const confirmationModal = useRef<ConfirmationModalRef>(null);
  const hasClusterWriteScope = useHasScope(
    Resources.Cluster,
    Permissions.Write,
  );
  const {
    data: agentTokens = [],
    isLoading,
    refetch,
  } = useQuery(
    ["agentTokens", orgId],
    () => tokenService.getAgentTokens(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      enabled: isActive,
    },
  );

  const fetchAgentTokens = () => {
    refetch();
  };

  const { mutate: enableToken } = useMutation(
    ({ id }: Pick<AgentToken, "id">) =>
      tokenService.enableAgentToken(orgId, id),
    {
      onMutate: ({ id }) =>
        optimisticDatasetUpdater<AgentToken, "id">(
          ["agentTokens", orgId],
          { id },
          { status: "active" },
        ),
      onError: errorToastHandler("There was an error enabling the Agent Token"),
      onSettled: fetchAgentTokens,
    },
  );

  const { mutate: disableToken } = useMutation(
    ({ id }: Pick<AgentToken, "id">) =>
      tokenService.disableAgentToken(orgId, id),
    {
      onMutate: ({ id }) =>
        optimisticDatasetUpdater<AgentToken, "id">(
          ["agentTokens", orgId],
          { id },
          { status: "disabled" },
        ),
      onError: errorToastHandler(
        "There was an error disabling the Agent Token",
      ),
      onSettled: fetchAgentTokens,
    },
  );

  const { mutate: revokeToken } = useMutation(
    ({ id }: Pick<AgentToken, "id">) =>
      tokenService.revokeAgentToken(orgId, id),
    {
      onMutate: ({ id }) =>
        optimisticDatasetUpdater<AgentToken, "id">(
          ["agentTokens", orgId],
          { id },
          { status: "revoked" },
        ),
      onError: errorToastHandler("There was an error revoking the Agent Token"),
      onSettled: fetchAgentTokens,
    },
  );

  const revokeTokenHandler = useCallback(
    ({ id }: AgentToken) => {
      confirmationModal.current?.openModal({
        title: "Revoke Agent Token",
        description: (
          <Fragment>
            Are you sure you want to revoke the token with id{" "}
            <IdTruncator id={id}></IdTruncator>
          </Fragment>
        ),
        confirm: "Revoke",
        cancel: "Cancel",
        action() {
          revokeToken({ id });
          confirmationModal.current?.closeModal();
        },
      });
    },
    [revokeToken],
  );

  const columns = useMemo(
    () =>
      columnsDef(
        {
          onEnableToken: enableToken,
          onDisableToken: disableToken,
          onRevokeToken: revokeTokenHandler,
        },
        hasClusterWriteScope,
      ),
    [disableToken, enableToken, hasClusterWriteScope, revokeTokenHandler],
  );

  return (
    <Fragment>
      <DataTable
        columns={columns}
        sortByDesc={true}
        defaultSortField="lastUseTs"
        data={agentTokens}
        isLoading={isLoading}
        pagination
        compact
        noDataComponent={
          <NoDataComponent>There are no agent tokens yet</NoDataComponent>
        }
      />

      <ConfirmationModal ref={confirmationModal} />
    </Fragment>
  );
};

export default AgentTokensDataTable;
