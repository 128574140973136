import React, { FC, Fragment, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import SyntaxHighlighter from "react-syntax-highlighter";
import xonokai from "react-syntax-highlighter/dist/cjs/styles/prism/xonokai";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DescriptionRounded } from "@material-ui/icons";
import { upperFirst } from "lodash";

import { useCurrentOrgId } from "providers";

import zodiacService from "services/zodiacService";

interface ZodiacInstanceLogsModalProps {
  instanceId: string;
}

const ZodiacInstanceLogsModal: FC<ZodiacInstanceLogsModalProps> = ({
  instanceId,
}) => {
  const orgId = useCurrentOrgId();
  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const { data: logs, isLoading } = useQuery(
    ["job-instance-logs", orgId, instanceId],
    () => zodiacService.getZodiacInstanceLogs(orgId, instanceId),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: open,
    },
  );

  return (
    <Fragment>
      <Box>
        <Tooltip arrow title="View logs">
          <IconButton onClick={handleClickOpen} color="primary">
            <DescriptionRounded />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClickClose}
        aria-labelledby="form-dialog-title"
      >
        <Box p={3} pb={0} display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            {`${upperFirst(instanceId)} Logs`}
          </Typography>
        </Box>
        <DialogContent>
          {isLoading ? (
            <Card variant="outlined">
              <Box p={2}>
                <Skeleton count={7} />
              </Box>
            </Card>
          ) : (
            <SyntaxHighlighter language="json" style={xonokai}>
              {logs?.log ?? "There are no logs for this instance."}
            </SyntaxHighlighter>
          )}
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Box mr={2} display="inline-block">
              <Button onClick={handleClickClose} color="primary">
                Close
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ZodiacInstanceLogsModal;
