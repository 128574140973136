/**
 * This function is an implementation of the Clipboard API to copy a text.
 *
 * The API is supported over 82% of browsers, and 100% of evergreen browsers.
 *
 * @ref https://caniuse.com/mdn-api_clipboard_writetext
 * @ref https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
 *
 * @param text The text to be copied.
 */
export const copy = (text: string): Promise<void> =>
  navigator?.clipboard?.writeText(text);
