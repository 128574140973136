import React, {
  forwardRef,
  RefForwardingComponent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { FileCopyRounded, GetApp } from "@material-ui/icons";
import moment, { Moment } from "moment";

import { useUser } from "providers";

import { copy } from "helpers/copy";
import tokenService from "services/tokenService";
import { NewAccessToken } from "types";

interface CreateAccessTokenModalProps {
  onRefreshTable: () => void;
}

export interface CreateAccessTokenModalRef {
  open(): void;
}

type CreateAccessTokenModal = RefForwardingComponent<
  CreateAccessTokenModalRef,
  CreateAccessTokenModalProps
>;

const CreateAccessTokenModal: CreateAccessTokenModal = (
  { onRefreshTable },
  ref,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [newToken, setNewToken] = useState<NewAccessToken>();
  const [tokenDate, setTokenDate] = useState<Moment>();
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const currentUser = useUser();

  useImperativeHandle(ref, () => ({
    open,
  }));

  const createAccessToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await tokenService.createAccessToken();
      setNewToken(result.data);
      setTokenDate(
        moment(new Date(result.headers.date).toISOString(), moment.ISO_8601),
      );
    } catch {
      toast.error("An error ocurred trying to generate the new token.");
    }
    setIsLoading(false);
  }, []);

  const copyToken = useCallback(async () => {
    if (!newToken) {
      return;
    }

    await copy(newToken.token);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  }, [newToken]);

  const downloadTxtFile = useCallback(() => {
    if (!newToken) {
      return;
    }

    const element = document.createElement("a");
    const file = new Blob([newToken.token], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `Access token ${
      currentUser.displayName
    } ${tokenDate?.toString()}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }, [currentUser.displayName, newToken, tokenDate]);

  const deleteToken = useCallback(() => {
    setNewToken(void 0);
  }, []);

  useEffect(() => {
    if (!isOpen || newToken) {
      return;
    }

    createAccessToken();
  }, [createAccessToken, isOpen, newToken]);

  useEffect(() => {
    onRefreshTable?.();
  }, [newToken, onRefreshTable]);

  return (
    <Dialog maxWidth="xs" fullWidth open={isOpen} onExited={deleteToken}>
      <DialogTitle>New Access Token</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box display="flex" pb={2}>
            <TextField
              variant="outlined"
              color="primary"
              fullWidth
              label="Token"
              name="token"
              disabled
              value={newToken?.token}
            />
            <Tooltip
              color="primary"
              placement="top"
              open={tooltipOpen}
              title="Copied!"
              arrow
            >
              <IconButton
                title="Copy token"
                color="primary"
                onClick={copyToken}
                disabled={!newToken}
              >
                <FileCopyRounded />
              </IconButton>
            </Tooltip>
            <IconButton
              title="Download token"
              color="primary"
              disabled={!newToken}
              onClick={downloadTxtFile}
            >
              <GetApp />
            </IconButton>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={close}>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(CreateAccessTokenModal);
