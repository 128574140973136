import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import ReportDetailsPage from "./Reports/ReportDetails";
import Reports from "./Reports/Reports";
import HasFlagRoute from "router/HasFlagRoute";

const AnalyticsRouter: FC<Childless> = () => {
  return (
    <Routes>
      <Route
        path="reports"
        element={<HasFlagRoute flag="analytics" element={<Reports />} />}
      />
      <Route
        path="reports/:reportName"
        element={
          <HasFlagRoute flag="analytics" element={<ReportDetailsPage />} />
        }
      />
      <Route path="analytics" element={<Navigate to="reports" />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default AnalyticsRouter;
