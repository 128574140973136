import axios from "axios";
import config from "config";

const instance = axios.create({ baseURL: config.API });
const unauthenticated = axios.create({ baseURL: config.API });

// Add a 401 response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        if (
          window.location.pathname !== "/" &&
          window.location.pathname !== "/error" &&
          window.location.pathname !== "/test" &&
          window.location.pathname !== "/private-beta" &&
          window.location.pathname !== "/register" &&
          !window.location.pathname.startsWith("/api/v1/auth/login") &&
          !window.location.pathname.startsWith("/login/") &&
          !window.location.pathname.startsWith("/get-started") &&
          !window.location.pathname.includes("/success")
        ) {
          window.location.href = "/";
        }
      }
    }

    return Promise.reject(error);
  },
);

// If the user is unauthenticated and receives a 409 GONE with a location header,
// redirect the browser to that location. This is used to let Lacework users follow
// Soluble assessment URLs.
unauthenticated.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Check conditions very carefully here because an error at this point may cause an endless loop
    if (
      error.response &&
      error.response.data &&
      error.response.data.status &&
      error.response.data.status === 409 &&
      error.response.headers &&
      error.response.headers.location
    ) {
      window.location.replace(error.response.headers.location);
    } else {
      return Promise.reject(error);
    }
  },
);

export { unauthenticated };
export default instance;
