import React, {
  CSSProperties,
  FC,
  Fragment,
  useCallback,
  useState,
} from "react";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";

import { copy } from "helpers/copy";

interface CodeSnippedProps {
  children: string;
  className?: string;
  style?: CSSProperties;
  wrap?: boolean;
  onCopy?(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyButton: {
      position: "absolute",
      right: "0.75rem",
      top: "0.75rem",
    },
    codeSnippedWrap: {
      whiteSpace: "pre-wrap",
      paddingRight: "2rem",
    },
    codeSnipped: {
      alignItems: "center",
      background: theme.palette.lightBackground.dark,
      display: "flex",
      justifyContent: "space-between",
      minHeight: "3rem",
      padding: theme.spacing(2),
      paddingRight: "2rem",
      position: "relative",
    },
  }),
);

const CodeSnipped: FC<CodeSnippedProps> = ({
  children,
  className,
  style,
  wrap = false,
  onCopy,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const copyCode = useCallback(async () => {
    await copy(children);
    handleTooltipOpen();
    setTimeout(() => handleTooltipClose(), 3000);
    onCopy?.();
  }, [children, onCopy]);

  const classes = useStyles();

  return (
    <Fragment>
      <figure
        className={classNames(classes.codeSnipped, className)}
        style={style}
      >
        <pre className={classNames(wrap && classes.codeSnippedWrap)}>
          {children}
        </pre>

        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={isTooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied!"
          placement="top"
          arrow
        >
          <IconButton
            id="CopySnippedButton"
            data-testid="copy-icon-button"
            size="small"
            onClick={copyCode}
            className={classes.copyButton}
          >
            <Typography variant="srOnly">Copy</Typography>
            <FontAwesomeIcon icon={faCopy} />
          </IconButton>
        </Tooltip>
      </figure>
    </Fragment>
  );
};

export default CodeSnipped;
