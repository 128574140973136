import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  TextField,
} from "@material-ui/core";
import validator from "validator";

import { useCurrentOrgId } from "providers";

import { normalizeFormError } from "helpers/normalizeFormErrors";
import userService from "services/userService";

export interface InviteUserModalRef {
  open(): void;
  close(): void;
}

export interface InviteUserForm {
  email: string;
}

interface InviteUserModal
  extends ForwardRefRenderFunction<InviteUserModalRef> {}

const InviteUserModal: InviteUserModal = (_props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const orgId = useCurrentOrgId();
  const { register, handleSubmit, errors } = useForm<InviteUserForm>();

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const onSubmit = useCallback(
    async ({ email }: { email: string }) => {
      try {
        setIsLoading(true);
        await userService.inviteUser(orgId, email);
        handleClose();
        toast.success(`You have successfully invited ${email}!`);
      } catch {
        toast.error("There was an error inviting the user. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [handleClose, orgId],
  );

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen}>
      <DialogTitle>Invite User</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box my={3} textAlign="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <form
            id="inviteUser"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box mb={4}>
              <TextField
                variant="outlined"
                color="primary"
                label="Email"
                fullWidth
                name="email"
                inputRef={register({
                  required: "Please enter an e-mail.",
                  validate: (value) =>
                    validator.isEmail(value) || "Please enter a valid email.",
                })}
              />
              <Fade in={!!errors.email}>
                <Box component="small" color="error.main">
                  {normalizeFormError(errors.email)}
                </Box>
              </Fade>
            </Box>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
          <Box mr={1} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            form="inviteUser"
            disabled={isLoading}
          >
            Invite
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(InviteUserModal);
