import { Box, Button, Typography } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";

import { useLogout } from "providers";

import { ReactComponent as BugIllustration } from "assets/svg/illustrations/bug-fix.svg";
import Logo from "assets/svg/logos/lw-logo.svg";

const UserModelError = () => {
  const logout = useLogout();
  const logOut = async () => {
    await logout();
    window.location.reload();
  };
  return (
    <Box
      height="100vh"
      width="100vw"
      bgcolor="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      overflow="scroll"
    >
      <Box mb={2}>
        <img width={245} src={Logo} alt="lacework-iac-security-logo" />
      </Box>

      <Box p={5} textAlign="center">
        <Box
          component={BugIllustration}
          maxWidth={350}
          width="100%"
          height="auto"
          my={4}
        ></Box>
        <Typography variant="h4" color="textSecondary">
          The current user does not belong to any organization
        </Typography>
        <Box my={2}>
          <Typography variant="body1">
            Please <a href="mailto:info@exmaple.com">contact support</a> for
            help resolving this issue.
          </Typography>
        </Box>

        <Button
          startIcon={<ExitToApp />}
          onClick={() => logOut()}
          size="small"
          color="primary"
          variant="outlined"
        >
          Log out
        </Button>
      </Box>
    </Box>
  );
};

export default UserModelError;
