import React, { FC, useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { AxiosError } from "axios";
import { isEmpty } from "lodash";

import { useCurrentOrgId } from "providers";

import ConsolidatedAssessmentDetailBody from "./components/ConsolidatedAssessmentDetailBody";
import ConsolidatedAssessmentDetailHeader from "./components/ConsolidatedAssessmentDetailHeader";
import { useQueryParams } from "hooks/useQueryParams";
import { AssessmentDetailsContext } from "pages/Assessments/pages/AssessessmentDetails/context/AssessmentDetailsContext";
import repositoryService from "services/repositoryService";
import { AssessmentDetail } from "types/assessments";

type Error = {
  ok: false;
  message: string;
  error: string;
};

const ConsolidatedAssessmentDetails: FC = () => {
  const { gitRepoName = "" } = useParams<{
    gitRepoName: string;
  }>();
  const { diag, ciBuildId } =
    useQueryParams<{ diag?: string; ciBuildId?: string }>();
  const gitRepo = decodeURIComponent(gitRepoName);

  const orgId = useCurrentOrgId();
  const { data, error, isLoading, refetch, isFetched, isError } = useQuery<
    AssessmentDetail,
    AxiosError<Error>
  >(
    ["consolidatedAssessmentDetail", orgId, gitRepo, ciBuildId].filter(
      (e) => e !== null,
    ),
    () =>
      ciBuildId
        ? repositoryService.getRepoConsolidatedBranchesAssessmentDetail(
            orgId,
            ciBuildId,
          )
        : repositoryService.getRepoConsolidatedAssessmentDetail(orgId, gitRepo),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  );

  const assessmentDetail = useMemo(
    () =>
      data && !isEmpty(data)
        ? {
            ...data,
            findings: data?.findings?.map((f) => ({
              ...f,
              autofix: f.pass ? false : !!f.autofix,
              compliance:
                f.compliance?.length === 0 || f.compliance === undefined
                  ? ["No Compliance"]
                  : f.compliance,
            })),
            suppressedFindings: data?.suppressedFindings?.map((f) => ({
              ...f,
              autofix: f.pass ? false : !!f.autofix,
              compliance:
                f.compliance?.length === 0 || f.compliance === undefined
                  ? ["No Compliance"]
                  : f.compliance,
            })),
          }
        : undefined,
    [data],
  );

  if ((diag || diag === "") && isFetched) {
    if (!assessmentDetail?.diagnosticPath) {
      toast.warn("No diagnostic URL found.");
    } else {
      window.location.href = assessmentDetail.diagnosticPath;
    }
  }

  return (
    <AssessmentDetailsContext.Provider
      value={{ assessmentDetail, isLoading, refetch, isFetched }}
    >
      <ConsolidatedAssessmentDetailHeader gitRepo={gitRepo} />
      {(assessmentDetail === undefined || isError) && !isLoading ? (
        <Card>
          <Alert style={{ borderRadius: 0 }} severity="warning">
            <AlertTitle>{error?.response?.data.error ?? "No data"}</AlertTitle>
            {error?.response?.data.message ??
              "No assessment data found for this repository."}
          </Alert>
        </Card>
      ) : (
        <ConsolidatedAssessmentDetailBody gitRepoName={gitRepoName} />
      )}
    </AssessmentDetailsContext.Provider>
  );
};

export default ConsolidatedAssessmentDetails;
