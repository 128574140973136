import React, { FC, Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";

const OrganizationLogin: FC = () => {
  const { state } = useLocation();
  const { pathname = "", search = "" } = state || {};
  const { orgId } = useParams<{ orgId: string }>();

  window.location.href = `/api/v1/auth/login/${orgId}/${
    state ? `?resumeUrl=${encodeURIComponent(pathname + search)}` : ""
  }`;

  return <Fragment />;
};

export default OrganizationLogin;
