import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import useCommonStyles from "theme/useCommonStyles";

import GitRepoInformation from "components/GitRepoInformation/GitRepoInformation";
import { Violation } from "types/violations";

interface ViolationResourceProps {
  violation: Violation;
}

const ViolationResource: FC<ViolationResourceProps> = ({ violation }) => {
  const commonStyles = useCommonStyles();
  const lines =
    violation.line === violation.lineRangeEnd
      ? ` ${violation.line}`
      : `s ${violation.line} - ${violation.lineRangeEnd}`;
  return (
    <Box>
      <Box className={commonStyles.wordBreakAll}>{violation.resource}</Box>
      <Box>
        <Box
          component={DescriptionOutlined}
          mr={0.5}
          ml={-0.5}
          fontSize={"inherit"}
        />
        <a
          className={commonStyles.wordBreakAll}
          onClick={(e) => e.stopPropagation()}
          href={violation.gitFilePermaLink}
          rel="noreferrer noopener"
          target="_blank"
        >
          {`${violation.filePath} : Line${lines}`}
        </a>
      </Box>
      <GitRepoInformation {...violation} />
    </Box>
  );
};

export default ViolationResource;
