import React, { FC, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Grid, Tooltip, Typography } from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import PageHeader from "components/PageHeader";
import { errorToastHandler } from "helpers/queryHelpers";
import usageService from "services/usageService";

const Usage: FC = () => {
  const orgId = useCurrentOrgId();
  const {
    data = { reposCount: "-", assessmentsCount: "-", resourcesCount: "-" },
    isLoading,
  } = useQuery(
    ["usage-report", orgId],
    () => usageService.getUsageReport(orgId),
    {
      onError(error) {
        errorToastHandler(
          "An error ocurred trying to get the usage data. Please try again later.",
        )(error);
      },
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Fragment>
      <PageHeader>
        <Box display="flex" justifyContent="space-between">
          <Box>
            Usage{" "}
            <Tooltip
              placement="right"
              title="These metrics indicate the organization’s resources being analyzed by Lacework IaC Security for the last 30 days."
              arrow
            >
              <Box
                component="span"
                ml={1}
                fontSize={16}
                style={{ opacity: 0.35 }}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Box>
            </Tooltip>
          </Box>
          <Typography variant="body1" color="textSecondary">
            Last 30 days
          </Typography>
        </Box>
      </PageHeader>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box component={Card} p={2}>
            <Box py={3}>
              <Typography color="primary" variant="h2" gutterBottom>
                {isLoading ? <Skeleton width={40} /> : data?.resourcesCount}
              </Typography>
              <Typography variant="h3">
                RESOURCES{" "}
                <Tooltip
                  placement="right"
                  title="Indicates unique resources."
                  arrow
                >
                  <Box
                    component="span"
                    ml={1}
                    fontSize={16}
                    style={{ opacity: 0.35 }}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Box>
                </Tooltip>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box component={Card} p={2}>
            <Box py={3}>
              <Typography color="primary" variant="h2" gutterBottom>
                {isLoading ? <Skeleton width={40} /> : data?.reposCount}
              </Typography>
              <Typography variant="h3">REPOSITORIES</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box component={Card} p={2}>
            <Box py={3}>
              <Typography color="primary" variant="h2" gutterBottom>
                {isLoading ? <Skeleton width={40} /> : data?.assessmentsCount}
              </Typography>
              <Typography variant="h3">ASSESSMENTS</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Usage;
