import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Backdrop,
  Box,
  createStyles,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { MenuRounded } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useCurrentOrg } from "providers";

import AppMenu from "./AppMenu/AppMenu";
import ProfileDropDown from "./ProfileDropDown/ProfileDropDown";
import LWLogo from "assets/svg/logos/lw-logo.svg";
import LWWhiteLogo from "assets/svg/logos/lw-logo-dashboard.svg";
import ChangeOrgDropdown from "components/AppSidebar/ChangeOrgDropdown/ChangeOrgDropdown";
import WhatsNewDropdown from "components/WhatsNewDropdown/WhatsNewDropdown";
import NotificationsDropdown from "pages/Notifications/components/NotificationsDropdown";

const useStyles = (isCollapsed: boolean) => {
  const drawerWidth = isCollapsed ? 64 : 190;

  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
      },
      logoContainer: {
        display: "grid",
        paddingLeft: 16,
        overflow: "hidden",
        height: 52,
      },
      appBar: {
        [theme.breakpoints.up("md")]: {
          width: "calc(100% - " + drawerWidth + "px)",
          marginLeft: drawerWidth,
        },
        transition: "width 0.2s",
        boxShadow:
          "0px 2px 1px -1px rgba(156,156,156,0.05),0px 1px 1px 0px rgba(156,156,156,0.03),0px 1px 3px 0px rgba(156,156,156,0.04)",
        willChange: "width, margin",
      },
      menuButton: {
        marginRight: theme.spacing(2),
        color: theme.palette.darkBackground.main,
      },
      logoMobile: {
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
        cursor: "pointer",
      },
      drawer: {
        [theme.breakpoints.up("md")]: {
          width: drawerWidth,
          flexShrink: 0,
        },
        [theme.breakpoints.only("md")]: {
          width: 64,
        },
        transition: "width 0.2s",
        willChange: "width",
      },
      drawerPaper: {
        backgroundColor: theme.palette.darkBackground.main,
        width: drawerWidth,
        transition: "width 0.2s",
        zIndex: 1045,
        overflow: "visible",
      },
      toolbarStyles: {
        position: "relative",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: 52,
      },
      content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(1.5),
        },
        minWidth: 0,
      },
      clickable: {
        cursor: "pointer",
        margin: "auto 0",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer - 6,
        color: "#fff",
      },
    }),
  );
};

const AppSidebar: FC = ({ children }) => {
  const isCollapsedByDefault =
    localStorage.getItem("isSidebarCollapsed") === "true";
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedByDefault);
  const theme = useTheme();
  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };
  const classes = useStyles(isCollapsed)();
  const navigate = useNavigate();
  const goHome = () => navigate("/");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.only("md"));
  const { notificationsUiEnabled } = useFlags();
  const { active = true } = useCurrentOrg();

  const handleExpandedDrawerClick = useCallback(() => {
    if (!matches && !matchesMd) return;
    setMobileOpen((prev) => {
      if (prev) return false;
      return prev;
    });
    if (matchesMd) setIsCollapsed(true);
  }, [matches, matchesMd]);

  useEffect(() => {
    if (matches) setIsCollapsed(false);
    else if (matchesMd) setIsCollapsed(true);
    else setIsCollapsed(isCollapsedByDefault);
  }, [matches, isCollapsedByDefault, matchesMd]);

  const handleDrawerClick = useCallback(
    () =>
      setIsCollapsed((prev) => {
        if (matchesMd) return false;
        localStorage.setItem("isSidebarCollapsed", prev ? "false" : "true");
        return !prev;
      }),
    [matchesMd],
  );

  const drawer = (
    <div>
      <Hidden smDown>
        <div className={classes.logoContainer}>
          <img
            className={classes.clickable}
            onClick={() => {
              goHome();
              handleExpandedDrawerClick();
            }}
            src={LWWhiteLogo}
            alt="lacework-iac-security-logo"
            height={36}
          />
        </div>
      </Hidden>
      <Hidden mdUp>
        <Toolbar variant="dense" className={classes.logoContainer} />
      </Hidden>
      <Divider />
      <AppMenu onClick={handleExpandedDrawerClick} isCollapsed={isCollapsed} />
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolbarStyles}>
          <Box overflow="hidden" display="flex">
            <WhatsNewDropdown />
            {notificationsUiEnabled && <NotificationsDropdown />}
            <ChangeOrgDropdown />
            <ProfileDropDown />
          </Box>
          <img
            onClick={goHome}
            src={LWLogo}
            className={classes.logoMobile}
            width={150}
            alt="lacework-iac-security-logo"
          />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={matches ? handleDrawerToggle : handleDrawerClick}
            className={classes.menuButton}
          >
            <MenuRounded />
          </IconButton>
        </Toolbar>
        {!active && (
          <Alert
            style={{
              borderRadius: 0,
              border: `1px ${theme.palette.warning.light}`,
              borderStyle: "solid solid solid none",
            }}
            // variant="outlined"
            severity="warning"
          >
            Trial has ended. No new assesments will be run. To continue running
            assesments, please contact{" "}
            <a
              href="mailto:support@lacework.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@lacework.com
            </a>
          </Alert>
        )}
      </AppBar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="js">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Backdrop
          className={classes.backdrop}
          open={matchesMd && !isCollapsed}
          onClick={() => setIsCollapsed(true)}
        />
        {/* necessary for content to be below app bar */}
        {!active && <Toolbar variant="dense" />}
        <Toolbar variant="dense" />
        {children}
      </main>
    </div>
  );
};
export default AppSidebar;
