import React, { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { Box, Breadcrumbs, Button, Card, Typography } from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import CodeSnipped from "components/CodeSnipped";
import StepNumber from "components/ListGroupSteps";
import PageHeader from "components/PageHeader";

const AddZodiac: FC = () => {
  const orgId = useCurrentOrgId();

  return (
    <Fragment>
      <PageHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          Add Zodiac
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to="/zodiac">
              Zodiac
            </Link>
            <Typography color="textPrimary">Add Zodiac</Typography>
          </Breadcrumbs>
        </Box>
      </PageHeader>
      <Box component={Card} p={2}>
        <Box py={3}>
          <Typography variant="h5" color="primary">
            <StepNumber>1</StepNumber> Download Kubernetes Manifest
          </Typography>
          <Box pl={4} pt={2}>
            <Button
              color="primary"
              variant="outlined"
              href={`/api/v1/org/${orgId}/zodiac/manifest`}
            >
              Download Zodiac YAML
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography variant="h5" color="primary">
            <StepNumber>2</StepNumber>Deploy Agent
          </Typography>
          <Box pl={4} py={2}>
            Deploy the Agent in your cluster with Kubectl. Run:
            <CodeSnipped>kubectl apply -f zodiac.yaml</CodeSnipped>
          </Box>
        </Box>
        <Box>
          <Typography variant="h5" color="primary">
            <StepNumber>3</StepNumber> Confirm
          </Typography>
          <Box pl={4} py={2}>
            Zodiac should start and connect to Lacework IaC Security. Once it is
            up and running, you will need to configure it to communicate with
            your Git hosting provider.
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AddZodiac;
