import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import Notifications from "./pages/Notifications/Notifications";
import HasFlagRoute from "router/HasFlagRoute";

const NotificationsRouter: FC<Childless> = () => {
  return (
    <Routes>
      <Route
        path=""
        element={
          <HasFlagRoute
            flag="notificationsUiEnabled"
            element={<Notifications />}
          />
        }
      />
    </Routes>
  );
};

export default NotificationsRouter;
