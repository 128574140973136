import React, { FC, Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ClickAwayListener,
  createStyles,
  Grow,
  Hidden,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
} from "@material-ui/core";
import { PersonOutline } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useLogout, useUser } from "providers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: "none",
      fontSize: 11.5,
      color: "rgba(0,0,0,.75)",
      [theme.breakpoints.up("md")]: {
        borderRadius: theme.spacing(1),
      },
    },
    root: {
      display: "flex",
    },
    item: {
      fontSize: 11.5,
    },
    icon: {
      marginLeft: 8,
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }),
);

const ProfileDropDown: FC = () => {
  const classes = useStyles();

  const user = useUser();
  const logout = useLogout();
  const { profileProfilePageEnabled, profileEmailSupportEnabled } = useFlags();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        className={classes.button}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Hidden smDown implementation="css">
          {user &&
          user.displayName &&
          user.displayName !== "null" &&
          user.displayName !== "" ? (
            user.displayName
          ) : user.email && user.email !== "null" && user.email !== "" ? (
            user.email
          ) : (
            <PersonOutline style={{ color: "#757575" }} />
          )}
          <FontAwesomeIcon className={classes.icon} icon={faCaretDown} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <PersonOutline style={{ color: "#757575" }} />
        </Hidden>
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {profileProfilePageEnabled && (
                    <Link style={{ textDecoration: "none" }} to="/profile">
                      <MenuItem className={classes.item} onClick={handleClose}>
                        Profile
                      </MenuItem>
                    </Link>
                  )}
                  {profileEmailSupportEnabled && (
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto:support@lacework.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MenuItem className={classes.item} onClick={handleClose}>
                        Email Support
                      </MenuItem>
                    </a>
                  )}
                  <MenuItem className={classes.item} onClick={logout}>
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default ProfileDropDown;
