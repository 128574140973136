import React, { FC, useContext, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Box,
  Card,
  createStyles,
  Fade,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { AssessmentDetailsContext } from "../context/AssessmentDetailsContext";
import ChartWrapper from "components/ChartWrapper/ChartWrapper";
import { generatePastelColorFromString } from "helpers/colorHelpers";
import { useQueryState } from "hooks/useQueryState";
import { COLORS_FAILURES } from "types/colors";

interface QueryState {
  severity?: string;
  compliance?: string;
  pass?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertRoot: { borderRadius: 0 },
  }),
);

const DetailMetrics: FC = () => {
  const { assessmentDetail, isLoading } = useContext(AssessmentDetailsContext);
  const classes = useStyles();
  const { setQueryState } = useQueryState<QueryState>("assessment-details");
  const COLORS_TOTAL = ["#77dd77", "#ff6961"];
  const SEVERITIES = ["Critical", "High", "Medium", "Low", "Info"];
  const failureText =
    assessmentDetail?.module === "tfplan"
      ? "Findings"
      : assessmentDetail?.category === "imageScan"
      ? "Vulnerabilities"
      : "Violations";

  const compliancesMap = useMemo(() => {
    const compliances =
      assessmentDetail?.findings
        .map((f) => (!f.pass ? f.compliance ?? [] : []))
        .flat()
        .filter((f) => f) ?? [];
    const uniqueCompliances = [...new Set(compliances)].filter(
      (f) => f !== "No Compliance",
    );

    const res = uniqueCompliances.reduce(
      (acc: { [key: string]: number }, curr) => (
        // eslint-disable-next-line no-sequences
        (acc[curr + ""] = compliances.filter((f) => f === curr).length), acc
      ),
      {},
    );
    return res;
  }, [assessmentDetail]);

  const compliancesLabel = useMemo(
    () => Object.keys(compliancesMap),
    [compliancesMap],
  );

  const totalComplianceData = useMemo(
    () => Object.values(compliancesMap),
    [compliancesMap],
  );

  const backgroundColors = useMemo(
    () => compliancesLabel.map((e) => generatePastelColorFromString(e)),
    [compliancesLabel],
  );

  const totalPieData = useMemo(
    () => [
      assessmentDetail?.metrics?.findingsPass ?? null,
      assessmentDetail?.metrics?.findingsFail ?? null,
    ],
    [assessmentDetail?.metrics],
  );
  const failuresPieData = useMemo(
    () => [
      assessmentDetail?.metrics?.findingsFailCritical ?? null,
      assessmentDetail?.metrics?.findingsFailHigh ?? null,
      assessmentDetail?.metrics?.findingsFailMedium ?? null,
      assessmentDetail?.metrics?.findingsFailLow ?? null,
      assessmentDetail?.metrics?.findingsFailInfo ?? null,
    ],
    [assessmentDetail?.metrics],
  );

  if (
    assessmentDetail?.metrics?.findings === 0 &&
    assessmentDetail?.metrics?.suppressedFindings === 0
  )
    return null;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={assessmentDetail?.findingsIncludePassedChecks ? 12 : 6}
        lg={assessmentDetail?.findingsIncludePassedChecks ? 4 : 6}
      >
        <Box
          component={Card}
          height="100%"
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography variant="h1" color="textSecondary">
            {isLoading ? (
              <Skeleton width={40} />
            ) : (
              assessmentDetail?.metrics?.suppressedFindings ?? "-"
            )}
          </Typography>
          <Typography variant="h4">{`Suppressed`}</Typography>
        </Box>
      </Grid>
      {assessmentDetail?.findingsIncludePassedChecks ? (
        <Grid item xs={12} lg={8}>
          <Box component={Card} height="100%">
            <Box p={2}>
              <Typography variant="h4" gutterBottom>
                {`${assessmentDetail?.metrics?.findings ?? ""} Total`}
              </Typography>
              <Fade in={!!totalPieData}>
                <ChartWrapper
                  type="doughnut"
                  height={200}
                  data={{
                    datasets: [
                      {
                        data: totalPieData,
                        backgroundColor: COLORS_TOTAL,
                        borderColor: "#d9dce0",
                        borderWidth: 0.5,
                      },
                    ],
                    labels: ["Passed", "Failed"],
                  }}
                  options={{
                    onClick: (_e, elements, chart) => {
                      const index = elements[0]?.index ?? undefined;
                      if (index === undefined) return;
                      const label = chart.data.labels?.[index] + "";
                      setQueryState({
                        pass: (label === "Passed") + "",
                        severity: undefined,
                        compliance: undefined,
                      });
                    },
                    onHover: (evt, activeEls) => {
                      activeEls.length > 0
                        ? (evt.chart.canvas.style.cursor = "pointer")
                        : (evt.chart.canvas.style.cursor = "default");
                    },
                    layout: {
                      padding: {
                        top: 20,
                        bottom: 20,
                      },
                    },
                    plugins: {
                      legend: {
                        position: "right",
                      },
                    },
                    maintainAspectRatio: false,
                  }}
                />
              </Fade>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12} md={6}>
          <Box
            component={Card}
            height="100%"
            p={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h1" color="error">
              {isLoading ? (
                <Skeleton width={40} />
              ) : (
                assessmentDetail?.metrics?.findingsFail ?? "-"
              )}
            </Typography>
            <Typography variant="h4">{failureText}</Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <Box component={Card} height="100%">
          <Box p={2}>
            <Typography variant="h4" gutterBottom>
              {failureText} by severity
            </Typography>
            {assessmentDetail?.metrics?.findingsFail === 0 ? (
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Alert className={classes.alertRoot} color="info">
                  No {failureText} found.
                </Alert>
              </Box>
            ) : (
              <Fade in={!!failuresPieData}>
                <div>
                  <ChartWrapper
                    type="doughnut"
                    height={200}
                    data={{
                      datasets: [
                        {
                          data: failuresPieData,
                          backgroundColor: Object.values(COLORS_FAILURES),
                          borderColor: "#d9dce0",
                          borderWidth: 0.5,
                        },
                      ],
                      labels: SEVERITIES,
                    }}
                    options={{
                      onClick: (_e, elements, chart) => {
                        const index = elements[0]?.index ?? undefined;
                        if (index === undefined) return;
                        const label = chart.data.labels?.[index] + "";
                        setQueryState({
                          pass: "false",
                          severity: label,
                          compliance: undefined,
                        });
                      },
                      onHover: (evt, activeEls) => {
                        activeEls.length > 0
                          ? (evt.chart.canvas.style.cursor = "pointer")
                          : (evt.chart.canvas.style.cursor = "default");
                      },
                      layout: {
                        padding: {
                          top: 20,
                          bottom: 20,
                        },
                      },
                      plugins: {
                        legend: {
                          position: "right",
                        },
                      },
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </Fade>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box component={Card} height="100%">
          <Box p={2} height="100%">
            <Typography variant="h4" gutterBottom>
              Compliance {failureText}
            </Typography>
            {totalComplianceData.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                minHeight={100}
              >
                <Alert className={classes.alertRoot} color="info">
                  No Compliance {failureText} found.
                </Alert>
              </Box>
            ) : (
              <Fade in={!!failuresPieData}>
                <div>
                  <ChartWrapper
                    type="doughnut"
                    height={200}
                    data={{
                      datasets: [
                        {
                          data: totalComplianceData,
                          backgroundColor: backgroundColors,
                          borderColor: "#d9dce0",
                          borderWidth: 0.5,
                        },
                      ],
                      labels: compliancesLabel,
                    }}
                    options={{
                      onClick: (_e, elements, chart) => {
                        const index = elements[0]?.index ?? undefined;
                        if (index === undefined) return;
                        const label = chart.data.labels?.[index] + "";
                        setQueryState({
                          pass: "false",
                          severity: undefined,
                          compliance: label,
                        });
                      },
                      onHover: (evt, activeEls) => {
                        activeEls.length > 0
                          ? (evt.chart.canvas.style.cursor = "pointer")
                          : (evt.chart.canvas.style.cursor = "default");
                      },

                      layout: {
                        padding: {
                          top: 20,
                          bottom: 20,
                        },
                      },
                      plugins: {
                        legend: {
                          position: "right",
                        },
                      },
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </Fade>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DetailMetrics;
