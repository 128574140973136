import React, { FC, Fragment } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Box, Card, LinearProgress } from "@material-ui/core";
import { Alert, AlertTitle, TabContext, TabPanel } from "@material-ui/lab";
import { upperFirst } from "lodash";
import useCommonStyles from "theme/useCommonStyles";

import { useCurrentOrgId } from "providers";

import RepositoryDetailBranches from "./components/RepositoryBranches/RepositoryBranches";
import RepositoryDetailHeader from "./components/RepositoryDetailHeader";
import RepositoryJobs from "./components/RepositoryDetailJobs/RepositoryJobs";
import RepositoryDetailOverview from "./components/RepositoryDetailOverview";
import RepositoryTabs, { RepoInsightTabs } from "./components/RepositoryTabs";
import ReactMarkdown from "components/ReactMarkdown/ReactMarkdown";
import repositoriesService from "services/repositoryService";

const RepositoryDetail: FC = () => {
  const { gitRepoName = "", rTab = RepoInsightTabs.Overview } = useParams<{
    gitRepoName: string;
    rTab: string;
  }>();
  const orgId = useCurrentOrgId();
  const gitRepo = decodeURIComponent(gitRepoName);
  const classes = useCommonStyles();

  const { data: status, isLoading: isStatusLoading } = useQuery(
    ["repo-insights-status", orgId, gitRepo],
    () => repositoriesService.getRepoInsightsStatus(orgId, gitRepo),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  return (
    <Fragment>
      <RepositoryDetailHeader gitRepo={gitRepo} status={status?.state} />
      <RepositoryTabs tab={rTab} />
      {isStatusLoading ? (
        <Box component={Card} p={2}>
          <LinearProgress color="secondary" />
        </Box>
      ) : (
        <Fragment>
          {(status?.state === "failed" || status?.state === "warning") &&
          rTab !== RepoInsightTabs.Branches ? (
            <Card>
              <Alert severity={status.state === "failed" ? "error" : "warning"}>
                <AlertTitle>{upperFirst(status.state)}</AlertTitle>
                <ReactMarkdown>{status?.message}</ReactMarkdown>
              </Alert>
            </Card>
          ) : (
            <TabContext value={rTab}>
              <TabPanel
                className={classes.tabPanel}
                value={RepoInsightTabs.Overview}
              >
                <RepositoryDetailOverview
                  gitRepoName={gitRepo}
                  status={status}
                  isStatusLoading={isStatusLoading}
                />
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={RepoInsightTabs.Jobs}
              >
                <RepositoryJobs />
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={RepoInsightTabs.Branches}
              >
                <RepositoryDetailBranches />
              </TabPanel>
            </TabContext>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default RepositoryDetail;
