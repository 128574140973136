import React, { FC, Fragment } from "react";
import { Navigate } from "react-router-dom";

export interface CanActivateRouteProps {
  canActivate: boolean;
  redirectTo?: string;
  element: React.ReactNode;
}

const CanActivateRoute: FC<CanActivateRouteProps> = ({
  canActivate,
  element,
  redirectTo,
}) => {
  return canActivate ? (
    <Fragment>{element}</Fragment>
  ) : (
    <Navigate to={redirectTo ?? "/404"} />
  );
};

export default CanActivateRoute;
