import React, { FC } from "react";

import { ReactComponent as AnalyticsIcon } from "assets/svg/icons/analytics.svg";
import { ReactComponent as AssessmentsIcon } from "assets/svg/icons/assessments.svg";
import { ReactComponent as CIPipelineIcon } from "assets/svg/icons/ci-cd-pipeline.svg";
import { ReactComponent as CommandLineIcon } from "assets/svg/icons/cli.svg";
import { ReactComponent as DashboardIcon } from "assets/svg/icons/dashboard.svg";
import { ReactComponent as DocsIcon } from "assets/svg/icons/docs.svg";
import { ReactComponent as GitReposIcon } from "assets/svg/icons/git-repos.svg";
import { ReactComponent as InfoIcon } from "assets/svg/icons/info.svg";
import { ReactComponent as IntegrationsIcon } from "assets/svg/icons/integrations.svg";
import { ReactComponent as KubernetesIcon } from "assets/svg/icons/kubernetes.svg";
import { ReactComponent as LogsIcon } from "assets/svg/icons/logs.svg";
import { ReactComponent as PoliciesIcon } from "assets/svg/icons/policies.svg";
import { ReactComponent as Robot } from "assets/svg/icons/robot.svg";
import { ReactComponent as ServicesIcon } from "assets/svg/icons/services.svg";
import { ReactComponent as SettingsIcon } from "assets/svg/icons/settings.svg";
import { ReactComponent as Support } from "assets/svg/icons/support.svg";
import { ReactComponent as TrendingArrowDown } from "assets/svg/icons/trend-arrow-down.svg";
import { ReactComponent as TrendingArrowUp } from "assets/svg/icons/trend-arrow-up.svg";
import { ReactComponent as TrendingDash } from "assets/svg/icons/trend-dash.svg";
import { ReactComponent as Usage } from "assets/svg/icons/usage.svg";
import { ReactComponent as Violations } from "assets/svg/icons/violations.svg";
import { ReactComponent as Zodiac } from "assets/svg/icons/zodiac.svg";

interface SvgIconProps extends React.SVGProps<SVGElement> {
  icon: string;
}

const Icons: { [id: string]: React.ReactNode } = {
  analytics: <AnalyticsIcon />,
  assessments: <AssessmentsIcon />,
  ciPipeline: <CIPipelineIcon />,
  cli: <CommandLineIcon />,
  dashboard: <DashboardIcon />,
  docs: <DocsIcon />,
  gitRepos: <GitReposIcon />,
  info: <InfoIcon />,
  integrations: <IntegrationsIcon />,
  kubernetes: <KubernetesIcon />,
  logs: <LogsIcon />,
  policies: <PoliciesIcon />,
  robot: <Robot />,
  services: <ServicesIcon />,
  settings: <SettingsIcon />,
  support: <Support />,
  trendArrowDown: <TrendingArrowDown />,
  trendArrowUp: <TrendingArrowUp />,
  trendDash: <TrendingDash />,
  usage: <Usage />,
  violations: <Violations />,
  zodiac: <Zodiac />,
};

const SvgIcon: FC<SvgIconProps> = ({ icon, ...rest }) => {
  const svgIcon = Icons[icon];
  return React.cloneElement(svgIcon as React.ReactElement, rest);
};

export default SvgIcon;
