import React, { FC, Fragment, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  Box,
  Breadcrumbs,
  Card,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { LaunchRounded } from "@material-ui/icons";

import { useCurrentOrgId } from "providers";

import PipelineAssessmentCollapse from "./components/PipelineRunCollapse";
import DefaultTablePagination from "components/DataTable/components/DefaultTablePagination";
import SearchBar from "components/DataTable/components/SearchBar";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import LogoBadge from "components/LogoBadge";
import PageHeader from "components/PageHeader";
import SvgIcon from "components/SvgIcon/SvgIcon";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import pipelinesService from "services/pipelinesService";
import { PipelineAssessment } from "types/assessments";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    latest: {
      backgroundColor: theme.palette.grey[600],
      color: "white",
      fontWeight: "bolder",
      verticalAlign: "center",
      fontSize: 10,
      width: 50,
      textAlign: "center",
      position: "absolute",
    },
  }),
);

const columns: Column<PipelineAssessment>[] = [
  {
    Header: "Repo",
    accessor: "ciPipelineName",
    Cell: ({ row: { original: row } }) => (
      <a href={row.ciPipelineUrl} rel="noreferrer noopener" target="_blank">
        {row.ciPipelineName}
      </a>
    ),
  },
  {
    Header: "Platform",
    accessor: "ciPlatform",
  },
  {
    Header: "Repo",
    accessor: "gitRepo",
  },
  {
    Header: "Branch",
    accessor: "gitBranch",
  },
  {
    Header: "Commit",
    accessor: "gitCommit",
  },
  {
    Header: "Ts",
    accessor: "title",
  },
];

const PipelineDetails: FC = () => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const { ciPipelineId = "" } = useParams<{ ciPipelineId: string }>();
  const navigate = useNavigate();

  const { data = [], isLoading } = useQuery(
    ["pipeline", ciPipelineId],
    () => pipelinesService.getPipelineRuns(orgId, ciPipelineId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const properties = {
    name: data?.[0]?.ciPipelineName ?? "",
    url: data?.[0]?.ciPipelineUrl ?? "",
    platform: data?.[0]?.ciPlatform ?? "",
  };

  const tableInstance = useTable(
    {
      data,
      columns,
      initialState: {
        pageSize: 10,
      },
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const tableRef = useRef<DataTableRef<PipelineAssessment>>({
    tableInstance,
  });

  const {
    page: runs,
    filteredRows,
    setGlobalFilter,
    gotoPage,
    state,
  } = tableInstance;

  const handlePageChange = (_: unknown, newPage: number) => {
    gotoPage(newPage);
  };

  const handleSearchChange = (filterValue: string) => {
    setGlobalFilter(filterValue);
    gotoPage(0);
  };

  useEffect(() => {
    if (ciPipelineId === "") {
      return navigate("/pipelines");
    }
  }, [ciPipelineId, navigate]);

  return (
    <Fragment>
      <PageHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          Pipeline Runs
          {!isLoading && (
            <Breadcrumbs separator="/" aria-label="breadcrumb">
              <Link color="inherit" to="/pipelines/">
                Pipelines
              </Link>
              <Typography color="textPrimary">{properties.name}</Typography>
            </Breadcrumbs>
          )}
        </Box>
      </PageHeader>
      <Box display="flex" component={Card} p={2} mb={2}>
        {isLoading ? (
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Skeleton width={28} height={28} circle />
            </Box>
            <Skeleton width={150} />
          </Box>
        ) : (
          <>
            <Box mr={1}>
              <LogoBadge width={28} logo={properties.platform} />
            </Box>
            <Box fontSize={18} mr={1}>
              {properties.name}
            </Box>
            <IconButton
              color="primary"
              component="a"
              size="small"
              href={properties.url}
              rel="noreferrer noopener"
              target="_blank"
            >
              <LaunchRounded fontSize="small" />
            </IconButton>
          </>
        )}
      </Box>
      <Box component={Card} p={3}>
        {isLoading ? (
          [...Array(5)].map((_e, i) => (
            <Grid key={i} item xs={12}>
              <Box mb={2}>
                <Card variant="outlined">
                  <Box p={3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={7} md={6} lg={5}>
                        <Skeleton width="65%" />
                        <Skeleton width="80%" />
                      </Grid>
                      <Grid item xs={12} sm={3} md={4} lg={5}>
                        <Skeleton width="80%" />
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Box>
            </Grid>
          ))
        ) : (
          <Fragment>
            <Box
              mb={2}
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Box ml={-2} display="flex" alignItems="center">
                <SearchBar initialValue="" onChange={handleSearchChange} />
                <FiltersModal
                  title="Filter Runs"
                  fields={[
                    {
                      displayName: "Repository",
                      type: "textfield",
                      key: "gitRepo",
                    },
                    {
                      displayName: "Branch",
                      type: "textfield",
                      key: "gitBranch",
                    },
                    {
                      displayName: "Commit",
                      type: "textfield",
                      key: "gitCommit",
                    },
                  ]}
                  tableRef={tableRef}
                />
              </Box>
              <DefaultTablePagination
                pageIndex={state.pageIndex}
                count={filteredRows.length}
                onChangePage={handlePageChange}
                pageSize={10}
                pageSizeOptions={[]}
              />
            </Box>
            {runs.length === 0 ? (
              <Box width="100%" textAlign="center">
                <SvgIcon
                  width={80}
                  height={80}
                  color="lightgray"
                  icon="ciPipeline"
                />
                <Typography align="center">
                  There are no runs that match the current query.
                </Typography>
              </Box>
            ) : (
              runs.map((run, i) => (
                <Box mb={2} key={run.id}>
                  {i === 0 && (
                    <Box className={classes.latest} ml={-1}>
                      LATEST
                    </Box>
                  )}
                  <PipelineAssessmentCollapse pipeline={run.original} />
                </Box>
              ))
            )}
            <DefaultTablePagination
              pageIndex={state.pageIndex}
              count={filteredRows.length}
              onChangePage={handlePageChange}
              pageSize={10}
              pageSizeOptions={[]}
            />
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default PipelineDetails;
