import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, useTheme } from "@material-ui/core";
import {
  Cancel,
  Error,
  Info,
  ScheduleOutlined,
  Warning,
} from "@material-ui/icons";
import useCommonStyles from "theme/useCommonStyles";

import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import {
  Notification as NotificationI,
  NotificationType,
} from "types/notifications";

interface NotificationDescriptionProps {
  notification: NotificationI;
  expanded?: boolean;
  hideHeader?: boolean;
  unread?: boolean;
}

const NotificationDescription: FC<NotificationDescriptionProps> = ({
  notification,
  expanded,
  hideHeader,
  unread,
}) => {
  const commonStyles = useCommonStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Box display="flex">
        {!hideHeader && <NotificationTypeIcon type={notification.type} />}
        <Box display="flex" flexDirection="column">
          {notification.title && !hideHeader && (
            <Typography variant="h6">{notification.title}</Typography>
          )}
          {notification.url ? (
            <Link to={notification.url}>
              <Typography className={commonStyles.wordBreakAll} variant="body1">
                {expanded
                  ? notification.body
                  : notification.body.length > 140
                  ? notification.body.slice(0, 140) + "..."
                  : notification.body}
              </Typography>
            </Link>
          ) : (
            <Typography className={commonStyles.wordBreakAll} variant="body1">
              {expanded
                ? notification.body
                : notification.body.length > 140
                ? notification.body.slice(0, 140) + "..."
                : notification.body}
            </Typography>
          )}
          <Box display="flex" alignItems="center">
            <Box
              style={{ opacity: 0.7 }}
              component={ScheduleOutlined}
              mr={1}
              fontSize={18}
            />
            <RelativeTimeFormatter dateTs={notification.createTs} />
            {expanded && (
              <Box ml={2} my={0}>
                {notification.source && (
                  <Box display="flex">
                    <Box component="dt" mr={1}>
                      Source
                    </Box>
                    <Box mr={2} my={0}>
                      {notification.source}
                    </Box>
                  </Box>
                )}
                {notification.resource && (
                  <Box display="flex">
                    <Box component="dt" mr={1}>
                      Resource
                    </Box>
                    <Box my={0}>{notification.resource}</Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {unread && (
        <Box
          m={1}
          height={8}
          width={8}
          bgcolor="secondary.main"
          borderRadius="100%"
        />
      )}
    </Box>
  );
};

export const NotificationTypeIcon: FC<{ type: NotificationType }> = ({
  type,
}) => {
  const theme = useTheme();

  switch (type) {
    case "Critical":
      return <Box component={Error} color={theme.palette.error.dark} mr={1} />;
    case "Error":
      return <Box component={Cancel} color={theme.palette.error.main} mr={1} />;
    case "Warning":
      return (
        <Box component={Warning} color={theme.palette.warning.main} mr={1} />
      );

    default:
      return <Box component={Info} color={theme.palette.info.main} mr={1} />;
  }
};

export default NotificationDescription;
