import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { ReactComponent as Git } from "assets/svg/illustrations/version-control.svg";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import LogoBadge from "components/LogoBadge";
import { useInterval } from "hooks/useInterval";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    illustration: {
      width: 450,
      height: 450,
      marginTop: theme.spacing(3),
    },
  }),
);

const SuccessBitBucket: FC = () => {
  const classes = useStyles();
  const [seconds, setSeconds] = useState(10);
  const navigate = useNavigate();

  useInterval(() => {
    setSeconds((prev) => prev - 1);
  }, 1000);

  useEffect(() => {
    if (seconds === 0) navigate("/dashboard");
  }, [seconds, navigate]);

  return (
    <NavbarTopLayout white>
      <div className={classes.root}>
        <Git className={classes.illustration} />
        <Box mb={4}>
          <LogoBadge logo="bitbucket-full" width={150} />
        </Box>
        <Box component="h3" color="primary.main" mb={3} textAlign="center">
          You have successfully installed the Lacework IaC Security BitBucket
          App!
        </Box>
        <Typography variant="body2" color="textSecondary">
          You will be redirected in {seconds}s ...
        </Typography>
      </div>
    </NavbarTopLayout>
  );
};

export default SuccessBitBucket;
