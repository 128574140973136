import React, { FC } from "react";
import {
  Box,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { LaunchRounded } from "@material-ui/icons";
import { upperFirst } from "lodash";

import { useHasScope } from "providers";

import ProviderIconLabel from "./ProviderIconLabel";
import Badge from "components/BootstrapBadge/BootstrapBadge";
import SeverityComponent, {
  Severity,
} from "components/SeverityComponent/SeverityComponent";
import TimestampFormatter from "components/TimestampFormatter";
import { Permissions, Resources } from "types/auth-roles";
import { Policy } from "types/policies";

interface PolicyConfigProps {
  policy: Policy;
  handlers: {
    handleOnSeverityChange: (
      policy: Policy,
      severity: keyof typeof Severity,
    ) => void;
    handleOnPolicyToggle: (policy: Policy) => void;
  };
}

const PolicyConfig: FC<PolicyConfigProps> = ({ policy, handlers }) => {
  const hasOrgOwnerScope = useHasScope(
    Resources.Organization,
    Permissions.Owner,
  );

  return (
    <Box>
      <Box display="flex" my={1}>
        <Box component="dt" width={130}>
          SID
        </Box>
        <Box>{policy.sid}</Box>
      </Box>
      <Box display="flex" mb={1}>
        <Box component="dt" width={130}>
          Enabled
        </Box>
        <Box>
          <Tooltip
            arrow
            title={
              !hasOrgOwnerScope
                ? "Insufficient permissions."
                : policy.enabled
                ? "Disabling the policy suppresses the corresponding findings in all Assessments."
                : "Enabling the policy un-suppresses the corresponding findings in all Assessments."
            }
          >
            <span>
              <Switch
                checked={policy.enabled}
                onChange={(event) => {
                  handlers.handleOnPolicyToggle(policy);
                }}
                disabled={!hasOrgOwnerScope}
                id="policy-toggle-switch"
                name="policy-toggle"
                color="primary"
              />
            </span>
          </Tooltip>
        </Box>
      </Box>
      <Box display="flex" mb={2}>
        <Box component="dt" width={130}>
          Severity
        </Box>
        <Box>
          {hasOrgOwnerScope ? (
            <Box onClick={(e) => e.stopPropagation()}>
              <Select
                variant="outlined"
                fullWidth
                onChange={({ target: { value } }) => {
                  const severity = value as keyof typeof Severity;
                  handlers.handleOnSeverityChange(policy, severity);
                }}
                value={policy.severity ? upperFirst(policy.severity) : "Info"}
                classes={{ root: "custom-select" }}
                renderValue={(selected) => (
                  <SeverityComponent
                    severity={Severity[selected as keyof typeof Severity]}
                  />
                )}
              >
                {(Object.keys(Severity) as (keyof typeof Severity)[]).map(
                  (n, i) => (
                    <MenuItem value={n} key={i}>
                      <ListItemText
                        primary={
                          <Box mr={2}>
                            <SeverityComponent severity={Severity[n]} />
                          </Box>
                        }
                      />
                    </MenuItem>
                  ),
                )}
              </Select>
            </Box>
          ) : (
            <SeverityComponent
              severity={
                Severity[
                  policy.severity
                    ? (upperFirst(policy.severity) as keyof typeof Severity)
                    : "Info"
                ]
              }
            />
          )}
        </Box>
      </Box>
      <Box display="flex" mb={2}>
        <Box component="dt" width={130}>
          Provider
        </Box>
        <Box>
          <ProviderIconLabel provider={policy.provider} />
        </Box>
      </Box>
      <Box display="flex" mb={2}>
        <Box component="dt" width={130}>
          Category
        </Box>
        <Box>
          <Badge color="secondary">{policy.category}</Badge>
        </Box>
      </Box>
      <Box display="flex" mb={2}>
        <Box component="dt" width={130}>
          Check Type
        </Box>
        <Box>{policy.checkType.join(", ")}</Box>
      </Box>
      <Box display="flex" mb={2}>
        <Box component="dt" width={130}>
          Last Update
        </Box>
        <Box>
          {policy.updateTs ? (
            <TimestampFormatter
              dateTs={policy.updateTs}
              disableCenterText
              oneLine
            />
          ) : (
            "-"
          )}
        </Box>
      </Box>
      <Box display="flex" mb={2}>
        <Box component="dt" width={130}>
          Compliance
        </Box>
        <Box>
          {!policy.compliance.includes("No Compliance") &&
          policy.compliance?.length > 0 ? (
            <Box display="flex" flexWrap="wrap" width={200} minWidth={100}>
              {policy.compliance.map((v) => (
                <Box key={v} mr={0.5}>
                  <Badge color="info">{v}</Badge>
                </Box>
              ))}
            </Box>
          ) : (
            "-"
          )}
        </Box>
      </Box>
      {policy.source && (
        <Box display="flex" mb={2}>
          <Box component="dt" width={130}>
            Source
          </Box>
          <Box>
            <a
              href={policy.sourceUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {policy.source}
              <Box display="inline-block" ml={1}>
                <LaunchRounded fontSize="inherit" />
              </Box>
            </a>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PolicyConfig;
