import React, { FC } from "react";
import { Box, Card } from "@material-ui/core";

import MissingEmailForm from "./components/MissingEmailForm";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";

const MissingEmail: FC<Childless> = () => {
  return (
    <NavbarTopLayout disableLink>
      <Box display="flex" p={2} justifyContent="center" alignItems="center">
        <Box
          width={450}
          component={Card}
          p={3}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <MissingEmailForm />
        </Box>
      </Box>
    </NavbarTopLayout>
  );
};

export default MissingEmail;
