import React, { FC, Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import ProviderIconLabel from "./ProviderIconLabel";
import Badge from "components/BootstrapBadge/BootstrapBadge";
import { optimisticDatasetUpdater } from "helpers/queryHelpers";
import policiesService from "services/policiesService";
import { Policy } from "types/policies";

interface PolicyToggleModalProps {
  policy?: Policy;
  query?: string;
  onClose(): void;
}

interface PolicyToggleModalForm {
  comment: string;
}

const PolicyToggleModal: FC<PolicyToggleModalProps> = ({
  policy,
  query,
  onClose,
}) => {
  const orgId = useCurrentOrgId();
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, errors } = useForm<PolicyToggleModalForm>({
    defaultValues: { comment: "" },
    mode: "onSubmit",
  });
  const actionText = policy?.enabled ? "Disable" : "Enable";

  const { mutate: togglePolicyStatus } = useMutation(
    ({ policy: p, comment }: { policy: Policy; comment: string }) =>
      policiesService.configPolicies(
        { orgId, sid: p.sid },
        { enabled: !p.enabled, comment },
      ),
    {
      onMutate: ({ policy: { enabled, sid } }) => {
        return optimisticDatasetUpdater<Policy, "sid">(
          ["policies", orgId, query ?? ""],
          { sid },
          { enabled: !enabled },
        );
      },
      onError: (_, { policy: { enabled } }, rollback: any) => {
        rollback?.();
        toast.error(
          `Something went wrong ${
            enabled ? "enabling" : "disabling"
          } the Policy. Please try again.`,
        );
      },
      onSuccess: ({ data: { updateTs } }, { policy: { sid } }) => {
        optimisticDatasetUpdater<Policy, "sid">(
          ["policies", orgId, query ?? ""],
          { sid },
          { updateTs },
        );
      },
    },
  );

  const onSubmit = ({ comment }: PolicyToggleModalForm) => {
    if (policy) togglePolicyStatus({ policy, comment });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (policy) setOpen(true);
  }, [policy]);

  return (
    <Fragment>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        onExited={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Box p={3} pb={0} display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            {actionText} Policy
          </Typography>
        </Box>
        <DialogContent>
          <Box p={2} mb={2} bgcolor="lightBackground.main" borderRadius={4}>
            <Box mb={1}>{policy?.title}</Box>
            <Box mb={1}>
              <Badge color="secondary">{policy?.category}</Badge>
            </Box>
            <Box mb={1}>
              <ProviderIconLabel provider={policy?.provider} />
            </Box>
          </Box>
          <form id="policytoggle" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter a comment.",
                },
                minLength: {
                  value: 12,
                  message: "Comments needs to be at least 12 characters long.",
                },
              }}
              as={TextField}
              label="Comment *"
              name="comment"
              error={!!errors.comment}
              helperText={
                errors.comment?.message || "Enter at least 12 characters."
              }
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </form>
          <Box my={2}>
            <Typography variant="h6">
              Are you sure you want to {policy?.enabled ? "disable" : "enable"}{" "}
              this policy?
            </Typography>
          </Box>
          <Typography variant="body2">
            {policy?.enabled
              ? "Disabling the policy suppresses the corresponding findings in all Assessments."
              : "Enabling the policy un-suppresses the corresponding findings in all Assessments."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Box mr={2} display="inline-block">
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </Box>
            <Button
              color="primary"
              form="policytoggle"
              variant="contained"
              type="submit"
            >
              {actionText}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PolicyToggleModal;
