/* istanbul ignore file */
import React, { FC } from "react";
import { Link } from "react-router-dom";
import {
  ClickAwayListener,
  createStyles,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
    },
    menuListRoot: {
      minWidth: 100,
    },
    compactIcon: {
      fontSize: "1.2rem",
    },
    compactIconButton: {
      padding: 0,
    },
    popper: {
      zIndex: 1050,
    },
  }),
);

interface DropdownItemAction extends MenuItemProps {
  label: React.ReactNode;
  action(): void;
  disabled?: boolean;
  linkTo: never;
  externalLinkTo: never;
  hover?: string;
}

interface DropdownItemLink extends MenuItemProps {
  label: React.ReactNode;
  action?(): void;
  linkTo?: string;
  externalLinkTo?: string;
  hover?: string;
}

export type DropdownItem = DropdownItemAction | DropdownItemLink;

interface DropdownActionProps {
  items?: (DropdownItemAction | DropdownItemLink)[];
}

const DropdownActions: FC<DropdownActionProps> = ({ items = [] }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root} onClick={(e) => e.stopPropagation()}>
      <IconButton
        className={classes.compactIconButton}
        size="small"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        data-testid="dropdown-actions"
        onClick={handleToggle}
      >
        <MoreVert className={classes.compactIcon} />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom"
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className={classes.menuListRoot}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {items.length === 0 ? (
                    <MenuItem disabled>
                      <Typography variant="caption">
                        No actions available
                      </Typography>
                    </MenuItem>
                  ) : (
                    items.map(
                      (
                        {
                          action,
                          linkTo,
                          externalLinkTo,
                          label,
                          disabled,
                          hover = "",
                        },
                        index,
                      ) =>
                        linkTo ? (
                          <Tooltip key={index} title={hover} arrow>
                            <span>
                              <MenuItem
                                disabled={disabled}
                                component={Link}
                                onClick={handleClose}
                                to={linkTo}
                              >
                                <Typography
                                  variant="caption"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {label}
                                </Typography>
                              </MenuItem>
                            </span>
                          </Tooltip>
                        ) : externalLinkTo ? (
                          <Tooltip key={index} title={hover} arrow>
                            <span>
                              <MenuItem
                                component="a"
                                disabled={disabled}
                                onClick={handleClose}
                                href={externalLinkTo}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Typography
                                  variant="caption"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {label}
                                </Typography>
                              </MenuItem>
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip key={index} title={hover} arrow>
                            <div>
                              <MenuItem
                                disabled={disabled}
                                onClick={(e) => {
                                  handleClose(e);
                                  action?.();
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {label}
                                </Typography>
                              </MenuItem>
                            </div>
                          </Tooltip>
                        ),
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default DropdownActions;
