import React, { FC, Fragment } from "react";
import { useMatch } from "react-router-dom";
import { Container } from "@material-ui/core";

import AppSidebar from "components/AppSidebar/AppSidebar";
import Footer from "components/Footer";

const AppLayout: FC = ({ children }) => {
  const is404 = useMatch("/404");
  const isTest = useMatch("/test/*");
  const isSlackAuthorization = useMatch("/slack-user-auth/*");
  const isError = useMatch("/error/*");
  const isSuccess = useMatch("/success/*");
  const isGetStarted = useMatch("/get-started/*");
  const isCLIAuth = useMatch("/cli/callback/*");
  const isToS = useMatch("/tos/*");
  const isAddEmail = useMatch("/profile/add-email/*");
  const isVerifyEmail = useMatch("/profile/verify-email/*");
  const isConnectGitLab = useMatch("/connect-gitlab/*");
  const isConnectBitbucket = useMatch("/connect-bitbucket/*");

  if (
    is404 ||
    isTest ||
    isSuccess ||
    isSlackAuthorization ||
    isConnectBitbucket ||
    isError ||
    isCLIAuth ||
    isGetStarted ||
    isToS ||
    isVerifyEmail ||
    isAddEmail ||
    isConnectGitLab
  ) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Container maxWidth={false} disableGutters>
      <AppSidebar>
        {children}
        <Footer />
      </AppSidebar>
    </Container>
  );
};

export default AppLayout;
