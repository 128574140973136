import instance from "./axiosInstance";
import { APIResponse } from "types";
import { DashboardElementData, DashboardMetaData } from "types/dashboard";

const getDashboardElements = (orgId: string) =>
  instance
    .get<APIResponse<DashboardMetaData>>(`/org/${orgId}/dashboard-metadata`)
    .then((res) => res.data.data.elements);

const getDashboardElementData = (orgId: string, apiEndpoint: string) =>
  instance
    .get<APIResponse<DashboardElementData>>(
      `/org/${orgId}/dashboard/${apiEndpoint}`,
    )
    .then((res) => res.data);

export default {
  getDashboardElements,
  getDashboardElementData,
};
