import React, { FC } from "react";
import {
  Chip,
  ChipProps,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: { background: theme.palette.info.light, color: "white" },
    success: { background: theme.palette.success.light, color: "white" },
    error: { background: theme.palette.error.light, color: "white" },
    warning: { background: theme.palette.warning.light, color: "white" },
    primary: { background: theme.palette.primary.main, color: "white" },
    secondary: { background: theme.palette.secondary.main, color: "white" },
  }),
);

export type ColoredChipColor =
  | "default"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";

interface ColoredChipProps extends Omit<ChipProps, "color"> {
  color?: ColoredChipColor;
}

const ColoredChip: FC<ColoredChipProps> = (props) => {
  const classes = useStyles();
  return (
    <Chip
      {...props}
      className={classNames(
        {
          [classes.info]: props.color === "info",
          [classes.success]: props.color === "success",
          [classes.error]: props.color === "error",
          [classes.warning]: props.color === "warning",
          [classes.primary]: props.color === "primary",
          [classes.secondary]: props.color === "secondary",
        },
        props.className,
      )}
      color="default"
    />
  );
};

export default ColoredChip;
