import { FC } from "react";
import { useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import { Box, Button, Card, Typography } from "@material-ui/core";

import { useCurrentOrgId, useLogout, useUser } from "providers";

import { ReactComponent as Add } from "assets/svg/illustrations/add-user.svg";
import ChangeOrgDropdown from "components/AppSidebar/ChangeOrgDropdown/ChangeOrgDropdown";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import PageHeader from "components/PageHeader";
import integrationsService from "services/integrationsService";

const ConnectBitbucket: FC = () => {
  const logout = useLogout();
  const orgId = useCurrentOrgId();
  const { provider } = useUser();
  const isBitBucketProvider = provider?.toLocaleLowerCase() === "bitbucket";
  const { data: completed, isLoading: isStatusLoading } = useQuery(
    ["bitbucket-integration-status", orgId],
    () => integrationsService.getBitBucketIntegrationStatus(orgId),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      enabled: isBitBucketProvider,
    },
  );
  const { data: URL } = useQuery(
    ["bitbucket-integration-connection-url", orgId],
    () => integrationsService.getBitBucketConnectionURL(orgId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: isBitBucketProvider,
    },
  );

  if (!isBitBucketProvider) return <Navigate to="/dashboard" replace />;

  if (completed?.completed && !isStatusLoading)
    return <Navigate to="/success/bitbucket-app-install" replace />;

  return (
    <NavbarTopLayout disableLink>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box maxWidth={600}>
          <Box component={Card} p={2}>
            <Box my={2}>
              <PageHeader>
                <Box display="flex" justifyContent="space-between">
                  Connect Bitbucket
                  <ChangeOrgDropdown />
                </Box>
              </PageHeader>
            </Box>
            <Box my={1}>
              <Typography>
                Bitbucket integration enables Lacework IaC Security to scan
                Infrastructure-as-code projects.
              </Typography>
              <Typography gutterBottom>
                Lacework IaC Security finds security issues or
                mis-configurations in Infrastructure-as-Code (IaC) written in
                AWS CloudFormation, HashiCorp Terraform and Kubernetes
                Manifests.
              </Typography>
              <Box textAlign="center">
                <Box my={4} component={Add} width={270} height="auto" />
              </Box>
            </Box>
            <Box textAlign="center" mb={2} ml={1}>
              <Button
                color="primary"
                variant="outlined"
                component="a"
                href={URL?.connectUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Connect Bitbucket
              </Button>
            </Box>
            <Button color="primary" onClick={logout}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </NavbarTopLayout>
  );
};

export default ConnectBitbucket;
