import React, { FC, Fragment } from "react";

interface IdTruncatorProps {
  id: string;
}

const IdTruncator: FC<IdTruncatorProps> = ({ id }) => {
  const slice = id.slice(0, 12);

  return <Fragment>{`${slice}`}</Fragment>;
};

export default IdTruncator;
