import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core";

const tableStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      userSelect: "none",
      position: "relative",
    },
    headerClickable: {
      "&:hover": {
        opacity: 0.6,
        "& > span.MuiBox-root": {
          opacity: 0.6,
        },
      },
      transition: "opacity 0.2s",
    },
    checkboxColumn: {
      paddingRight: theme.spacing(2),
      padding: theme.spacing(1),
    },
    checkboxCompact: {
      padding: 0,
      paddingLeft: theme.spacing(1.25),
    },
    checkboxColumnCompact: {
      padding: 5,
      paddingLeft: theme.spacing(1.25),
    },

    rowHover: {
      "&:hover": {
        backgroundColor: "#f8f8f8",
        cursor: "pointer",
      },
      transition: "background-color 0.2s",
    },
    rowClickable: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    rowSelected: {
      backgroundColor: `${theme.palette.secondary.main}14`,
      "&:hover": {
        backgroundColor: `${theme.palette.secondary.main}0D`,
        cursor: "pointer",
      },
    },
    rowDisabled: {
      "&:hover": {
        cursor: "auto",
      },
    },
    table: {
      overflowX: "scroll",
    },
    activeBackground: {
      backgroundColor: lighten(theme.palette.secondary.main, 0.85),
    },
    inactiveBackground: {
      backgroundColor: "white",
    },
    fadeBackground: {
      transition: "background 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
    },
    noBottomBorder: {
      borderBottom: 0,
    },
    cell: {},
    sortIcon: {
      transition: "transform 0.2s, opacity 0.2s",
      fontSize: "0.875rem",
    },
    rotate: {
      transform: "rotate(180deg)",
    },
    invisible: {
      opacity: 0,
    },
  }),
);

export default tableStyles;
