import React, { FC } from "react";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";

interface CircularProgressProps {
  color?: "primary" | "secondary" | "warning";
  size?: number;
}

const CircularProgress: FC<CircularProgressProps> = ({
  color = "primary",
  size = 30,
}) => {
  return (
    <Box
      fontSize={size}
      color={color + ".main"}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <FontAwesomeIcon
        fontSize="inherit"
        color="inherit"
        icon={faArrowsRotate}
        spin
      />
    </Box>
  );
};

export default CircularProgress;
