import React, { FC, Fragment, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { faChartPie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Fade, Typography } from "@material-ui/core";

import ChartWrapper from "components/ChartWrapper/ChartWrapper";
import { generatePastelColorFromString } from "helpers/colorHelpers";
import { PIE_COLORS_DASHBOARD } from "types/colors";
import {
  DashboardElementData,
  DashboardElementMetadata,
} from "types/dashboard";

interface DoughnutElementProps {
  element?: DashboardElementData;
  metadata: DashboardElementMetadata;
  isLoading: boolean;
}

const DoughnutChartElement: FC<DoughnutElementProps> = ({
  element,
  metadata,
  isLoading,
}) => {
  const navigate = useNavigate();

  const pieData = useMemo(
    () =>
      element
        ? Object.values(element.data[0]).map((d) => Number(d) ?? null) ?? []
        : [],
    [element],
  );
  const pieLabels = useMemo(() => (element ? element.labels : []), [element]);

  const backgroundColors = useMemo(
    () =>
      element
        ? element?.labels.map(
            (e) =>
              PIE_COLORS_DASHBOARD[e as keyof typeof PIE_COLORS_DASHBOARD] ??
              generatePastelColorFromString(e),
          )
        : [],
    [element],
  );

  const isDataAllZeroes = useMemo(
    () => pieData.every((v) => v === 0),
    [pieData],
  );

  return (
    <Fragment>
      {isLoading ? (
        <Box
          component="h2"
          textAlign="center"
          py={5}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Skeleton circle height={170} width={170} />
        </Box>
      ) : pieData?.length ? (
        isDataAllZeroes ? (
          <Box
            height="100%"
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            py={3}
          >
            <Typography variant="h1" align="center" color="primary">
              0
            </Typography>
          </Box>
        ) : (
          <Fade style={{ height: "100%" }} in={!!pieData.length}>
            <Box
              height={"100%"}
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={2}
              flex={1}
            >
              <ChartWrapper
                height={220}
                type="doughnut"
                data={{
                  datasets: [
                    {
                      data: pieData,
                      backgroundColor: backgroundColors,
                      borderColor: "#f5f5f5",
                      borderWidth: 0.5,
                    },
                  ],
                  labels: pieLabels,
                }}
                options={{
                  borderColor: "transparent",
                  layout: {
                    padding: {
                      top: 20,
                      bottom: 20,
                    },
                  },
                  onClick: (_e, elements, chart) => {
                    const index = elements[0]?.index ?? undefined;
                    if (index === undefined) return;
                    const label = chart.data.labels?.[index] + "";
                    _e.native?.preventDefault();
                    if (
                      element?.metadata.displayHints?.pieChartLink?.link ===
                        "" ||
                      !element?.metadata.displayHints?.pieChartLink?.link
                    )
                      return;

                    return navigate(
                      `${element?.metadata.displayHints?.pieChartLink?.link?.replace(
                        "{value}",
                        label,
                      )}`,
                    );
                  },
                  onHover: element?.metadata.displayHints?.pieChartLink?.link
                    ? (evt, activeEls) => {
                        activeEls.length > 0
                          ? (evt.chart.canvas.style.cursor = "pointer")
                          : (evt.chart.canvas.style.cursor = "default");
                      }
                    : undefined,
                  plugins: {
                    legend: {
                      position: "right",
                    },
                    tooltip: {
                      enabled: true,
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </Box>
          </Fade>
        )
      ) : (
        <Box
          p={2}
          textAlign="center"
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box p={2} textAlign="center">
            <Box mb={1} style={{ opacity: 0.25 }}>
              <FontAwesomeIcon icon={faChartPie} size="2x" />
            </Box>
            <Typography variant="body2">{metadata.defaultMessage}</Typography>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};

export default DoughnutChartElement;
