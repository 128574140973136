import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Filters, Row } from "react-table";
import {
  Box,
  Button,
  Card,
  createStyles,
  Drawer,
  Grid,
  Hidden,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Equalizer } from "@material-ui/icons";

import { useCurrentOrgId } from "providers";

import AssessmentConfigYamlModal from "../../Assessments/components/AssessmentConfigYamlModal";
import { AssessmentDetailsContext } from "../context/AssessmentDetailsContext";
import DetailMetrics from "./DetailMetrics";
import DetailSummary from "./DetailSummary";
import FilterModal from "./FilterModal";
import FindingDetail from "./FindingDetail";
import FindingsTable from "./FindingsTable";
import SuppressedFindingsTable from "./SuppressedFindingsTable";
import SearchBar from "components/DataTable/components/SearchBar";
import { DataTableRef } from "components/DataTable/DataTable";
import MountedTabPanel from "components/MountedTabPanel/MountedTabPanel";
import { useQueryParams } from "hooks/useQueryParams";
import { Finding } from "types/assessments";

interface DetailBodyProps {
  assessmentId: string;
}

export interface AssessmentDetailsTableActions {
  onSuppressFinding(finding: Finding): void;
  onFixFinding(finding: Finding): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: { height: "100%" },
    search: { margin: 0 },
    drawer: { height: "80%" },
    summaryDrawer: {
      display: "block",
      height: "80%",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
  }),
);

const DetailBody: FC<DetailBodyProps> = ({ assessmentId }) => {
  const { assessmentDetail, isLoading, isFetched } = useContext(
    AssessmentDetailsContext,
  );
  const { findingId } = useParams<{
    findingId: string;
  }>();
  const { suppressed } = useQueryParams<{ suppressed?: string }>();
  const history = useNavigate();
  const { search } = useLocation();
  const [selectedFinding, setSelectedFinding] = useState<Finding>();
  const [initialPageIndex, setInitialPageIndex] = useState(1);
  const [tab, setTab] = useState(suppressed === "" || !!suppressed ? 1 : 0);
  const [summaryDrawerOpen, setSummaryDrawerOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isUsingDrawer = useMediaQuery(theme.breakpoints.down("sm"));
  const orgId = useCurrentOrgId();
  const findingsTableRef = useRef<DataTableRef<Finding>>(null);
  const suppressedTableRef = useRef<DataTableRef<Finding>>(null);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const handleChange = useCallback((filterText: string) => {
    findingsTableRef.current?.tableInstance.setGlobalFilter(filterText);
    suppressedTableRef.current?.tableInstance.setGlobalFilter(filterText);
  }, []);

  const unselectAll = useCallback(() => {
    findingsTableRef.current?.tableInstance.toggleAllRowsSelected(false);
    suppressedTableRef.current?.tableInstance.toggleAllRowsSelected(false);
  }, []);

  const toggleTableSelected = useCallback((row: Row<Finding>) => {
    row.original.suppressedTs
      ? suppressedTableRef.current?.tableInstance.toggleRowSelected(
          row.id,
          true,
        )
      : findingsTableRef.current?.tableInstance.toggleRowSelected(row.id, true);
  }, []);

  const handleOnFindingSelect = useCallback(
    (row: Row<Finding>) => {
      history(
        `/assessments/details/${assessmentId}/${row.original.id}/summary${search}`,
        { replace: true },
      );
    },
    [history, assessmentId, search],
  );

  const handleSummaryClick = useCallback(() => {
    history(`/assessments/details/${assessmentId}${search}`);
    setSelectedFinding(undefined);
    unselectAll();
  }, [history, assessmentId, search, unselectAll]);

  const handleSummaryDrawerClick = useCallback(() => {
    setSummaryDrawerOpen(true);
  }, []);

  const handleBackdropSummaryClick = useCallback(() => {
    setSummaryDrawerOpen(false);
  }, []);

  const setFilters = useCallback((filters: Filters<Finding>) => {
    findingsTableRef.current?.tableInstance.setAllFilters(filters);
    suppressedTableRef.current?.tableInstance.setAllFilters(filters);
    findingsTableRef.current?.tableInstance.gotoPage(0);
    suppressedTableRef.current?.tableInstance.gotoPage(0);
    setInitialPageIndex(1);
  }, []);

  useEffect(() => {
    if (!findingId) return;
    let finding = findingsTableRef.current?.tableInstance.rows.find(
      (r) => r.original.id === findingId,
    );
    if (finding) {
      setSelectedFinding(finding.original);
      findingsTableRef.current?.tableInstance.prepareRow(finding);
      unselectAll();
      toggleTableSelected(finding);
      return;
    }
    finding = suppressedTableRef.current?.tableInstance.rows.find(
      (r) => r.original.id === findingId,
    );
    if (finding) {
      setSelectedFinding(finding.original);
      findingsTableRef.current?.tableInstance.prepareRow(finding);
      unselectAll();
      toggleTableSelected(finding);
      return;
    }
  }, [findingId, assessmentDetail, toggleTableSelected, unselectAll]);

  const compliances = useMemo(
    () =>
      [
        ...(new Set(
          assessmentDetail?.findings
            .map((f) => f.compliance ?? [])
            .flat()
            .concat(
              assessmentDetail.suppressedFindings
                .map((f) => f.compliance ?? [])
                .flat(),
            )
            .filter((f) => f),
        ) ?? []),
      ] as string[],
    [assessmentDetail],
  );

  useEffect(() => {
    let findingIndex = findingsTableRef.current?.tableInstance.rows.findIndex(
      (r) => r.original.id === findingId,
    );
    if (findingIndex !== -1) {
      setTab(0);

      setInitialPageIndex(Math.floor((findingIndex ?? 0) / 5 + 1));
      return;
    }
    findingIndex = suppressedTableRef.current?.tableInstance.rows.findIndex(
      (r) => r.original.id === findingId,
    );
    if (findingIndex !== -1) {
      setTab(1);
      setInitialPageIndex(Math.floor((findingIndex ?? 0) / 5 + 1));
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={5}>
        <Card>
          <Box
            bgcolor="lightBackground.main"
            pt={4}
            px={2}
            borderRadius="inherit"
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                <SearchBar
                  className={classes.search}
                  initialValue=""
                  onChange={handleChange}
                />
                <FilterModal
                  disabled={isLoading}
                  compliances={compliances}
                  setTableFilters={setFilters}
                />
                <AssessmentConfigYamlModal
                  disabled={isLoading}
                  assessmentId={assessmentId}
                  assessmentTitle={assessmentDetail?.title ?? ""}
                />
              </Box>
              <Button
                onClick={
                  isUsingDrawer ? handleSummaryDrawerClick : handleSummaryClick
                }
                color="primary"
                disabled={
                  isLoading ||
                  (isUsingDrawer ? summaryDrawerOpen : !selectedFinding)
                }
                startIcon={<Equalizer />}
              >
                <Typography variant="button" noWrap>
                  SHOW SUMMARY
                </Typography>
              </Button>
              {/* <Button color="primary" startIcon={<GetApp />}>
            <Typography variant="button" noWrap>
              DOWNLOAD CSV
            </Typography>
          </Button> */}
            </Box>
            <Box display="flex" mt={1} borderBottom="1px solid Gainsboro">
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
              >
                <Tab disabled={isLoading} label="FINDINGS" />
                <Tab disabled={isLoading} label="SUPPRESSED FINDINGS" />
              </Tabs>
            </Box>
          </Box>
          <Box p={1} pt={0}>
            <MountedTabPanel value={tab} index={0}>
              <FindingsTable
                gitRepo={assessmentDetail?.gitRepo}
                queryKey={["assessmentDetail", orgId, assessmentId]}
                onFindingSelect={handleOnFindingSelect}
                tableRef={findingsTableRef}
                initialPageIndex={initialPageIndex}
              />
            </MountedTabPanel>
            <MountedTabPanel value={tab} index={1}>
              <SuppressedFindingsTable
                gitRepo={assessmentDetail?.gitRepo}
                queryKey={["assessmentDetail", orgId, assessmentId]}
                onFindingSelect={handleOnFindingSelect}
                tableRef={suppressedTableRef}
              />
            </MountedTabPanel>
          </Box>
        </Card>
      </Grid>
      <Grid item md={6} lg={7}>
        <Hidden smDown>
          {isLoading ? (
            <Box component={Card} p={2} className={classes.card} mb={2}>
              <Typography variant="h2" gutterBottom>
                <Skeleton width={150} />
              </Typography>
              <Box lineHeight={2}>
                <Skeleton count={10} />
              </Box>
              <Box mb={2} />
              <Box lineHeight={2}>
                <Skeleton count={7} />
              </Box>
            </Box>
          ) : selectedFinding ? (
            <FindingDetail
              queryKey={["assessmentDetail", orgId, assessmentId]}
              finding={selectedFinding}
              tableRefs={{ findingsTableRef, suppressedTableRef }}
              changeFinding={handleOnFindingSelect}
            />
          ) : (
            <Fragment>
              <DetailSummary />
              <DetailMetrics />
            </Fragment>
          )}
        </Hidden>
        <Hidden mdUp>
          <Drawer
            classes={{ paperAnchorBottom: classes.drawer }}
            anchor="bottom"
            open={!!selectedFinding}
            ModalProps={{
              onBackdropClick: handleSummaryClick,
            }}
          >
            {assessmentDetail && (
              <FindingDetail
                queryKey={["assessmentDetail", orgId, assessmentId]}
                finding={selectedFinding}
                tableRefs={{ findingsTableRef, suppressedTableRef }}
                changeFinding={handleOnFindingSelect}
              />
            )}
          </Drawer>
          <Drawer
            classes={{ paperAnchorBottom: classes.summaryDrawer }}
            anchor="bottom"
            open={summaryDrawerOpen}
            ModalProps={{
              onBackdropClick: handleBackdropSummaryClick,
            }}
          >
            <DetailSummary />
            <DetailMetrics />
          </Drawer>
        </Hidden>
      </Grid>
    </Grid>
  );
};
export default DetailBody;
