import React, { FC } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid } from "@material-ui/core";
import moment from "moment";

import SeverityComponent, {
  Severity,
} from "components/SeverityComponent/SeverityComponent";
import { PolicyActivitySeverity as PolicyActivitySeverityI } from "types/policies";

interface PolicyActivitySeverityProps {
  activity: PolicyActivitySeverityI;
}

const PolicyActivitySeverity: FC<PolicyActivitySeverityProps> = ({
  activity,
}) => {
  return (
    <Box my={1}>
      <Grid container spacing={4}>
        <Grid item xs={3} sm={2}>
          {moment(activity.createTs, moment.ISO_8601).format("hh:mm:ss A")}
        </Grid>
        <Grid item xs={9} sm={10}>
          <Box>
            <Box fontWeight="fontWeightMedium" display="inline">
              {activity.user}
            </Box>{" "}
            changed the severity from{" "}
            <SeverityComponent severity={Severity[activity.from]} />
            <Box display="inline-block" mx={1}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Box>
            <SeverityComponent severity={Severity[activity.to]} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PolicyActivitySeverity;
