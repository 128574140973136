import instance from "./axiosInstance";
import { APIResponse, PaginatedResponse } from "types";
import { IacBotJob, RepoRunStatus, ReposWithRunningIacBot } from "types/iacBot";
import { ZodiacLog, ZodiacTriggerPayload } from "types/zodiac";

const invokeIacBot = (orgId: string, payload: ZodiacTriggerPayload) =>
  instance
    .post(`/org/${orgId}/iacbot/trigger`, payload)
    .then((res) => res.data);

const getIacBotJobLogs = (orgId: string, invocatonId: string) =>
  instance
    .get<APIResponse<ZodiacLog>>(
      `org/${orgId}/iacbot/invocations/${invocatonId}/result`,
    )
    .then((res) => res.data.data);

const getIacBotStatus = (orgId: string) =>
  instance
    .get<APIResponse<{ repoCount: number; assessmentCount: number }>>(
      `/org/${orgId}/iacbot/status`,
    )
    .then((res) => res.data.data);

const getIacBotJobs = (orgId: string) =>
  instance
    .get<PaginatedResponse<IacBotJob>>(`/org/${orgId}/iacbot/invocations`)
    .then((res) => res.data.data);

const getIacBotRunningRepos = (orgId: string) =>
  instance
    .get<APIResponse<ReposWithRunningIacBot>>(
      `/org/${orgId}/iacbot/invocations/repos-with-running-jobs`,
    )
    .then<RepoRunStatus[]>((res) =>
      Object.keys(res.data.data).map((k) => ({
        repo: k,
        running: res.data.data[k].running,
      })),
    );

export default {
  getIacBotJobLogs,
  getIacBotJobs,
  getIacBotRunningRepos,
  getIacBotStatus,
  invokeIacBot,
};
