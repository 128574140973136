import React, { FC } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { overrides } from "theme/overrides";
import palette from "theme/palette";
import shadows from "theme/shadows";
import typography from "theme/typography";

const theme = createMuiTheme({
  palette,
  shadows,
  typography,
  overrides,
  props: {
    MuiButton: { disableElevation: true },
    MuiCard: { elevation: 1 },
    MuiDialogTitle: {
      disableTypography: true,
    },
  },
  zIndex: {
    drawer: 1050,
    appBar: 1040,
  },
});

const MuiTheme: FC = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiTheme;
