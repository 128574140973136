import React, { FC, useMemo } from "react";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import soluble from "../../soluble-version.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      textAlign: "center",
      fontSize: 12,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3),
    },
  }),
);

const Footer: FC = () => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const gitRevision = useMemo(
    () =>
      orgId === "900000000000" ? (
        <a
          data-testid="revision"
          href={`https://github.com/soluble-ai/ui-server/commit/${soluble.sourceRevision}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {soluble.sourceRevision.slice(0, 7)}
        </a>
      ) : (
        soluble.sourceRevision.slice(0, 7)
      ),
    [orgId],
  );

  return (
    <footer>
      <Grid
        container
        className={classes.footer}
        justify="space-between"
        spacing={0}
      >
        <Grid item sm="auto"></Grid>
        <Grid item sm="auto">
          <Box component="p" mb={0} color={theme.palette.text.secondary}>
            {matches && <br />} &copy;
            <span>{" Lacework "} </span>
            {`${new Date().getFullYear()} - `}
            {gitRevision}
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
