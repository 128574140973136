import React, { FC, Fragment } from "react";
import { faCodeBranch, faFingerprint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip } from "@material-ui/core";
import useCommonStyles from "theme/useCommonStyles";

import { getGitPlatformIcon, getPipelineIcon } from "helpers/iconHelper";

interface GitRepoInformationProps {
  ciBuildNum?: string;
  ciBuildUrl?: string;
  ciPipelineName?: string;
  ciPipelineUrl?: string;
  ciPlatform?: string;
  gitBranch?: string;
  gitBranchUrl?: string;
  gitCommit?: string;
  gitCommitUrl?: string;
  gitProvider?: string;
  gitRepo?: string;
  gitRepoName?: string;
  gitRepoUrl?: string;
  spacer?: boolean;
}

const GitRepoInformation: FC<GitRepoInformationProps> = ({
  ciBuildNum,
  ciBuildUrl,
  ciPipelineName,
  ciPipelineUrl,
  ciPlatform,
  gitBranch,
  gitBranchUrl,
  gitCommit,
  gitCommitUrl,
  gitProvider,
  gitRepo,
  gitRepoName,
  gitRepoUrl,
  spacer = true,
  ...rest
}) => {
  const commonStyles = useCommonStyles();
  const stopPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => event.stopPropagation();

  return (
    <Box display="inline-flex" flexWrap="wrap" onClick={stopPropagation}>
      {gitRepoName && (
        <Box display="inline-block">
          <Box display="inline" mr={0.5}>
            <FontAwesomeIcon icon={getGitPlatformIcon(gitProvider)} />
          </Box>
          <a
            className={commonStyles.wordBreakAll}
            href={gitRepoUrl}
            rel="noreferrer noopener"
            target="_blank"
          >
            {gitRepoName}
          </a>
        </Box>
      )}
      {gitRepoName && gitBranch && (
        <Box component="span" mx={0.5}>
          {spacer ? "|" : " "}
        </Box>
      )}
      {gitBranch && (
        <Box display="inline-block">
          <Box display="inline" mx={0.5}>
            <FontAwesomeIcon icon={faCodeBranch} />
          </Box>
          {gitBranchUrl ? (
            <a
              className={commonStyles.wordBreakAll}
              href={gitBranchUrl}
              rel="noreferrer noopener"
              target="_blank"
            >
              {gitBranch}
            </a>
          ) : (
            gitBranch
          )}
        </Box>
      )}

      {gitBranch && gitCommit && (
        <Box component="span" mx={0.5}>
          {spacer ? "|" : " "}
        </Box>
      )}

      {gitCommit && (
        <Box display="inline-block">
          <Box display="inline" mx={0.5}>
            <FontAwesomeIcon icon={faFingerprint} />
          </Box>

          {gitCommitUrl ? (
            <a
              href={`${gitCommitUrl}`}
              rel="noreferrer noopener"
              target="_blank"
            >
              {gitCommit?.slice(0, 7)}
            </a>
          ) : (
            gitCommit?.slice(0, 7)
          )}
        </Box>
      )}

      {gitCommit && ciPlatform && (
        <Box component="span" mx={0.5}>
          {spacer ? "|" : " "}
        </Box>
      )}
      {ciPlatform && (
        <Fragment>
          <Tooltip title={ciPlatform ?? "Pipeline"} arrow>
            <Box mx={0.5}>
              <FontAwesomeIcon
                title={ciPlatform}
                icon={getPipelineIcon(ciPlatform ?? "default")}
              />
            </Box>
          </Tooltip>

          {ciPipelineName}

          {ciBuildNum && (
            <Box ml={0.5}>
              <a
                href={`${ciBuildUrl}`}
                rel="noreferrer noopener"
                target="_blank"
              >
                {`(${ciBuildNum})`}
              </a>
            </Box>
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default GitRepoInformation;
