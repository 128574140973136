import { FC, Fragment } from "react";
import { Box, Card } from "@material-ui/core";

import OrganizationsDataTable from "./components/OrganizationsDataTable";
import PageHeader from "components/PageHeader";

export interface OrgsQueryState {
  page: number;
  pageSize: number;
  query: string;
  sortBy: string;
  sortDirection: "asc" | "desc";
}

const Organizations: FC = () => {
  return (
    <Fragment>
      <PageHeader>Organizations</PageHeader>
      <Box component={Card} p={2}>
        <OrganizationsDataTable />
      </Box>
    </Fragment>
  );
};

export default Organizations;
