import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import classNames from "classnames";

import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import GitRepoInformation from "components/GitRepoInformation/GitRepoInformation";
import TimestampFormatter from "components/TimestampFormatter";
import { useStyles } from "pages/Assessments/pages/Assessments/components/AssessmentRow";
import RepositoryFindingsSeverity from "pages/Repositories/pages/Repositories/components/RepositoryFindingsSeverity";
import { PipelineAssessment } from "types/assessments";

const useStylesL = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,

      margin: `0 -${theme.spacing(1)}px `,
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
      },
      justifyContent: "space-between",
    },
  }),
);

interface PipelineAssessmentRowProps {
  assessment: PipelineAssessment;
}

const PipelineAssessmentRow: FC<PipelineAssessmentRowProps> = ({
  assessment,
}) => {
  const { metrics, ciPipelineName, idNum, ciBuildNum } = assessment;
  const navigate = useNavigate();
  const goToDetail = () =>
    navigate(
      `/assessments/details/${idNum}?ciPipelineName=${ciPipelineName}&ciBuildId=${ciBuildNum}`,
    );
  const classes = useStyles();
  const classesC = useStylesL();

  const hasMetrics = assessment?.metrics?.findingsFail !== undefined;
  const hasViolations = assessment?.metrics?.findingsFail !== 0;

  const actions: DropdownItem[] = [
    {
      label: "View Findings",
      linkTo: `/assessments/details/${idNum}?ciPipelineName=${ciPipelineName}&ciBuildId=${ciBuildNum}`,
    },
  ];

  return (
    <Card
      onClick={goToDetail}
      className={classNames(
        classesC.root,
        hasViolations ? classes.fail : classes.success,
      )}
      variant="outlined"
    >
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={12} md={7} lg={6}>
          <Box display="flex" alignItems="center">
            {hasMetrics &&
              (hasViolations ? (
                <Box
                  my={-1}
                  fontSize={30}
                  className={classes.icon}
                  color="error.main"
                >
                  <Cancel fontSize="inherit" />
                </Box>
              ) : (
                <Box
                  my={-1}
                  fontSize={30}
                  className={classes.icon}
                  color="success.main"
                >
                  <CheckCircle fontSize="inherit" />
                </Box>
              ))}
            <Box>
              <Box display="inline-flex" alignItems="center">
                <Typography variant="h6">
                  {assessment.gitPrTitle === "" || !assessment.gitPrTitle
                    ? assessment.title
                    : assessment.gitPrTitle}
                </Typography>
              </Box>
              <Box>
                <GitRepoInformation
                  {...assessment}
                  ciPlatform={undefined}
                  spacer={false}
                />
              </Box>
              <Typography variant="h6">
                <TimestampFormatter dateTs={assessment.assessmentTs} oneLine />
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={1} lg={2}>
          <Box
            mr={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box fontSize={16} color="gray">
              Violations
            </Box>
            <Box fontSize={18} color="text.primary">
              {metrics.findingsFail}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={7} md={4}>
          <Box fontSize={16}>
            <RepositoryFindingsSeverity
              stats={{
                critical: metrics.findingsFailCritical,
                high: metrics.findingsFailHigh,
                medium: metrics.findingsFailMedium,
                low: metrics.findingsFailLow,
                info: metrics.findingsFailInfo,
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box ml="auto">
        <DropdownActions items={actions} />
      </Box>
    </Card>
  );
};

export default PipelineAssessmentRow;
