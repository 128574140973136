import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import sanitize from "sanitize-html";

/**
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useQueryParams<T, K extends keyof T = keyof T>() {
  const { search } = useLocation();

  const queryParams = useMemo<T>(() => {
    return [...new URLSearchParams(search).entries()].reduce(
      (obj, [key, value]) => {
        try {
          // Value was parsed to another type, but string.
          obj[key] = JSON.parse(value);
        } catch {
          // Value is string, should be sanitized.
          obj[key] = sanitize(value, {
            allowedTags: [],
            allowedAttributes: {},
          });
        }

        return obj;
      },
      {} as any,
    );
  }, [search]);
  return queryParams;
}
