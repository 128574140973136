import React, { FC, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@material-ui/core";

import GitRepoInformation from "components/GitRepoInformation/GitRepoInformation";
import PageHeader from "components/PageHeader";
import { AssessmentDetailsContext } from "pages/Assessments/pages/AssessessmentDetails/context/AssessmentDetailsContext";

interface ZodiacConsolidatedAssessmentsHeaderProps {
  ciBuildId: string;
}

const ZodiacConsolidatedAssessmentsHeader: FC<
  ZodiacConsolidatedAssessmentsHeaderProps
> = ({ ciBuildId }) => {
  const { assessmentDetail, isLoading } = useContext(AssessmentDetailsContext);
  const title = `Zodiac-Job ${assessmentDetail?.idNum ?? ciBuildId ?? ""}`;
  return (
    <Fragment>
      <PageHeader mb={0}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          {title}
        </Box>
      </PageHeader>
      <Box
        my={1}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
      >
        {isLoading ? <Box /> : <GitRepoInformation {...assessmentDetail} />}
        <Breadcrumbs separator="/" aria-label="breadcrumb">
          <Link color="inherit" to="/zodiac/">
            Zodiac
          </Link>
          <Link color="inherit" to={`/zodiac/jobs/${ciBuildId}`}>
            {ciBuildId}
          </Link>
          <Typography color="textPrimary">Assessments</Typography>
        </Breadcrumbs>
      </Box>
    </Fragment>
  );
};

export default ZodiacConsolidatedAssessmentsHeader;
