import React, { FC } from "react";
import classNames from "classnames";

interface InitialsAvatarProps {
  text: string;
  width?: number;
  className?: string;
}

const InitialsAvatar: FC<InitialsAvatarProps> = ({
  text,
  width,
  className,
}) => {
  const circleContainerStyles = { width, height: width };
  const initialsStyles = {
    top: Number(width) / 4,
    fontSize: Number(width) / 2,
  };
  const twoInitials = text.indexOf(" ");

  return (
    <div
      style={circleContainerStyles}
      className={classNames("circle-avatar", className)}
    >
      <p style={initialsStyles} className="circle-avatar-initials">
        {text[0]}
        {twoInitials !== -1 && text[twoInitials + 1]}
      </p>
    </div>
  );
};

export default InitialsAvatar;
