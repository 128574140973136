import instance from "./axiosInstance";
import {
  AccessToken,
  AgentToken,
  NewAccessToken,
  PaginatedResponse,
} from "types";

const getAccessTokens = async () =>
  instance
    .get<PaginatedResponse<AccessToken>>("access-tokens")
    .then((res) => res.data.data);

const getAgentTokens = (orgId: string) =>
  instance
    .get<PaginatedResponse<AgentToken>>(`/org/${orgId}/agent-tokens`)
    .then((res) => res.data.data);

const revokeAccessToken = (tokenId: string) =>
  instance.post(`/access-tokens/${tokenId}`, { status: "revoked" });
const disableAccessToken = (tokenId: string) =>
  instance.post(`/access-tokens/${tokenId}`, { status: "disabled" });
const enableAccessToken = (tokenId: string) =>
  instance.post(`/access-tokens/${tokenId}`, { status: "active" });

const createAccessToken = () =>
  instance.post<NewAccessToken>(`/auth/create-access-token/`, {});

const revokeAgentToken = (orgId: string, tokenId: string) =>
  instance.post<PaginatedResponse<AgentToken>>(
    `/org/${orgId}/agent-tokens/${tokenId}`,
    { status: "revoked" },
  );

const disableAgentToken = (orgId: string, tokenId: string) =>
  instance.post<PaginatedResponse<AgentToken>>(
    `/org/${orgId}/agent-tokens/${tokenId}`,
    { status: "disabled" },
  );

const enableAgentToken = (orgId: string, tokenId: string) =>
  instance.post<PaginatedResponse<AgentToken>>(
    `/org/${orgId}/agent-tokens/${tokenId}`,
    { status: "active" },
  );

export default {
  getAccessTokens,
  getAgentTokens,
  revokeAccessToken,
  disableAccessToken,
  enableAccessToken,
  createAccessToken,
  revokeAgentToken,
  disableAgentToken,
  enableAgentToken,
};
