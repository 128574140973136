import React, { FC, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import useCommonStyles from "theme/useCommonStyles";

import { AssessmentDetailsContext } from "../context/AssessmentDetailsContext";
import GitRepoInformation from "components/GitRepoInformation/GitRepoInformation";
import PageHeader from "components/PageHeader";
import { useQueryParams } from "hooks/useQueryParams";

interface DetailHeaderProps {
  assessmentId: string;
}

interface QueryParams {
  ciPipelineName: string;
  ciBuildId: string;
  diag?: string;
}

const DetailHeader: FC<DetailHeaderProps> = ({ assessmentId }) => {
  const { assessmentDetail, isLoading } = useContext(AssessmentDetailsContext);
  const commonStyles = useCommonStyles();
  const { ciPipelineName = "", ciBuildId = "" } = useQueryParams<QueryParams>();

  const isRun = ciPipelineName !== "" && ciBuildId !== "";
  return (
    <Fragment>
      <PageHeader mb={0}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          {isLoading ? "Assessment" : assessmentDetail?.title}
        </Box>
      </PageHeader>
      <Box
        my={1}
        display="flex"
        flexWrap="wrap-reverse"
        justifyContent="space-between"
        alignItems="baseline"
      >
        {isLoading ? <Box /> : <GitRepoInformation {...assessmentDetail} />}
        {isLoading ? null : isRun ? (
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to="/pipelines/">
              Pipelines
            </Link>
            <Link
              color="inherit"
              to={`/pipelines/${assessmentDetail?.id}/runs`}
            >
              {ciPipelineName}
            </Link>
            <Typography
              className={commonStyles.wordBreakAll}
              color="textPrimary"
            >
              {ciBuildId}
            </Typography>
          </Breadcrumbs>
        ) : (
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to="/assessments/">
              Assessments
            </Link>
            <Typography
              className={commonStyles.wordBreakAll}
              color="textPrimary"
            >
              {assessmentId}
            </Typography>
          </Breadcrumbs>
        )}
      </Box>
    </Fragment>
  );
};

export default DetailHeader;
