import React, { FC, Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import validator from "validator";

import { useCurrentOrgId, useLogout, useUser } from "providers";

import { ReactComponent as EmailIllustration } from "assets/svg/illustrations/email.svg";
import profileService from "services/profileService";

const MissingEmailForm: FC = () => {
  const orgId = useCurrentOrgId();
  const logout = useLogout();
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { userId } = useUser();
  const { control, handleSubmit, errors } = useForm();

  const onSubmit = async (data: { email: string }) => {
    setIsLoading(true);
    await profileService
      .addEmail(userId, { orgId, email: data.email })
      .then((response) => {
        setEmailSent(true);
      })
      .catch((error) => {
        toast.error(
          error.message ??
            "An error ocurred while registering your e-mail address, please try again.",
        );
      });
    setIsLoading(false);
  };

  if (emailSent) return <Navigate to="/profile/verify-email" />;

  return (
    <Fragment>
      <Box
        mb={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box component={EmailIllustration} width={200} height={160} mb={3} />
        <Typography gutterBottom variant="h3">
          Enter your e-mail address
        </Typography>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" my={14}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box mb={3} width={350}>
            <FormControl variant="outlined" fullWidth>
              <Controller
                control={control}
                as={TextField}
                name="email"
                color="primary"
                fullWidth
                type="email"
                label="E-mail Address"
                placeholder="user@example.com"
                defaultValue=""
                variant="standard"
                helperText={errors.email?.message}
                error={!!errors.email}
                rules={{
                  required: "Please enter an e-mail.",
                  validate: (value) =>
                    validator.isEmail(value) || "Please enter a valid email.",
                }}
              />
            </FormControl>
            <Box mt={1}>
              <Typography variant="subtitle1">
                In order to start using your Lacework IaC Security account, you
                need to provide an e-mail address.
              </Typography>
            </Box>
          </Box>
          <Box mb={4} width={350}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
            <Box mt={2}>
              <Typography variant="caption">
                You can opt out of any weekly digests and updates from us at any
                time on your profile settings.
              </Typography>
            </Box>
          </Box>
          <Box mt={2} mr="auto">
            <Button variant="text" color="primary" onClick={logout}>
              Cancel
            </Button>
          </Box>
        </form>
      )}
    </Fragment>
  );
};

export default MissingEmailForm;
