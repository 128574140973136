import React, { FC, useMemo } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Box, Card, Tab, Tabs } from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import useCommonStyles from "theme/useCommonStyles";

export enum RepoInsightTabs {
  Overview = "overview",
  Jobs = "jobs",
  Branches = "branches",
}

interface JobPipelineTabsProps {
  tab: string;
}

const RepositoryTabs: FC<JobPipelineTabsProps> = ({ tab }) => {
  const classes = useCommonStyles();
  const { gitRepoName } = useParams<{ gitRepoName: string }>();
  const { repositoryBranchesUiEnabled, repositoryJobsUiEnabled } = useFlags();
  const repo = encodeURIComponent(gitRepoName ?? "");

  const isTabValid = useMemo(
    () =>
      tab === RepoInsightTabs.Overview ||
      (repositoryJobsUiEnabled && tab === RepoInsightTabs.Jobs) ||
      (repositoryBranchesUiEnabled && tab === RepoInsightTabs.Branches),
    [repositoryBranchesUiEnabled, repositoryJobsUiEnabled, tab],
  );

  if (!isTabValid) {
    // BEWARE
    // Because of the gitRepoName parameter having the asterisk* it includes the `/`s that may be sent as part of the parameter itself
    // this redirect utilizes the current invalid tab value because it assumes that it is part of the gitRepoName query parameter.
    // This introduces a bug where the UI can't redirect to the correct tabValue when an invalid tab is sent.

    const isExistingTab =
      tab === RepoInsightTabs.Jobs || tab === RepoInsightTabs.Branches;

    return (
      <Navigate
        to={
          isExistingTab
            ? `/repos/details/${repo}/${RepoInsightTabs.Overview}`
            : `/repos/details/${repo}/${tab}/${RepoInsightTabs.Overview}`
        }
      />
    );
  }

  return (
    <Box component={Card} mb={2}>
      <Tabs value={tab} indicatorColor="primary" textColor="primary">
        <Tab
          className={classes.tabLink}
          component={Link}
          to={`/repos/details/${repo}/${RepoInsightTabs.Overview}`}
          value={RepoInsightTabs.Overview}
          label="OVERVIEW"
          replace
        />
        {repositoryJobsUiEnabled && (
          <Tab
            className={classes.tabLink}
            component={Link}
            to={`/repos/details/${repo}/${RepoInsightTabs.Jobs}`}
            value={RepoInsightTabs.Jobs}
            label="JOBS"
            replace
          />
        )}
        {repositoryBranchesUiEnabled && (
          <Tab
            className={classes.tabLink}
            component={Link}
            to={`/repos/details/${repo}/${RepoInsightTabs.Branches}`}
            value={RepoInsightTabs.Branches}
            label="BRANCHES"
            replace
          />
        )}
      </Tabs>
    </Box>
  );
};

export default RepositoryTabs;
