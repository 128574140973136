import React, { FC, Fragment, useCallback, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import Notification from "../../components/Notification";
import DataTable from "components/DataTable";
import SearchBar from "components/DataTable/components/SearchBar";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import { NotificationTypeIcon } from "pages/Notifications/components/NotificationDescription";
import notificationService from "services/notificationService";
import {
  Notification as NotificationI,
  NotificationType,
} from "types/notifications";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& > table > tbody > tr > td": {
        border: 0,
      },
    },
    search: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
  }),
);

const columns: Column<NotificationI>[] = [
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ row: { original: row } }) => (
      <Card variant="outlined">
        <Notification notification={row} expanded />
      </Card>
    ),
  },
  {
    Header: "Description",
    accessor: "body",
  },
  {
    Header: "Type",
    accessor: "type",
    filter: "includesSome",
  },
  {
    Header: "Source",
    accessor: "source",
    filter: "includesSome",
  },
  {
    Header: "Resource",
    accessor: "resource",
    filter: "includesSome",
  },
  {
    Header: "Ts",
    accessor: "createTs",
  },
];

const Notifications: FC = () => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const tableRef = useRef<DataTableRef<NotificationI>>(null);

  const { data: notifications = [], isLoading } = useQuery(
    ["notifications", orgId],
    () => notificationService.getNotifications(orgId),
    {
      onError(error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleChange = useCallback((filterText: string) => {
    tableRef.current?.tableInstance.setGlobalFilter(filterText);
  }, []);

  const { data: sources = [] } = useQuery(
    ["notifications-sources", orgId],
    () => notificationService.getNotificationSources(orgId),
    {
      onError(error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: resources = [] } = useQuery(
    ["notifications-resources", orgId],
    () => notificationService.getNotificationResources(orgId),
    {
      onError(error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const notificationTypes = Object.values(NotificationType);
  const sourcesS = useMemo(() => [...new Set(sources)], [sources]);
  const resourcesS = useMemo(() => [...new Set(resources)], [resources]);

  return (
    <Fragment>
      <PageHeader>Notifications</PageHeader>
      <Card>
        <Box p={2}>
          <Box
            display="flex"
            flexDirection="row-reverse"
            alignItems="center"
            flexWrap="wrap"
            mr={2}
          >
            <Box display="flex"></Box>
          </Box>
          <DataTable
            containerClassname={classes.table}
            columns={columns}
            data={notifications}
            isLoading={isLoading}
            pagination
            searchActions={
              <Box display="flex">
                <SearchBar
                  className={classes.search}
                  initialValue=""
                  onChange={handleChange}
                  disabled={isLoading}
                />
                <FiltersModal
                  tableRef={tableRef}
                  title="Filter Notifications"
                  persistInUrl
                  fields={[
                    {
                      displayName: "Type",
                      key: "type",
                      type: "multiselect",
                      renderValue: (value) => (
                        <Fragment>
                          <NotificationTypeIcon
                            type={
                              typeof value === "object" ? value.join("") : value
                            }
                          />
                          {value}
                        </Fragment>
                      ),
                      values: notificationTypes.map((t) => ({
                        displayName: t,
                        value: t,
                      })),
                    },
                    {
                      displayName: "Source",
                      key: "source",
                      type: "multiselect",
                      values: sourcesS.map((s) => ({
                        displayName: s,
                        value: s,
                      })),
                    },
                    {
                      displayName: "Resource",
                      key: "resource",
                      type: "multiselect",
                      values: resourcesS.map((r) => ({
                        displayName: r,
                        value: r,
                      })),
                    },
                  ]}
                  disabled={isLoading}
                />
              </Box>
            }
            defaultSortField={"createTs"}
            sortByDesc
            pageSizeOptions={[10, 25, 50, 100]}
            compact
            hideTableHead
            hiddenColumns={["body", "createTs", "type", "source", "resource"]}
            noDataComponent={
              <Box
                py={4}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box mb={3}>
                  <FontAwesomeIcon color="lightgray" icon={faBell} size="4x" />
                </Box>
                <Box>
                  <Typography align="center">
                    No notifications
                    {notifications.length === 0
                      ? "."
                      : " matching the current query."}
                  </Typography>
                </Box>
              </Box>
            }
            ref={tableRef}
          />
        </Box>
      </Card>
    </Fragment>
  );
};

export default Notifications;
