import React, { FC } from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles, Tab, Tabs, Theme } from "@material-ui/core";
import useCommonStyles from "theme/useCommonStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      zIndex: theme.zIndex.mobileStepper,
      marginBottom: theme.spacing(2),
    },
  }),
);

export enum IacBotTabsE {
  Jobs = "jobs",
  Instances = "instances",
}

interface IacBotTabsProps {
  tab: string;
}

const IacBotTabs: FC<IacBotTabsProps> = ({ tab }) => {
  const cClasses = useStyles();
  const classes = useCommonStyles();

  return (
    <Tabs
      className={cClasses.tabs}
      value={tab}
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab
        className={classes.tabLink}
        component={Link}
        to="/zodiac/jobs"
        value={IacBotTabsE.Jobs}
        label="JOBS"
      />
      <Tab
        className={classes.tabLink}
        component={Link}
        to="/zodiac/instances"
        value={IacBotTabsE.Instances}
        label="INSTANCES"
      />
    </Tabs>
  );
};

export default IacBotTabs;
