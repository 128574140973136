import React, { FC, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";

import { useHasScope } from "providers";

import DeleteSuppressionModal from "./DeleteSuppressionModal";
import FixFindingModal from "./FixFindingModal";
import SuppressionModal from "./SuppressionModal";
import CollapsibleButtons from "components/CollapsibleButtons/CollapsibleButtons";
import { DropdownItem } from "components/DataTable/components/DropdownActions";
import { ArrayQueryKey, optimisticObjectUpdater } from "helpers/queryHelpers";
import { AssessmentDetail, Finding } from "types/assessments";
import { Permissions, Resources } from "types/auth-roles";

interface FindingDetailActionsProps {
  assessmentDetail?: AssessmentDetail;
  finding?: Finding;
  refetch(): void;
  onDeleteSuppression: () => void;
  onSuppress: () => void;
  queryKey: ArrayQueryKey;
}

const FindingDetailActions: FC<FindingDetailActionsProps> = ({
  assessmentDetail,
  finding,
  refetch,
  onDeleteSuppression,
  onSuppress,
  queryKey,
}) => {
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );
  const { gitRepoName } = useParams<{
    gitRepoName: string;
  }>();
  const theme = useTheme();
  const matchesO = useMediaQuery(theme.breakpoints.between("xs", 800));
  const matchesB = useMediaQuery(theme.breakpoints.between("md", 1650));
  const matches = matchesO || matchesB;
  const suppressRef = useRef<HTMLButtonElement>(null);
  const fixRef = useRef<HTMLButtonElement>(null);
  const deleteSuppressionRef = useRef<HTMLButtonElement>(null);
  const { autofixUiEnabled } = useFlags();

  const handleOnSuppressClick = () => suppressRef.current?.click();

  const handleOnFixClick = () => fixRef.current?.click();

  const handleOnDeleteSuppressionClick = () =>
    deleteSuppressionRef.current?.click();

  const hasNoSuppressionOptions =
    !(
      assessmentDetail?.category === "iac" ||
      assessmentDetail?.category === "infraScan"
    ) &&
    !assessmentDetail?.gitRepo &&
    !gitRepoName;

  const isPolicyLevel = finding?.suppressionLevel === "policy";
  const isFileLevel = finding?.suppressionLevel === "file";
  const fix = {
    label: "Fix",
    action: handleOnFixClick,
  };
  const suppress = {
    label: "Suppress",
    action: handleOnSuppressClick,
    disabled:
      !hasOrgWriteScope ||
      hasNoSuppressionOptions ||
      !(finding?.allowSuppression ?? true),
    hover: hasNoSuppressionOptions
      ? "No suppression methods available for this assessment"
      : !hasOrgWriteScope
      ? "Insufficient permissions"
      : "",
  };
  const deleteSuppression = {
    label: "Delete Suppression",
    hover: !hasOrgWriteScope
      ? "Insufficient permissions"
      : isPolicyLevel
      ? "This is a policy level suppression. Deleting this suppression finding will enable the policy that caused it."
      : isFileLevel
      ? "File level suppression is controlled from config file in the repository."
      : "Publishes this particular finding to this assessment. This will add the finding back to the assessment metrics.",
    action: handleOnDeleteSuppressionClick,
    disabled: !hasOrgWriteScope || isFileLevel,
  };

  const showPolicy = {
    label: "Show Policy",
    linkTo: `/policies?selected=${finding?.sid}`,
  };

  const dropdownActions: DropdownItem[] = [
    finding?.sid ? showPolicy : null,
    finding?.suppressedTs ? deleteSuppression : suppress,
    !finding?.pass && autofixUiEnabled && finding?.autofix ? fix : null,
  ].filter((i) => i !== null) as DropdownItem[];

  const gitRepo = gitRepoName
    ? decodeURIComponent(gitRepoName)
    : assessmentDetail?.gitRepo;

  const getSuppressCondition = useCallback(
    (f: Finding, suppressionLevel: string) => {
      switch (suppressionLevel) {
        case "policy":
          return f.sid === finding?.sid;
        case "resource":
          return f.resource === finding?.resource;
        default:
          return f.id === finding?.id;
      }
    },
    [finding?.id, finding?.resource, finding?.sid],
  );

  const getDeleteSuppressionCondition = useCallback(
    (f: Finding) => {
      switch (finding?.suppressionLevel) {
        case "policy":
          return f.sid === finding?.sid && f.suppressionLevel === "policy";
        case "resource":
          return (
            f.resource === finding?.resource &&
            f.suppressionLevel === "resource"
          );
        default:
          return f.id === finding?.id;
      }
    },
    [finding],
  );

  const optimisticDeleteSuppression = useCallback(() => {
    const movedFindings = assessmentDetail?.suppressedFindings
      .filter((f) => getDeleteSuppressionCondition(f))
      .map((f) => ({ ...f, suppressedTs: undefined }));
    return optimisticObjectUpdater<AssessmentDetail>(queryKey, {
      suppressedFindings: assessmentDetail?.suppressedFindings.filter(
        (f) => !getDeleteSuppressionCondition(f),
      ),
      findings: [
        ...(assessmentDetail?.findings ?? []),
        ...(movedFindings ?? []),
      ],
    });
  }, [
    assessmentDetail?.suppressedFindings,
    assessmentDetail?.findings,
    queryKey,
    getDeleteSuppressionCondition,
  ]);

  const optimisticSuppress = useCallback(
    (suppressionLevel: string) => {
      const movedFindings = assessmentDetail?.findings
        .filter((f) => getSuppressCondition(f, suppressionLevel))
        .map((f) => ({
          ...f,
          suppressedTs: moment().toISOString(),
          suppressionLevel,
        }));

      return optimisticObjectUpdater<AssessmentDetail>(queryKey, {
        findings: assessmentDetail?.findings.filter(
          (f) => !getSuppressCondition(f, suppressionLevel),
        ),
        suppressedFindings: [
          ...(assessmentDetail?.suppressedFindings ?? []),
          ...(movedFindings ?? []),
        ],
      });
    },
    [
      assessmentDetail?.findings,
      assessmentDetail?.suppressedFindings,
      queryKey,
      getSuppressCondition,
    ],
  );

  return (
    <CollapsibleButtons
      buttons={
        <Box display="flex" alignSelf="baseline" textAlign="right">
          <Box display="inline-block" mr={1} mb={1}>
            {finding?.suppressedTs ? (
              <DeleteSuppressionModal
                onMutate={optimisticDeleteSuppression}
                ref={deleteSuppressionRef}
                gitRepo={gitRepo}
                finding={finding}
                assessmentDetail={assessmentDetail}
                refetch={refetch}
                onDeleteSuppression={onDeleteSuppression}
              />
            ) : (
              <SuppressionModal
                onMutate={optimisticSuppress}
                ref={suppressRef}
                gitRepo={gitRepo}
                finding={finding}
                assessmentDetail={assessmentDetail}
                refetch={refetch}
                onSuppress={onSuppress}
              />
            )}
          </Box>
          {finding?.autofix && !finding.pass && autofixUiEnabled && (
            <FixFindingModal
              ref={fixRef}
              gitRepo={gitRepo}
              finding={finding}
              assessmentDetail={assessmentDetail}
            />
          )}
          {finding?.sid && (
            <Box ml={1}>
              <Button
                component={Link}
                variant="text"
                to={`/policies?selected=${finding.sid}`}
                color="primary"
              >
                Show Policy
              </Button>
            </Box>
          )}
        </Box>
      }
      items={dropdownActions}
      collapsed={matches}
    />
  );
};

export default FindingDetailActions;
