import React, { FC, Fragment, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Switch, Typography } from "@material-ui/core";
import { groupBy } from "lodash";
import moment from "moment";

import { getLocaleDateString } from "helpers/formatter";
import organizationService from "services/organizationService";
import { OrganizationActivity } from "types";

interface OrganizationDetailActivityProps {
  orgId: string;
}

const OrganizationDetailActivity: FC<OrganizationDetailActivityProps> = ({
  orgId,
}) => {
  const {
    data = [],
    isLoading,
    isError,
  } = useQuery(
    `organizations-activity-${orgId}`,
    () => organizationService.getOrganizationActivity(orgId),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );

  const groupedActivity = useMemo(() => {
    const groupedResults = groupBy(data, (result) => {
      const date = moment(result.createTs, moment.ISO_8601);
      const formattedDate = date.calendar(Date.now(), {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse: getLocaleDateString(),
      });
      return formattedDate;
    });
    return groupedResults;
  }, [data]);

  const groupedActivityKeys = useMemo(
    () => Object.keys(groupedActivity),
    [groupedActivity],
  );

  return (
    <Fragment>
      {isLoading ? (
        <Skeleton count={5} />
      ) : isError || data.length === 0 ? (
        <Box pt={8} textAlign="center">
          <Typography color="textSecondary" variant="h1" gutterBottom>
            <FontAwesomeIcon icon={faBookOpen} />
          </Typography>
          <Typography variant="body2" gutterBottom>
            There is no activity recorded.
          </Typography>
        </Box>
      ) : (
        groupedActivityKeys.map((day, i) => (
          <Fragment key={i}>
            <Box mt={i === 0 ? 0 : 2.5} mb={2.5}>
              <Typography variant="h4">{day}</Typography>
            </Box>
            {groupedActivity[day].map((j, y) => (
              <OrganizationActivityRow activity={j} key={y} />
            ))}
          </Fragment>
        ))
      )}
    </Fragment>
  );
};

const OrganizationActivityRow: FC<{ activity: OrganizationActivity }> = ({
  activity,
}) => {
  return (
    <Box my={1}>
      <Grid container spacing={2}>
        <Grid item xs={3} sm="auto">
          {moment(activity.createTs, moment.ISO_8601).format("hh:mm:ss A")}
        </Grid>
        <Grid item xs={9}>
          <Box>
            <Box fontWeight="fontWeightMedium" display="inline">
              {activity.user}
            </Box>{" "}
            <OrganizationActivityAction activity={activity} />
          </Box>
          <Box maxWidth={600} p={2} bgcolor="lightBackground.main">
            {activity.comment}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const OrganizationActivityAction: FC<{ activity: OrganizationActivity }> = ({
  activity,
}) => {
  switch (activity.activityType) {
    case "STATUS_CHANGE":
      return (
        <Fragment>
          {`${
            activity.active ? "activated" : "deactivated"
          } this organization.`}
          <Switch
            checked={activity.active}
            color="primary"
            size="small"
            disabled
          />
        </Fragment>
      );
    default:
      return <Fragment>made a {activity.activityType}.</Fragment>;
  }
};

export default OrganizationDetailActivity;
