import React, { FC, Fragment } from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import useCommonStyles from "theme/useCommonStyles";

import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import { ZodiacInstance } from "types/zodiac";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 600,
    },
    bold: { fontWeight: 500 },
  }),
);

interface ZodiacInstanceExpandedProps {
  instance: ZodiacInstance;
}

const ZodiacInstanceExpanded: FC<ZodiacInstanceExpandedProps> = ({
  instance,
}) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  return (
    <Grid className={classes.root} container spacing={1}>
      <Grid className={classes.bold} item xs={3}>
        Instance Id
      </Grid>
      <Grid className={commonStyles.wordBreakAll} item xs={9}>
        {instance.instanceId}
      </Grid>
      {instance.buildRevision && (
        <Fragment>
          <Grid className={classes.bold} item xs={3}>
            Revision
          </Grid>
          <Grid className={commonStyles.wordBreakAll} item xs={9}>
            {instance.buildRevision}
          </Grid>
        </Fragment>
      )}
      {instance.buildRevision && (
        <Fragment>
          <Grid className={classes.bold} item xs={3}>
            Built
          </Grid>
          <Grid className={commonStyles.wordBreakAll} item xs={9}>
            <RelativeTimeFormatter dateTs={instance.buildTs} />
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default ZodiacInstanceExpanded;
