import React, { FC, useState } from "react";
import {
  Box,
  Collapse,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  Theme,
} from "@material-ui/core";
import {
  AddCircleOutlineRounded,
  RemoveCircleRounded,
} from "@material-ui/icons";
import moment from "moment";

import { PolicyActivityToggle as PolicyActivityToggleI } from "types/policies";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      opacity: 0.7,
      padding: 0,
    },
    switch: {
      position: "absolute",
      top: -8,
    },
    cursorPointer: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  }),
);

interface PolicyActivityToggleProps {
  activity: PolicyActivityToggleI;
}

const PolicyActivityToggle: FC<PolicyActivityToggleProps> = ({ activity }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const toggleExpanded = () => setExpanded((prev) => !prev);

  return (
    <Box className={classes.cursorPointer} my={1} onClick={toggleExpanded}>
      <Grid container spacing={4}>
        <Grid item xs={3} sm={2}>
          {moment(activity.createTs, moment.ISO_8601).format("hh:mm:ss A")}
        </Grid>
        <Grid item xs={9} sm={10}>
          <Box position="relative">
            <IconButton className={classes.iconButton} size="small">
              {expanded ? <RemoveCircleRounded /> : <AddCircleOutlineRounded />}
            </IconButton>
            <Box ml={1} fontWeight="fontWeightMedium" display="inline">
              {activity.user}
            </Box>{" "}
            {!activity.suppressed
              ? "enabled this policy"
              : "disabled this policy"}
            .
            <Switch
              disabled
              readOnly
              color="primary"
              checked={!activity.suppressed}
              className={classes.switch}
            />
          </Box>
          <Collapse in={expanded}>
            <Box borderRadius={4} p={2} bgcolor="lightBackground.main">
              {activity.comment}
            </Box>
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PolicyActivityToggle;
