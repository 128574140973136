/* istanbul ignore file */
import React, { FC } from "react";
import { TablePagination } from "@material-ui/core";

import TablePaginationActions from "components/DataTable/components/TablePaginationActions";

export interface TablePaginationProps {
  pageSizeOptions: number[];
  pageSize: number;
  count: number;
  pageIndex: number;
  onChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ): void;
  onChangeRowsPerPage?(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void;
}

const AssessmentsPagination: FC<TablePaginationProps> = ({
  count,
  onChangePage,
  pageIndex,
  pageSize,
  pageSizeOptions,
  onChangeRowsPerPage,
}) => {
  const labelDisplayedRows = ({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) =>
    `${from}-${to} of ${
      count !== -1 ? (count >= 500 ? "many" : count) : `more than ${to}`
    }`;

  return (
    <TablePagination
      rowsPerPageOptions={pageSizeOptions}
      component="div"
      count={count}
      rowsPerPage={pageSize}
      page={pageIndex}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      labelDisplayedRows={labelDisplayedRows}
      SelectProps={{
        inputProps: { "aria-label": "rows per page" },
      }}
      ActionsComponent={(props) => <TablePaginationActions {...props} />}
    />
  );
};

export default AssessmentsPagination;
