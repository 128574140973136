import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import CLI from "./CLI";
import CLIOAuth from "./pages/CLIOAuth/CLIOAuth";
import HasFlagRoute from "router/HasFlagRoute";

const CLIRouter: FC = () => {
  return (
    <Routes>
      <Route path="/callback" element={<CLIOAuth />} />

      <Route
        path=""
        element={<HasFlagRoute flag="cliPageEnabled" element={<CLI />} />}
      />

      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default CLIRouter;
