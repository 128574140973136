import React, { FC, useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { upperFirst } from "lodash";

import { useInterval } from "hooks/useInterval";

interface ErrorCardProps {
  title: string;
  message: string;
  onAction: () => void;
  delay: number;
}

const ErrorCard: FC<ErrorCardProps> = ({ title, message, onAction, delay }) => {
  const [seconds, setSeconds] = useState(delay);

  useInterval(() => setSeconds((prev) => prev - 1), 1000);

  useEffect(() => {
    if (seconds === 0) onAction();
  }, [seconds, onAction]);

  return (
    <Box textAlign="center">
      <Box component={"h2"} mb={5}>
        {title}
      </Box>
      <Box component={"h4"} fontWeight="400" mb={5}>
        {upperFirst(message)}.
      </Box>
      <Button onClick={onAction} color="primary" variant="contained">
        Continue
      </Button>
      <Box mt={5} mb={2} style={{ opacity: 0.7 }}>
        You will be redirected in {seconds}s ...
      </Box>
    </Box>
  );
};

export default ErrorCard;
