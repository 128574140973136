import axios from "./axiosInstance";
import { AdapterMetadata, APIResponse } from "types";

const getIntegrations = async (orgId: string) =>
  axios
    .get<APIResponse<{ types: AdapterMetadata[] }>>(
      `/org/${orgId}/adapter-metadata`,
    )
    .then((res) => res.data.data.types)
    .then((data) => [
      ...data.sort(
        (itemA: AdapterMetadata, itemB: AdapterMetadata) =>
          (itemA.enabled === itemB.enabled ? 0 : itemA.enabled ? -1 : 1) ||
          (itemA.displayName > itemB.displayName ? 1 : -1),
      ),
    ]);

const getConfiguration = async (orgId: string, configType: string) =>
  axios.get(`/org/${orgId}/adapter-metadata/${configType}`);

const getBitBucketIntegrationStatus = async (orgId: string) =>
  axios
    .get<APIResponse<{ completed: boolean }>>(
      `/org/${orgId}/integration/bitbucket/completed`,
    )
    .then((res) => res.data.data);

const getBitBucketConnectionURL = async (orgId: string) =>
  axios
    .get<APIResponse<{ connectUrl: string }>>(
      `org/${orgId}/bitbucket/connect-url`,
    )
    .then((res) => res.data.data);

const getGitLabIntegrationStatus = async (orgId: string) =>
  axios
    .get<APIResponse<{ completed: boolean }>>(
      `/org/${orgId}/integration/gitlab/completed`,
    )
    .then((res) => res.data.data);

const sendFeedback = async (data: { message: string }) =>
  axios.post(`/feedback`, data);

export default {
  getIntegrations,
  getConfiguration,
  getBitBucketIntegrationStatus,
  getBitBucketConnectionURL,
  getGitLabIntegrationStatus,
  sendFeedback,
};
