import React, { FC, Fragment } from "react";
import { useLocation } from "react-router-dom";
import {
  faBitbucket,
  faGithub,
  faGitlab,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: "0.9rem",
      fontSize: "1rem",
      lineHeight: "1.25",
      borderRadius: "5px",
      fontWeight: 600,
      color: "white",
      marginBottom: theme.spacing(3),
    },
    buttonGoogle: {
      background: "#4285f4",
      "&:hover": {
        backgroundColor: "#1e6ef2",
        borderColor: "#1266f1",
        boxShadow: "none",
        color: "white ",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "#1266f1",
        borderColor: "#0e60e9",
        color: "white",
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
      },
    },
    buttonGitHub: {
      background: "#333",
      "&:hover": {
        backgroundColor: "#202020",
        borderColor: "#1e6ef2",
        boxShadow: "none",
        color: "white ",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "#202020",
        borderColor: "#1a1a1a",
        color: "white",
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
      },
    },
    buttonGitlab: {
      background: "#eb5338",
      "&:hover": {
        backgroundColor: "#e63617",
        borderColor: "#da3416",
        boxShadow: "none",
        color: "white ",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "#da3416",
        borderColor: "#ce3115",
        color: "white",
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
      },
    },
    buttonBitbucket: {
      background: "#0747a6",
      "&:hover": {
        backgroundColor: "#033f99",
        borderColor: "#0375d1",
        boxShadow: "none",
        color: "white ",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "#0375d1",
        borderColor: "#0375d1",
        color: "white",
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
      },
    },
    mb0: {
      marginBottom: 0,
    },
  }),
);

interface SocialButtonsProps {
  isNewUser?: boolean;
}

const SocialButtons: FC<SocialButtonsProps> = ({ isNewUser }) => {
  const { state } = useLocation();
  const classes = useStyles();
  const { pathname = "", search = "" } = state || {};
  const buttonActionText = isNewUser ? "Sign up" : "Log in";

  return (
    <Fragment>
      <Button
        role="github-login"
        component="a"
        fullWidth
        href={`/api/v1/auth/github-login${
          state ? `?resumeUrl=${encodeURIComponent(pathname + search)}` : ""
        }`}
        className={classNames(classes.button, classes.buttonGitHub)}
      >
        <Box mr={2.5}>
          <FontAwesomeIcon icon={faGithub} transform="grow-8" />
        </Box>
        <Box width={192}> {`${buttonActionText} with GitHub`}</Box>
      </Button>

      <Button
        component="a"
        fullWidth
        href={`/api/v1/auth/gitlab-login${
          state ? `?resumeUrl=${encodeURIComponent(pathname + search)}` : ""
        }`}
        className={classNames(classes.button, classes.buttonGitlab)}
      >
        <Box mr={2.5}>
          <FontAwesomeIcon icon={faGitlab} transform="grow-8" />
        </Box>
        <Box width={192}> {`${buttonActionText} with GitLab`}</Box>
      </Button>

      <Button
        component="a"
        fullWidth
        href={`/api/v1/auth/bitbucket-login${
          state ? `?resumeUrl=${encodeURIComponent(pathname + search)}` : ""
        }`}
        className={classNames(classes.button, classes.buttonBitbucket)}
      >
        <Box mr={2.5}>
          <FontAwesomeIcon icon={faBitbucket} transform="grow-8" />
        </Box>
        <Box width={192}> {`${buttonActionText} with Bitbucket`}</Box>
      </Button>
      <Button
        role="google-login"
        component="a"
        fullWidth
        href={`/api/v1/auth/google-login${
          state ? `?resumeUrl=${encodeURIComponent(pathname + search)}` : ""
        }`}
        className={classNames(
          classes.button,
          classes.buttonGoogle,
          classes.mb0,
        )}
      >
        <Box mr={2.5}>
          <FontAwesomeIcon icon={faGoogle} transform="grow-8" />
        </Box>
        <Box width={192}> {`${buttonActionText} with Google`}</Box>
      </Button>
    </Fragment>
  );
};

export default SocialButtons;
