import React, { Component, ErrorInfo } from "react";
import { Box, Grid } from "@material-ui/core";

import { ReactComponent as BugIllustration } from "assets/svg/illustrations/bug-fix.svg";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import ErrorCard from "pages/Error/ErrorPage/components/ErrorCard";
import UserModelError from "pages/Error/UserModelError/UserModelError";
import crashService from "services/crashService";

class ErrorBoundary extends Component {
  public state = {
    hasError: false,
    isErrorReportSent: false,
    type: "",
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // A single error could cause an indefinite amount of internal errors on the app
  // because of this, we only want to create one report for crash instance.

  componentDidCatch(error: Error, info: ErrorInfo) {
    if (!this.state.isErrorReportSent) {
      if (process.env.NODE_ENV !== "development") {
        crashService.sendCrashReport({ ...error, ...info });
      }
      this.setState({
        ...this.state,
        isErrorReportSent: true,
        type: error.cause?.name,
      });
    }
  }

  public render() {
    if (process.env.NODE_ENV === "development") return this.props.children;

    if (this.state.hasError) {
      return this.state.type === "userMissingOrgs" ? (
        <UserModelError />
      ) : (
        <NavbarTopLayout white>
          <Grid container alignItems="center" justify="center">
            <Grid item sm={12}>
              <Box my={5} textAlign="center">
                <BugIllustration width={320} height={208} />
              </Box>
              <ErrorCard
                title="Something went wrong"
                message="An unexpected error has ocurred"
                onAction={() => window.location.reload()}
                delay={10}
              />
            </Grid>
          </Grid>
        </NavbarTopLayout>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
