import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Box, BoxProps } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { ChartData, ChartOptions, ChartType } from "chart.js";
import Chart from "chart.js/auto";
import _, { noop } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    canvasContainer: {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
    },
    canvas: {
      width: "100% !important",
      height: "100% !important",
    },
  }),
);

interface ChartWrapperProps extends BoxProps {
  type: ChartType;
  data: ChartData;
  options: ChartOptions;
  _test?: boolean;
}

const ChartWrapper: FC<ChartWrapperProps> = ({
  type,
  data,
  options,
  height = "auto",
  width = "100%",
  _test,
  ...props
}) => {
  const classes = useStyles();

  const chartInstance = useRef<Chart>({
    update: noop,
    destroy: noop,
  } as Chart);
  const CHART_ID = useMemo(() => _.uniqueId("Chart"), []);

  useEffect(() => {
    chartInstance.current.data = data;
    chartInstance.current.options = options;
    chartInstance.current.update("none");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options]);

  const nodeRef = useCallback<(instance: HTMLCanvasElement | null) => void>(
    (node) => {
      chartInstance.current.destroy();

      if (node) {
        chartInstance.current = new Chart(node, {
          type,
          data,
          options,
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    return () => chartInstance.current.destroy();
  }, []);

  if (_test) return null;

  return (
    <Box
      className={classes.root}
      position="relative"
      width={width}
      height={height}
      {...props}
    >
      <div className={classes.canvasContainer}>
        <canvas id={CHART_ID} ref={nodeRef} height={height} width={width} />
      </div>
    </Box>
  );
};

export default ChartWrapper;
