import React, { FC } from "react";
import {
  Box,
  Card,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";

import ComingSoonProviderModal from "./components/ComingSoonProviderModal";
import GitProviderButton from "./components/GitProviderButton";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 500,
      textAlign: "center",
      padding: theme.spacing(6.5),
    },
    rootSmall: {
      border: 0,
      padding: theme.spacing(4),
    },
    title: {
      fontWeight: 500,
      marginBottom: theme.spacing(7),
    },
    primaryDarkTextColor: {
      color: theme.palette.primary.dark,
    },
  }),
);

const GetStarted: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <NavbarTopLayout disableLink white>
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Card
          variant="outlined"
          className={classNames(classes.root, { [classes.rootSmall]: matches })}
        >
          <Typography
            variant="h1"
            color="primary"
            className={classNames(classes.title, classes.primaryDarkTextColor)}
          >
            Get Started with Lacework IaC Security
          </Typography>

          <Typography className={classes.title} variant="h3">
            Connect to Git Provider
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height={170}
            mb={matches ? 0 : 8}
            justifyContent="space-between"
          >
            <GitProviderButton
              href="api/v1/public/setup/git-provider/github"
              style={{ width: 160 }}
              provider="github"
            >
              GitHub
            </GitProviderButton>
            <GitProviderButton
              href="api/v1/public/setup/git-provider/gitlab"
              style={{ width: 160 }}
              provider="gitlab"
            >
              Gitlab
            </GitProviderButton>
            <GitProviderButton
              href="api/v1/public/setup/git-provider/bitbucket"
              style={{ width: 160 }}
              provider="bitbucket"
            >
              Bitbucket
            </GitProviderButton>
          </Box>
        </Card>
      </Box>
      <ComingSoonProviderModal />
    </NavbarTopLayout>
  );
};

export default GetStarted;
