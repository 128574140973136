import React, { FC, Fragment, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Column } from "react-table";
import {
  Box,
  Card,
  createStyles,
  Drawer,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { LayersOutlined } from "@material-ui/icons";

import { useCurrentOrgId } from "providers";

import ResourceDrawer from "./components/ResourceDrawer";
import DataTable from "components/DataTable";
import { DataTableRef } from "components/DataTable/DataTable";
import PageHeader from "components/PageHeader";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import resourcesService from "services/resourcesService";
import { Resource } from "types/resources";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      height: "80%",
    },
  }),
);

const columns: Column<Resource>[] = [
  {
    Header: "Resource Name (ARN)",
    accessor: "resourceArn",
  },
  {
    Header: "File",
    accessor: "file",
    Cell: ({ row: { original: row } }) => (
      <Box onClick={(e) => e.stopPropagation()}>
        <a href={row.gitLocationUrl} target="_blank" rel="noopener noreferrer">
          {`${row.file} : Line ${row.line}`}
        </a>
      </Box>
    ),
  },
  {
    Header: "Line #",
    accessor: "line",
    width: 72,
    minWidth: 72,
  },
  {
    Header: "Terraform Address",
    accessor: "terraformAddress",
    collapse: true,
  },
  {
    Header: "Last Updated",
    accessor: "updateTs",
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    Cell: ({ row: { original: row } }) => (
      <RelativeTimeFormatter dateTs={row.updateTs} />
    ),
  },
];

const Resources: FC = () => {
  const tableRef = useRef<DataTableRef<Resource>>(null);
  const [resourceDrawer, setResourceDrawer] = useState<{
    resource?: Resource;
    open: boolean;
  }>({ open: false });

  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: resources = [], isLoading } = useQuery(
    ["resources", orgId],
    () => resourcesService.getResources(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    },
  );

  return (
    <Fragment>
      <PageHeader>Resources</PageHeader>
      <Card>
        <Box p={2}>
          <DataTable
            search
            ref={tableRef}
            columns={columns}
            data={resources}
            isLoading={isLoading}
            pagination
            onRowClick={(row) =>
              setResourceDrawer({ open: true, resource: row.original })
            }
            rowHover
            pageSizeOptions={[50, 100, 200]}
            initialRowsPerPage={50}
            noDataComponent={
              <Box
                py={4}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ opacity: 0.4 }}
              >
                <Box mb={3}>
                  <Typography variant="h4" gutterBottom>
                    No resources found
                  </Typography>
                </Box>
                <Box component={LayersOutlined} fontSize={60} />
              </Box>
            }
          />
        </Box>
      </Card>

      <Drawer
        classes={{ paperAnchorBottom: classes.drawer }}
        anchor={matches ? "bottom" : "right"}
        open={resourceDrawer.open}
        onClose={() => setResourceDrawer({ ...resourceDrawer, open: false })}
        onExited={() => setResourceDrawer({ open: false })}
      >
        {resourceDrawer.resource && (
          <ResourceDrawer resource={resourceDrawer.resource} />
        )}
      </Drawer>
    </Fragment>
  );
};

export default Resources;
