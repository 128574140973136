import { FC, useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Card } from "@material-ui/core";
import { isUndefined } from "lodash";

import { useCurrentOrgId } from "providers";

import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import IacBotJobs from "pages/IacBot/pages/IacBot/components/IacBotJobs";
import repositoryService from "services/repositoryService";
import { IacBotJob } from "types/iacBot";

const defaultQuery: IacBotJob[] = [];

const RepositoryJobs: FC = () => {
  const orgId = useCurrentOrgId();

  const { gitRepoName = "" } = useParams<{ gitRepoName: string }>();

  const {
    data = defaultQuery,
    isLoading,
    refetch,
  } = useQuery(
    ["repository-jobs", orgId, gitRepoName],
    () => repositoryService.getRepoIaCJobs(orgId, gitRepoName),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    },
  );

  const refetchData = () => {
    toast.promise(
      refetch,
      {
        pending: "Refreshing data...",
        success: "Refreshed successfully!",
        error:
          "An error ocurred while refreshing the data. Please try again later.",
      },
      {
        autoClose: 2000,
      },
    );
  };

  const validatedJobs = useMemo(() => {
    return data?.map((j) => {
      const jobStatus = !isUndefined(j.status)
        ? j.status
        : isUndefined(j.success)
        ? "QUEUED"
        : j.success
        ? "COMPLETED"
        : "FAILED";

      return {
        ...j,
        status: jobStatus,
      };
    });
  }, [data]);

  return (
    <Box component={Card} p={2}>
      <IacBotJobs
        repoSpecific
        refetch={refetchData}
        isLoading={isLoading}
        jobs={validatedJobs}
      />
    </Box>
  );
};

export default RepositoryJobs;
