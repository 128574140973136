import React, { FC, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import {
  Box,
  Card,
  createStyles,
  lighten,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import zodiacService from "services/zodiacService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: lighten(theme.palette.primary.light, 0.7),
      color: theme.palette.primary.main,
      height: "100%",
    },
  }),
);
interface ZodiacBuildSummaryProps {
  ciBuildId: string;
}

const ZodiacBuildSummary: FC<ZodiacBuildSummaryProps> = ({ ciBuildId }) => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const { data: jobBuildSummary, isLoading: isMetadataLoading } = useQuery(
    ["job-detail-summary", orgId, ciBuildId],
    () => zodiacService.getZodiacBuildAssessmentsSummary(orgId, ciBuildId),
    {
      onError(error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Card className={classes.root}>
      <Box p={2}>
        {isMetadataLoading ? (
          <Skeleton count={4} />
        ) : (
          <Fragment>
            <Typography variant="h4" color="primary" gutterBottom>
              Summary
            </Typography>
            <Box component="ul" m={0} pl={2}>
              {jobBuildSummary?.length === 0 ? (
                <Box py={2}>No data</Box>
              ) : (
                jobBuildSummary?.map((s, i) => (
                  <li key={i}>
                    <Box display="inline" fontWeight="600">{`${
                      s.count ?? ""
                    } `}</Box>
                    {s.message ?? ""}
                    <Box display="inline" fontWeight="600">{` ${
                      s.category ?? ""
                    } `}</Box>
                  </li>
                ))
              )}
            </Box>
          </Fragment>
        )}
      </Box>
    </Card>
  );
};

export default ZodiacBuildSummary;
