import React, {
  createRef,
  FC,
  Fragment,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Column, Row } from "react-table";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { BuildRounded } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";

import { useHasScope } from "providers";

import { AssessmentDetailsContext } from "../context/AssessmentDetailsContext";
import { AssessmentDetailsTableActions } from "./DetailBody";
import FindingsRow from "./FindingsRow";
import FixFindingModal from "./FixFindingModal";
import SuppressionModal from "./SuppressionModal";
import BootstrapBadge from "components/BootstrapBadge/BootstrapBadge";
import DataTable from "components/DataTable";
import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import { DataTableRef } from "components/DataTable/DataTable";
import { ArrayQueryKey, optimisticObjectUpdater } from "helpers/queryHelpers";
import { sortSeverity } from "helpers/tableHelpers";
import { AssessmentDetail, Finding } from "types/assessments";
import { Permissions, Resources } from "types/auth-roles";

const columnsDef = (
  showFindingAction: boolean,
  showResource: boolean,
  showFix: boolean,
  hasNoSuppressionOptions: boolean,
  hasOrgWriteScope: boolean,
  { onSuppressFinding, onFixFinding }: AssessmentDetailsTableActions,
): Column<Finding>[] => [
  {
    Header: "Severity",
    accessor: "severity",
    filter: "includesSome",
    sortType: sortSeverity,
  } as Column<Finding>,
  {
    Header: "Compliance",
    accessor: "compliance",
    filter: "includesSome",
  } as Column<Finding>,
  {
    Header: "Pass",
    accessor: "pass",
    filter: (rows, columnIds, filterValue) =>
      filterValue === ""
        ? rows
        : filterValue
        ? rows.filter((r) => r.original.pass)
        : rows.filter((r) => !r.original.pass),
  },
  {
    Header: "Autofix",
    accessor: "autofix",
    filter: (rows, columnIds, filterValue) =>
      filterValue === ""
        ? rows
        : filterValue
        ? rows.filter((r) => r.original.autofix)
        : rows.filter((r) => !r.original.autofix),
  },
  {
    Header: "Resource",
    accessor: "resource",
  },
  {
    Header: "SID",
    accessor: "sid",
  },
  {
    Header: "File",
    accessor: "fileName",
  },
  {
    Header: "File Path",
    accessor: "filePath",
  },
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ row: { original: row } }) => (
      <FindingsRow finding={row} showResource={showResource} />
    ),
    collapse: true,
  },
  {
    Header: "Actions",
    accessor: "id",
    isButton: true,
    collapse: true,
    disableSortBy: true,
    Cell: ({ row: { original: row } }) => {
      const shouldShowPolicy = row.category
        ? row.category === "iac" || row.category === "infraScan"
        : showFindingAction;

      const dropdownActions: DropdownItem[] = [];
      const showPolicy = {
        label: "Show Policy",
        linkTo: `/policies?selected=${row.sid}`,
      };
      const togglePolicy = {
        label: "Suppress",
        action: () => onSuppressFinding(row),
        disabled:
          !hasOrgWriteScope ||
          hasNoSuppressionOptions ||
          !(row?.allowSuppression ?? true),
        hover: hasNoSuppressionOptions
          ? "No suppression methods available for this assessment"
          : !hasOrgWriteScope
          ? "Insufficient permissions"
          : !(row?.allowSuppression ?? true)
          ? "This finding only supports file-based suppresssion."
          : "",
      };

      const fix = {
        label: "Fix",
        action: () => onFixFinding(row),
      };

      // tslint:disable-next-line: no-unused-expression
      shouldShowPolicy && dropdownActions.push(showPolicy);
      // tslint:disable-next-line: no-unused-expression
      dropdownActions.push(togglePolicy);
      if (row.autofix && !row.pass && showFix) dropdownActions.push(fix);

      return (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-end"
          position="relative"
        >
          <DropdownActions items={dropdownActions} />
          <Box mt={1} width={19}>
            {row.pass ? (
              <Box color="success.main">
                <Typography variant="h5" color="inherit">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="h5" color="error">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </Typography>
              </Box>
            )}
          </Box>
          {row.autofix && !row.pass && showFix && (
            <Box ml={-2.75} onClick={() => onFixFinding(row)}>
              <BootstrapBadge color="primary">
                <Box display="flex" alignItems="center">
                  <Box mr={0.5} fontSize={8}>
                    <BuildRounded fontSize="inherit" />
                  </Box>
                  Fix Now
                </Box>
              </BootstrapBadge>
            </Box>
          )}
        </Box>
      );
    },
  },
];

interface FindingsTableProps {
  gitRepo?: string;
  onFindingSelect(finding: Row<Finding>): void;
  tableRef?: RefObject<DataTableRef<Finding>>;
  queryKey: ArrayQueryKey;
  initialPageIndex?: number;
}

const FindingsTable: FC<FindingsTableProps> = ({
  gitRepo,
  onFindingSelect,
  tableRef,
  queryKey,
  initialPageIndex,
}) => {
  const { assessmentDetail, refetch, isLoading } = useContext(
    AssessmentDetailsContext,
  );
  const [findingToProcess, setFindingToProcess] = useState<Finding>();
  const suppressRef = createRef<HTMLButtonElement>();
  const { autofixUiEnabled } = useFlags();
  const fixRef = createRef<HTMLButtonElement>();
  const clearFinding = () => setFindingToProcess(undefined);
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );

  const openSuppressionModal = useCallback(
    (finding: Finding) => {
      setFindingToProcess(finding);
      suppressRef.current?.click();
    },
    [suppressRef],
  );

  const openFixModal = useCallback(
    async (finding: Finding) => {
      setFindingToProcess(finding);
      fixRef.current?.click();
    },
    [fixRef],
  );

  const hasNoSuppressionOptions =
    !(
      assessmentDetail?.category === "iac" ||
      assessmentDetail?.category === "infraScan"
    ) &&
    !assessmentDetail?.gitRepoName &&
    !gitRepo;

  const columns = useMemo(
    () =>
      columnsDef(
        assessmentDetail?.category === "iac" ||
          assessmentDetail?.category === "infraScan" ||
          assessmentDetail?.category === "secrets",
        !!assessmentDetail?.showResource,
        autofixUiEnabled,
        hasNoSuppressionOptions,
        hasOrgWriteScope,
        {
          onSuppressFinding: openSuppressionModal,
          onFixFinding: openFixModal,
        },
      ),
    [
      assessmentDetail?.category,
      assessmentDetail?.showResource,
      autofixUiEnabled,
      hasNoSuppressionOptions,
      hasOrgWriteScope,
      openSuppressionModal,
      openFixModal,
    ],
  );

  const data = assessmentDetail?.findings ?? [];

  const getSuppressCondition = useCallback(
    (f: Finding, suppressionLevel: string) => {
      switch (suppressionLevel) {
        case "policy":
          return f.sid === findingToProcess?.sid;
        case "resource":
          return f.resource === findingToProcess?.resource;
        default:
          return f.id === findingToProcess?.id;
      }
    },
    [findingToProcess?.id, findingToProcess?.resource, findingToProcess?.sid],
  );

  const optimisticSuppress = useCallback(
    (suppressionLevel: string) => {
      const movedFindings = assessmentDetail?.findings
        .filter((f) => getSuppressCondition(f, suppressionLevel))
        .map((f) => ({
          ...f,
          suppressedTs: moment().toISOString(),
          suppressionLevel,
        }));

      return optimisticObjectUpdater<AssessmentDetail>(queryKey, {
        findings: assessmentDetail?.findings.filter(
          (f) => !getSuppressCondition(f, suppressionLevel),
        ),
        suppressedFindings: [
          ...(assessmentDetail?.suppressedFindings ?? []),
          ...(movedFindings ?? []),
        ],
      });
    },
    [
      assessmentDetail?.findings,
      assessmentDetail?.suppressedFindings,
      queryKey,
      getSuppressCondition,
    ],
  );

  return (
    <Fragment>
      <DataTable
        autoResetFilters={false}
        columns={columns}
        data={data}
        disableSelectActions
        searchActions={
          data.length ? (
            <Box
              position="absolute"
              width="99%"
              borderBottom="1px solid Gainsboro"
            >
              &nbsp;
            </Box>
          ) : null
        }
        hiddenColumns={[
          "severity",
          "pass",
          "resource",
          "compliance",
          "sid",
          "fileName",
          "filePath",
          "autofix",
        ]}
        hideTableHead
        initialPageIndex={initialPageIndex}
        initialRowsPerPage={5}
        isLoading={isLoading}
        noDataComponent="No findings."
        onRowClick={onFindingSelect}
        ref={tableRef}
        rowHover
      />
      <SuppressionModal
        hideButton
        gitRepo={gitRepo}
        ref={suppressRef}
        assessmentDetail={assessmentDetail}
        refetch={refetch}
        finding={findingToProcess}
        onClose={clearFinding}
        onMutate={optimisticSuppress}
      />
      <FixFindingModal
        hideButton
        gitRepo={gitRepo}
        ref={fixRef}
        assessmentDetail={assessmentDetail}
        finding={findingToProcess}
        onClose={clearFinding}
      />
    </Fragment>
  );
};

export default FindingsTable;
