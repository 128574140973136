import React, { FC, Fragment, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import SyntaxHighlighter from "react-syntax-highlighter";
import xonokai from "react-syntax-highlighter/dist/cjs/styles/prism/xonokai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  fade,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";

import { useCurrentOrgId } from "providers";

import NotificationDescription, {
  NotificationTypeIcon,
} from "./NotificationDescription";
import { facSeeConfig } from "assets/svg/icons/custom-fa-icons";
import notificationService from "services/notificationService";
import { Notification as NotificationI } from "types/notifications";

interface NotificationProps {
  notification: NotificationI;
  expanded?: boolean;
  unread?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transition: "background 0.2s",
      "&:hover": {
        background: theme.palette.lightBackground.main,
        textDecoration: "none",
        color: "inherit",
      },
      padding: theme.spacing(1.5),
      display: "flex",
      textDecoration: "none",
      color: "inherit",
    },
    unread: {
      background: fade(theme.palette.secondary.light, 0.075),
      "&:hover": {},
    },
  }),
);

const Notification: FC<NotificationProps> = ({
  notification,
  expanded,
  unread,
}) => {
  const classes = useStyles();
  const orgId = useCurrentOrgId();
  const [open, setOpen] = useState(false);

  const { data: notificationDetail, isLoading } = useQuery(
    ["notificationDetail", orgId, notification.id],
    () => notificationService.getNotificationDetail(orgId, notification.id),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: open,
    },
  );

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  return (
    <Fragment>
      <Box className={classNames(classes.root, { [classes.unread]: unread })}>
        <Box width="100%" display="flex" justifyContent="space-between">
          <NotificationDescription
            notification={notification}
            expanded={expanded}
            unread={unread}
          />
          {expanded && (
            <Tooltip title="See config" arrow>
              <Box alignSelf="center">
                <IconButton onClick={handleClickOpen} color="primary">
                  <FontAwesomeIcon icon={facSeeConfig} />
                </IconButton>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleOnClose}>
        <Box p={3} pb={0} display="flex">
          <Typography variant="h3" gutterBottom>
            <NotificationTypeIcon type={notification.type} />
            {notification.title}
          </Typography>
        </Box>
        <DialogContent>
          <Box mb={2} p={1}>
            <NotificationDescription
              notification={notification}
              expanded
              hideHeader
            />
          </Box>
          <Box mb={2} p={1}>
            {isLoading ? (
              <Card variant="outlined">
                <Box p={2}>
                  <Skeleton count={7} />
                </Box>
              </Card>
            ) : (
              <SyntaxHighlighter language="json" style={xonokai}>
                {notificationDetail?.raw + ""}
              </SyntaxHighlighter>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Button onClick={handleOnClose} color="primary">
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Notification;
