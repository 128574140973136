import React, { FC, Fragment } from "react";
import classNames from "classnames";
import moment from "moment";

import { getLocaleDateString } from "helpers/formatter";

interface TimestampFormatterProps {
  dateTs: string | undefined;
  oneLine?: boolean;
  disableCenterText?: boolean;
}

const DATE_FORMAT = getLocaleDateString().toUpperCase();
const TIME_FORMAT = "HH:mm:ss";

const TimestampFormatter: FC<TimestampFormatterProps> = ({
  dateTs,
  oneLine = false,
  disableCenterText = false,
}) => {
  return (
    <div className={classNames({ "text-center": !disableCenterText })}>
      {dateTs &&
        (oneLine ? (
          <Fragment>
            {moment(dateTs).format(`${DATE_FORMAT} - ${TIME_FORMAT}`)}
          </Fragment>
        ) : (
          <Fragment>
            {moment(dateTs).format(DATE_FORMAT)} <br />{" "}
            {dateTs && moment(dateTs).format(TIME_FORMAT)}
          </Fragment>
        ))}
    </div>
  );
};

export default TimestampFormatter;
