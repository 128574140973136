import React, { FC, Fragment } from "react";
import { Box, Card, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { ReactComponent as Cli } from "assets/svg/illustrations/cli.svg";
import PageHeader from "components/PageHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    illustration: {
      width: 400,
    },
  }),
);

const CLI: FC = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <PageHeader>Install Lacework IaC Security CLI</PageHeader>
      <Box component={Card} p={2}>
        <Fragment>
          <Typography gutterBottom>
            The Lacework IaC Security CLI is an essential part of the Fusion
            Platform.
          </Typography>
          <Typography gutterBottom>
            To install and configure please see the instructions:
          </Typography>
          <Box p={3}>
            <Cli className={classes.illustration} />
          </Box>
        </Fragment>
      </Box>
    </Fragment>
  );
};

export default CLI;
