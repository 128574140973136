import React, { FC } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Grid, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { LaunchRounded } from "@material-ui/icons";

import { useCurrentOrgId } from "providers";

import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import GitRepoInformation from "components/GitRepoInformation/GitRepoInformation";
import LogoBadge from "components/LogoBadge";
import StarCheckbox from "components/StarCheckbox/StarCheckbox";
import TimestampFormatter from "components/TimestampFormatter";
import { optimisticDatasetUpdater } from "helpers/queryHelpers";
import pipelinesService from "services/pipelinesService";
import { Pipeline } from "types/pipelines";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "visible",
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      position: "relative",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      },
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f8f8f8",
      },
      transition: "background-color 0.2s",
    },
    actions: {
      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(4),
      },
    },
    checkbox: {
      padding: 0,
      marginTop: 0,
      margin: theme.spacing(1),
    },
  }),
);
interface PipelineCardProps {
  pipeline: Pipeline;
}

const PipelineCard: FC<PipelineCardProps> = ({ pipeline }) => {
  const classes = useStyles();
  const orgId = useCurrentOrgId();
  const navigate = useNavigate();
  const stopPropagation = (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
    e.stopPropagation();

  const actions: DropdownItem[] = [
    {
      label: "View Runs",
      linkTo: `/pipelines/${pipeline.id}/runs`,
    },
  ];

  const goToDetail = () => navigate(`/pipelines/${pipeline.id}/runs`);

  const { mutate: toggleFavorite } = useMutation(
    ({ p, isFavorite }: { p: Pipeline; isFavorite: boolean }) =>
      pipelinesService.toggleFavorite(orgId, p, isFavorite),
    {
      onMutate: ({ p, isFavorite }) =>
        optimisticDatasetUpdater<Pipeline, "ciPipelineName">(
          ["pipelines", orgId],
          { ciPipelineName: p.ciPipelineName },
          { isFavorite },
        ),
      onError: (error, { p }, rollback: any) => {
        rollback?.();
        toast.error(
          `Something went wrong toggling ${p.ciPipelineName} favorite status. Please try again.`,
        );
      },
      onSuccess: ({ lastReportedTs }, { p }) => {
        optimisticDatasetUpdater<Pipeline, "ciPipelineName">(
          ["pipelines", orgId],
          { ciPipelineName: p.ciPipelineName },
          { lastReportedTs },
        );
      },
    },
  );

  return (
    <Box
      onClick={goToDetail}
      className={classes.root}
      px={3}
      py={2}
      component={Card}
      display="flex"
    >
      <Grid spacing={1} container wrap="wrap" alignItems="center">
        <Grid item xs={12} sm={6} lg={5}>
          <Box mb={1} display="flex">
            <Box mr={1}>
              <LogoBadge width={28} logo={pipeline.ciPlatform} />
            </Box>
            <Box fontSize={18} mr={1}>
              {pipeline.ciPipelineName}
            </Box>
            <IconButton
              color="primary"
              component="a"
              size="small"
              href={pipeline.ciPipelineUrl}
              onClick={stopPropagation}
              rel="noreferrer noopener"
              target="_blank"
            >
              <LaunchRounded fontSize="small" />
            </IconButton>
          </Box>
          <Box>
            <GitRepoInformation
              spacer={false}
              gitRepoName={pipeline.gitRepoName}
              gitRepo={pipeline.gitRepo}
              gitRepoUrl={pipeline.gitRepoUrl}
              gitProvider={pipeline.gitProvider}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={7}>
          <Typography variant="h6" gutterBottom>
            Latest Assessment
          </Typography>
          <Box display="flex">
            <GitRepoInformation
              spacer={false}
              gitBranch={pipeline.gitBranch}
              gitCommit={pipeline.gitCommit}
              gitCommitUrl={pipeline.gitCommitUrl}
            />
            <Box display="inline" mx={1}>
              <FontAwesomeIcon icon={faClock} />
            </Box>
            <TimestampFormatter dateTs={pipeline.lastReportedTs} oneLine />
          </Box>
        </Grid>
      </Grid>
      <Box ml="auto">
        <Box display="flex" flexDirection="column" onClick={stopPropagation}>
          <StarCheckbox
            className={classes.checkbox}
            onChange={({ target: { checked } }) => {
              toggleFavorite({
                p: pipeline,
                isFavorite: checked,
              });
            }}
            defaultChecked={!!pipeline.isFavorite}
            size="medium"
          />
          <DropdownActions items={actions} />
        </Box>
      </Box>
    </Box>
  );
};

export default PipelineCard;
