export interface ServiceDetails {
  shortDescription: string;
  description: string;
  title: string;
  code: string;
}

export const servicesDetails: Record<string, ServiceDetails | undefined> = {
  config: {
    title: "Security Configuration",
    code: "config",
    shortDescription:
      "Automate security configuration checks using industry standards and benchmarks, as well as checks from Soluble’s research team",
    description:
      "Use Soluble Security Config to automate security configuration checks against one or a thousand Kubernetes clusters in minutes. Scan your clusters navigating Kubernetes versions and heterogeneous cloud deploys (GKE, EKS, AKS, Kops managed, etc), saving you what could be hundreds of hours of work, and turning it into a handful of clicks. It includes industry best practices and standards like the Center for Internet Security (CIS) Kubernetes configuration benchmark, as well as custom checks from Soluble’s research team as they become available.",
  },
  netscan: {
    title: "NetScan",
    code: "netscan",
    shortDescription:
      "Set up automated vulnerability scans across your clusters, with results aggregated in one place for security and compliance teams",
    description:
      "Soluble NetScan makes vulnerability scanning for kubernetes simple. Scan one or a thousand clusters distributed across cloud providers in minutes. Setup rolling schedules with results sent to our platform for reporting, alerting and actions. Aggregate results in one place, and most importantly, keep security and compliance teams happy. Do all this without drowning in data, administrative overhead, and excessive cost.",
  },
  cinderella: {
    title: "Cinderella Clusters",
    code: "cinderella",
    shortDescription:
      "Explore the Kubernetes ecosystem — no muss, no fuss — with short-lived clusters.",
    description:
      "Soluble Cinderella clusters provide a quick, easy way to “kick the tires” of the Kubernetes ecosystem. Create a cluster, deploy containers, test different configurations, go wild. And, like Cinderella’s coach — which turns back into a pumpkin when the clock strikes midnight — Cinderella clusters are automatically deleted when their time runs out.",
  },
};
