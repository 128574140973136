import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Card, Typography } from "@material-ui/core";

import { useLogout } from "providers";

import { ReactComponent as EmailSentIllustration } from "assets/svg/illustrations/email-sent.svg";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";

const VerifyEmail: FC<Childless> = () => {
  const logout = useLogout();

  return (
    <NavbarTopLayout disableLink>
      <Box display="flex" p={2} justifyContent="center" alignItems="center">
        <Box
          width={450}
          component={Card}
          p={3}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Box
            mb={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              component={EmailSentIllustration}
              width={200}
              height={160}
              mb={3}
            />
            <Typography gutterBottom variant="h3">
              Check your e-mail
            </Typography>
          </Box>

          <Box my={1} width={350}>
            <Typography gutterBottom variant="subtitle1">
              To verify your e-mail address, tap the button in the email we sent
              you.
            </Typography>
            <Button
              fullWidth
              component={Link}
              variant="outlined"
              color="primary"
              to="/profile/add-email"
              replace
            >
              Resend verification link
            </Button>
            <Box mt={2}>
              <Typography variant="caption">
                Re-entering your e-mail address will trigger another
                verification e-mail.
              </Typography>
            </Box>
            <Box mt={2} mr="auto">
              <Button variant="text" color="primary" onClick={logout}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </NavbarTopLayout>
  );
};

export default VerifyEmail;
