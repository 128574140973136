import React, { FC, Fragment, useCallback, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useLogout, useUser } from "providers";

import ColoredButton from "components/ColoredButton/ColoredButton";
import ConfirmationModal, {
  ConfirmationModalRef,
} from "components/ConfirmationModal/ConfirmationModal";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
  optimisticObjectUpdater,
} from "helpers/queryHelpers";
import profileService from "services/profileService";
import { UserProfile } from "types/user-profile";

interface EmailPreferencesForm {
  emailMarketing: boolean;
  emailDigest: boolean;
}

const Profile: FC = () => {
  const { email, userId } = useUser();
  const { control, reset, formState, handleSubmit } =
    useForm<EmailPreferencesForm>();

  const { data, refetch } = useQuery(
    ["profile", userId],
    () => profileService.getEmailPreference(userId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    },
  );

  const { mutate: updateEmailPreference } = useMutation(
    (payload: EmailPreferencesForm) =>
      profileService.updateEmailPreference(userId, payload),
    {
      onMutate: (payload) =>
        optimisticObjectUpdater<UserProfile>(["profile", userId], payload),
      onError: errorToastHandler(
        "There was an error updating your email preferences",
      ),
      onSettled: () => {
        refetch();
      },
    },
  );
  const confirmationModal = useRef<ConfirmationModalRef>(null);

  const logout = useLogout();
  const navigate = useNavigate();

  const handleUpdateEmailPreference = useCallback(
    (formModel: EmailPreferencesForm) => {
      updateEmailPreference(formModel);
    },
    [updateEmailPreference],
  );

  const deleteAccount = useCallback(async () => {
    try {
      await profileService.deleteAccount(userId);
      confirmationModal.current?.closeModal();
      logout();
      navigate("/success/account-deletion");
    } catch (error) {
      toast.error("An error ocurred trying to delete your account.");
    }
  }, [userId, logout, navigate]);

  const openDeleteModal = useCallback(() => {
    confirmationModal.current?.openModal({
      description: (
        <Fragment>
          <Typography color="error" variant="h6">
            Are you sure?
          </Typography>
          <Typography variant="h6">
            Once applied, you won't be able to restore your account.
          </Typography>
        </Fragment>
      ),
      title: "Confirmation",
      confirm: "Delete my account",
      cancel: "Cancel",
      color: "error",
      action: deleteAccount,
    });
  }, [deleteAccount]);

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  return (
    <Fragment>
      <PageHeader> Profile</PageHeader>
      <Box component={Card} mb={2}>
        <Box p={2}>
          <Typography gutterBottom color="primary" variant="h5">
            User Profile
          </Typography>
          <Typography variant="h6" gutterBottom>
            Current Email
          </Typography>
          <Box mb={3} pl={0.5}>
            <Box mb={1}>{email}</Box>
            <Box maxWidth={650}>
              <Box mt={2}>
                <Alert severity="warning" style={{ borderRadius: 0 }}>
                  <Box
                    style={{ opacity: 0.75 }}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    fontWeight="medium"
                  >
                    <small>
                      Email address is managed by your identity provider.
                    </small>
                  </Box>
                </Alert>
              </Box>
            </Box>
          </Box>
          <Typography variant="h6" gutterBottom>
            Delete Account
          </Typography>
          <Box mb={2} pl={0.5}>
            <Box mb={2}>We're sorry to see you go.</Box>
            <Box mb={3}>
              Account deletion is final. There is no way to restore your
              account.
            </Box>
            <ColoredButton
              color="error"
              variant="contained"
              onClick={openDeleteModal}
            >
              Delete my account
            </ColoredButton>
          </Box>
        </Box>
      </Box>
      <Card>
        <Box p={2}>
          <Box>
            <Typography gutterBottom color="primary" variant="h5">
              Email Preference
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(handleUpdateEmailPreference)}>
            <Box>
              <FormControlLabel
                label="Receive email news and updates from Lacework IaC Security"
                control={
                  <Controller
                    control={control}
                    name="emailMarketing"
                    id="email-marketing"
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={!!props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                        color="primary"
                      />
                    )}
                  />
                }
              />
            </Box>
            <FormControlLabel
              label="Lacework IaC Security
               digest emails"
              id="email-digest"
              control={
                <Controller
                  control={control}
                  name="emailDigest"
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={!!props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                      color="primary"
                    />
                  )}
                />
              }
            />
            <Collapse in={formState.isDirty}>
              <Box mt={1} display="flex" justifyContent="flex-end">
                <Button type="button" onClick={() => reset()} color="primary">
                  Cancel
                </Button>
                <Box mr={1} />
                <Button type="submit" color="primary" variant="contained">
                  Save
                </Button>
              </Box>
            </Collapse>
          </form>
        </Box>
      </Card>
      <ConfirmationModal ref={confirmationModal} />
    </Fragment>
  );
};

export default Profile;
