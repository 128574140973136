import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import capitalize from "lodash/capitalize";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-block",
      padding: "0.35556em 0.71111em",
      fontSize: "75%",
      fontWeight: 600,
      lineHeight: "1",
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "baseline",
      borderRadius: "0.25rem",
    },
    success: {
      color: "#28670f",
      backgroundColor: "#d8ecd1",
    },
    danger: {
      color: "#932338",
      backgroundColor: "#fad7dd",
    },
  }),
);

const StatusBadge = ({ status }: { status: string }) => {
  const classes = useStyles();

  return (
    <Box
      className={classNames(
        classes.root,
        status === "active" ? classes.success : classes.danger,
      )}
    >
      {capitalize(status)}
    </Box>
  );
};

export default StatusBadge;
