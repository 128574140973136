import React, { FC, useMemo } from "react";
import {
  Checkbox,
  CheckboxProps,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { StarBorderRounded, StarRounded } from "@material-ui/icons";

interface StarCheckboxProps extends CheckboxProps {
  defaultChecked: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checked: {
      color: "gold",
    },
  }),
);

const StarCheckbox: FC<StarCheckboxProps> = ({
  defaultChecked,
  onChange,
  ...props
}) => {
  const classes = useStyles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defC = useMemo(() => defaultChecked, []);

  return (
    <Checkbox
      onChange={onChange}
      size="small"
      icon={<StarBorderRounded />}
      defaultChecked={defC}
      checkedIcon={<StarRounded className={classes.checked} />}
      {...props}
    />
  );
};

export default StarCheckbox;
