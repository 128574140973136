import React, {
  forwardRef,
  Fragment,
  MutableRefObject,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { useCurrentOrgId, useHasScope } from "providers";

import FindingsRow from "./FindingsRow";
import assessmentService from "services/assessmentService";
import { AssessmentDetail, Finding } from "types/assessments";
import { Permissions, Resources } from "types/auth-roles";
import { Violation } from "types/violations";

interface SuppressionModalProps {
  gitRepo?: string;
  finding?: Finding | Violation;
  assessmentDetail?: AssessmentDetail;
  onClose?(): void;
  refetch?(): void;
  hideButton?: boolean;
  onSuppress?(): void;
  onMutate(suppressionLevel: string): () => void;
  ref?: MutableRefObject<HTMLButtonElement>;
}

interface SuppressionModalForm {
  comment: string;
}

const SuppressionModal: React.RefForwardingComponent<
  HTMLButtonElement,
  SuppressionModalProps
> = (
  {
    gitRepo,
    finding,
    assessmentDetail,
    onClose,
    refetch,
    hideButton,
    onSuppress,
    onMutate,
  },
  ref,
) => {
  const orgId = useCurrentOrgId();
  const [open, setOpen] = useState(false);
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findingM = useMemo(() => finding, [open]);
  const category = finding?.category ?? assessmentDetail?.category;

  const { control, handleSubmit, errors } = useForm<SuppressionModalForm>({
    defaultValues: { comment: "" },
    mode: "onChange",
  });
  const handleClose = () => {
    setOpen(false);
  };

  const { mutate: onSuppressFindingRepoLevel } = useMutation(
    (comment: string) => {
      const repoName = gitRepo ?? assessmentDetail?.gitRepo ?? "";
      const assessmentId =
        assessmentDetail?.assessmentId ?? findingM?.assessmentId ?? "";
      return assessmentService.setAssessmentFindingRepoLevelSuppression(
        orgId,
        assessmentId,
        findingM?.id ?? "",
        {
          gitRepo: repoName,
          suppressed: true,
          comment,
        },
      );
    },
    {
      onMutate: () => onMutate("repository"),
      onError: (_, _v, rollback) => {
        rollback?.();
        toast.error(
          `An error ocurred trying to suppress the finding, please try again.`,
        );
      },
      onSuccess: refetch,
    },
  );

  const hasNoSuppressionOptions =
    !(category === "iac" || category === "infraScan") &&
    !assessmentDetail?.gitRepo &&
    !gitRepo;

  const onSubmit = (form: SuppressionModalForm) => {
    onSuppressFindingRepoLevel(form.comment);
    handleClose();
    onSuppress?.();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <Box
        mr={1}
        display="inline-block"
        visibility={hideButton ? "hidden" : "visible"}
      >
        <Tooltip
          title={
            hasNoSuppressionOptions
              ? "No suppression methods available for this assessment"
              : !hasOrgWriteScope
              ? "Insufficient permissions"
              : !(finding?.allowSuppression ?? true)
              ? "This finding only supports file-based suppresssion."
              : ""
          }
        >
          <span>
            <Button
              innerRef={ref}
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
              disabled={
                !hasOrgWriteScope ||
                hasNoSuppressionOptions ||
                !(finding?.allowSuppression ?? true)
              }
            >
              Suppress
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        onExited={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Box p={3} pb={0} display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            Suppress Finding
          </Typography>
        </Box>
        <DialogContent>
          <Box mb={2} p={1} bgcolor="lightBackground.main" borderRadius={4}>
            {findingM && (
              <Fragment>
                <FindingsRow
                  finding={findingM}
                  gitRepo={gitRepo ?? assessmentDetail?.gitRepo}
                />
              </Fragment>
            )}
          </Box>
          <form id="suppressions" onSubmit={handleSubmit(onSubmit)}>
            Only suppress this resource found within the repository.
            <Box mt={2}>
              <Controller
                control={control}
                rules={{ required: true }}
                as={TextField}
                label="Comment *"
                name="comment"
                error={!!errors.comment}
                helperText={!!errors.comment && "Please enter a comment."}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Box mr={2} display="inline-block">
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </Box>
            <Button
              color="primary"
              form="suppressions"
              variant="contained"
              type="submit"
            >
              Add Suppression
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default forwardRef(SuppressionModal);
