/* istanbul ignore file */
import React, {
  forwardRef,
  Fragment,
  memo,
  PropsWithChildren,
  Ref,
  RefObject,
  useCallback,
  useImperativeHandle,
} from "react";
import {
  Column,
  HeaderGroup,
  IdType,
  Row,
  TableInstance,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useGroupBy,
  useMountedLayoutEffect,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  ArrowUpward,
  ClearAllRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  LibraryAddCheckOutlined,
  UnfoldLess,
  UnfoldMore,
} from "@material-ui/icons";
import classNames from "classnames";

import DataTableSkeleton from "./components/DataTableSkeleton";
import DefaultTablePagination, {
  TablePaginationProps,
} from "./components/DefaultTablePagination";
import RowSelectionToolbar from "./components/RowSelectionToolbar";
import SearchBar from "./components/SearchBar";
import tableStyles from "./tableStyles";

export interface DataTableRef<
  T extends Record<string, any> = Record<string, any>,
> {
  tableInstance: TableInstance<T>;
}

interface DataTableProps<T extends Record<string, any>> {
  autoResetFilters?: boolean;
  autoResetGlobalFilter?: boolean;
  columns: Column<T>[];
  compact?: boolean;
  containerClassname?: string;
  data: T[];
  defaultSortField?: keyof T;
  disableSelectActions?: boolean;
  expandOnRowClick?: boolean;
  expandableComponent?(row: T): React.ReactNode;
  expanded?: Record<IdType<T>, boolean>;
  footer?: boolean;
  groupBy?: boolean;
  hiddenColumns?: IdType<T>[];
  hideTableHead?: boolean;
  initialPageIndex?: number;
  initialRowsPerPage?: number;
  isLoading?: boolean;
  noDataComponent?: React.ReactNode;
  onChangePage?(value: number): void;
  onChangeRowsPerPage?(value: number): void;
  onRowClick?(row: Row<T>): void;
  onSearchChange?(filterText: string): void;
  onSearchSubmit?(filterText: string): void;
  onSelectedRowsChange?(selectedRows: T[]): void;
  onSortChange?(id: string, isSorted: boolean, isSortedDesc?: boolean): void;
  pageSizeOptions?: number[];
  pagination?: boolean;
  paginationComponent?(props: TablePaginationProps): React.ReactNode;
  query?: string;
  rowHover?: boolean;
  rowSelection?: boolean;
  search?: boolean;
  searchActions?: React.ReactNode;
  selectActions?: React.ReactNode;
  selectOnRowClick?: boolean;
  selectableRowDisabled?(row: T): boolean;
  sortByDesc?: boolean;
  // Don't consume this as a prop. This definition is just for type purposes.
  ref?: RefObject<DataTableRef<T>>;
}

const INITIAL_SELECTED_ROW_IDS = {};

const DataTable = <T extends Record<string, any>>(
  {
    autoResetFilters = true,
    autoResetGlobalFilter = true,
    columns,
    compact = false,
    containerClassname,
    data,
    defaultSortField,
    disableSelectActions = false,
    expandOnRowClick = false,
    expandableComponent,
    expanded,
    footer = false,
    groupBy = false,
    hiddenColumns = [],
    hideTableHead = false,
    initialPageIndex = 1,
    initialRowsPerPage = 10,
    isLoading = false,
    noDataComponent = "No results found.",
    onChangePage,
    onChangeRowsPerPage,
    onRowClick,
    onSearchChange,
    onSearchSubmit,
    onSelectedRowsChange,
    onSortChange,
    pageSizeOptions = [5, 10, 25],
    pagination = true,
    paginationComponent = DefaultTablePagination,
    query = "",
    rowHover = false,
    rowSelection = false,
    search = false,
    searchActions,
    selectActions,
    selectOnRowClick = false,
    selectableRowDisabled,
    sortByDesc = false,
  }: PropsWithChildren<DataTableProps<T>>,
  ref: Ref<DataTableRef<T>>,
) => {
  const classes = tableStyles();

  const instance = useTable<T>(
    {
      columns,
      data,
      autoResetPage: true,
      autoResetFilters,
      autoResetGlobalFilter,
      pageCount: !pagination ? -1 : undefined,
      manualPagination: !pagination,
      disableGroupBy: !groupBy,
      disableSortRemove: true,
      initialState: {
        hiddenColumns,
        expanded: expanded ?? ({ "0": false } as Record<IdType<T>, boolean>),
        globalFilter: onSearchSubmit ? undefined : query,
        selectedRowIds: INITIAL_SELECTED_ROW_IDS as Record<IdType<T>, boolean>,
        pageSize: initialRowsPerPage,
        pageIndex: initialPageIndex - 1,
        ...(defaultSortField && {
          sortBy: [{ id: defaultSortField as string, desc: sortByDesc }],
        }),
      },
    },
    useFilters,
    useGroupBy,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  const {
    // ------Basic------
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    footerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize, selectedRowIds },

    // ------Pagination------
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,

    // ------Sorting------
    toggleSortBy,

    // ------Row Selection------
    toggleRowSelected,
    // toggleAllRowsSelected,
    // isAllRowsSelected,
    selectedFlatRows,
    // getToggleAllPageRowsSelectedProps,

    // ------Global Filtering------
    setGlobalFilter,
  } = instance;

  useImperativeHandle(ref, () => ({
    tableInstance: instance,
  }));

  const stopPropagation = useCallback(
    (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) =>
      event.stopPropagation(),
    [],
  );

  const handleSearchChange = useCallback(
    (filterText: string) => {
      setGlobalFilter(filterText);
      onSearchChange?.(filterText);
    },
    [setGlobalFilter, onSearchChange],
  );

  const handleSortChange = useCallback(
    ({ id, isSorted, isSortedDesc }: HeaderGroup<T>) => {
      toggleSortBy(id);
      onSortChange?.(id, isSorted, isSortedDesc);
    },
    [toggleSortBy, onSortChange],
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    gotoPage(newPage);
    onChangePage?.(newPage + 1);
  };

  const handleChangeRowsPerPage = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(Number(value));
    onChangeRowsPerPage?.(Number(value));
  };

  const handleRowClick = (row: Row<T>) =>
    onRowClick
      ? onRowClick?.(row)
      : selectOnRowClick
      ? row.toggleRowSelected(!row.isSelected)
      : expandOnRowClick
      ? row.toggleRowExpanded(!row.isExpanded)
      : undefined;

  const toggleAllRowsSelected = () => {
    rows.forEach((row) => {
      if (!selectableRowDisabled?.(row.original)) {
        const value = isAllRowsSelected ? false : true;
        toggleRowSelected(row.id, value);
      }
    });
  };

  const toggleAllPageRowsSelected = () => {
    page.forEach((row) => {
      if (!selectableRowDisabled?.(row.original)) {
        const value = isAllPageRowsSelected ? false : true;
        toggleRowSelected(row.id, value);
      }
    });
  };

  const isSomeRowsSelected = page.some((row) => row.isSelected);

  const isAllPageRowsDisabled = page.every((row) =>
    selectableRowDisabled?.(row.original),
  );

  const isAllPageRowsSelected = isAllPageRowsDisabled
    ? false
    : page.every((row) => {
        if (selectableRowDisabled?.(row.original)) return true;
        else return row.isSelected;
      });

  const isAllRowsSelected = isAllPageRowsDisabled
    ? false
    : rows.every((row) => {
        if (selectableRowDisabled?.(row.original)) return true;
        else return row.isSelected;
      });

  const selectedRowsCount = selectedFlatRows.length;
  const isToolbarActive = selectedRowsCount > 0 && !disableSelectActions;

  useMountedLayoutEffect(() => {
    onSelectedRowsChange?.(selectedFlatRows.map((r) => r.original));
  }, [selectedRowIds, onSelectedRowsChange]);

  if (isLoading && !query && page.length === 0)
    return (
      <DataTableSkeleton search={search} compact={compact} columns={columns} />
    );

  return (
    <Fragment>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        position="relative"
        className={classNames(
          isToolbarActive
            ? classes.activeBackground
            : classes.inactiveBackground,
          classes.fadeBackground,
        )}
      >
        {rowSelection && (
          <RowSelectionToolbar
            dense={(search && !rowSelection) || compact}
            active={isToolbarActive}
            collapse={!search}
          >
            <Box mr={2}>
              <Tooltip
                placement="top"
                title={isAllRowsSelected ? "Clear All" : "Select All"}
              >
                <IconButton
                  onClick={toggleAllRowsSelected}
                  color="secondary"
                  size="small"
                >
                  {isAllRowsSelected ? (
                    <ClearAllRounded />
                  ) : (
                    <LibraryAddCheckOutlined />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            {selectedRowsCount} selected
            <Box ml="auto">{selectActions}</Box>
          </RowSelectionToolbar>
        )}
        <Box display="flex" alignItems="center" flexWrap="wrap" width="100%">
          {search && (
            <SearchBar
              initialValue={query}
              onChange={handleSearchChange}
              onSubmit={onSearchSubmit}
            />
          )}
          {searchActions && <Box ml={search ? 2 : 0}>{searchActions} </Box>}
          {pagination && data.length > 0 && (
            <Box ml="auto">
              {paginationComponent({
                pageSizeOptions,
                count: rows.length,
                pageSize,
                pageIndex,
                onChangePage: handleChangePage,
                onChangeRowsPerPage: handleChangeRowsPerPage,
              })}
            </Box>
          )}
        </Box>
      </Box>

      <Fragment>
        <TableContainer className={containerClassname}>
          {page.length === 0 && !isLoading ? (
            <Box p={3}>{noDataComponent}</Box>
          ) : (
            <Table
              className={classes.table}
              size={compact ? "small" : "medium"}
              {...getTableProps()}
            >
              {!hideTableHead && (
                <TableHead>
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                      // Apply the header row props
                      <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {rowSelection && (
                          <TableCell
                            style={{
                              width: "0.0000000001%",
                            }}
                            className={
                              compact
                                ? classes.checkboxColumnCompact
                                : classes.checkboxColumn
                            }
                          >
                            <Checkbox
                              size={compact ? "small" : "medium"}
                              className={
                                compact ? classes.checkboxCompact : undefined
                              }
                              disabled={isAllPageRowsDisabled}
                              onChange={toggleAllPageRowsSelected}
                              indeterminate={
                                isAllPageRowsSelected
                                  ? false
                                  : isSomeRowsSelected
                              }
                              checked={isAllPageRowsSelected}
                            />
                          </TableCell>
                        )}
                        {expandableComponent && (
                          <TableCell
                            style={{
                              width: "0.0000000001%",
                            }}
                          ></TableCell>
                        )}
                        {
                          // Loop over the headers in each row

                          headerGroup.headers.map((column) => (
                            // Apply the header cell props
                            <TableCell
                              {...column.getHeaderProps({
                                style: {
                                  minWidth: column.minWidth,
                                  width: column.collapse
                                    ? "0.0000000001%"
                                    : column.width,
                                  maxWidth: column.maxWidth,
                                },
                              })}
                            >
                              {groupBy && column.canGroupBy ? (
                                // If the column can be grouped, let's add a toggle
                                <span {...column.getGroupByToggleProps()}>
                                  {column.isGrouped ? (
                                    <Box mr={0.5} component="span">
                                      <UnfoldMore />
                                    </Box>
                                  ) : (
                                    <Box mr={0.5} component="span">
                                      <UnfoldLess />
                                    </Box>
                                  )}
                                </span>
                              ) : null}
                              {
                                // Render the header

                                <span
                                  {...column.getSortByToggleProps({
                                    onClick: () => handleSortChange(column),
                                  })}
                                >
                                  <span
                                    className={classNames(classes.header, {
                                      [classes.headerClickable]: column.canSort,
                                    })}
                                  >
                                    {column.render("Header")}
                                    {column.canSort && (
                                      <Box
                                        className={classNames({
                                          [classes.invisible]: !column.isSorted,
                                        })}
                                        component="span"
                                        position="absolute"
                                        right={-20}
                                      >
                                        <ArrowUpward
                                          className={classNames(
                                            classes.sortIcon,
                                            {
                                              [classes.rotate]:
                                                column.isSortedDesc,
                                            },
                                          )}
                                        />
                                      </Box>
                                    )}
                                  </span>
                                </span>
                              }
                            </TableCell>
                          ))
                        }
                      </TableRow>
                    ))
                  }
                </TableHead>
              )}
              {/* Apply the table body props */}
              <TableBody {...getTableBodyProps()}>
                {isLoading && query && (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1}>
                      <LinearProgress
                        style={{ width: "100%" }}
                        color="secondary"
                      />
                    </TableCell>
                  </TableRow>
                )}
                {
                  // Loop over the table rows
                  page.map((row) => {
                    // Prepare the row for display
                    prepareRow(row);
                    const isDisabled = selectableRowDisabled?.(row.original);
                    return (
                      // Apply the row props
                      <Fragment key={row.id}>
                        <TableRow
                          onClick={
                            isDisabled ? undefined : () => handleRowClick(row)
                          }
                          className={classNames({
                            [classes.rowClickable]:
                              selectOnRowClick ||
                              (expandOnRowClick && !isDisabled),
                            [classes.rowSelected]:
                              row.isSelected && !isDisabled,
                            [classes.rowHover]: rowHover && !isDisabled,
                          })}
                          {...row.getRowProps()}
                        >
                          {rowSelection && (
                            <TableCell
                              onClick={stopPropagation}
                              className={
                                compact
                                  ? classes.checkboxColumnCompact
                                  : classes.checkboxColumn
                              }
                            >
                              <Checkbox
                                size={compact ? "small" : "medium"}
                                className={
                                  compact ? classes.checkboxCompact : undefined
                                }
                                disabled={isDisabled}
                                {...row.getToggleRowSelectedProps()}
                              />
                            </TableCell>
                          )}
                          {expandableComponent && !groupBy && (
                            <TableCell
                              {...row.getToggleRowExpandedProps({
                                style: { padding: 6 },
                              })}
                            >
                              {row.isExpanded ? (
                                <ExpandLessRounded />
                              ) : (
                                <ExpandMoreRounded />
                              )}
                            </TableCell>
                          )}
                          {
                            // Loop over the rows cells
                            row.cells.map((cell) => {
                              // Apply the cell props
                              return (
                                <TableCell
                                  {...cell.getCellProps()}
                                  onClick={
                                    cell.column.isButton
                                      ? stopPropagation
                                      : undefined
                                  }
                                >
                                  {cell.isGrouped ? (
                                    // If it's a grouped cell, add an expander and row count
                                    <Fragment>
                                      <span
                                        {...row.getToggleRowExpandedProps()}
                                      >
                                        {row.isExpanded ? (
                                          <Box mr={0.5} component="span">
                                            <ExpandLessRounded fontSize="small" />
                                          </Box>
                                        ) : (
                                          <Box mr={0.5} component="span">
                                            <ExpandMoreRounded fontSize="small" />
                                          </Box>
                                        )}
                                      </span>{" "}
                                      {cell.render("Cell")}({row.subRows.length}
                                      )
                                    </Fragment>
                                  ) : cell.isAggregated ? (
                                    // If the cell is aggregated, use the Aggregated
                                    // renderer for cell
                                    cell.render("Aggregated")
                                  ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                    // Otherwise, just render the regular cell
                                    cell.render("Cell")
                                  )}
                                </TableCell>
                              );
                            })
                          }
                        </TableRow>
                        {expandableComponent && !groupBy && (
                          <TableRow>
                            <TableCell
                              className={classNames({
                                [classes.noBottomBorder]: !row.isExpanded,
                              })}
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                                padding: 0,
                              }}
                              colSpan={columns.length + 1}
                            >
                              {" "}
                              <Collapse in={row.isExpanded} mountOnEnter>
                                <Box width="100%" p={1}>
                                  {expandableComponent(row.original)}
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    );
                  })
                }
              </TableBody>
              {footer && (
                <TableFooter>
                  {footerGroups.map((group) => (
                    <TableRow {...group.getFooterGroupProps()}>
                      {group.headers.map((column) => (
                        <td {...column.getFooterProps()}>
                          {column.render("Footer")}
                        </td>
                      ))}
                    </TableRow>
                  ))}
                </TableFooter>
              )}
            </Table>
          )}
        </TableContainer>
        {pagination &&
          data.length > 0 &&
          paginationComponent({
            pageSizeOptions,
            count: rows.length,
            pageSize,
            pageIndex,
            onChangePage: handleChangePage,
            onChangeRowsPerPage: handleChangeRowsPerPage,
          })}
      </Fragment>
    </Fragment>
  );
};

export default memo(forwardRef(DataTable)) as typeof DataTable;
