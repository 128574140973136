import React, { FC } from "react";

import LWLogoWhite from "assets/svg/logos/lw-logo-white.svg";

export const WhiteLogo: FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({
  ...props
}) => {
  return <img src={LWLogoWhite} alt="lacework-iac-security-logo" {...props} />;
};

export default WhiteLogo;
