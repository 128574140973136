import { TypographyOptions } from "@material-ui/core/styles/createTypography";

const typography: TypographyOptions = {
  h1: {
    fontSize: 32,
    fontWeight: 600,
  },
  h2: {
    fontSize: 24,
    fontWeight: 600,
  },
  h3: {
    fontSize: 20,
    fontWeight: 600,
  },
  h4: {
    fontSize: 18,
    fontWeight: 600,
  },
  h5: {
    fontSize: 16,
    fontWeight: 600,
  },
  h6: {
    fontSize: 14,
    fontWeight: 600,
  },
  fontFamily: [
    `"Inter"`,
    `"-apple - system"`,
    `"BlinkMacSystemFont"`,
    `"Segoe UI"`,
    `"Roboto"`,
    `"Helvetica Neue"`,
    `"Arial"`,
    `"Noto Sans"`,
    `"sans - serif"`,
    `"Apple Color Emoji"`,
    `"Segoe UI Emoji"`,
    `"Segoe UI Symbol"`,
    `"Noto Color Emoji"`,
  ].join(","),
};

export default typography;
