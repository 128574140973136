import React, { FC, Fragment, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  ClickAwayListener,
  createStyles,
  Grow,
  IconButton,
  makeStyles,
  Paper,
  Popper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { groupBy } from "lodash";
import moment from "moment";

import { useCurrentOrgId } from "providers";

import { facAnnouncement } from "assets/svg/icons/custom-fa-icons";
import ColoredChip, {
  ColoredChipColor,
} from "components/ColoredChip/ColoredChip";
import announcementService from "services/announcementService";
import { Announcement } from "types/announcement";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1090,
      position: "absolute",
    },
    header: {},
    body: {
      maxHeight: "calc(60vh - 100px)",
      overflowY: "auto",
    },
  }),
);

const defaultQuery: Announcement[] = [];

const WhatsNewDropdown: FC = () => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const orgId = useCurrentOrgId();
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: announcements = defaultQuery, isLoading } = useQuery(
    ["latest-announcements", orgId],
    () => announcementService.getAnnouncements(orgId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const lastOpenedWhatsNew = moment(
    localStorage.getItem("lastOpenedWhatsNew") ?? "",
  );

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => {
      if (!prevOpen) {
        setReset((p) => !p);
        localStorage.setItem(
          "lastOpenedWhatsNew",
          moment().format("YYYY-MM-DD").toString(),
        );
      }
      return !prevOpen;
    });
  };

  const groupedAnnouncements = useMemo(() => {
    const l = groupBy(
      announcements.sort(
        (a, b) =>
          new Date(b.releaseTs).getTime() - new Date(a.releaseTs).getTime(),
      ),
      (a) => moment(a.releaseTs, "YYYY-MM-DD").format("YYYY-MM-DD"),
    );

    return Object.keys(l).reduce(
      (acc: Record<string, Record<string, Announcement[]>>, key, i) => {
        acc[key] = groupBy(l[key], (a) => a.type);
        return acc;
      },
      {},
    );
  }, [announcements]);

  const hasNewAnnouncements = useMemo(() => {
    const dates = Object.keys(groupedAnnouncements);
    return dates.length > 0
      ? lastOpenedWhatsNew.toString() === "Invalid date"
        ? true
        : moment(dates[0], "YYYY-MM-DD").isAfter(lastOpenedWhatsNew)
      : false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedAnnouncements, lastOpenedWhatsNew, reset]);

  const chipColors: Record<string, ColoredChipColor> = {
    new: "info",
    improvement: "secondary",
    fix: "warning",
    removed: "error",
  };

  return (
    <Fragment>
      <IconButton onClick={handleToggle} ref={anchorRef}>
        <Badge variant="dot" color="secondary" invisible={!hasNewAnnouncements}>
          <FontAwesomeIcon icon={facAnnouncement} size="xs" />
        </Badge>
      </IconButton>
      <Popper
        className={classes.root}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box width={matches ? 400 : 450}>
                  <Box p={1}>
                    <Typography gutterBottom variant="h5" align="center">
                      What's New
                    </Typography>
                  </Box>
                  <Box component="hr" my={0} />
                  <Box p={2} maxHeight={600} overflow="scroll">
                    {isLoading ? (
                      <Skeleton count={4} />
                    ) : announcements.length === 0 ? (
                      <Box textAlign="center">There are no announcements</Box>
                    ) : (
                      Object.keys(groupedAnnouncements).map((d, i) => (
                        <Box key={i} mb={2}>
                          <Box mb={1}>
                            <Typography align="center" variant="subtitle2">
                              {d}
                            </Typography>
                          </Box>
                          {Object.keys(groupedAnnouncements[d]).map((t, j) => (
                            <Fragment key={j}>
                              <Box mb={1} display="block">
                                <ColoredChip
                                  size="small"
                                  color={
                                    chipColors[t.toLowerCase()] ?? "primary"
                                  }
                                  label={t.toUpperCase()}
                                />
                              </Box>
                              <ul>
                                {groupedAnnouncements[d][t].map((a, k) => (
                                  <li key={k}>{a.title}</li>
                                ))}
                              </ul>
                            </Fragment>
                          ))}
                        </Box>
                      ))
                    )}
                  </Box>
                  <Box component="hr" my={0} />
                  <Box p={1} textAlign="center">
                    <a
                      href="https://www.lacework.com/blog/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Lacework Blog
                    </a>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default WhatsNewDropdown;
