import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import CreateGHZodiacApp from "./pages/CreateGHZodiacApp/CreateGHZodiacApp";
import ZodiacJobDetail from "./pages/ZodiacBuild/ZodiacBuild";
import ZodiacConsolidatedAssessments from "./pages/ZodiacConsolidatedAssessments/ZodiacConsolidatedAssessments";
import IacBot from "pages/IacBot/pages/IacBot/IacBot";
import JobDetail from "pages/IacBot/pages/JobDetail/JobDetail";
import HasFlagRoute from "router/HasFlagRoute";

const ZodiacRouter: FC<Childless> = () => {
  return (
    <Routes>
      {/* BUILD */}
      <Route
        path="jobs/:ciBuildId/assessments/"
        element={<ZodiacConsolidatedAssessments />}
      />
      <Route
        path="jobs/:ciBuildId/assessments/:findingId"
        element={<ZodiacConsolidatedAssessments />}
      />
      <Route
        path="jobs/:ciBuildId/assessments/:findingId/:tab"
        element={<ZodiacConsolidatedAssessments />}
      />

      {/* CREATE GITHUB APP*/}
      <Route
        path="credentials"
        element={
          <HasFlagRoute
            flag="zodiacCredentialsEnabled"
            element={<CreateGHZodiacApp />}
          />
        }
      />

      <Route path="jobs/:ciBuildId/diff" element={<ZodiacJobDetail />} />

      <Route path="jobs/:ciBuildId" element={<JobDetail />} />

      <Route path=":tab" element={<IacBot />} />

      <Route path="*" element={<Navigate to="jobs" />} />
    </Routes>
  );
};

export default ZodiacRouter;
