import React, { FC, Fragment, useMemo } from "react";
import { useQuery } from "react-query";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Card } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { isUndefined } from "lodash";
import useCommonStyles from "theme/useCommonStyles";

import { useCurrentOrgId, useIsOnPremIacbotEnabled } from "providers";

import IacBotJobs from "./components/IacBotJobs";
import IacBotTabs, { IacBotTabsE } from "./components/IacBotTabs";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import ZodiacInstances from "pages/Zodiac/pages/Zodiac/components/ZodiacInstances";
import iacBotService from "services/iacBotService";
import zodiacService from "services/zodiacService";

const IacBot: FC = () => {
  const orgId = useCurrentOrgId();
  const { tab = IacBotTabsE.Jobs } = useParams<{
    tab: IacBotTabsE;
  }>();
  const classes = useCommonStyles();
  const isOnPremIacbotEnabled = useIsOnPremIacbotEnabled();

  const {
    data: jobs = [],
    isLoading,
    refetch,
  } = useQuery(
    ["iacbot-jobs", orgId],
    () => iacBotService.getIacBotJobs(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    },
  );
  const { data: instances = [], isLoading: isInstancesLoading } = useQuery(
    ["zodiac-instances", orgId],
    () => zodiacService.getZodiacInstances(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      enabled: tab === IacBotTabsE.Instances,
    },
  );

  const isTabValid = useMemo(
    () =>
      isOnPremIacbotEnabled
        ? tab === IacBotTabsE.Jobs || tab === IacBotTabsE.Instances
        : tab === IacBotTabsE.Jobs,
    [isOnPremIacbotEnabled, tab],
  );

  const validatedJobs = useMemo(() => {
    return jobs.map((j) => {
      const jobStatus = !isUndefined(j.status)
        ? j.status
        : isUndefined(j.success)
        ? "QUEUED"
        : j.success
        ? "COMPLETED"
        : "FAILED";

      return {
        ...j,
        status: jobStatus,
        gitRepoName: j.gitRepo,
      };
    });
  }, [jobs]);

  const refetchData = () => {
    toast.promise(
      refetch,
      {
        pending: "Refreshing data...",
        success: "Refreshed successfully!",
        error:
          "An error ocurred while refreshing the data. Please try again later.",
      },
      {
        autoClose: 2000,
      },
    );
  };

  if (!isTabValid) {
    return <Navigate to="/zodiac/jobs" />;
  }

  return (
    <Fragment>
      <PageHeader>Jobs</PageHeader>
      <Card>
        <Box px={4} py={2}>
          {isOnPremIacbotEnabled && <IacBotTabs tab={tab} />}
          <TabContext value={tab}>
            <TabPanel className={classes.tabPanel} value={IacBotTabsE.Jobs}>
              <IacBotJobs
                refetch={refetchData}
                jobs={validatedJobs}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel
              className={classes.tabPanel}
              value={IacBotTabsE.Instances}
            >
              <ZodiacInstances
                instances={instances}
                isLoading={isInstancesLoading}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
    </Fragment>
  );
};

export default IacBot;
