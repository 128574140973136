import instance from "./axiosInstance";
import { APIResponse, PaginatedResponse } from "types";
import { AssessmentDetail, BranchAssessmentMain } from "types/assessments";
import { DashboardElementData, DashboardMetaData } from "types/dashboard";
import { IacBotJob } from "types/iacBot";
import {
  GitRepoBranchCommits,
  GitRepoBranchesAndCommits,
  GitRepoJobMetadata,
  GitRepoJobStatus,
  GitRepoMetadata,
  GitRepoSettings,
  GitRepoStats,
  JobStepStatus,
  RepoInsightStatus,
  RepoRecommendation,
  Repository,
} from "types/repositories";

const getRepos = (orgId: string) =>
  instance
    .get<PaginatedResponse<Repository>>(`/org/${orgId}/repos`)
    .then((res) => res.data.data);

const getRepoBranches = (orgId: string, repo: string) =>
  instance
    .get<{ data: string[] }>(`/org/${orgId}/repo/branches?gitRepo=${repo}`)
    .then((res) => res.data.data);

const getAllRepoBranchesAndCommits = (orgId: string, repo: string) =>
  instance
    .get<{ data: GitRepoBranchesAndCommits }>(
      `org/${orgId}/repo/commits?repo=${repo}`,
    )
    .then((res) => res.data.data);

const getRepoBranchCommits = (orgId: string, repo: string, branch: string) =>
  instance
    .get<{ data: GitRepoBranchCommits }>(
      `org/${orgId}/repo/commits?gitRepo=${repo}&branch=${branch}`,
    )
    .then((res) => res.data.data);

const getRepoCommitStats = (orgId: string, repo: string, commit: string) =>
  instance
    .get<{ data: GitRepoStats }>(
      `/org/${orgId}/assessments/stats?gitRepo=${repo}&commit=${commit}`,
    )
    .then((res) => res.data.data);

const getRepoDashboardMetadata = (orgId: string) =>
  instance
    .get<APIResponse<DashboardMetaData>>(
      `/org/${orgId}/repo/insights/dashboard/metadata`,
    )
    .then((res) => res.data.data);

const getRepoConfigYaml = (orgId: string, repo: string) =>
  instance
    .get<APIResponse<{ config: string }>>(
      `/org/${orgId}/config.yml?gitRepo=${repo}`,
    )
    .then((res) => res.data.data.config);

const getRepoBadges = (orgId: string, repo: string) =>
  instance
    .get<APIResponse<Record<string, { markdown: string }>>>(
      `/org/${orgId}/repository/badges?gitRepo=${repo}`,
    )
    .then((res) => res.data.data);

const getManageRepoUrl = (orgId: string) =>
  instance
    .get<APIResponse<{ appUrl: string }>>(`/org/${orgId}/manage-repo-url`)
    .then((res) => res.data.data);

const getDashboardElementData = (
  orgId: string,
  apiEndpoint: string,
  repo: string,
) =>
  instance
    .get<APIResponse<DashboardElementData>>(
      `/org/${orgId}/repo/insights/dashboard/${apiEndpoint}?gitRepo=${repo}`,
    )
    .then((res) => res.data);

const getRepoConsolidatedAssessmentDetail = (orgId: string, repo: string) => {
  return instance
    .get<APIResponse<AssessmentDetail>>(
      `/org/${orgId}/repo/insights/dashboard/assessments/consolidated?gitRepo=${repo}`,
    )
    .then((res) => res.data.data);
};

const getRepoConsolidatedBranchesAssessmentDetail = (
  orgId: string,
  ciBuildId: string,
) => {
  return instance
    .get<APIResponse<AssessmentDetail>>(
      `/org/${orgId}/repo/assessments/consolidated`,
      {
        params: { ciBuildId },
      },
    )
    .then((res) => res.data.data);
};

const getRepoAssessments = (orgId: string, gitRepo: string) => {
  return instance
    .get<BranchAssessmentMain>(
      `/org/${orgId}/repo/assessments?gitRepo=${gitRepo}`,
    )
    .then((res) => res.data);
};

const getRepoMetadata = (orgId: string, repo: string) => {
  return instance
    .get<APIResponse<GitRepoMetadata>>(
      `/org/${orgId}/repo/insights/metadata?gitRepo=${repo}`,
    )
    .then((res) => res.data.data);
};

const getRepoAnalysisData = (orgId: string, repo: string) => {
  return instance
    .get<
      APIResponse<{
        terraformEnabled: boolean;
        planAnalysis?: GitRepoJobStatus;
      }>
    >(`/org/${orgId}/repo/analysis?gitRepo=${repo}`)
    .then((res) => res.data.data);
};

const getRepoInsightsStatus = (orgId: string, repo: string) => {
  return instance
    .get<APIResponse<RepoInsightStatus>>(
      `/org/${orgId}/repo/insights/status?gitRepo=${repo}`,
    )
    .then((res) => res.data.data);
};

const getRepoRecommendations = (orgId: string, repo: string) => {
  return instance
    .get<PaginatedResponse<RepoRecommendation>>(
      `/org/${orgId}/repo/insights/dashboard/suggestions?gitRepo=${repo}`,
    )
    .then((res) => res.data.data);
};

const getRepoJobs = (orgId: string, repo: string) => {
  return instance
    .get<PaginatedResponse<IacBotJob>>(
      `/org/${orgId}/repo/zodiac-jobs/recent?gitRepo=${repo}`,
    )
    .then((res) => res.data.data);
};

const getRepoJobStatus = (orgId: string, taskId: string) =>
  instance
    .get<APIResponse<GitRepoJobMetadata>>(`org/${orgId}/zodiac/tasks/${taskId}`)
    .then((res) => res.data.data);

const getRepoJobStepStatus = (orgId: string, taskId: string, step: string) =>
  instance
    .get<APIResponse<JobStepStatus>>(
      `/org/${orgId}/zodiac/tasks/${taskId}/steps/${step}/status`,
    )
    .then((res) => res.data.data);

const getRepoIaCJobs = (orgId: string, repo: string) => {
  return instance
    .get<PaginatedResponse<IacBotJob>>(
      `/org/${orgId}/iacbot/invocations?gitRepo=${repo}`,
    )
    .then((res) => res.data.data);
};

const getRepoSettings = (orgId: string, repo: string) => {
  return instance
    .get<APIResponse<GitRepoSettings>>(
      `org/${orgId}/repo/config/settings?gitRepo=${repo}`,
    )
    .then((res) => res.data.data);
};

const getRepoFindingsOverTime = (orgId: string, gitRepo: string) =>
  instance
    .get<{ data: { labels: string[]; findings: number[] } }>(
      `/org/${orgId}/repository/stats/iac-findings-over-time?gitRepo=${gitRepo}`,
    )
    .then((res) => res.data.data);

interface RepoSettingsPayload {
  repoEnabled: boolean;
}

const postRepoSettings = (
  orgId: string,
  gitRepo: string,
  settings: RepoSettingsPayload,
) =>
  instance
    .post(`/org/${orgId}/repo/config/settings?gitRepo=${gitRepo}`, settings)
    .then((res) => res.data);

const triggerDiscovery = (orgId: string, force?: boolean) =>
  instance
    .post(
      `/org/${orgId}/iacbot/repository/discovery${
        force ? "?forceDiscovery=true" : ""
      }`,
    )
    .then((res) => res.data);

const toggleFavorite = (orgId: string, gitRepo: string, isFavorite: boolean) =>
  instance
    .post<APIResponse<Repository>>(`/org/${orgId}/repo/favorite`, {
      gitRepo,
      isFavorite,
    })
    .then((res) => res.data.data);

export default {
  getAllRepoBranchesAndCommits,
  getRepoConsolidatedBranchesAssessmentDetail,
  getDashboardElementData,
  getManageRepoUrl,
  getRepoAssessments,
  getRepoAnalysisData,
  getRepoBadges,
  getRepoBranchCommits,
  getRepoBranches,
  getRepoCommitStats,
  getRepoConfigYaml,
  getRepoConsolidatedAssessmentDetail,
  getRepoDashboardMetadata,
  getRepoFindingsOverTime,
  getRepoIaCJobs,
  getRepoInsightsStatus,
  getRepoJobStatus,
  getRepoJobStepStatus,
  getRepoJobs,
  getRepoMetadata,
  getRepoRecommendations,
  getRepoSettings,
  getRepos,
  postRepoSettings,
  toggleFavorite,
  triggerDiscovery,
};
