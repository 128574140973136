import React, {
  FC,
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";

import { useCurrentOrgId } from "providers";

import AssessmentRow, { AssessmentRowProps } from "./components/AssessmentRow";
import AssessmentsPagination from "./components/AssessmentsPagination";
import DataTable from "components/DataTable";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import { useQueryState } from "hooks/useQueryState";
import assessmentService from "services/assessmentService";
import { AssessmentA as AssessmentI } from "types/assessments";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& > table > tbody > tr > td": {
        border: 0,
      },
    },
  }),
);

const columnsDef = (
  rowProps: Omit<AssessmentRowProps, "assessment">,
): Column<AssessmentI>[] => [
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ row: { original: row } }) => (
      <AssessmentRow {...rowProps} assessment={row} />
    ),
  },
  {
    Header: "ts",
    accessor: "createTs",
  },
  {
    Header: "repo",
    accessor: "gitRepo",
  },
  {
    Header: "ts",
    accessor: "gitBranch",
  },
  {
    Header: "ts",
    accessor: "idNum",
  },
  {
    Header: "ts",
    accessor: "gitCommit",
  },
  {
    Header: "ts",
    accessor: "pass",
    filter: "equals",
  },
];

interface QueryState {
  sortBy: string;
  sortDirection: "asc" | "desc";
  page: number;
  pageSize: number;
  query: string;
  ciBuildUrl: string;
}

const defaultQuery: AssessmentI[] = [];

const Assessments: FC = () => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const tableRef = useRef<DataTableRef<AssessmentI>>(null);

  const [processingRows, setProcessingRows] = useState<string[]>([]);

  const {
    state: {
      sortBy = "createTs",
      sortDirection = "desc",
      page = 1,
      pageSize = 10,
      ciBuildUrl,
      query,
    },
    setQueryState,
  } = useQueryState<QueryState>("assessments");

  const { data = defaultQuery, isLoading } = useQuery(
    ["assessments", orgId, ciBuildUrl],
    () => assessmentService.getAllAssessments(orgId, ciBuildUrl),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const dataA: AssessmentI[] = useMemo(
    () =>
      data.map((d) => ({
        ...d,
        pass:
          d.metrics?.findingsFail === 0 ||
          d.metrics?.findingsFail === undefined,
      })),
    [data],
  );

  const handleOnSearchChange = useCallback(
    (filterText: string) => {
      setQueryState({
        query: filterText,
        page: undefined,
      });
    },
    [setQueryState],
  );

  const columns = useMemo(
    () => columnsDef({ processingRows, setProcessingRows }),
    [processingRows],
  );

  return (
    <Fragment>
      <PageHeader>Assessments</PageHeader>
      <Card>
        <Box px={matches ? 0 : 2} py={2}>
          <DataTable
            data={dataA}
            columns={columns}
            isLoading={isLoading}
            pagination
            onSearchChange={handleOnSearchChange}
            hiddenColumns={[
              "createTs",
              "idNum",
              "gitRepo",
              "gitBranch",
              "gitCommit",
              "pass",
            ]}
            containerClassname={classes.table}
            defaultSortField={sortBy as keyof AssessmentI}
            sortByDesc={sortDirection === "desc"}
            initialPageIndex={page}
            initialRowsPerPage={pageSize}
            autoResetFilters={false}
            pageSizeOptions={[10, 25, 50, 100]}
            onChangePage={(page) => setQueryState({ page })}
            onChangeRowsPerPage={(pageSize) =>
              setQueryState({ pageSize, page: 1 })
            }
            search
            searchActions={
              <FiltersModal
                fields={[
                  {
                    displayName: "Status",
                    type: "select",
                    key: "pass",
                    renderValue: (v) => (
                      <Box display="flex" alignItems="center">
                        {v ? (
                          <Fragment>
                            <Box
                              component={CheckCircle}
                              mr={1}
                              color="success.main"
                            />
                            Pass
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Box component={Cancel} mr={1} color="error.main" />
                            Fail
                          </Fragment>
                        )}
                      </Box>
                    ),
                    values: [
                      { displayName: "Pass", value: true },
                      { displayName: "Fail", value: false },
                    ],
                  },
                  {
                    displayName: "ID",
                    type: "textfield",
                    key: "idNum",
                  },
                  {
                    displayName: "Repository",
                    type: "textfield",
                    key: "gitRepo",
                  },
                  {
                    displayName: "Branch",
                    type: "textfield",
                    key: "gitBranch",
                  },
                  {
                    displayName: "Commit",
                    type: "textfield",
                    key: "gitCommit",
                  },
                ]}
                title="Filter Assessments"
                disabled={isLoading}
                tableRef={tableRef}
                persistInUrl
              />
            }
            paginationComponent={(props) => (
              <AssessmentsPagination {...props} />
            )}
            compact
            query={query}
            hideTableHead
            noDataComponent={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                py={4}
                flexDirection="column"
              >
                <Box fontSize={60} style={{ opacity: 0.25 }}>
                  <FontAwesomeIcon icon={faClipboard} />
                </Box>
                <Box textAlign="center" mb={3}>
                  <Typography variant="body1">
                    There are no assessments available.
                  </Typography>
                </Box>
              </Box>
            }
            ref={tableRef}
          />
        </Box>
      </Card>
    </Fragment>
  );
};

export default Assessments;
