import React, { FC, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import useCommonStyles from "theme/useCommonStyles";

import { useCurrentOrgId } from "providers";

import ChartWrapper from "components/ChartWrapper/ChartWrapper";
import LogoBadge from "components/LogoBadge";
import IaCRepoIcon from "pages/Repositories/pages/Repositories/components/IaCRepoIcon";
import LanguageIcon from "pages/Repositories/pages/Repositories/components/LanguageIcon";
import RepositoryFindingsSeverity from "pages/Repositories/pages/Repositories/components/RepositoryFindingsSeverity";
import repositoryService from "services/repositoryService";
import { DashboardRepositoriesElementData } from "types/dashboard";
import { Repository } from "types/repositories";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f8f8f8",
      },
      transition: "background-color 0.2s",
    },
    ellipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    findingsRow: {
      [theme.breakpoints.down("sm")]: {
        marginTop: -theme.spacing(2.5),
      },
    },
  }),
);
interface RepositoriesElementProps {
  data: DashboardRepositoriesElementData;
  isLoading: boolean;
}

const RepositoriesElement: FC<RepositoriesElementProps> = ({
  data,
  isLoading,
}) => {
  return (
    <Box p={3} px={2}>
      {isLoading ? (
        <Grid container spacing={3}>
          <Grid item md={6} lg={5} container alignItems="center">
            <Skeleton circle height={50} width={50} />
            <Box mr={1} />
            <Skeleton width={200} />
          </Grid>
          <Grid item md={6} lg={5}>
            <Box maxWidth={350}>
              <Skeleton />
            </Box>
          </Grid>
        </Grid>
      ) : data?.data?.length > 0 ? (
        <Fragment>
          {data?.data?.map((r, i) => (
            <RepositoryElement key={i} repo={r} />
          ))}
          <Box mt={2} textAlign="center">
            <Link to="/repos"> View All</Link>
          </Box>
        </Fragment>
      ) : (
        <Box p={2} textAlign="center">
          <Box mb={1} style={{ opacity: 0.25 }}>
            <FontAwesomeIcon icon={faGitAlt} size="2x" />
          </Box>
          <Typography variant="body2">
            No Repositories with Violations.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const RepositoryElement: FC<{ repo: Repository }> = ({ repo }) => {
  const orgId = useCurrentOrgId();
  const theme = useTheme();
  const classes = useStyles();
  const commonClasss = useCommonStyles();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { data = { labels: [], findings: [] }, isLoading } = useQuery(
    ["repositories-findings", orgId, repo.gitRepo],
    () => repositoryService.getRepoFindingsOverTime(orgId, repo.gitRepo),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Link
      className={commonClasss.tabLink}
      to={`/repos/details/${encodeURIComponent(repo.gitRepo)}/overview`}
    >
      <Box px={1} className={classes.root} mb={matches ? 2 : 0}>
        <Grid container spacing={3} alignItems="center">
          <Fragment>
            <Grid item md={6} lg={5} className={classes.ellipsis}>
              <Grid container wrap="nowrap" spacing={2} alignItems="center">
                <Grid item>
                  <LogoBadge logo={repo.gitProvider} width={50} />
                </Grid>
                <Grid item className={classes.ellipsis}>
                  <Typography className={classes.ellipsis} variant="subtitle1">
                    {repo.gitRepoName}
                  </Typography>
                  <Box display="flex">
                    <IaCRepoIcon hasIac={repo.hasIac} />
                    <LanguageIcon hasLanguage={repo.hasLanguage} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classes.findingsRow}
              container
              spacing={2}
              item
              md={6}
              lg={5}
              alignItems="center"
            >
              <Grid item>
                <Box
                  mr={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box fontSize={13} color="gray">
                    Violations
                  </Box>
                  <Box fontSize={16} color="text.primary">
                    {repo.metrics?.summary?.findingsFail}
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <RepositoryFindingsSeverity
                  stats={{
                    critical: repo.metrics?.summary?.findingsFailCritical,
                    high: repo.metrics?.summary?.findingsFailHigh,
                    medium: repo.metrics?.summary?.findingsFailMedium,
                    low: repo.metrics?.summary?.findingsFailLow,
                    info: repo.metrics?.summary?.findingsFailInfo,
                  }}
                />
              </Grid>
            </Grid>
          </Fragment>

          {!matches && (
            <Grid item lg={2}>
              {isLoading ? (
                <Skeleton />
              ) : (
                <Tooltip
                  title="Findings trend for past week"
                  arrow
                  placement="bottom"
                >
                  <div>
                    <ChartWrapper
                      height={66}
                      width="100%"
                      maxWidth={250}
                      type="line"
                      data={{
                        labels: data.labels,
                        datasets: [
                          {
                            fill: false,
                            data: data.findings,
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          tooltip: {
                            enabled: true,
                          },
                          legend: {
                            display: false,
                          },
                        },
                        layout: {
                          padding: {
                            top: 20,
                            left: 10,
                            right: 10,
                            bottom: 20,
                          },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        elements: {
                          line: {
                            borderColor: theme.palette.primary.main,
                            borderWidth: 4,
                          },
                          point: {
                            radius: 0,
                          },
                        },
                        scales: {
                          y: {
                            display: false,
                          },
                          x: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Link>
  );
};

export default RepositoriesElement;
