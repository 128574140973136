import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import { toast } from "react-toastify";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { useCurrentOrgId, useHasScope } from "providers";

import AddAwsAccountModal, {
  AddAwsAccountForm,
  AddAwsAccountModalRef,
} from "./components/AddAwsAccountModal";
import DataTable from "components/DataTable";
import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import NoDataComponent from "components/NoDataComponent";
import PageHeader from "components/PageHeader";
import { errorExtractor } from "helpers/errorExtractor";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
  optimisticDatasetRemover,
} from "helpers/queryHelpers";
import { sortOkStatus } from "helpers/tableHelpers";
import { useQueryParams } from "hooks/useQueryParams";
import integrationService from "services/integrationService";
import { Permissions, Resources } from "types/auth-roles";
import { AwsAccount, AwsAccountStatus } from "types/integration";

interface AwsAccountTableActions {
  onConnectAccountClick: (awsAccount: AddAwsAccountForm) => void;
  onTestConnectionClick: (row: AwsAccount) => void;
  onRemoveAccountClick: (row: AwsAccount) => void;
}

const columnsDef = (
  {
    onConnectAccountClick,
    onTestConnectionClick,
    onRemoveAccountClick,
  }: AwsAccountTableActions,
  hasAdapterWriteScope: boolean,
  testingAccounts: string[],
): Column<AwsAccount>[] => [
  {
    Header: "AWS Account Number",
    accessor: "account",
  },
  {
    Header: "AWS Account Name",
    accessor: "name",
  },
  {
    Header: "Status",
    accessor: "status",
    width: 60,
    sortType: sortOkStatus,
    Cell: ({ row: { original: row } }) =>
      row.status === AwsAccountStatus.Ok ? (
        <Box color="success.main" fontSize={16} textAlign="left">
          <FontAwesomeIcon fontSize="inherit" icon={faCheckCircle} />
        </Box>
      ) : (
        <Box color="error.main" fontSize={16} textAlign="left">
          <FontAwesomeIcon fontSize="inherit" icon={faTimesCircle} />
        </Box>
      ),
  },
  {
    Header: "Actions",
    accessor: "name",
    id: "actions",
    collapse: true,
    Cell: ({ row: { original: row } }) => {
      if (testingAccounts.includes(row.account)) {
        return <CircularProgress size={22} color="primary" />;
      }

      if (row.status !== AwsAccountStatus.Ok) {
        return (
          hasAdapterWriteScope && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => onConnectAccountClick(row)}
            >
              <Typography variant="button" noWrap>
                Connect Account
              </Typography>
            </Button>
          )
        );
      }

      const dropdownActions: DropdownItem[] = [
        {
          label: "Test Connection",
          action: () => onTestConnectionClick(row),
        },
      ];

      // tslint:disable-next-line: no-unused-expression
      hasAdapterWriteScope &&
        dropdownActions.push({
          label: "Remove Account",
          action: () => onRemoveAccountClick(row),
        });

      return <DropdownActions items={dropdownActions} />;
    },
  },
];

const AwsConfiguration: FC = () => {
  const orgId = useCurrentOrgId();
  const hasAdapterWriteScope = useHasScope(
    Resources.Adapter,
    Permissions.Write,
  );
  const modalRef = useRef<AddAwsAccountModalRef>(null);
  const [apiError, setApiError] = useState<string>();
  const [connectAccountDefaultValue, setConnectAccountDefaultValue] =
    useState<AddAwsAccountForm>();
  const [testingAccounts, setTestingAccounts] = useState<string[]>([]);
  const { addAccount } = useQueryParams<{ addAccount: boolean }>();

  const {
    data: awsAccounts = [],
    isLoading,
    refetch,
  } = useQuery(
    ["awsAccounts", orgId],
    () => integrationService.getAwsAccounts(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    },
  );

  const { mutate: activateAwsAccount } = useMutation(
    (payload: AddAwsAccountForm) =>
      integrationService.connectAwsAccount(orgId, payload),
    {
      onError(error, _values, rollback) {
        setApiError(errorExtractor(error, "Connection error"));
        refetch();
      },
      onSettled() {
        refetch();
      },
      onSuccess(_, payload) {
        modalRef.current?.close();
        toast.success(`${payload.account} was successfully added.`);
      },
    },
  );

  const { mutate: removeAwsAccount } = useMutation(
    (account: string) => integrationService.removeAwsAccount(orgId, account),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      onMutate: (account) =>
        optimisticDatasetRemover<AwsAccount, "account">(
          ["awsAccounts", orgId],
          { account },
        ),
      onSettled() {
        refetch();
      },
      onSuccess(_, account) {
        toast.success(`${account} was successfully removed.`);
      },
    },
  );

  const handleOpenModal = useCallback(() => modalRef.current?.open(), []);

  const handleModalClosed = useCallback(() => {
    setApiError(void 0);
    setConnectAccountDefaultValue(void 0);
  }, []);

  const handleActivateAccountSubmit = useCallback(
    (value: AddAwsAccountForm) => {
      activateAwsAccount(value);
      setApiError(void 0);
    },
    [activateAwsAccount],
  );

  const handleConnectAccountClick = useCallback(
    (awsAccount: AddAwsAccountForm) => {
      setConnectAccountDefaultValue(awsAccount);
      handleOpenModal();
    },
    [handleOpenModal],
  );

  const handleRemoveAccountClick = useCallback(
    ({ account }: AwsAccount) => {
      removeAwsAccount(account);
    },
    [removeAwsAccount],
  );

  const handleTestConnectionClick = useCallback(
    async ({ account }: AwsAccount) => {
      try {
        setTestingAccounts((prev) => [...prev, account]);
        const awsAccountStatus = await integrationService.getAwsAccountStatus(
          orgId,
          account,
        );
        if (awsAccountStatus.status === AwsAccountStatus.Ok) {
          toast.success("Connection Success.");
        } else {
          toast.error("Connection Failure.");
        }
      } catch (error) {
        errorToastHandler(error);
      } finally {
        setTestingAccounts((prev) => prev.filter((acc) => acc !== account));
      }
    },
    [orgId],
  );

  useEffect(() => {
    if (!addAccount) {
      return;
    }

    modalRef.current?.open();
  }, [addAccount]);

  const columns = useMemo(
    () =>
      columnsDef(
        {
          onConnectAccountClick: handleConnectAccountClick,
          onRemoveAccountClick: handleRemoveAccountClick,
          onTestConnectionClick: handleTestConnectionClick,
        },
        hasAdapterWriteScope,
        testingAccounts,
      ),
    [
      handleConnectAccountClick,
      handleRemoveAccountClick,
      handleTestConnectionClick,
      hasAdapterWriteScope,
      testingAccounts,
    ],
  );

  return (
    <Fragment>
      <PageHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          AWS Configuration
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to="/integrations">
              Integrations
            </Link>
            <Typography color="textPrimary">AWS Configuration</Typography>
          </Breadcrumbs>
        </Box>
      </PageHeader>
      <Card>
        <Box p={2}>
          {hasAdapterWriteScope && (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                startIcon={<Add />}
              >
                Add Account
              </Button>
            </Box>
          )}
          <DataTable
            isLoading={isLoading}
            noDataComponent={
              <NoDataComponent>
                You don't have any AWS Account in this organization.
              </NoDataComponent>
            }
            defaultSortField="status"
            data={awsAccounts}
            columns={columns}
          />
        </Box>
      </Card>
      <AddAwsAccountModal
        ref={modalRef}
        onSubmit={handleActivateAccountSubmit}
        onClosed={handleModalClosed}
        error={apiError}
        defaultValue={connectAccountDefaultValue}
      />
    </Fragment>
  );
};

export default AwsConfiguration;
