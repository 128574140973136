import React, { FC, Fragment, useRef, useState } from "react";
import { Box, Button, Switch, Tooltip, Typography } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import config from "config";
import { isEmpty, isUndefined } from "lodash";

import { useHasScope } from "providers";

import OrganizationToggleModal from "../../Organizations/components/OrganizationToggleModal";
import ConfirmationModal, {
  ConfirmationModalRef,
} from "components/ConfirmationModal/ConfirmationModal";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import { OrganizationDescription } from "types";
import { Permissions, Resources } from "types/auth-roles";

interface OrganizationDetailConfigProps {
  orgDetail: OrganizationDescription;
  orgId: string;
}

const OrganizationDetailConfig: FC<OrganizationDetailConfigProps> = ({
  orgDetail,
  orgId,
}) => {
  const [processingOrg, setProcessingOrg] = useState<
    OrganizationDescription | undefined
  >(undefined);
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );
  const isActive = isUndefined(orgDetail?.active) ? true : orgDetail?.active;

  const formRef = useRef<HTMLFormElement>(null);
  const confirmationModal = useRef<ConfirmationModalRef>(null);

  const title =
    isEmpty(orgDetail?.name) && isEmpty(orgDetail?.displayName)
      ? orgId
      : orgDetail?.name ?? orgDetail?.displayName;

  const onToggleOrg = () => setProcessingOrg(orgDetail);

  const onSubmit = () => {
    confirmationModal.current?.openModal({
      description: (
        <Box>
          <Box
            p={2}
            mt={-2}
            mb={2}
            bgcolor="lightBackground.main"
            borderRadius={4}
          >
            <Typography variant="body1">{title}</Typography>
          </Box>
          <Box display="flex" mb={2}>
            <Typography variant="h5">
              Are you sure you want to impersonate this Organization?
            </Typography>
          </Box>
          <Typography variant="body1" gutterBottom>
            Impersonating organizations grants you temporary access to their
            system via their support account.
          </Typography>
          <Typography variant="body1">
            To undo this action a logout is needed.
          </Typography>
        </Box>
      ),
      title: "Impersonate Organization",
      confirm: "Impersonate",
      cancel: "Cancel",
      action() {
        formRef.current?.submit();
        confirmationModal.current?.closeModal();
      },
    });
  };
  return (
    <Fragment>
      <Box>
        <Box display="flex" my={1}>
          <Box component="dt" width={140}>
            Org Name
          </Box>
          <Box>{orgDetail?.name ?? "-"}</Box>
        </Box>
        <Box display="flex" mb={2}>
          <Box component="dt" width={140}>
            Org Display Name
          </Box>
          <Box>{orgDetail?.displayName ?? "-"}</Box>
        </Box>
        <Box display="flex" mb={2}>
          <Box component="dt" width={140}>
            Org ID
          </Box>
          <Box>{orgDetail?.orgId ?? "-"}</Box>
        </Box>
        <Box display="flex" mb={2}>
          <Box component="dt" width={140}>
            Last Activity
          </Box>
          <Box>
            <RelativeTimeFormatter
              dateTs={orgDetail?.lastActivityTs}
              invalidDateText="-"
            />
          </Box>
        </Box>
        <Box display="flex" mb={2}>
          <Box component="dt" width={140}>
            Provider
          </Box>
          <Box>{orgDetail?.provider ?? "-"}</Box>
        </Box>
        <Box display="flex" mb={2}>
          <Box component="dt" width={140}>
            Active
          </Box>
          <Box>
            {hasOrgWriteScope ? (
              <Box onClick={() => onToggleOrg()}>
                <Switch color="primary" checked={isActive} />
              </Box>
            ) : isActive ? (
              <Box color="success.main" component={CheckCircle} />
            ) : (
              <Box color="error.main" component={Cancel} />
            )}
          </Box>
        </Box>
      </Box>
      <Box display="none">
        <form
          ref={formRef}
          action={`${config.API}/internal/impersonate`}
          method="POST"
        >
          <input
            onChange={() => {}}
            value={orgId}
            type="text"
            name="orgId"
            id="orgId"
          ></input>
        </form>
      </Box>
      <Tooltip
        arrow
        title={hasOrgWriteScope ? "" : "Insufficient Permissions."}
      >
        <span>
          <Button
            disabled={!hasOrgWriteScope}
            color="primary"
            variant="outlined"
            onClick={onSubmit}
          >
            Impersonate
          </Button>
        </span>
      </Tooltip>
      <ConfirmationModal ref={confirmationModal} />
      <OrganizationToggleModal
        onClose={() => setProcessingOrg(undefined)}
        organization={processingOrg}
      />
    </Fragment>
  );
};

export default OrganizationDetailConfig;
