import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import { Home } from "@material-ui/icons";

import { ReactComponent as NotFound } from "assets/svg/illustrations/404.svg";
import Logo from "assets/svg/logos/lw-logo.svg";

const NoMatch = () => {
  return (
    <Box
      height="100vh"
      width="100vw"
      bgcolor="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      overflow="scroll"
    >
      <Box mb={2}>
        <img width={245} src={Logo} alt="lacework-iac-security-logo" />
      </Box>

      <Box p={5} textAlign="center">
        <Box
          component={NotFound}
          maxWidth={450}
          width="100%"
          height="auto"
          mb={3}
        ></Box>
        <Typography variant="h4" color="textSecondary">
          We can't seem to find the page you're looking for.
        </Typography>
        <hr />
        <p>
          Make sure the address is correct and that the page hasn't moved. If
          you think this is a mistake,
          <Box display="inline-block" ml={0.5}>
            <a href="mailto:info@exmaple.com">contact us</a>.
          </Box>
        </p>

        <Button
          startIcon={<Home />}
          component={Link}
          to="/"
          size="small"
          color="primary"
          variant="outlined"
        >
          Take me home
        </Button>
      </Box>
    </Box>
  );
};

export default NoMatch;
