import React, { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { CellProps, Column } from "react-table";
import { faChevronRight, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { startCase } from "lodash";

import BootstrapBadge from "components/BootstrapBadge/BootstrapBadge";
import DataTable from "components/DataTable";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import SeverityComponent, {
  Severity,
} from "components/SeverityComponent/SeverityComponent";
import TimestampFormatter from "components/TimestampFormatter";
import UrnHyperlink from "components/UrnHyperlink";
import { sortSeverity } from "helpers/tableHelpers";
import ProviderIconLabel from "pages/Policies/components/ProviderIconLabel";
import IaCRepoIcon from "pages/Repositories/pages/Repositories/components/IaCRepoIcon";
import LanguageIcon from "pages/Repositories/pages/Repositories/components/LanguageIcon";
import {
  DashboardElementData,
  DashboardElementMetadata,
} from "types/dashboard";

interface TabularElementProps {
  metadata: DashboardElementMetadata;
  element?: DashboardElementData;
  isLoading: boolean;
}

const TabularElement: FC<TabularElementProps> = ({
  metadata,
  element,
  isLoading,
}) => {
  const columns: Column<Record<string, string>>[] = !element
    ? []
    : element?.labels.map((label) => {
        const {
          hints = [],
          displayName,
          link = "",
        } = element.metadata.displayHints[
          label as keyof typeof element.metadata
        ] || {};
        const isTimestamp = hints.includes("TS");
        const isRelative = hints.includes("RELATIVE_TS");
        const isUrn = hints.includes("URN");
        const isUrl = hints.includes("URL");
        const isWide = hints.includes("WIDE");
        const isSeverity = hints.includes("SEVERITY");
        const isIAC = hints.includes("IACREPOSITORIES");
        const hasLanguages = hints.includes("HASLANGUAGE");
        const isHyperlink = hints.includes("HYPERLINK");
        const isPolicyCategory = hints.includes("POLICYCATEGORY");
        const isPolicyProvider = hints.includes("POLICYPROVIDER");
        const replaceableField =
          isHyperlink || isUrl
            ? link !== ""
              ? link.match(/{(.*)}/)?.[1] ?? ""
              : ""
            : "";

        return {
          Header: displayName ? startCase(displayName) : startCase(label),
          accessor: label,
          width: isWide ? 300 : 100,
          collapse: (isUrl || isIAC) && !isWide,
          sortType: isSeverity ? sortSeverity : "basic",
          Cell: (props: CellProps<any, any>) => {
            const {
              row: { original: row },
            } = props;
            if (isRelative) {
              return <RelativeTimeFormatter dateTs={row[label]} />;
            }
            if (isTimestamp) {
              return (
                <TimestampFormatter
                  dateTs={row[label]}
                  oneLine
                  disableCenterText
                />
              );
            }
            if (isUrn) {
              return <UrnHyperlink urn={row[label]} />;
            }
            if (isUrl) {
              const url = link.replace(
                `{${replaceableField}}`,
                row[replaceableField ?? ""],
              );
              return (
                <a href={url ?? ""} target="_blank" rel="noopener noreferrer">
                  {row[label]}
                </a>
              );
            }
            if (isHyperlink) {
              const hyperlink = link.replace(
                `{${encodeURIComponent(replaceableField)}}`,
                encodeURIComponent(row[replaceableField ?? ""]),
              );
              return <Link to={hyperlink ?? ""}>{row[label]}</Link>;
            }
            if (isSeverity) {
              return (
                <SeverityComponent
                  severity={Severity[row[label] as keyof typeof Severity]}
                />
              );
            }
            if (isIAC || hasLanguages) {
              return (
                <Box display="flex">
                  {isIAC && <IaCRepoIcon hasIac={row.hasIac ?? []} />}
                  {hasLanguages && (
                    <LanguageIcon hasLanguage={row.hasLanguage ?? []} />
                  )}
                </Box>
              );
            }

            if (isPolicyProvider) {
              return <ProviderIconLabel provider={row[label]} />;
            }
            if (!row[label]) {
              return "-";
            }
            if (isPolicyCategory) {
              return (
                <BootstrapBadge color="secondary">{row[label]}</BootstrapBadge>
              );
            }

            return (
              <Typography variant="body2">{String(row[label])} </Typography>
            );
          },
        } as Column<Record<string, string>>;
      });

  return (
    <Fragment>
      {isLoading ? (
        <Box
          component="h2"
          textAlign="center"
          py={5}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          -
        </Box>
      ) : (
        <Fragment>
          <Box display="flex" flexDirection="column" flex="1" p={2}>
            <DataTable
              columns={columns}
              data={element?.data ?? [{}]}
              compact
              pagination={false}
              noDataComponent={
                <Box p={2} textAlign="center">
                  <Box mb={1} style={{ opacity: 0.25 }}>
                    <FontAwesomeIcon icon={faTable} size="2x" />
                  </Box>
                  <Typography variant="body2">
                    {metadata.defaultMessage}
                  </Typography>
                </Box>
              }
            />
            {metadata.hyperlink && (
              <Box alignSelf="center" mt="auto" pt={2}>
                <Link to={metadata.hyperlink}>
                  View all
                  <Box ml={1} display="inline-block">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Box>
                </Link>
              </Box>
            )}
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

export default TabularElement;
