import React, { FC, Fragment } from "react";
import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { startCase } from "lodash";
import useCommonStyles from "theme/useCommonStyles";

import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import UrnHyperlink from "components/UrnHyperlink";
import { Field } from "types/reports";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:empty::after": {
        content: "-",
      },
    },
  }),
);

const DetailList: FC<{
  metadata: readonly Field[];
  data: Record<string, any>;
}> = ({ metadata, data }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  return (
    <dl>
      {metadata.map(({ displayName, name, displayHints = [] }, index) => {
        const isTimestamp = displayHints.includes("TS");
        const isRelative = displayHints.includes("RELATIVE_TS");
        const isUrn = displayName.toLowerCase() === "urn";
        const value = data[name];
        return (
          <Grid container spacing={1} key={index}>
            <Grid
              item
              component="dt"
              style={{ minWidth: "20ch" }}
              xs={4}
              sm={3}
              md={2}
            >
              {startCase(displayName)}
            </Grid>
            <Grid
              item
              component="dd"
              className={classNames(classes.root, commonClasses.textBreak)}
            >
              {isTimestamp || isRelative ? (
                <RelativeTimeFormatter dateTs={value} />
              ) : isUrn ? (
                <UrnHyperlink urn={value} />
              ) : value === null ? (
                "-"
              ) : typeof value === "object" ? (
                "Data cannot be displayed"
              ) : (
                String(value)
              )}
            </Grid>
          </Grid>
        );
      })}
    </dl>
  );
};

interface ReportRowExpandedProps {
  metadata: readonly Field[];
  data?: Record<string, any>;
}

const ReportRowExpanded: FC<ReportRowExpandedProps> = ({ metadata, data }) => {
  if (!data) {
    return <Fragment></Fragment>;
  }

  return (
    <Box p={3}>
      <DetailList metadata={metadata} data={data}></DetailList>
    </Box>
  );
};

export default ReportRowExpanded;
