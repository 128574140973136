import React, { FC, Fragment, useState } from "react";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Collapse,
  createStyles,
  darken,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandLessRounded, ExpandMoreRounded } from "@material-ui/icons";

import PipelineAssessmentRow from "./PipelineAssessmentRow";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import { PipelineAssessment } from "types/assessments";

interface PipelineAssessmentCollapseProps {
  pipeline: PipelineAssessment;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        cursor: "pointer",
        background: darken(theme.palette.lightBackground.main, 0.025),
      },
      margin: `0 -${theme.spacing(1)}px `,
      transition: "background-color 0.2s",
    },
  }),
);

const PipelineAssessmentCollapse: FC<PipelineAssessmentCollapseProps> = ({
  pipeline,
}) => {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => setIsCollapsed((prev) => !prev);

  return (
    <Fragment>
      <Box
        className={classes.root}
        onClick={toggleCollapse}
        p={2}
        border="1px solid lightgray"
        bgcolor="lightBackground.main"
      >
        <Box display="flex" alignItems="center">
          <Box mr={1.5}>
            <FontAwesomeIcon size="lg" icon={faWrench} />
          </Box>
          <Typography variant="h5">
            <Box display="inline" onClick={(e) => e.stopPropagation()}>
              <a
                href={pipeline.ciBuildUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Build ({pipeline.ciBuildNum})
              </a>
            </Box>

            <Box display="inline">
              {" - "}
              <RelativeTimeFormatter dateTs={pipeline.assessmentTs} />
            </Box>
          </Typography>
          <Box
            ml="auto"
            component={isCollapsed ? ExpandLessRounded : ExpandMoreRounded}
          />
        </Box>
      </Box>
      <Box border="1px solid lightgray" borderTop={0} mt="-1px" mx={-1}>
        <Collapse in={isCollapsed}>
          <Box p={2} display="flex" flexDirection="column" gridGap={16}>
            {pipeline.assessments.map((a, i) => (
              <PipelineAssessmentRow key={i} assessment={a} />
            ))}
          </Box>
        </Collapse>
      </Box>
    </Fragment>
  );
};

export default PipelineAssessmentCollapse;
