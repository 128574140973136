import React, { FC } from "react";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";

import { DashboardElementMetadata } from "types/dashboard";

interface ElementHeaderProps {
  metadata: DashboardElementMetadata;
  tooltip?: boolean;
}

const ElementHeader: FC<ElementHeaderProps> = ({
  metadata,
  tooltip = false,
}) => {
  const isTooltipEmpty = isEmpty(metadata.toolstip);
  return (
    <Box px={3} py={2} style={{ textTransform: "uppercase" }}>
      <Typography variant="h4" display="inline">
        {metadata.title}
      </Typography>
      {tooltip && !isTooltipEmpty && (
        <Tooltip placement="right" title={metadata.toolstip} arrow>
          <Box component="span" ml={1} style={{ opacity: 0.25 }}>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};
export default ElementHeader;
