import React, { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { useHasScope, useUser } from "providers";

import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import { User } from "types";
import { Permissions, Resources } from "types/auth-roles";

export interface UserDataTableActions {
  onRemoveClick: (row: User) => void;
  onActivateClick: (row: User) => void;
  onDisableClick: (row: User) => void;
  onEditRoleClick: (row: User) => void;
}

interface UserDataDropdownActionsProps extends UserDataTableActions {
  data: User;
}

const UserDataDropdownActions: FC<UserDataDropdownActionsProps> = ({
  data,
  onRemoveClick,
  onActivateClick,
  onDisableClick,
  onEditRoleClick,
}) => {
  const user = useUser();
  const navigate = useNavigate();
  const hasUserWriteScope = useHasScope(Resources.User, Permissions.Write);

  const isEmailServiceAccount = data.email?.includes(
    "serviceaccount.soluble.cloud",
  );

  const viewProfile: DropdownItem = {
    label: "View Profile",
    action: () => navigate("/profile"),
  };

  if (isEmailServiceAccount) {
    return <Fragment> </Fragment>;
  }

  if (user.userId === data.userId) {
    return <DropdownActions items={[viewProfile]}></DropdownActions>;
  }

  const dropdownActions: DropdownItem[] = [];

  const activateUser: DropdownItem = {
    label: "Activate User",
    action: () => onActivateClick(data),
  };

  const disableUser: DropdownItem = {
    label: "Disable User",
    action: () => onDisableClick(data),
  };

  const removeUser: DropdownItem = {
    label: "Remove User",
    action: () => onRemoveClick(data),
  };

  const updateUserRole: DropdownItem = {
    label: "Edit role",
    action: () => onEditRoleClick(data),
  };

  if (data.status === "active") dropdownActions.push(disableUser);
  if (data.status === "disabled") dropdownActions.push(activateUser);
  if (hasUserWriteScope) dropdownActions.push(updateUserRole);

  dropdownActions.push(removeUser);

  return <DropdownActions items={dropdownActions} />;
};

export default UserDataDropdownActions;
