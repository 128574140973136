import React, { FC } from "react";
import {
  Box,
  BoxProps,
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-block",
      padding: ".35em .65em",
      fontSize: ".75em",
      fontWeight: 600,
      lineHeight: 1,
      color: "#fff",
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "baseline",
      borderRadius: ".25rem",
    },
    pill: {
      borderRadius: "50rem",
    },
    default: {
      backgroundColor: "#6c757d",
    },
    primary: {
      background: theme.palette.primary.main,
    },
    secondary: {
      background: "#748194",
    },
    success: {
      background: theme.palette.success.main,
    },
    error: {
      background: theme.palette.error.main,
    },
    warning: {
      background: theme.palette.warning.main,
    },
    info: {
      background: theme.palette.info.main,
    },
    "soft-primary": {
      color: theme.palette.primary.dark,
      background: lighten(theme.palette.primary.light, 0.7),
    },
    "soft-secondary": {
      color: theme.palette.secondary.dark,
      background: lighten(theme.palette.secondary.light, 0.7),
    },
    "soft-success": {
      color: theme.palette.success.dark,
      background: lighten(theme.palette.success.light, 0.7),
    },
    "soft-error": {
      color: theme.palette.error.dark,
      background: lighten(theme.palette.error.light, 0.7),
    },
    "soft-warning": {
      color: theme.palette.warning.dark,
      background: lighten(theme.palette.warning.light, 0.7),
    },
    "soft-info": {
      color: theme.palette.info.dark,
      background: lighten(theme.palette.info.light, 0.7),
    },
  }),
);

interface BootstrapBadgeProps extends BoxProps {
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "warning"
    | "info"
    | "soft-primary"
    | "soft-secondary"
    | "soft-success"
    | "soft-error"
    | "soft-warning"
    | "soft-info";
  pill?: boolean;
}

const BootstrapBadge: FC<BootstrapBadgeProps> = ({
  color = "default",
  pill,
  children,
  className,
  ...boxProps
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classNames(
        classes.root,
        classes[color],
        {
          [classes.pill]: pill,
        },
        className,
      )}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default BootstrapBadge;
