import React, { FC, Ref, RefObject, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Row } from "react-table";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@material-ui/icons";

import { DataTableRef } from "components/DataTable/DataTable";
import { Finding } from "types/assessments";

interface FindingDetailControlsProps {
  tableRefs: {
    findingsTableRef: RefObject<DataTableRef<Finding>>;
    suppressedTableRef: RefObject<DataTableRef<Finding>>;
  };
  changeFinding(row: Row<Finding>): void;
  suppressed: boolean;
  actionsRef?: {
    goPrev: Ref<HTMLButtonElement>;
    goNext: Ref<HTMLButtonElement>;
  };
}

const FindingDetailControls: FC<FindingDetailControlsProps> = ({
  tableRefs,
  changeFinding,
  suppressed,
  actionsRef,
}) => {
  const { findingId } = useParams<{
    findingId: string;
  }>();

  const currentTableRef = suppressed
    ? tableRefs.suppressedTableRef
    : tableRefs.findingsTableRef;

  const rows = currentTableRef.current?.tableInstance.rows;

  const selectedFindingIndex = useMemo(
    () => rows?.findIndex((d) => d.original.id === findingId) ?? 0,
    [findingId, rows],
  );
  const selectedFinding = useMemo(
    () => rows?.[selectedFindingIndex ?? 0],
    [selectedFindingIndex, rows],
  );

  const canGoNext = useMemo(
    () => selectedFindingIndex + 1 !== rows?.length,
    [selectedFindingIndex, rows?.length],
  );
  const goNext = () => {
    if (
      selectedFinding &&
      (selectedFindingIndex || selectedFindingIndex === 0) &&
      rows
    ) {
      if (!currentTableRef.current) return;

      const { pageSize, pageIndex } =
        currentTableRef.current?.tableInstance.state;
      const nextPageIndex = Math.floor((selectedFindingIndex + 1) / pageSize);
      if (pageIndex !== nextPageIndex)
        currentTableRef.current?.tableInstance.gotoPage(nextPageIndex);
      changeFinding(rows[selectedFindingIndex + 1]);
    }
  };

  const canGoPrev = useMemo(
    () => selectedFindingIndex !== 0,
    [selectedFindingIndex],
  );

  const goPrev = () => {
    if (
      selectedFinding &&
      (selectedFindingIndex || selectedFindingIndex === 0) &&
      rows
    ) {
      if (!currentTableRef.current) return;

      const { pageSize, pageIndex } =
        currentTableRef.current?.tableInstance.state;
      const nextPageIndex = Math.floor((selectedFindingIndex - 1) / pageSize);
      if (pageIndex !== nextPageIndex)
        currentTableRef.current?.tableInstance.gotoPage(nextPageIndex);
      changeFinding(rows[selectedFindingIndex - 1]);
    }
  };

  return (
    <Box display="flex" justifyContent="flex-end" mb={1}>
      <Box mr={1}>
        <Tooltip title="←">
          <span>
            <IconButton
              onClick={goPrev}
              disabled={!canGoPrev}
              innerRef={actionsRef?.goPrev}
            >
              <ArrowBackIosRounded fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Tooltip title="→">
        <span>
          <IconButton
            onClick={goNext}
            disabled={!canGoNext}
            innerRef={actionsRef?.goNext}
          >
            <ArrowForwardIosRounded fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default FindingDetailControls;
