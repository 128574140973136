import { QueryKey } from "react-query";
import { toast } from "react-toastify";

import { queryClient } from "providers/ExternalProviders/ReactQueryConfigProvider";

import { errorExtractor } from "./errorExtractor";

/**
 * This is a helper function to update an object in a dataset, that return a rollback function to
 * be called on errors.
 *
 * @param queryKey The object used as queryKey for the dataset in the useQuery function
 * @param searcherObject The object to be used as query to identify the wanted object in the
 * dataset. This object should only have one property.
 * @param update The partial update to be applied to the found object.
 *
 * @returns A rollback function that will restore the dataset to the original state.
 * Call this from `onError` handler.
 */

export type ArrayQueryKey = QueryKey[] | string;

export const optimisticDatasetUpdater = <Model, QueryKey extends keyof Model>(
  queryKey: ArrayQueryKey,
  searcherObject: Record<QueryKey, Model[QueryKey]>,
  update: Partial<Model>,
) => {
  queryClient.cancelQueries(queryKey, { exact: true });

  const currentSet = queryClient.getQueryData<Model[]>(queryKey);
  const idKey = Object.keys(searcherObject)[0] as QueryKey;
  const idValue = searcherObject[idKey];

  queryClient.setQueryData<Model[] | undefined>(queryKey, (oldSet) =>
    oldSet?.map((oldValue: any) => {
      if (oldValue[idKey] === idValue) {
        return { ...oldValue, ...update };
      } else {
        return oldValue;
      }
    }),
  );

  return () => queryClient.setQueryData(queryKey, currentSet);
};

/**
 * This is a helper function to update an object in a dataset, that return a rollback function to
 * be called on errors.
 *
 * @param queryKey The object used as queryKey for the dataset in the useQuery function
 * @param searcherObject The object to be used as query to identify the wanted object in the
 * dataset. This object should only have one property. The values of this objects will be used to
 * filter the dataset.
 * @param update The partial update to be applied to the found object.
 *
 * @returns A rollback function that will restore the dataset to the original state.
 * Call this from `onError` handler.
 */
export const optimisticDatasetBulkUpdater = <
  Model,
  QueryKey extends keyof Model,
>(
  queryKey: ArrayQueryKey,
  searcherObject: Record<QueryKey, Model[QueryKey][]>,
  update: Partial<Model>,
) => {
  queryClient.cancelQueries(queryKey, { exact: true });

  const currentSet = queryClient.getQueryData<Model[]>(queryKey);
  const idKey = Object.keys(searcherObject)[0] as QueryKey;
  const idValues = searcherObject[idKey];

  queryClient.setQueryData<Model[] | undefined>(queryKey, (oldSet) =>
    oldSet?.map((oldValue: any) => {
      if (!idValues.includes(oldValue[idKey])) {
        return oldValue;
      }

      return {
        ...oldValue,
        ...update,
      };
    }),
  );

  return () => queryClient.setQueryData(queryKey, currentSet);
};

/**
 * This is a helper function to remove an object in a dataset, that return a rollback function to
 * be called on errors.
 *
 * @param queryKey The object used as queryKey for the dataset in the useQuery function
 * @param searcherObject The object to be used as query to identify the wanted object in the
 * dataset. This object should only have one property.
 *
 * @returns A rollback function that will restore the dataset to the original state.
 * Call this from `onError` handler.
 */
export const optimisticDatasetRemover = <Model, QueryKey extends keyof Model>(
  queryKey: ArrayQueryKey,
  searcherObject: Record<QueryKey, Model[QueryKey]>,
) => {
  queryClient.cancelQueries(queryKey, { exact: true });

  const currentSet = queryClient.getQueryData<Model[]>(queryKey);
  const idKey = Object.keys(searcherObject)[0] as QueryKey;
  const idValue = searcherObject[idKey];

  queryClient.setQueryData<Model[] | undefined>(queryKey, (oldSet) =>
    oldSet?.filter((oldValue: any) => oldValue[idKey] !== idValue),
  );

  return () => queryClient.setQueryData(queryKey, currentSet);
};

/**
 * This is a helper function to insert an object in a dataset, that return a rollback function to
 * be called on errors.
 *
 * @param queryKey The object used as queryKey for the dataset in the useQuery function
 * @param model The object to be inserted in the dataset.
 *
 * @returns A rollback function that will restore the dataset to the original state.
 * Call this from `onError` handler.
 */
export const optimisticDatasetInserter = <
  Model,
  ExcludedKeys extends keyof Model,
>(
  queryKey: ArrayQueryKey,
  model: Omit<Model, ExcludedKeys>,
) => {
  queryClient.cancelQueries(queryKey, { exact: true });

  const currentSet = queryClient.getQueryData<Model[]>(queryKey);

  queryClient.setQueryData<Model[] | undefined>(queryKey, (oldSet: any) => [
    ...(oldSet ? oldSet : []),
    model,
  ]);

  return () => queryClient.setQueryData(queryKey, currentSet);
};

/**
 * This is a helper function to update an object in a dataset, that return a rollback function to
 * be called on errors.
 *
 * @param queryKey The object used as queryKey for the dataset in the useQuery function
 * @param model The object to be inserted in the dataset.
 *
 * @returns A rollback function that will restore the dataset to the original state.
 * Call this from `onError` handler.
 */

export const optimisticObjectUpdater = <Model>(
  queryKey: ArrayQueryKey,
  update: Partial<Model>,
) => {
  queryClient.cancelQueries(queryKey, { exact: true });

  const currentObject = queryClient.getQueryData<Model>(queryKey);

  queryClient.setQueryData<Model | undefined>(queryKey, (oldObject: any) => ({
    ...oldObject,
    ...update,
  }));

  return () => queryClient.setQueryData(queryKey, currentObject);
};

/**
 * A HOF to handle errors on the queries. Will show a toast and, on mutations call the rollback function.
 *
 * @param errorMsg The error message to be showed in the toaster. If omitted, this will use the
 * errorExtractor function on the error property.
 */

export const FALLBACK_ERROR_MESSAGE =
  "Something went wrong. Please try again later.";

export const errorToastHandler =
  <T>(errorMsg?: string) =>
  (error: any, _payload?: T, rollback?: () => void): void => {
    toast.error(errorExtractor(error, errorMsg));
    rollback?.();
  };
