import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";

import { ReactComponent as Success } from "assets/svg/illustrations/email-success.svg";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import { useInterval } from "hooks/useInterval";

const SuccessEmailVerification: FC = () => {
  const [seconds, setSeconds] = useState(10);
  const navigate = useNavigate();

  useInterval(() => {
    setSeconds((prev) => prev - 1);
  }, 1000);

  useEffect(() => {
    if (seconds === 0) navigate("/dashboard");
  }, [seconds, navigate]);

  return (
    <NavbarTopLayout white>
      <Box textAlign="center" p={3}>
        <Box
          component={Success}
          maxWidth="100%"
          width={600}
          height="auto"
          my={4}
        />
        <Box mb={2}>
          <Typography variant="h3">
            Your email address has been verified
          </Typography>
        </Box>
        <Box mb={3}>
          <Button component={Link} to="/" color="primary" variant="outlined">
            Go to Dashboard
          </Button>
        </Box>

        <Typography variant="body2" color="textSecondary" gutterBottom>
          You will be redirected in {seconds}s ...
        </Typography>
      </Box>
    </NavbarTopLayout>
  );
};

export default SuccessEmailVerification;
