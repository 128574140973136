import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import ErrorGoogleSignIn from "./ErrorGoogleSignIn/ErrorGoogleSignIn";
import ErrorPage from "./ErrorPage/ErrorPage";
import Unauthorized from "./Unauthorized/Unauthorized";

const AnalyticsRouter: FC<Childless> = () => {
  return (
    <Routes>
      <Route path="" element={<ErrorPage />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/google-login" element={<ErrorGoogleSignIn />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default AnalyticsRouter;
