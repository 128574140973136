import React, { FC, Fragment, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import { AssessmentOutlined } from "@material-ui/icons";

import { useCurrentOrgId } from "providers";

import { errorToastHandler } from "helpers/queryHelpers";
import DashboardElement from "pages/Dashboard/pages/Dashboard/components/DashboardElement";
import repositoriesService from "services/repositoryService";
import { DEFAULT_SIZES } from "types/dashboard";
import { RepoInsightStatus } from "types/repositories";

interface RepositoryDetailOverviewProps {
  status?: RepoInsightStatus;
  gitRepoName: string;
  isStatusLoading: boolean;
}

const RepositoryDetailOverview: FC<RepositoryDetailOverviewProps> = ({
  status,
  gitRepoName,
  isStatusLoading,
}) => {
  const orgId = useCurrentOrgId();
  const { data: dashboardMetadata, isLoading: isMetadataLoading } = useQuery(
    ["repo-dashboard-metadata", orgId],
    () => repositoriesService.getRepoDashboardMetadata(orgId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: status?.state.toLowerCase() === "success",
      onError: errorToastHandler(
        "An unexpected error ocurred while loading the repo insights. Please try again later.",
      ),
    },
  );

  const getterFunction = useCallback(
    (id: string, apiEndpoint: string) =>
      repositoriesService.getDashboardElementData(id, apiEndpoint, gitRepoName),
    [gitRepoName],
  );

  const dashboardElementSizes = {
    ...DEFAULT_SIZES,
    pie: { xs: 12, md: 6, lg: 4 },
    chart: { xs: 12, md: 6, lg: 4 },
    numerical: { xs: 6, md: 4, lg: 3 },
    list: { xs: 6, md: 4, lg: 3 },
  } as typeof DEFAULT_SIZES;

  return (
    <Fragment>
      <Box mb={2}>
        {isMetadataLoading ? (
          <Grid container spacing={2}>
            {[...Array(4)].map((d, ind) => (
              <Grid key={ind} item xs={6} md={4} lg={3}>
                <Box component={Card} p={2}>
                  <Skeleton width="40%" />
                  <Box height={50}>
                    <Skeleton width="100%" />
                  </Box>
                </Box>
              </Grid>
            ))}
            {[...Array(3)].map((d, ind) => (
              <Grid key={ind} item xs={12} md={6} lg={4}>
                <Box component={Card} p={2}>
                  <Skeleton width="30%" />
                  <Box height={50}>
                    <Skeleton width="100%" />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : status?.state === "failed" ? (
          <Box>{status?.message}</Box>
        ) : (
          <Grid container spacing={2}>
            {dashboardMetadata?.elements.map((element) => (
              <DashboardElement
                sizes={dashboardElementSizes}
                getterFunction={getterFunction}
                id={gitRepoName}
                key={element.id}
                element={element}
              />
            ))}
          </Grid>
        )}
      </Box>
      <Box id="l_assessments" bgcolor="white" p={2}>
        <Link
          color="primary"
          to={`/repos/details/${encodeURIComponent(gitRepoName)}/assessments`}
        >
          <Box display="flex" alignItems="center">
            <Box component={AssessmentOutlined} mr={2} />
            <Typography variant="h5" display="inline">
              VIEW FINDINGS
            </Typography>
          </Box>
        </Link>
      </Box>
    </Fragment>
  );
};

export default RepositoryDetailOverview;
