/* istanbul ignore file */
import React, { FC } from "react";
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import {
  FirstPageSharp,
  KeyboardArrowLeftSharp,
  KeyboardArrowRightSharp,
  LastPageSharp,
} from "@material-ui/icons";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: theme.typography.caption.fontSize,
      flexShrink: 0,
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        marginLeft: -5,
      },
    },
    iconButton: {
      color: theme.palette.text.primary,
      padding: 8,
      "&:disabled": {
        color: theme.palette.text.disabled,
      },
    },
  }),
);

const TablePaginationActions: FC<TablePaginationActionsProps> = (
  props: TablePaginationActionsProps,
) => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.iconButton}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageSharp /> : <FirstPageSharp />}
      </IconButton>
      <IconButton
        className={classes.iconButton}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightSharp />
        ) : (
          <KeyboardArrowLeftSharp />
        )}
      </IconButton>
      <IconButton
        className={classes.iconButton}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftSharp />
        ) : (
          <KeyboardArrowRightSharp />
        )}
      </IconButton>
      <IconButton
        className={classes.iconButton}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageSharp /> : <LastPageSharp />}
      </IconButton>
    </div>
  );
};

export default TablePaginationActions;
