import React, { FC } from "react";

import { useHasScope } from "providers";

import CanActivateRoute from "./CanActivateRoute";
import { Permissions, Resources } from "types/auth-roles";

interface HasScopeRouteProps {
  resource: Resources;
  permission: Permissions;
  element: React.ReactNode;
}

const HasScopeRoute: FC<HasScopeRouteProps> = ({
  resource,
  permission,
  element,
}) => {
  const hasScope = useHasScope(resource, permission);

  return <CanActivateRoute canActivate={hasScope} element={element} />;
};

export default HasScopeRoute;
