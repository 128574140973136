import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { Box, Card, Grid } from "@material-ui/core";
import useCommonStyles from "theme/useCommonStyles";

import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import { ZodiacBuildMetadata as ZodiacBuildMetadataI } from "types/zodiac";

interface ZodiacBuildMetadataProps {
  metadata?: ZodiacBuildMetadataI;
  isLoading: boolean;
}

const ZodiacBuildMetadata: FC<ZodiacBuildMetadataProps> = ({
  metadata,
  isLoading,
}) => {
  const classes = useCommonStyles();
  return (
    <Card>
      <Box p={2}>
        {isLoading ? (
          <Skeleton count={4} />
        ) : (
          <Grid container component="dl" spacing={2}>
            <Grid item xs={6} lg={4}>
              <dt>Repository</dt>
              <dd className={classes.wordBreakAll}>
                <a
                  href={metadata?.gitRepoUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {metadata?.gitRepo ?? "-"}
                </a>
              </dd>
            </Grid>
            <Grid item xs={6} lg={4}>
              <dt>Branch</dt>
              <dd className={classes.wordBreakAll}>
                {metadata?.gitBranch ?? "-"}
              </dd>
            </Grid>
            <Grid item xs={6} lg={4}>
              <dt>Commit</dt>
              <dd className={classes.wordBreakAll}>
                <a
                  href={metadata?.gitCommitUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {metadata?.gitCommit?.slice(0, 7) ?? "-"}
                </a>
              </dd>
            </Grid>
            <Grid item xs={6} lg={4}>
              <dt>Created</dt>
              <dd className={classes.wordBreakAll}>
                <RelativeTimeFormatter
                  dateTs={metadata?.createTs}
                  invalidDateText="-"
                />
              </dd>
            </Grid>
            <Grid item xs={6} lg={4}>
              <dt>Host</dt>
              <dd className={classes.wordBreakAll}>{metadata?.host ?? "-"}</dd>
            </Grid>
          </Grid>
        )}
      </Box>
    </Card>
  );
};

export default ZodiacBuildMetadata;
