import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { Overrides } from "@material-ui/core/styles/overrides";
import typography from "theme/typography";

const breakpoints = createBreakpoints({});

const borderRadiusZero = {
  root: {
    borderRadius: 0,
  },
};

export const overrides: Overrides = {
  // Render empty table cells as '-'
  MuiTableCell: {
    body: {
      "&:not(input):empty::after, & > *:not(input):empty::after": {
        content: `"-"`,
      },
    },
  },
  MuiTablePagination: {
    menuItem: {
      fontSize: 13,
    },
    root: {
      color: "rgba(0,0,0,0.54)",
    },
    caption: {
      fontSize: 13,
    },
    selectRoot: {
      marginRight: 24,
      [breakpoints.down("sm")]: {
        marginLeft: 6,
        marginRight: 6,
      },
    },
    select: {
      fontSize: 13,
    },
  },
  MuiDialogTitle: {
    root: {
      fontSize: typography.h3?.fontSize,
      fontWeight: typography.h3?.fontWeight,
    },
  },
  MuiButton: {
    root: {
      // borderRadius: 8,
      whiteSpace: "nowrap",
    },
  },
  MuiSwitch: {
    colorPrimary: {
      "&.Mui-disabled&.Mui-checked": {
        color: "#9498be",
      },
      "&.Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#9498be",
      },
      "& > input.MuiSwitch-thumb": {
        backgroundColor: "#9498be !important",
      },
    },
    colorSecondary: {
      "&.Mui-disabled&.Mui-checked": {
        color: "#678d8d",
      },
      "&.Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#678d8d",
      },
    },
  },
  MuiTextField: borderRadiusZero,
  MuiCard: borderRadiusZero,
  MuiDialog: {
    paper: {
      borderRadius: 0,
    },
  },
  MuiFormControl: borderRadiusZero,
  MuiInputBase: borderRadiusZero,
  MuiInput: borderRadiusZero,
  MuiFilledInput: borderRadiusZero,
  MuiOutlinedInput: borderRadiusZero,
};
