import React, { FC } from "react";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transform: "translate(-0.5rem, 0.2rem)",
      fontSize: theme.typography.h2.fontSize,
    },
  }),
);

interface StepTaskProps {
  isDone?: boolean;
}

const StepTask: FC<StepTaskProps> = ({ isDone = false }) => {
  const icon = isDone ? faCheckCircle : faCircle;
  const classes = useStyles();

  return <FontAwesomeIcon icon={icon} className={classes.root} />;
};

export default StepTask;
