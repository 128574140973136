import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import useCommonStyles from "theme/useCommonStyles";

import Logo from "assets/svg/logos/lw-logo.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: "none",
      background: theme.palette.background.default,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    bgWhite: {
      background: "white",
    },
    bgTransparent: {
      background: "transparent",
    },
  }),
);

interface NavbarTopLayoutProps {
  white?: boolean;
  className?: string;
  transparent?: boolean;
  disableLink?: boolean;
}

const NavbarTopLayout: FC<NavbarTopLayoutProps> = ({
  children,
  className,
  white,
  transparent,
  disableLink = false,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const goHome = () => navigate("/dashboard");

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classNames(className, {
        [classes.bgWhite]: white,
      })}
      height="100vh"
    >
      <AppBar
        className={classNames(classes.appBar, {
          [classes.bgWhite]: white,
          [classes.bgTransparent]: transparent,
        })}
        position="fixed"
      >
        <img
          className={classNames({
            [commonClasses.cursorPointer]: !disableLink,
          })}
          onClick={disableLink ? undefined : goHome}
          src={Logo}
          alt="lacework-iac-security-logo"
          width={170}
        />
      </AppBar>
      <Box
        className={classNames({
          [classes.bgWhite]: white,
        })}
        flex={1}
        mt={6}
        pt={1}
        pb={1}
      >
        {children}
      </Box>
    </Box>
  );
};

export default NavbarTopLayout;
