import { useFlags } from "launchdarkly-react-client-sdk";

import { useDocsRoute, useHasScope, useIsSupportOrg, useUser } from "providers";

import { Route } from "types";
import { Permissions, Resources } from "types/auth-roles";

export const useRoutes = (): Route[] => {
  const docsRoute = useDocsRoute();
  const hasSsoOwnerScope = useHasScope(Resources.SSO, Permissions.Read);
  const isGitLabProvider = useUser().provider?.toLocaleLowerCase() === "gitlab";
  const isSupportOrg = useIsSupportOrg();

  const {
    analytics,
    assessmentsUiVisible,
    ciCdPipelinesEnabled,
    gitReposEnabled,
    policyEnabled,
    resourcesPageVisible,
    servicesEnabled,
    tokensPageVisible,
    usagePageEnabled,
    violationsPageEnabled,
  } = useFlags();

  const routes = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: "dashboard",
    },
    violationsPageEnabled &&
      !isSupportOrg && {
        name: "Violations",
        to: "/violations",
        icon: "violations",
      },
    gitReposEnabled &&
      !isSupportOrg && {
        name: "Repositories",
        to: "/repos",
        icon: "gitRepos",
      },
    ciCdPipelinesEnabled &&
      !isSupportOrg && {
        name: "Pipelines",
        to: "/pipelines",
        icon: "ciPipeline",
      },
    resourcesPageVisible && {
      name: "Resources",
      to: "/resources",
      icon: "services",
    },
    assessmentsUiVisible && {
      name: "Assessments",
      to: "/assessments",
      icon: "assessments",
    },
    policyEnabled &&
      !isSupportOrg && {
        name: "Policies",
        to: "/policies",
        icon: "policies",
      },
    analytics && {
      name: "Analytics",
      to: "/analytics",
      icon: "analytics",
      children: [{ to: "/analytics/reports", name: "Reports" }],
    },
    isGitLabProvider && {
      name: "Integrations",
      to: "/integrations",
      icon: "integrations",
    },
    servicesEnabled &&
      !isSupportOrg && {
        name: "Services",
        to: "/services",
        icon: "services",
      },
    {
      name: "Docs",
      to: `${docsRoute}/category/iac-security`,
      isExternal: true,
      icon: "docs",
    },
    {
      name: "Settings",
      to: "/admin",
      icon: "settings",
      children: [
        hasSsoOwnerScope && { to: "/admin/org", name: "Organization" },
        { to: "/admin/users", name: "Users" },
        tokensPageVisible && { to: "/admin/tokens", name: "Tokens" },
      ].filter((child): child is Route => !!child),
    },
    usagePageEnabled &&
      !isSupportOrg && {
        name: "Usage",
        to: "/usage",
        icon: "usage",
      },
    isSupportOrg && {
      name: "Support",
      to: "/support",
      icon: "support",
      children: [{ to: "/support/organizations", name: "Organizations" }],
    },
  ].filter((route) => !!route);

  return routes as Route[];
};
