import React, {
  forwardRef,
  ReactNode,
  RefForwardingComponent,
  useImperativeHandle,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import ColoredButton, {
  ColoredButtonProps,
} from "components/ColoredButton/ColoredButton";

interface ConfirmationModalValues {
  /**
   * The description to be show inside the modal body.
   */
  description: ReactNode;
  /**
   * The title of the modal. To be show inside the modal header.
   */
  title: string;
  /**
   * The confirmation text to be show inside the confirmation button.
   */
  confirm: string;
  /**
   * A valid Bootstrap button color. Default to danger.
   */
  color?: ColoredButtonProps["color"];
  /**
   * If passed, will render a cancel button with specified text.
   */
  cancel?: string;
  /**
   * The action to be performed. This will just be called.
   */
  action(): void;

  /**
   * A callback function to be performed after the modal is fully closed.
   */
  onClosed?(): void;

  /**
   * Determines the confirm button disabled status.
   */
  disabled?: boolean;
}

export interface ConfirmationModalRef {
  closeModal(): void;
  openModal(values: ConfirmationModalValues): void;
}

const ConfirmationModal: RefForwardingComponent<ConfirmationModalRef> = (
  _p,
  ref,
) => {
  const [values, setValues] = useState<ConfirmationModalValues>();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (v: ConfirmationModalValues) => {
    setValues(v);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const action = () => {
    values?.action();
    closeModal();
  };

  useImperativeHandle(ref, () => ({
    closeModal,
    openModal,
  }));

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={closeModal}
      open={isOpen}
      onExited={values?.onClosed}
    >
      <DialogTitle>{values?.title}</DialogTitle>
      <DialogContent>
        <Box py={2}>{values?.description}</Box>
      </DialogContent>
      <DialogActions>
        {values?.cancel && (
          <Button onClick={closeModal} color="primary">
            {values?.cancel}
          </Button>
        )}
        <ColoredButton
          data-testid="confirmation-button"
          variant="contained"
          color={values?.color ?? "primary"}
          onClick={action}
        >
          {values?.confirm}
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
};
export default forwardRef(ConfirmationModal);
