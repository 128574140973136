import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";

import CodeSnipped from "components/CodeSnipped";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import { useInterval } from "hooks/useInterval";
import { useQueryParams } from "hooks/useQueryParams";
import cliService from "services/cliService";

const CLIOAuth: FC = () => {
  const [seconds, setSeconds] = useState(10);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const {
    port = "",
    code = "",
    baseUrl = "",
  } = useQueryParams<{
    port: number;
    code: string;
    baseUrl: string;
  }>();

  const isHeadlessMode = useMemo(() => port === 0, [port]);
  const baseUrlParameter =
    baseUrl.includes("https://app.soluble.cloud") || baseUrl === ""
      ? ""
      : `--app ${baseUrl}`;

  useEffect(() => {
    if (isHeadlessMode) return;
    const checkStatus = async () => {
      try {
        await cliService.checkAuthStatus(port, code);
      } catch (error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    checkStatus();
  }, [code, port, isHeadlessMode]);

  useInterval(
    () => {
      setSeconds((prev) => prev - 1);
    },
    isLoading || isError ? null : 1000,
  );

  useEffect(() => {
    if (seconds === 0) navigate("/dashboard");
  }, [seconds, navigate]);

  return (
    <NavbarTopLayout white>
      <Grid
        justify="center"
        alignItems="center"
        container
        style={{ height: "100%" }}
      >
        <Grid item xs={11} md={9} lg={5} xl={4}>
          <Card variant="outlined">
            <Box p={6} style={{ minHeight: 300 }}>
              {isHeadlessMode ? (
                <div>
                  <Box component="h3" mb={4}>
                    Enter the authorization code below in the Lacework IaC
                    Security CLI prompt:
                  </Box>
                  <Box mb={6}>
                    <CodeSnipped wrap>{`${code}`}</CodeSnipped>
                  </Box>
                  <Box textAlign="center">
                    <Button
                      component={Link}
                      to="/dashboard"
                      color="primary"
                      variant="outlined"
                    >
                      Back to Dashboard
                    </Button>
                  </Box>
                </div>
              ) : isLoading ? (
                <Box textAlign="center" my={10}>
                  <LinearProgress />
                </Box>
              ) : isError ? (
                <div>
                  <Box component="h3" mb={4}>
                    There was a problem connecting to the Lacework IaC Security
                    CLI.
                  </Box>
                  <p>
                    Please run the following to complete the authentication:
                  </p>
                  <Box mb={6}>
                    <CodeSnipped
                      wrap
                    >{`soluble login --headless ${baseUrlParameter}`}</CodeSnipped>
                  </Box>
                  <Box textAlign="center">
                    <Button
                      component={Link}
                      to="/dashboard"
                      color="primary"
                      variant="outlined"
                    >
                      Back to Dashboard
                    </Button>
                  </Box>
                </div>
              ) : (
                <Fragment>
                  <Box textAlign="center" mb={5}>
                    <Box
                      style={{ opacity: 0.75 }}
                      color="success.main"
                      mb={4}
                      fontSize={60}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Box>
                    <Box component="h4" mb={5}>
                      Soluble CLI is now ready for use.
                    </Box>
                    <Button
                      component={Link}
                      to="/dashboard"
                      color="primary"
                      variant="outlined"
                    >
                      Back to Dashboard
                    </Button>
                  </Box>
                  <Typography variant="body2" align="center" gutterBottom>
                    You will be redirected in {seconds}s ...
                  </Typography>
                </Fragment>
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </NavbarTopLayout>
  );
};

export default CLIOAuth;
