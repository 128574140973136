/* istanbul ignore file */
import React, { FC } from "react";
import { TablePagination, useTheme } from "@material-ui/core";

import TablePaginationActions from "./TablePaginationActions";

export interface TablePaginationProps {
  pageSizeOptions: number[];
  pageSize: number;
  count: number;
  pageIndex: number;
  onChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ): void;
  onChangeRowsPerPage?(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void;
}

const DefaultTablePagination: FC<TablePaginationProps> = ({
  count,
  onChangePage,
  pageIndex,
  pageSize,
  pageSizeOptions,
  onChangeRowsPerPage,
}) => {
  const theme = useTheme();

  return (
    <TablePagination
      style={{ color: theme.palette.text.primary }}
      labelRowsPerPage=""
      rowsPerPageOptions={pageSizeOptions}
      component="div"
      count={count}
      rowsPerPage={pageSize}
      page={pageIndex}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      SelectProps={{
        inputProps: { "aria-label": "rows per page" },
      }}
      ActionsComponent={(props) => <TablePaginationActions {...props} />}
    />
  );
};

export default DefaultTablePagination;
