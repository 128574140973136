import React, { FC, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "providers";

const EmailRedirector: FC = () => {
  const navigate = useNavigate();
  const { emailCaptured, emailVerified, email, tosAccepted } = useUser();
  const isEmailServiceAccount = email?.includes("serviceaccount.soluble.cloud");

  useEffect(
    () => {
      if (!isEmailServiceAccount && tosAccepted) {
        if (!emailCaptured) {
          navigate("/profile/add-email");
        } else if (!emailVerified) {
          navigate("/profile/verify-email");
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tosAccepted],
  );

  return <Fragment />;
};

export default EmailRedirector;
