import React, { FC, useEffect, useState } from "react";
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
      opacity: 0,
      transition: "opacity 0.3s ease-in-out",
    },
    visible: {
      opacity: 1,
    },
    spinner: {
      opacity: 1,
      marginBottom: theme.spacing(2),
    },
  }),
);

export const Loader: FC<{ delay?: number }> = ({ delay = 1000, children }) => {
  const [isHidden, setIsHidden] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setIsHidden(false);
    }, delay);

    return () => {
      clearTimeout(delayTimeout);
    };
  }, [delay]);

  return (
    <div className={classNames(classes.root, { [classes.visible]: !isHidden })}>
      <CircularProgress
        className={classes.spinner}
        size="5rem"
        thickness={2}
        aria-hidden="true"
      />
      {children}
    </div>
  );
};

export default Loader;
