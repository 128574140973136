/* istanbul ignore file */
import React, { FC } from "react";
import {
  Collapse,
  createStyles,
  Fade,
  lighten,
  makeStyles,
  Theme,
  Toolbar,
} from "@material-ui/core";

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingRight: 0,
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      color: theme.palette.secondary.dark,
      backgroundColor: lighten(theme.palette.secondary.main, 0.85),
    },
  }),
);

interface RowSelecitonToolbarProps {
  active: boolean;
  dense: boolean;
  collapse: boolean;
}

const RowSelectionToolbar: FC<RowSelecitonToolbarProps> = ({
  active,
  collapse,
  children,
  dense,
}) => {
  const classes = useToolbarStyles();
  const Transition = collapse ? Collapse : Fade;
  return (
    <Transition
      style={{ paddingLeft: collapse ? 0 : 16 }}
      className={classes.root}
      in={active}
    >
      <Toolbar
        variant={dense ? "dense" : "regular"}
        className={classes.toolbar}
      >
        {children}
      </Toolbar>
    </Transition>
  );
};

export default RowSelectionToolbar;
