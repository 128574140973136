import React, { FC } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { QueryBuilderRounded, SnoozeRounded } from "@material-ui/icons";
import classNames from "classnames";
import { startCase } from "lodash";
import moment from "moment";

import JobStatusIcon from "./JobStatusIcon";
import { useStyles } from "./JobStep";
import GitRepoInformation from "components/GitRepoInformation/GitRepoInformation";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import { GitRepoJobMetadata, GitRepoJobStatus } from "types/repositories";

const useStylesL = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
    },
  }),
);

interface JobDetailHeaderProps {
  metadata?: GitRepoJobMetadata;
}

const JobDetailHeader: FC<JobDetailHeaderProps> = ({ metadata }) => {
  const classes = useStyles();
  const classesL = useStylesL();
  const status =
    (metadata?.status.toLowerCase() as GitRepoJobStatus) ?? "unknown";

  const startTs = moment(metadata?.startTs ?? moment.invalid());
  const endTs = moment(metadata?.endTs ?? moment.invalid());
  const duration =
    status === "running"
      ? moment.duration(moment().diff(startTs))
      : moment.duration(endTs.diff(startTs));
  const durationString =
    startTs.isValid() && (status === "running" || endTs.isValid())
      ? `${
          status === "completed"
            ? "Passed in"
            : status === "failed"
            ? "Failed in"
            : status === "running"
            ? "Running for"
            : ""
        } ${duration.hours() ? duration.hours() + "h " : ""}${
          duration.minutes() ? duration.minutes() + "m " : ""
        }${duration.seconds() + "s"}`
      : undefined;
  return (
    <Box
      className={classNames(classes.root, classesL.root, classes[status])}
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box mr={1}>
          <JobStatusIcon fontSize={38} status={status} />
        </Box>
        <Box>
          <Typography variant="h4">{metadata?.commitMsg}</Typography>
          <Box mt={1}>
            <GitRepoInformation gitRepoName={metadata?.gitRepo} {...metadata} />
            <Box mt={1}>
              {"Created "}
              <RelativeTimeFormatter
                dateTs={metadata?.createTs}
                invalidDateText=""
              />
              {`${metadata?.commitBy ? " by " + metadata.commitBy : ""} ${
                metadata?.trigger
                  ? `| Triggered ${
                      metadata.trigger === "manually" ? "" : "by"
                    } ${startCase(metadata.trigger)}`
                  : ""
              }`}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="center">
          <Box
            component={
              status === "skipped" ? SnoozeRounded : QueryBuilderRounded
            }
            width={16}
            mr={1}
          />
          <Box mr={2}>
            <Typography variant="h6">{durationString}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default JobDetailHeader;
