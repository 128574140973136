import instance from "./axiosInstance";
import { PaginatedResponse } from "types";
import { Violation } from "types/violations";

const getViolations = (orgId: string) =>
  instance
    .get<PaginatedResponse<Violation>>(`/org/${orgId}/violations`)
    .then((res) => res.data.data);

export default {
  getViolations,
};
