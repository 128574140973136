import instance from "./axiosInstance";
import { APIResponse, PaginatedResponse } from "types";
import {
  Assessment,
  AssessmentDetail,
  FindingActivity,
} from "types/assessments";

const getAllAssessments = (orgId: string, q?: string, ciBuildUrl?: string) =>
  instance
    .get<PaginatedResponse<Assessment>>(
      `/org/${orgId}/assessments${q ? `?q=${q}` : ""}${
        ciBuildUrl ? `${q ? "&" : "?"}ciBuildUrl=${ciBuildUrl}` : ""
      }`,
    )
    .then((res) => res.data.data);

const getAssessmentDetail = (orgId: string, assessmentId: string) =>
  instance
    .get<APIResponse<AssessmentDetail>>(
      `/org/${orgId}/assessments/${assessmentId}`,
    )
    .then((res) => res.data.data);

const getAssessmentFindingsActivity = (
  orgId: string,
  assessmentId: string,
  findingId: string,
) =>
  instance
    .get<PaginatedResponse<FindingActivity>>(
      `/org/${orgId}/assessments/${assessmentId}/findings/${findingId}/suppression/history`,
    )
    .then((res) => res.data.data);

const getAssessmentFindingGuidelines = (orgId: string, sid: string) =>
  instance
    .get<{ data: { guidelines: string } }>(
      `/org/${orgId}/assessments/sid/${sid}/guidelines`,
    )
    .then((res) => res.data.data.guidelines);

const getAssessmentConfigYaml = (orgId: string, assessmentId: string) =>
  instance
    .get<APIResponse<{ config: string }>>(
      `/org/${orgId}/config.yml?assessmentId=${assessmentId}`,
    )
    .then((res) => {
      console.log(res.data.data);
      return res.data.data.config;
    });

interface AssessmentFindingRepoPayload {
  gitRepo: string;
  suppressed: boolean;
  comment: string;
}

const setAssessmentFindingRepoLevelSuppression = (
  orgId: string,
  assessmentId: string,
  findingId: string,
  { gitRepo, suppressed, comment }: AssessmentFindingRepoPayload,
) =>
  instance.post(
    `/org/${orgId}/repo/assessments/${assessmentId}/findings/${findingId}/suppress`,
    {
      gitRepo,
      comment,
      suppressed,
    },
  );

interface AssessmentFindingResourcePayload {
  comment: string;
  suppressed: boolean;
}

const setAssessmentFindingResourceLevelSuppression = (
  orgId: string,
  resource: string,
  { suppressed, comment }: AssessmentFindingResourcePayload,
) =>
  instance
    .post(`/org/${orgId}/assessments/resource/${resource}/suppress`, {
      suppressed,
      comment,
    })
    .then((res) => res.data.data);

// FINDINGS AUTOFIX

const getRemediationStatus = (
  orgId: string,
  assessmentId: string,
  findingId: string,
) =>
  instance
    .get<
      APIResponse<{ completed: boolean; prUrl: string; statusCode?: number }>
    >(
      `/org/${orgId}/assessments/${assessmentId}/findings/${findingId}/remediation/status`,
    )
    .then((res) => res.data.data);

const getRemediationFile = (
  orgId: string,
  assessmentId: string,
  findingId: string,
  type: "original" | "remediated",
) =>
  instance
    .get<APIResponse<{ content: string }>>(
      `/org/${orgId}/assessments/${assessmentId}/findings/${findingId}/file/${type}`,
    )
    .then((res) => res.data.data.content);

const postAutoFixInitiation = (
  orgId: string,
  assessmentId: string,
  findingId: string,
) =>
  instance
    .post(
      `/org/${orgId}/assessments/${assessmentId}/findings/${findingId}/remediate`,
    )
    .then((res) => res.data);

const postOpenAutoFixPr = (
  orgId: string,
  assessmentId: string,
  findingId: string,
) =>
  instance
    .post(
      `/org/${orgId}/assessments/${assessmentId}/findings/${findingId}/remediation/create-pull-request`,
    )
    .then((res) => res.data);

const postRegenerateAutoFix = (
  orgId: string,
  assessmentId: string,
  findingId: string,
) =>
  instance
    .post(
      `/org/${orgId}/assessments/${assessmentId}/findings/${findingId}/remediation/regenerate`,
    )
    .then((res) => res.data);

export default {
  getAllAssessments,
  getAssessmentConfigYaml,
  getAssessmentDetail,
  getAssessmentFindingsActivity,
  getAssessmentFindingGuidelines,
  setAssessmentFindingResourceLevelSuppression,
  setAssessmentFindingRepoLevelSuppression,
  getRemediationStatus,
  getRemediationFile,
  postAutoFixInitiation,
  postOpenAutoFixPr,
  postRegenerateAutoFix,
};
