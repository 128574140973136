import React, { FC, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import Badge from "components/BootstrapBadge/BootstrapBadge";
import LogoBadge from "components/LogoBadge";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import servicesService from "services/servicesService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pill: {
      position: "absolute",
      right: 0,
      top: 0,
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
);

const Services: FC = () => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const { data: services = [], isLoading } = useQuery(
    ["services", orgId],
    () => servicesService.getServices(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    },
  );

  return (
    <Fragment>
      <PageHeader>Services</PageHeader>
      {isLoading && (
        <Grid container spacing={3}>
          {[...Array(4)].map((i) => (
            <Grid key={i} item xs={12} md={6} lg={4} xl={3}>
              <Box component={Card} p={2} lineHeight={3.5}>
                <Skeleton width="40%" />
                <Skeleton count={2} />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      <Grid spacing={3} container>
        {services.map((item, index) => {
          if (item.code === "cinderella" && !item.visible) {
            return <Fragment />;
          }
          return (
            <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
              <Box component={Card} height="100%" key={index}>
                <Box p={2} position="relative">
                  <LogoBadge logo={item.code} className={classes.pill} />
                  <Box component="h4" width="75%" mb={2}>
                    {item.title}
                  </Box>

                  <p>{item.description}</p>
                </Box>
                <Box p={2}>
                  {item.enabled ? (
                    <Fragment>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="stretch"
                        mb={1}
                      >
                        <Button
                          component={Link}
                          color="primary"
                          variant="contained"
                          to={`/services/${item.code}`}
                        >
                          Start Now
                        </Button>
                      </Box>
                      <Link to={`/services/details/${item.code}`}>
                        See Details
                      </Link>
                    </Fragment>
                  ) : (
                    <Box position="absolute" right="1.2rem" bottom="1.2rem">
                      <Badge color="soft-info">coming soon</Badge>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
};

export default Services;
