import React, { FC, Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useCurrentOrgId, useHasScope, useLogout, useUser } from "providers";

import { ReactComponent as Add } from "assets/svg/illustrations/add-user.svg";
import ChangeOrgDropdown from "components/AppSidebar/ChangeOrgDropdown/ChangeOrgDropdown";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import integrationService from "services/integrationService";
import integrationsService from "services/integrationsService";
import { Permissions, Resources } from "types/auth-roles";

const ConnectGitLab: FC = () => {
  const orgId = useCurrentOrgId();
  const { provider } = useUser();
  const logout = useLogout();
  const navigate = useNavigate();
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );
  const isGitLabProvider = provider?.toLocaleLowerCase() === "gitlab";
  const [isAddingToken, setIsAddingToken] = useState(false);
  const { control, handleSubmit, errors } = useForm<{ token: string }>({
    defaultValues: { token: "" },
  });

  const { data: completed, isLoading: isStatusLoading } = useQuery(
    ["gitlab-integration-status", orgId],
    () => integrationsService.getGitLabIntegrationStatus(orgId),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      enabled: isGitLabProvider,
    },
  );

  if ((!isGitLabProvider || completed?.completed) && !isStatusLoading)
    return <Navigate to="/dashboard" replace />;

  const onSubmit = async ({ token }: { token: string }) => {
    try {
      setIsAddingToken(true);
      await integrationService.addGitLabAccessToken(orgId, token);
      navigate("/success/gitlab-app-install", { replace: true });
    } catch (error) {
      errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      setIsAddingToken(false);
    }
  };

  return (
    <NavbarTopLayout disableLink>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box maxWidth={600}>
          <Box component={Card} p={2}>
            <Box my={2}>
              <PageHeader>
                <Box display="flex" justifyContent="space-between">
                  Connect GitLab
                  <ChangeOrgDropdown />
                </Box>
              </PageHeader>
            </Box>
            <Box my={1}>
              <Typography>
                GitLab integration enables Lacework IaC Security to scan
                Infrastructure-as-code projects.
              </Typography>
              <Typography gutterBottom>
                Lacework IaC Security finds security issues or
                mis-configurations in Infrastructure-as-Code (IaC) written in
                AWS CloudFormation, HashiCorp Terraform and Kubernetes
                Manifests. It creates a personal access token that will be used
                to do the scans, gitlab merge request comments etc. Refer to{" "}
                <a href="https://docs.gitlab.com/ee/security/token_overview.html#oauth2-tokens">
                  OAuth2 tokens
                </a>{" "}
                for more information.
              </Typography>
              <Box textAlign="center">
                <Box my={1} component={Add} width={270} height="auto" />
              </Box>
            </Box>
            {hasOrgWriteScope ? (
              <Fragment>
                <Box textAlign="center" mb={2} ml={1}>
                  <Button
                    color="primary"
                    variant="outlined"
                    component="a"
                    href={`/api/v1/org/${orgId}/integration/gitlab`}
                    disabled={isAddingToken}
                  >
                    Connect GitLab
                  </Button>
                </Box>
                <Box position="relative">
                  <Box
                    position="absolute"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    top={-12}
                  >
                    <Box bgcolor="white" px={2}>
                      <Typography variant="subtitle2" color="textSecondary">
                        OR
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="hr" my={5} />
                </Box>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  my={2}
                  px={1}
                >
                  <Box mb={2}>
                    <Typography>
                      Create and provide your GitLab Bot / Impersonation Token .
                      You will need to select the scopes:{" "}
                      <code>read_repository</code> and <code>api</code>. This
                      allows Lacework IaC Security to scan the projects, send
                      merge request, comments on merge request and find the
                      security issues or mis-configurations in
                      Infrastructure-as-Code (IaC). Refer to{" "}
                      <a href="https://docs.gitlab.com/ee/security/token_overview.html#impersonation-tokens">
                        Impersonation tokens
                      </a>{" "}
                      for more information.
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Controller
                      as={TextField}
                      control={control}
                      rules={{ required: true }}
                      error={!!errors.token}
                      helperText={!!errors.token && "Please enter a token."}
                      name="token"
                      variant="outlined"
                      type="password"
                      disabled={isAddingToken}
                      color="primary"
                      autoComplete="off"
                      label="Bot / Impersonation Token"
                      placeholder="e.g. kKptReijz8Ke..."
                      fullWidth
                    />
                  </Box>
                  <Box pt={2} textAlign="center">
                    <Button
                      type="submit"
                      variant="outlined"
                      color="primary"
                      disabled={isAddingToken}
                    >
                      {isAddingToken ? (
                        <Fragment>
                          &nbsp; &nbsp;
                          <CircularProgress size={24} />
                          &nbsp; &nbsp;
                        </Fragment>
                      ) : (
                        "Connect GitLab"
                      )}
                    </Button>
                  </Box>
                </Box>
              </Fragment>
            ) : (
              <Box mb={3}>
                <Alert severity="warning">
                  <AlertTitle>Integration not completed</AlertTitle>
                  GitLab integration with your Lacework IaC Organization is not
                  completed. You do not have the correct permissions to complete
                  this integration, please contact your Lacework IaC
                  Organization owner regarding this integration.
                </Alert>
              </Box>
            )}
            <Button color="primary" onClick={logout}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </NavbarTopLayout>
  );
};

export default ConnectGitLab;
