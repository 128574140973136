import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";

import {
  DashboardElementData,
  DashboardElementMetadata,
} from "types/dashboard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    absoluteCenter: {
      position: "absolute",
      top: "65%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("md")]: {
        top: "70%",
      },
      [theme.breakpoints.down("sm")]: {
        position: "relative",
      },
    },
  }),
);
interface NumericalElementProps {
  metadata: DashboardElementMetadata;
  element?: DashboardElementData;
  isLoading: boolean;
}

const NumericalElement: FC<NumericalElementProps> = ({
  metadata,
  element,
  isLoading,
}) => {
  const { id, link, hyperlink } = metadata;
  const isHashLink = link !== "";
  const hasNoLink =
    link === "" &&
    hyperlink === "" &&
    (element?.dynamicUrl ? element?.dynamicUrl === "" : true);
  const classes = useStyles();

  if (hasNoLink)
    return (
      <Box
        p={2}
        flex={1}
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          className={classes.absoluteCenter}
          variant="h1"
          align="center"
          color="primary"
        >
          {isLoading ? <Skeleton width={40} /> : element?.total ?? "-"}
        </Typography>
      </Box>
    );

  if (isHashLink)
    return (
      <Box
        flex={1}
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <a
          id={id}
          href={`#${link}`}
          className={classNames(
            { "pointer-auto": !metadata.link && !metadata.hyperlink },
            "text-decoration-none text-color-inherit",
          )}
        >
          <Box p={2}>
            <Typography
              className={classes.absoluteCenter}
              variant="h1"
              align="center"
              color="primary"
            >
              {isLoading ? <Skeleton width={40} /> : element?.total ?? "-"}
            </Typography>
          </Box>
        </a>
      </Box>
    );

  return (
    <Box
      flex={1}
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Link
        id={id}
        to={element?.dynamicUrl ?? hyperlink}
        className={classNames(
          {
            "pointer-auto":
              !metadata.link && !element?.dynamicUrl && !metadata.hyperlink,
          },
          "text-decoration-none text-color-inherit",
        )}
      >
        <Box p={2} pt={1}>
          <Typography
            className={classes.absoluteCenter}
            variant="h1"
            align="center"
            color="primary"
          >
            {isLoading ? <Skeleton width={40} /> : element?.total ?? "-"}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default NumericalElement;
