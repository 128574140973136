import React, { FC, Fragment, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import {
  Box,
  Button,
  Card,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { InfoOutlined, LabelRounded } from "@material-ui/icons";
import config from "config";

import { useCurrentOrgId, useDocsRoute } from "providers";

import CodeSnipped from "components/CodeSnipped";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import repositoryService from "services/repositoryService";

interface GitHubBadgesModalProps {
  repo: string;
  dateTs?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    svgBadge: {
      marginBottom: 12,
      marginRight: 12,
      borderRadius: 3,
      background: "lightgray",
      minWidth: 140,
      minHeight: 20,
      "&:hover": {
        cursor: "pointer",
      },
    },
  }),
);

const GitHubBadgesModal: FC<GitHubBadgesModalProps> = ({ repo, dateTs }) => {
  const orgId = useCurrentOrgId();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const docsRoute = useDocsRoute();
  const [selectedBadge, setSelectedBadge] = useState<string>();

  const repoTitle = repo.split("/").pop()?.toUpperCase();

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const { data: badges, isLoading } = useQuery(
    ["gitRepoConfigYaml", orgId, repo],
    () => repositoryService.getRepoBadges(orgId, repo),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: open,
    },
  );

  return (
    <Fragment>
      <Tooltip arrow title="Badges">
        <IconButton onClick={handleClickOpen} color="primary">
          <LabelRounded />
        </IconButton>
      </Tooltip>
      <Dialog
        onExited={() => setSelectedBadge(undefined)}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClickClose}
        aria-labelledby="form-dialog-title"
      >
        <Box p={3} pb={0} display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            {repoTitle} Badges
          </Typography>
        </Box>
        <DialogContent>
          {isLoading ? (
            <Card variant="outlined">
              <Box p={2}>
                <Skeleton count={7} />
              </Box>
            </Card>
          ) : Object.keys(badges ?? {}).length === 0 ? (
            <Box bgcolor="lightBackground.dark" py={4} px={2} borderRadius={6}>
              <Box mb={2}>
                Badges are available when you run assessments for the
                repository.
              </Box>
              <a
                href={`${docsRoute}/getting-started-with-iac-security`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
              </a>
            </Box>
          ) : (
            <Box>
              <Typography variant="body2" display="block">
                Git repository badge provides the metrics of all the Lacework
                IaC Security Assessments enabled on this repo. Select the badge
                and copy the markdown to your repository README file. The badge
                will be automatically updated with the latest metrics.
              </Typography>
              <Box
                mt={2}
                display="flex"
                justifyContent="flex-start"
                flexWrap="wrap"
              >
                {Object.keys(badges ?? {}).map((bSvg) => (
                  <img
                    className={classes.svgBadge}
                    key={bSvg}
                    onClick={() =>
                      setSelectedBadge(badges?.[bSvg].markdown ?? "")
                    }
                    alt={`badge-${bSvg}`}
                    src={`${config.API}/public/badges/${bSvg}`}
                  />
                ))}
              </Box>
              {selectedBadge ? (
                <Box height="100%">
                  <CodeSnipped wrap>{selectedBadge}</CodeSnipped>
                </Box>
              ) : (
                <Box bgcolor="lightBackground.dark" py={4} px={2}>
                  Select a badge.
                </Box>
              )}
              <Box
                style={{ opacity: 0.7 }}
                display="flex"
                alignItems="flex-end"
                mt={2}
              >
                <Box mr={1}>
                  <InfoOutlined fontSize="small" />
                </Box>{" "}
                Generated from master branch{" "}
                <RelativeTimeFormatter dateTs={dateTs} />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Box mr={2} display="inline-block">
              <Button onClick={handleClickClose} color="primary">
                Close
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default GitHubBadgesModal;
