/* istanbul ignore file */
import React, { PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import { Column } from "react-table";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skeleton: {
      "& > span": {
        flex: 1,
        "& > .react-loading-skeleton": {
          maxWidth: "60%",
        },
      },
    },
  }),
);

interface DataTableSkeletonProps<T extends Record<string, any>> {
  columns: Column<T>[];
  search?: boolean;
  compact?: boolean;
}

const DataTableSkeleton = <T extends Record<string, any>>({
  columns,
  search = false,
  compact = false,
}: PropsWithChildren<DataTableSkeletonProps<T>>) => {
  const classes = useStyles();
  return (
    <Box p={1} data-testid="data-table-skeleton">
      {search && (
        <Box height={70} display="flex" alignItems="center">
          <Skeleton width={176} height={20} />
        </Box>
      )}
      <Box
        className={classes.skeleton}
        display="flex"
        justifyContent="space-between"
        mb={compact ? 1 : 2}
      >
        {columns.map((_, index) => (
          <Skeleton height={15} key={index} />
        ))}
      </Box>
      <Box lineHeight={compact ? 2 : 3} mb={2}>
        <Skeleton height={15} count={10} />
      </Box>
    </Box>
  );
};

export default DataTableSkeleton;
