import {
  PaletteOptions,
  SimplePaletteColorOptions,
} from "@material-ui/core/styles/createPalette";

interface DefaultPaletteOptions extends PaletteOptions {
  primary: SimplePaletteColorOptions;
  secondary: SimplePaletteColorOptions;
  error: SimplePaletteColorOptions;
  severities: {
    critical: string;
    high: string;
    medium: string;
    low: string;
    info: string;
  };
}

const palette: DefaultPaletteOptions = {
  primary: { main: "#03409C" },
  secondary: { main: "#3B9999" },
  background: { default: "#ebf0f5" },
  darkBackground: { main: "#01023B" },
  lightBackground: { main: "#f9fafd", dark: "#f6f8fa" },
  success: {
    main: "#19A442",
  },
  error: {
    main: "#FF4D4F",
  },
  severities: {
    critical: "#8c101c",
    high: "#e71d32",
    medium: "#ff7832",
    low: "#fdd600",
    info: "#5aaafa",
  },
};

export default palette;
