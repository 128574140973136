import React, { FC } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import CanActivateRoute from "./CanActivateRoute";

interface HasFlagRouteProps {
  flag: string;
  element: React.ReactNode;
}

const HasFlagRoute: FC<HasFlagRouteProps> = ({ flag, element }) => {
  const { [flag]: hasFlag } = useFlags();

  return <CanActivateRoute canActivate={hasFlag} element={element} />;
};

export default HasFlagRoute;
