import React, { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visible: {
      display: "block",
      height: "100%",
    },
    invisible: {
      height: "100%",
      display: "none",
    },
  }),
);

interface MountedTabPanelProps {
  value: string | number;
  index: string | number;
}

const MountedTabPanel: FC<MountedTabPanelProps> = ({
  value,
  index,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={index === value ? classes.visible : classes.invisible}>
      {children}
    </div>
  );
};

export default MountedTabPanel;
