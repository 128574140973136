import React, { FC, Fragment } from "react";
import moment from "moment";

import { getLocaleDateString } from "helpers/formatter";

interface RelativeTimeFormatterProps {
  dateTs: string | undefined;
  invalidDateText?: string;
}

const UNIX_EPOCH = "1970-01-01T00:00:00Z";
const LOCALE_DATE_FORMAT = getLocaleDateString().toUpperCase();

const RelativeTimeFormatter: FC<RelativeTimeFormatterProps> = ({
  dateTs,
  invalidDateText = "Never",
}) => {
  const date = moment(dateTs, moment.ISO_8601);
  const isEpochDate = moment(UNIX_EPOCH, moment.ISO_8601).isSame(date);
  const formattedDate = date.calendar(Date.now(), {
    sameDay: "[Today] hh:mm A",
    nextDay: "[Tomorrow] hh:mm A",
    nextWeek: "dddd hh:mm A",
    lastDay: "[Yesterday] hh:mm A",
    lastWeek: "[Last] dddd hh:mm A",
    sameElse: `${LOCALE_DATE_FORMAT} hh:mm A`,
  });
  return (
    <Fragment>
      {dateTs && !isEpochDate ? formattedDate : invalidDateText}
    </Fragment>
  );
};

export default RelativeTimeFormatter;
