import React, { FC, Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { isUndefined } from "lodash";

import ColoredButton from "components/ColoredButton/ColoredButton";
import { getGitPlatformIcon } from "helpers/iconHelper";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
  optimisticDatasetUpdater,
} from "helpers/queryHelpers";
import organizationService from "services/organizationService";
import { OrganizationDescription } from "types";

interface OrganizationToggleModalProps {
  organization?: OrganizationDescription;
  onClose(): void;
}

interface OrganizationToggleModalForm {
  comment: string;
}

const OrganizationToggleModal: FC<OrganizationToggleModalProps> = ({
  organization,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, errors } =
    useForm<OrganizationToggleModalForm>({
      defaultValues: { comment: "" },
      mode: "onSubmit",
    });
  const isOrgActive = isUndefined(organization?.active)
    ? true
    : Boolean(organization?.active);
  const actionText = isOrgActive ? "Deactivate" : "Activate";

  const { mutate: togglePolicyStatus } = useMutation(
    ({
      organization: p,
      comment,
    }: {
      organization: OrganizationDescription;
      comment: string;
    }) =>
      organizationService.configOrg(organization?.orgId ?? "", {
        active: !isOrgActive,
        comment,
      }),
    {
      onMutate: ({ organization: { active, orgId } }) => {
        return optimisticDatasetUpdater<OrganizationDescription, "orgId">(
          "organizations",
          { orgId },
          { active: !active },
        );
      },
      onError: (error, _p, rollback: any) => {
        rollback?.();
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      },
      onSuccess: ({ data }) => {
        const orgResponse = data as unknown as OrganizationDescription;
        toast.success(
          `${orgResponse.active ? "Activated" : "Deactivated"} org '${
            organization?.displayName ??
            organization?.name ??
            organization?.gitOrgName ??
            organization?.orgId
          }' successfully.`,
        );
      },
    },
  );

  const onSubmit = ({ comment }: OrganizationToggleModalForm) => {
    if (organization) togglePolicyStatus({ organization, comment });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (organization) setOpen(true);
  }, [organization]);

  return (
    <Fragment>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        onExited={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Box p={3} pb={0} display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            {actionText} Organization
          </Typography>
        </Box>
        <DialogContent>
          <Box
            p={2}
            mb={2}
            bgcolor="lightBackground.main"
            borderRadius={4}
            display="flex"
            alignItems="center"
          >
            {organization?.provider && (
              <Box mr={2}>
                <FontAwesomeIcon
                  fontSize={28}
                  icon={getGitPlatformIcon(organization?.provider)}
                />
              </Box>
            )}
            <Box>
              <Typography>
                {organization?.displayName ??
                  organization?.name ??
                  organization?.gitOrgName}
              </Typography>
              <Typography variant="h6">{organization?.orgId}</Typography>
            </Box>
          </Box>
          <form id="organizationtoggle" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter a comment.",
                },
                minLength: {
                  value: 12,
                  message: "Comments needs to be at least 12 characters long.",
                },
              }}
              as={TextField}
              label="Comment *"
              name="comment"
              error={!!errors.comment}
              helperText={
                errors.comment?.message || "Enter at least 12 characters."
              }
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </form>
          <Box my={2}>
            <Typography variant="h6">
              Are you sure you want to {isOrgActive ? "deactivate" : "activate"}{" "}
              this organization?
            </Typography>
          </Box>
          <Typography variant="body2">
            {isOrgActive
              ? "Deactivating organizations will stop running assessments for their repositories and will not post feedback on Pull Requests."
              : "Activating organizations will remove the inactive status and re-enable access to Lacework IaC functionalities."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Box mr={2} display="inline-block">
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </Box>
            <ColoredButton
              color="error"
              form="organizationtoggle"
              variant="contained"
              type="submit"
            >
              {actionText}
            </ColoredButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default OrganizationToggleModal;
