import { FC, Fragment, useCallback, useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  Box,
  Card,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { StarBorderRounded, StarRounded } from "@material-ui/icons";

import { useCurrentOrgId } from "providers";

import PipelineCard from "./components/PipelineCard";
import DefaultTablePagination from "components/DataTable/components/DefaultTablePagination";
import SearchBar from "components/DataTable/components/SearchBar";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import PageHeader from "components/PageHeader";
import SvgIcon from "components/SvgIcon/SvgIcon";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import { sortBool } from "helpers/tableHelpers";
import { useQueryState } from "hooks/useQueryState";
import pipelinesService from "services/pipelinesService";
import { Pipeline } from "types/pipelines";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationRoot: {
      display: "flex",
      justifyContent: "center",
    },
    paginationItem: {
      "& > li > button": { backgroundColor: "white", border: 0 },
    },
    search: {
      marginLeft: 0,
    },
    empty: {
      width: 450,
    },
    formControl: {
      minWidth: 120,
    },
    toggleSort: {
      borderRight: 0,
      height: 40,
      borderRadius: 0,
      minWidth: 0,
      width: 40,
    },
  }),
);

const columns: Column<Pipeline>[] = [
  {
    Header: "Repo",
    accessor: "ciPipelineName",
    Cell: ({ row: { original: row } }) => (
      <a href={row.ciPipelineUrl} rel="noreferrer noopener" target="_blank">
        {row.ciPipelineName}
      </a>
    ),
  },
  {
    Header: "Platform",
    accessor: "ciPlatform",
  },
  {
    Header: "Repo",
    accessor: "gitRepo",
  },
  {
    Header: "Branch",
    accessor: "gitBranch",
  },
  {
    Header: "Commit",
    accessor: "gitCommit",
  },
  {
    Header: "Ts",
    accessor: "lastReportedTs",
  },
  {
    Header: "Favorite",
    accessor: "isFavorite",
    sortInverted: true,
    sortType: sortBool,
  },
];

export interface PipelinesQueryState {
  page: number;
  query: string;
  desc?: boolean;
  enabled?: boolean;
  isFavorite?: boolean;
}

const CiCdPipelines: FC = () => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();

  const {
    state: { page = 1, query = "" },
    setQueryState,
  } = useQueryState<PipelinesQueryState>("pipelines");

  const { data = [], isLoading } = useQuery(
    ["pipelines", orgId],
    () => pipelinesService.getPipelines(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    },
  );

  const sortByMEMO = useMemo(
    () => [{ id: "isFavorite" }, { id: "lastReportedTs", desc: true }],
    [],
  );

  const tableInstance = useTable(
    {
      data,
      columns,
      initialState: {
        pageSize: 10,
        globalFilter: query,
        pageIndex: page - 1,
        sortBy: sortByMEMO,
      },
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const tableRef = useRef<DataTableRef<Pipeline>>({
    tableInstance,
  });

  const {
    page: pipelines,
    filteredRows,
    setGlobalFilter,
    gotoPage,
    state,
  } = tableInstance;

  const handleSearchChange = useCallback(
    (filterValue: string) => {
      setGlobalFilter(filterValue);
      setQueryState({ query: filterValue, page: undefined });
      gotoPage(0);
    },
    [setGlobalFilter, gotoPage, setQueryState],
  );

  const handlePageChange = (_: unknown, newPage: number) => {
    gotoPage(newPage);
    setQueryState({ page: newPage === 0 ? undefined : newPage + 1 });
  };

  return (
    <Fragment>
      <PageHeader>Pipelines</PageHeader>
      <Box
        display="flex"
        mb={1.5}
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box display="flex" alignItems="center">
            <SearchBar
              className={classes.search}
              disabled={isLoading}
              onChange={handleSearchChange}
              initialValue={query}
              color="primary"
            />
          </Box>
          <Box display="flex" alignItems="center">
            {!isLoading && (
              <FiltersModal
                twoWay
                tableRef={tableRef}
                fields={[
                  {
                    displayName: "Favorite",
                    key: "isFavorite",
                    type: "select",
                    displayEmptyOption: true,
                    renderValue: (val) =>
                      val ? (
                        <Fragment>
                          <Box component={StarRounded} color="gold" mr={2} />
                          Favorite
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Box
                            component={StarBorderRounded}
                            color="gold"
                            mr={2}
                          />
                          Not Favorite
                        </Fragment>
                      ),
                    values: [
                      {
                        displayName: "Favorite",
                        value: true,
                      },
                      {
                        displayName: "Non-favorite",
                        value: false,
                      },
                    ],
                  },
                  {
                    displayName: "Platform",
                    type: "textfield",
                    key: "ciPlatform",
                  },
                  {
                    displayName: "Repository",
                    type: "textfield",
                    key: "gitRepo",
                  },
                  {
                    displayName: "Branch",
                    type: "textfield",
                    key: "gitBranch",
                  },
                  {
                    displayName: "Commit",
                    type: "textfield",
                    key: "gitCommit",
                  },
                ]}
                title="Filter Pipelines"
                persistInUrl
              />
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <DefaultTablePagination
            pageIndex={state.pageIndex}
            count={filteredRows.length}
            onChangePage={handlePageChange}
            pageSize={10}
            pageSizeOptions={[]}
          />
        </Box>
      </Box>
      {isLoading ? (
        [...Array(10)].map((_e, i) => (
          <Grid key={i} item xs={12}>
            <Box component={Card} mb={2} p={3}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={7} md={6} lg={5}>
                  <Skeleton width="65%" />
                  <Skeleton width="80%" />
                </Grid>
                <Grid item xs={12} sm={3} md={4} lg={5}>
                  <Skeleton width="80%" />
                </Grid>
                <Grid item xs={12} sm={2} lg={2}>
                  <Skeleton width="40%" />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))
      ) : pipelines.length === 0 ? (
        <Box width="100%" textAlign="center">
          <SvgIcon width={80} height={80} color="lightgray" icon="ciPipeline" />
          <Typography align="center">
            There are no runs that match the current query.
          </Typography>
        </Box>
      ) : (
        pipelines.map((pipeline) => (
          <Box mb={2} key={pipeline.id}>
            <PipelineCard pipeline={pipeline.original} />
          </Box>
        ))
      )}
      <DefaultTablePagination
        pageIndex={state.pageIndex}
        count={filteredRows.length}
        onChangePage={handlePageChange}
        pageSize={10}
        pageSizeOptions={[]}
      />
    </Fragment>
  );
};

export default CiCdPipelines;
