import React, { FC, Fragment, useCallback, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Column, useGlobalFilter, usePagination, useTable } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@material-ui/core";
import { groupBy } from "lodash";
import moment from "moment";

import { useDocsRoute } from "providers";

import RestartZodiacButton from "./RestartZodiacButton";
import ZodiacInstance from "./ZodiacInstance";
import { facZodiac } from "assets/svg/icons/custom-fa-icons";
import DefaultTablePagination from "components/DataTable/components/DefaultTablePagination";
import SearchBar from "components/DataTable/components/SearchBar";
import { getLocaleDateString } from "helpers/formatter";
import { ZodiacInstance as ZodiacInstanceE } from "types/zodiac";

interface ZodiacInstancesProps {
  instances: ZodiacInstanceE[];
  isLoading: boolean;
}

const ZodiacInstances: FC<ZodiacInstancesProps> = ({
  instances,
  isLoading,
}) => {
  const docsRoute = useDocsRoute();
  const columns = useMemo<Column<ZodiacInstanceE>[]>(
    () => [
      {
        Header: "Build Version",
        accessor: "buildVersion",
      },
      {
        Header: "Last seen",
        accessor: "lastSeenTs",
      },
      {
        Header: "Host",
        accessor: "host",
      },
      {
        Header: "RemoteAddr",
        accessor: "remoteAddr",
      },
      {
        Header: "instanceId",
        accessor: "instanceId",
      },
      {
        Header: "clusterId",
        accessor: "clusterId",
      },
    ],
    [],
  );

  const {
    page: paginatedInstances,
    state,
    rows,
    gotoPage,
    setPageSize,
    setGlobalFilter,
  } = useTable(
    {
      data: instances,
      columns,
      autoResetPage: false,
    },
    useGlobalFilter,
    usePagination,
  );

  const handlePageChange = useCallback(
    (_, pageIndex: number) => gotoPage(pageIndex),
    [gotoPage],
  );

  const handleRowsPerPageChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setPageSize(Number(value));
      gotoPage(0);
    },
    [gotoPage, setPageSize],
  );

  const handleSearchChange = useCallback(
    (filterValue: string) => {
      setGlobalFilter(filterValue);
      gotoPage(0);
    },
    [setGlobalFilter, gotoPage],
  );

  const groupedPaginatedInstances = useMemo(() => {
    const zInstances = paginatedInstances.map((d) => d.original);
    const groupedResults = groupBy(zInstances, (result) => {
      const date = moment(result.createTs, moment.ISO_8601);
      const formattedDate = date.calendar(Date.now(), {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse: getLocaleDateString(),
      });
      return formattedDate;
    });
    return groupedResults;
  }, [paginatedInstances]);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {instances.length === 0 ? (
          <Button
            component={Link}
            to="/add-zodiac"
            color="primary"
            variant="outlined"
          >
            Add Zodiac
          </Button>
        ) : (
          <RestartZodiacButton />
        )}
        <SearchBar
          onChange={handleSearchChange}
          disabled={isLoading}
          initialValue=""
        />
      </Box>
      {isLoading ? (
        [...Array(10)].map((_, ind) => (
          <Box display="flex" flexDirection="column" key={ind} lineHeight={2.5}>
            <Skeleton width={"60%"} />
            <Skeleton width={"40%"} />
          </Box>
        ))
      ) : paginatedInstances.length === 0 && state.globalFilter ? (
        <Box
          py={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box fontSize={60} color="lightgray">
            <FontAwesomeIcon icon={facZodiac} />
          </Box>
          <Typography align="center" variant="body1" gutterBottom>
            There are no instances available that match the current query.
          </Typography>
        </Box>
      ) : instances.length === 0 ? (
        <Box
          py={4}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box fontSize={60} color="lightgray">
            <FontAwesomeIcon icon={facZodiac} />
          </Box>
          <Typography align="center" variant="body1" gutterBottom>
            There are no instances available.
          </Typography>
          <a
            href={`${docsRoute}/getting-started-with-iac-security`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn how to enable security assessments for all your IaC code
            changes.
          </a>
        </Box>
      ) : (
        Object.keys(groupedPaginatedInstances).map((day, i) => (
          <Fragment key={i}>
            <Box mb={2.5}>
              <Typography variant="h4">{day}</Typography>
            </Box>
            {groupedPaginatedInstances[day].map((ins, y) => (
              <Box mb={i + 1 === paginatedInstances.length ? 0 : 3} key={y}>
                <ZodiacInstance instance={ins} />
              </Box>
            ))}
          </Fragment>
        ))
      )}
      <DefaultTablePagination
        pageIndex={state.pageIndex}
        count={rows.length}
        pageSize={state.pageSize}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    </Box>
  );
};

export default ZodiacInstances;
