import React, {
  FC,
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  Box,
  Card,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { groupBy } from "lodash";
import moment from "moment";

import { useCurrentOrgId } from "providers";

import ViolationRow from "./components/ViolationRow";
import Badge from "components/BootstrapBadge/BootstrapBadge";
import DefaultTablePagination from "components/DataTable/components/DefaultTablePagination";
import SearchBar from "components/DataTable/components/SearchBar";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import PageHeader from "components/PageHeader";
import SeverityComponent, {
  Severity,
} from "components/SeverityComponent/SeverityComponent";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { objectArrayToCSV } from "helpers/formatter";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import { sortSeverity } from "helpers/tableHelpers";
import { useQueryState } from "hooks/useQueryState";
import violationsService from "services/violationsService";
import { Violation } from "types/violations";

interface GroupedViolations {
  gitRepo: string;
  sid: string;
  status: string;
  violations: Violation[];
  title: string;
  severity: keyof typeof Severity;
  count: number;
  criticalCount: number;
  compliance: string[];
}

const columns: Column<GroupedViolations>[] = [
  {
    Header: "Type",
    accessor: "sid",
  },
  {
    Header: "Subject",
    accessor: "title",
  },
  {
    Header: "Subject",
    accessor: "criticalCount",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Compliance",
    accessor: "compliance",
    filter: "includesSome",
  },
  {
    Header: "Severity",
    accessor: "severity",
    filter: "includesSome",
    sortType: sortSeverity,
  } as Column<GroupedViolations>,
];

const defaultQuery: Violation[] = [];

interface QueryState {
  page: number;
  pageSize: number;
  query: string;
  status?: string;
  gitRepo?: string;
}

const Violations: FC = () => {
  const orgId = useCurrentOrgId();

  const {
    state: { page = 1, pageSize = 20, query = "", status, gitRepo },
    setQueryState,
  } = useQueryState<QueryState>("violations");

  const [csv, setCSV] = useState<{ data: string; date: string }>({
    data: "",
    date: "",
  });

  const { data = defaultQuery, isLoading } = useQuery(
    ["violations", orgId],
    () => violationsService.getViolations(orgId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    },
  );

  const groupedViolations: GroupedViolations[] = useMemo(() => {
    const sidDictionary = groupBy(data, (v) => v.sid);
    const keys = Object.keys(sidDictionary);

    const groups = Object.values(sidDictionary).map((f, i) => {
      let filteredViolations = f.filter((v) =>
        status === "Open"
          ? v.status === "Open"
          : status === "Suppressed"
          ? v.status === "Suppressed"
          : v,
      );
      if (gitRepo) {
        filteredViolations = filteredViolations.filter((v) =>
          v.gitRepo.toLowerCase()?.includes(gitRepo?.toLocaleLowerCase() + ""),
        );
      }

      if (filteredViolations.length === 0) return null;

      return {
        sid: keys[i],
        violations: filteredViolations,
        title: sidDictionary[keys[i]][0].title,
        count: filteredViolations.length,
        criticalCount: sidDictionary[keys[i]].filter(
          (v) => v.severity.toLowerCase() === "critical",
        ).length,
        severity: sidDictionary[keys[i]][0].severity,
        compliance: sidDictionary[keys[i]][0].compliance?.length
          ? sidDictionary[keys[i]][0].compliance
          : ["No Compliance"],
      };
    });

    return groups.filter((f): f is GroupedViolations => !!f);
  }, [data, status, gitRepo]);

  const tableInstance = useTable(
    {
      data: groupedViolations,
      columns,
      autoResetPage: false,
      autoResetFilters: false,
      initialState: {
        pageIndex: page - 1,
        pageSize,
        sortBy: [
          {
            id: "criticalCount",
            desc: true,
          },
          {
            id: "severity",
            desc: true,
          },
        ],
        globalFilter: query,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const tableRef = useRef<DataTableRef<GroupedViolations>>({
    tableInstance,
  });

  const {
    rows,
    page: violations,
    state,
    setGlobalFilter,
    setPageSize,
    gotoPage,
  } = tableInstance;

  const handleSearchChange = useCallback(
    (filterValue: string) => {
      setGlobalFilter(filterValue);
      setQueryState({ query: filterValue, page: undefined });
      gotoPage(0);
    },
    [setGlobalFilter, setQueryState, gotoPage],
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    gotoPage(newPage);
    setQueryState({ page: newPage === 0 ? undefined : newPage + 1 });
  };

  const createCsvData = useCallback(() => {
    if (!rows) return;

    const violationRows = rows.map((d) => d.original.violations).flat();

    const csvData = objectArrayToCSV(
      violationRows.map(
        ({
          id,
          title,
          severity,
          status,
          resource,
          fileName,
          filePath,
          gitRepo,
          gitBranch,
          gitCommit,
          line,
          lineRangeEnd,
          assessmentId,
          ...others
        }) => ({
          id,
          title,
          severity,
          status,
          resource,
          fileName,
          filePath,
          gitRepo,
          gitBranch,
          gitCommit,
          line,
          lineRangeEnd,
          assessmentId,
          ...others,
        }),
      ),
    );

    const csvDate = moment(new Date(Date.now())).format("YYYYMMDDHHMMSS");
    setCSV({ data: csvData, date: csvDate });
  }, [rows]);

  const violationsCount = useMemo(() => {
    const rowsCount = rows.map((g) => g.original.count);
    return rowsCount.length === 0 ? 0 : rowsCount.reduce((a, c) => a + c);
  }, [rows]);

  const compliances = useMemo(
    () => [
      ...new Set(
        groupedViolations
          .map((g) => g.compliance)
          .filter((c) => c)
          .flat()
          .sort((a, b) => (a < b ? -1 : 1))
          .filter((c) => c !== "No Compliance"),
      ),
    ],
    [groupedViolations],
  );

  return (
    <Fragment>
      <PageHeader>
        <Box>Violations</Box>
        <Typography color="textSecondary" variant="caption">
          Violations are from the default branch of each repository.
        </Typography>
      </PageHeader>
      <Card>
        <Box p={2}>
          <Box pl={1} display="flex" alignItems="center" mb={1}>
            <Box
              bgcolor={isLoading ? "lightgray" : "error.light"}
              color="white"
              borderRadius={3}
              py={0.25}
              px={0.8}
              fontSize={13}
              mr={1}
            >
              {violationsCount}
            </Box>
            <Typography variant="subtitle1">Violations</Typography>
          </Box>
          <Box display="flex" flexWrap="wrap" mb={1}>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <SearchBar
                  disabled={isLoading}
                  initialValue={query}
                  onChange={handleSearchChange}
                />
              </Box>
              <Tooltip title="Download as CSV" arrow>
                <IconButton
                  disabled={isLoading || data.length === 0}
                  component="a"
                  onClick={createCsvData}
                  download={`violations-${csv.date}.csv`}
                  href={`data:text/csv;charset=utf-8,${csv.data}`}
                  color="primary"
                >
                  <GetApp />
                </IconButton>
              </Tooltip>
              {!isLoading && data.length !== 0 && (
                <FiltersModal
                  tableRef={tableRef}
                  fields={[
                    {
                      displayName: "Severity",
                      type: "multiselect",
                      key: "severity",
                      renderValue: (val) => (
                        <SeverityComponent
                          severity={Severity[val as keyof typeof Severity]}
                        />
                      ),
                      values: Object.keys(Severity).map((s) => ({
                        displayName: s + "",
                        value: s + "",
                      })),
                    },
                    {
                      displayName: "Compliance",
                      type: "multiselect",
                      key: "compliance",
                      values: [
                        {
                          displayName: "No Compliance",
                          value: "No Compliance",
                        },
                        ...compliances.map((c) => ({
                          displayName: c,
                          value: c,
                        })),
                      ],
                      renderValue: (val) => <Badge color="info">{val}</Badge>,
                    },
                    {
                      displayName: "Repository",
                      type: "textfield",
                      key: "gitRepo",
                      preventDefault: true,
                    },
                    {
                      displayName: "Status",
                      type: "select",
                      key: "status",
                      preventDefault: true,
                      renderValue: (value) =>
                        value === "Open" ? (
                          <Chip
                            size="small"
                            style={{ width: 90 }}
                            color="default"
                            label="Open"
                          />
                        ) : (
                          <Chip
                            size="small"
                            style={{ width: 90 }}
                            color="secondary"
                            label="Suppressed"
                          />
                        ),
                      values: [
                        {
                          displayName: "Open",
                          value: "Open",
                        },
                        {
                          displayName: "Suppressed",
                          value: "Suppressed",
                        },
                      ],
                    },
                  ]}
                  title="Filter Violations"
                  persistInUrl
                />
              )}
            </Box>
            <Box ml="auto">
              {!isLoading && (
                <DefaultTablePagination
                  count={rows.length}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={(e) =>
                    setPageSize(Number(e.target.value))
                  }
                  pageIndex={state.pageIndex}
                  pageSize={state.pageSize}
                  pageSizeOptions={[10, 20, 30]}
                />
              )}
            </Box>
          </Box>
          {isLoading ? (
            <Box lineHeight={3} p={2}>
              <Skeleton height={18} count={10} />
            </Box>
          ) : (
            <Fragment>
              {violations.length === 0 && (
                <Box width="100%" p={4} textAlign="center">
                  <SvgIcon
                    width={80}
                    height={80}
                    color="lightgray"
                    icon="violations"
                  />
                  <Typography align="center">
                    No data available.
                    {`${
                      state.globalFilter
                        ? " that match the current query."
                        : state.filters.length
                        ? " that match the current filters."
                        : "."
                    }`}
                  </Typography>
                </Box>
              )}
              <Box>
                {violations.map((violation, i) => (
                  <ViolationRow
                    key={violation.id}
                    violations={violation.original.violations}
                    count={violation.original.count}
                  />
                ))}
              </Box>
              <DefaultTablePagination
                count={rows.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={(e) => setPageSize(Number(e.target.value))}
                pageIndex={state.pageIndex}
                pageSize={state.pageSize}
                pageSizeOptions={[10, 20, 30]}
              />
            </Fragment>
          )}
        </Box>
      </Card>
    </Fragment>
  );
};

export default Violations;
