import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Column } from "react-table";
import {
  Box,
  Card,
  Collapse,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { TabContext, TabPanel } from "@material-ui/lab";
import { upperCase } from "lodash";

import { useCurrentOrgId } from "providers";

import ZodiacBuildDiffEntryItem from "./ZodiacBuildDiffEntryItem";
import DataTable from "components/DataTable";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import { sortSeverity } from "helpers/tableHelpers";
import zodiacService from "services/zodiacService";
import { ZodiacAssessmentDiffEntry } from "types/zodiac";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: { "&:hover": { cursor: "pointer" } },
    tabPanel: {
      padding: 0,
      width: "100%",
    },
    table: {
      marginBottom: theme.spacing(1),
      "& > table > tbody > tr > td": {
        border: 0,
      },
    },
  }),
);

const columns: Column<ZodiacAssessmentDiffEntry>[] = [
  {
    accessor: "description",
    Cell: ({ row: { original: row } }) => (
      <ZodiacBuildDiffEntryItem diff={row} />
    ),
  },
  {
    Header: "Severity",
    accessor: "severity",
    sortType: sortSeverity,
  } as Column<ZodiacAssessmentDiffEntry>,
  {
    accessor: "fileName",
  },
  {
    accessor: "resource",
  },
  {
    accessor: "title",
  },
];
interface ZodiacBuildDiffReportProps {
  ciBuildId: string;
}

const ZodiacBuildDiffReport: FC<ZodiacBuildDiffReportProps> = ({
  ciBuildId,
}) => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(true);
  const [tab, setTab] = useState("0");

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const { data: zodiacBuildDiff, isLoading } = useQuery(
    ["job-assessments-diff", orgId, ciBuildId],
    () => zodiacService.getZodiacBuildAssessmentsDiff(orgId, ciBuildId),
    {
      onError(error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  const categories = useMemo(
    () => zodiacBuildDiff?.map((zB) => zB.category),
    [zodiacBuildDiff],
  );

  useEffect(() => {
    setTab(categories?.[0] ?? "0");
  }, [categories, isLoading]);

  return (
    <Card>
      <Box
        className={classes.header}
        px={3}
        py={2}
        display="flex"
        justifyContent="space-between"
        bgcolor="lightBackground.dark"
        borderRadius="inherit"
        alignItems="center"
        onClick={handleClick}
      >
        <Fragment>
          <Box display="flex" alignItems="center">
            <Typography variant="h3" gutterBottom>
              Diff Report
            </Typography>
          </Box>

          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </Fragment>
      </Box>
      <Collapse in={isOpen}>
        {isLoading ? (
          <Box p={3}>
            <Skeleton count={8} />
          </Box>
        ) : (
          <Box>
            <Box px={3} py={1}>
              <Tabs value={tab} indicatorColor="primary" textColor="primary">
                {categories?.map((c, i) => (
                  <Tab
                    onClick={() => setTab(c)}
                    key={i}
                    value={c}
                    label={upperCase(c)}
                  />
                ))}
              </Tabs>
              <Box component="hr" mt={0} mb={1} />
            </Box>
            <Box px={3} py={1}>
              <TabContext value={tab}>
                {zodiacBuildDiff?.map((d, i) => (
                  <TabPanel
                    className={classes.tabPanel}
                    key={i}
                    value={d.category}
                  >
                    {d.diffUrl && (
                      <Fragment>
                        <Typography variant="h5" gutterBottom>
                          Diff Url
                        </Typography>
                        <Box mb={2} px={2}>
                          <Card variant="outlined">
                            <Box px={3} py={1}>
                              <a
                                href={d.diffUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {d.diffUrl}
                              </a>
                            </Box>
                          </Card>
                        </Box>
                      </Fragment>
                    )}
                    <Typography variant="h5" gutterBottom>
                      Opened
                    </Typography>
                    {d.opened.length === 0 ? (
                      <Box my={2} px={2}>
                        <Card variant="outlined">
                          <Box p={2}>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              No entries
                            </Typography>
                          </Box>
                        </Card>
                      </Box>
                    ) : (
                      <DataTable
                        hideTableHead
                        compact
                        search={d.opened.length > 5}
                        pagination={d.opened.length > 5}
                        sortByDesc
                        defaultSortField="severity"
                        hiddenColumns={[
                          "severity",
                          "fileName",
                          "title",
                          "resource",
                        ]}
                        containerClassname={classes.table}
                        pageSizeOptions={[5, 10, 20]}
                        initialRowsPerPage={5}
                        data={d.opened}
                        columns={columns}
                      />
                    )}
                    <Typography variant="h5" gutterBottom>
                      Resolved
                    </Typography>
                    {d.resolved.length === 0 ? (
                      <Box my={2} px={2}>
                        <Card variant="outlined">
                          <Box p={2}>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              No entries
                            </Typography>
                          </Box>
                        </Card>
                      </Box>
                    ) : (
                      <DataTable
                        hideTableHead
                        search={d.resolved.length > 5}
                        pagination={d.resolved.length > 5}
                        compact
                        sortByDesc
                        defaultSortField="severity"
                        hiddenColumns={[
                          "severity",
                          "fileName",
                          "title",
                          "resource",
                        ]}
                        containerClassname={classes.table}
                        pageSizeOptions={[5, 10, 20]}
                        initialRowsPerPage={5}
                        data={d.resolved}
                        columns={columns}
                      />
                    )}
                  </TabPanel>
                ))}
              </TabContext>
            </Box>
          </Box>
        )}
      </Collapse>
    </Card>
  );
};

export default ZodiacBuildDiffReport;
