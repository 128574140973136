export enum AuthRoles {
  Write = "org:write",
  Owner = "org:owner",
  Read = "org:read",
}

export const getLabel = (role: AuthRoles) =>
  Object.keys(AuthRoles).find(
    (k) => AuthRoles[k as keyof typeof AuthRoles] === role,
  )!;

export enum Resources {
  Adapter = "adapter",
  Billing = "billing",
  Cluster = "cluster",
  Organization = "org",
  Service = "service",
  SSO = "sso",
  User = "user",
}

export enum Permissions {
  Owner = "owner",
  Write = "write",
  Read = "read",
}
