import React, { FC, Fragment } from "react";
import { faDocker } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";

import {
  facCDK,
  facCloudFormation,
  facKubernetes,
  facKustomize,
  facTerraform,
} from "assets/svg/icons/custom-fa-icons";
import LogoBadge from "components/LogoBadge";

interface IaCRepoIconProps {
  hasIac?: string[];
}

const IaCRepoIcon: FC<IaCRepoIconProps> = ({ hasIac }) => {
  return (
    <Fragment>
      {hasIac?.includes("cloudformation") && (
        <Tooltip title="Cloud Formation" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "#93c845",
              color: "white",
            }}
          >
            <FontAwesomeIcon icon={facCloudFormation} />
          </div>
        </Tooltip>
      )}
      {hasIac?.includes("docker") && (
        <Tooltip title="Docker" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              paddingLeft: 1.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "#2492e6",
              color: "white",
            }}
          >
            <FontAwesomeIcon style={{ width: 15 }} icon={faDocker} />
          </div>
        </Tooltip>
      )}
      {hasIac?.includes("k8s") && (
        <Tooltip title="Kubernetes" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "#336be4",
              color: "white",
            }}
          >
            <FontAwesomeIcon icon={facKubernetes} />
          </div>
        </Tooltip>
      )}
      {hasIac?.includes("helm") && (
        <Tooltip title="Helm" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "white",
              border: "1px solid #0f1689",
              color: "white",
            }}
          >
            <LogoBadge logo="helm" width={22} />
          </div>
        </Tooltip>
      )}
      {hasIac?.includes("terraform") && (
        <Tooltip title="Terraform" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              paddingTop: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "#5c41e2",
              color: "white",
            }}
          >
            <FontAwesomeIcon icon={facTerraform} />
          </div>
        </Tooltip>
      )}
      {hasIac?.includes("CDK") && (
        <Tooltip title="CDK" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "white",
              color: "black",
              border: "1px solid black",
            }}
          >
            <FontAwesomeIcon icon={facCDK} />
          </div>
        </Tooltip>
      )}
      {hasIac?.includes("kustomize") && (
        <Tooltip title="Kustomize" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              paddingLeft: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "#36559E",
              color: "white",
            }}
          >
            <FontAwesomeIcon icon={facKustomize} />
          </div>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default IaCRepoIcon;
