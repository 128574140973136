import React, { FC, useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { useCurrentOrgId } from "providers";

import ZodiacConsolidatedAssessmentsBody from "./components/ZodiacConsolidatedAssessmentsBody";
import ZodiacConsolidatedAssessmentsHeader from "./components/ZodiacConsolidatedAssessmentsHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import { AssessmentDetailsContext } from "pages/Assessments/pages/AssessessmentDetails/context/AssessmentDetailsContext";
import zodiacService from "services/zodiacService";

const ZodiacConsolidatedAssessments: FC = () => {
  const orgId = useCurrentOrgId();
  const { ciBuildId = "" } = useParams<{ ciBuildId: string }>();
  const { data, isLoading, refetch, isFetched } = useQuery(
    ["job-detail-assessments", orgId, ciBuildId],
    () => zodiacService.getZodiacBuildAssessmentsConsolidated(orgId, ciBuildId),
    {
      onError(error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const zodiacConsolidated = useMemo(
    () =>
      data
        ? {
            ...data,
            findings: data?.findings?.map((f) => ({
              ...f,
              autofix: f.pass ? false : !!f.autofix,
              compliance:
                f.compliance?.length === 0 || f.compliance === undefined
                  ? ["No Compliance"]
                  : f.compliance,
            })),
            suppressedFindings: data?.suppressedFindings?.map((f) => ({
              ...f,
              autofix: f.pass ? false : !!f.autofix,
              compliance:
                f.compliance?.length === 0 || f.compliance === undefined
                  ? ["No Compliance"]
                  : f.compliance,
            })),
          }
        : undefined,
    [data],
  );

  return (
    <AssessmentDetailsContext.Provider
      value={{
        assessmentDetail: zodiacConsolidated,
        isLoading,
        refetch,
        isFetched,
      }}
    >
      <ZodiacConsolidatedAssessmentsHeader ciBuildId={ciBuildId} />
      <ZodiacConsolidatedAssessmentsBody ciBuildId={ciBuildId} />
    </AssessmentDetailsContext.Provider>
  );
};

export default ZodiacConsolidatedAssessments;
