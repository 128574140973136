import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import ServiceDetail from "./pages/ServiceDetail";
import Services from "./Services";
import HasFlagRoute from "router/HasFlagRoute";

const ServiceRouter: FC = () => {
  return (
    <Routes>
      <Route path="details/:service" element={<ServiceDetail />} />
      <Route path="details/:service/:tab" element={<ServiceDetail />} />
      <Route
        path=""
        element={<HasFlagRoute flag="servicesEnabled" element={<Services />} />}
      />
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default ServiceRouter;
