import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PipelineDetails from "./pages/PipelineRuns/PipelineRuns";
import CiCdPipelines from "./pages/Pipelines/CiCdPipelines";

const PipelinesRouter: FC = () => {
  return (
    <Routes>
      <Route path="/:ciPipelineId/runs" element={<PipelineDetails />} />
      <Route path="" element={<CiCdPipelines />} />
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default PipelinesRouter;
