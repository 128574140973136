import React, { FC, Fragment } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import TimestampFormatter from "components/TimestampFormatter";
import { Resource } from "types/resources";

interface ResourceDrawerProps {
  resource: Resource;
}

const ResourceDrawer: FC<ResourceDrawerProps> = ({ resource }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Fragment>
      <Box
        position="fixed"
        width={matches ? "100%" : 750}
        zIndex={theme.zIndex.tooltip}
        py={4}
        px={3}
        bgcolor="lightBackground.main"
      >
        <Typography variant="h5" gutterBottom>
          {resource.resourceArn}
        </Typography>
      </Box>
      <Box mt={13} p={2} width={matches ? "100%" : 750}>
        <Box display="flex" my={1}>
          <Box width={140} component="dt">
            File
          </Box>
          <Box>
            <a
              href={resource.gitLocationUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${resource.file} : Line ${resource.line}`}
            </a>
          </Box>
        </Box>
        <Box mb={2} display="flex">
          <Box width={140} component="dt">
            Line #
          </Box>
          <Box>{resource.line}</Box>
        </Box>
        <Box mb={2} display="flex">
          <Box width={140} component="dt">
            Terraform Address
          </Box>
          <Box>{resource.terraformAddress}</Box>
        </Box>
        <Box mb={2} display="flex">
          <Box width={140} component="dt">
            Repository
          </Box>
          <Box>
            <a
              href={resource.gitRepoUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {resource.gitRepo}
            </a>
          </Box>
        </Box>
        <Box mb={2} display="flex">
          <Box width={140} component="dt">
            Branch
          </Box>
          <Box>{resource.gitBranch}</Box>
        </Box>
        <Box mb={2} display="flex">
          <Box width={140} component="dt">
            Commit
          </Box>
          <Box>{resource.gitCommit}</Box>
        </Box>
        <Box mb={2} display="flex">
          <Box width={140} component="dt">
            Created
          </Box>
          <Box>
            <TimestampFormatter
              dateTs={resource.createTs}
              oneLine
              disableCenterText
            />
          </Box>
        </Box>
        {resource?.module && (
          <Box mb={2} display="flex">
            <Box width={140} component="dt">
              Module Name
            </Box>
            <Box>{resource.module}</Box>
          </Box>
        )}
        {resource?.moduleFile && (
          <Box mb={2} display="flex">
            <Box width={140} component="dt">
              Module File
            </Box>
            <Box>{resource.moduleFile}</Box>
          </Box>
        )}
        {(resource?.moduleLine || resource?.moduleEndLine) && (
          <Box mb={2} display="flex">
            <Box width={140} component="dt">
              {resource?.moduleLine && resource?.moduleEndLine
                ? "Module Lines"
                : "Module Line"}
            </Box>
            <Box>
              {`${resource.moduleLine} ${
                resource?.moduleLine && resource?.moduleEndLine ? "-" : ""
              } ${resource.moduleEndLine}`}
            </Box>
          </Box>
        )}
        <Box mb={2} display="flex">
          <Box width={140} component="dt">
            Last Updated
          </Box>
          <Box>
            <RelativeTimeFormatter dateTs={resource.createTs} />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ResourceDrawer;
