import React, {
  FC,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { LoopRounded } from "@material-ui/icons";
import moment from "moment";

import { useCurrentOrgId } from "providers";

import Notification from "./Notification";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import notificationService from "services/notificationService";
import { Notification as NotificationI } from "types/notifications";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1090,
      position: "absolute",
    },
    body: {
      maxHeight: "calc(60vh - 100px)",
      overflowY: "auto",
    },
  }),
);

const defaultQuery: NotificationI[] = [];

const NotificationsDropdown: FC = () => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [loaded, setLoaded] = useState(5);
  const theme = useTheme();
  const { pathname } = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const orgId = useCurrentOrgId();

  const { data: notifications = defaultQuery, isLoading } = useQuery(
    ["latest-notifications", orgId],
    () => notificationService.getLatestNotifications(orgId),
    {
      onError(error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      },
      refetchInterval: 10000,
    },
  );

  const lastOpenedMap = useMemo(() => {
    const map = JSON.parse(
      localStorage.getItem("lastOpenedNotifications") ?? "{}",
    );
    if (map?.[orgId] === undefined) {
      const updatedMap = {
        ...map,
        [orgId]: moment().toISOString(),
      };
      localStorage.setItem(
        "lastOpenedNotifications",
        JSON.stringify(updatedMap),
      );
      return updatedMap;
    }
    return map;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, reset, notifications]);

  const unreadNotificationsCounter = useMemo(
    () =>
      notifications.filter((n) =>
        moment(n.createTs).isAfter(moment(lastOpenedMap?.[orgId])),
      ).length,
    [lastOpenedMap, notifications, orgId],
  );

  const resetLastOpened = () => {
    const lastOpenedResettedMap = JSON.stringify({
      ...lastOpenedMap,
      [orgId]: moment().toISOString(),
    });
    localStorage.setItem("lastOpenedNotifications", lastOpenedResettedMap);
    setReset((prev) => !prev);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    resetLastOpened();
    setOpen(false);
  };

  useEffect(() => setOpen((prev) => (prev ? false : prev)), [pathname]);

  const handleToggle = () => {
    setOpen((prevOpen) => {
      if (prevOpen) resetLastOpened();
      return !prevOpen;
    });
  };

  return (
    <Fragment>
      <IconButton onClick={handleToggle} ref={anchorRef}>
        <Badge
          badgeContent={unreadNotificationsCounter}
          color="secondary"
          max={10}
        >
          <FontAwesomeIcon icon={faBell} size="xs" />
        </Badge>
      </IconButton>
      <Popper
        className={classes.root}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Box width={matches ? 400 : 450}>
                    <Box mb={2}>
                      <Box display="flex" justifyContent="space-between" p={2}>
                        <Typography variant="h5">Notifications</Typography>
                        <Link onClick={handleClose} to="/notifications">
                          See All
                        </Link>
                      </Box>
                      <Box component="hr" my={0} />
                      <Box className={classes.body}>
                        {isLoading ? (
                          <Box p={3}>
                            <Skeleton count={6} />
                          </Box>
                        ) : notifications.length === 0 ? (
                          <Box py={3} textAlign="center">
                            No notifications
                          </Box>
                        ) : (
                          notifications
                            .slice(0, loaded)
                            .map((notification, i) => (
                              <Notification
                                key={i}
                                notification={notification}
                                unread={moment(notification.createTs).isAfter(
                                  lastOpenedMap?.[orgId],
                                )}
                              />
                            ))
                        )}
                      </Box>
                      <Box component="hr" my={0} />
                      {notifications.length !== 0 && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          p={1}
                        >
                          {loaded + 5 > notifications.length ? (
                            <Box p={1}>
                              <Link
                                onClick={(e) => {
                                  handleClose(e);
                                  setLoaded(5);
                                }}
                                to="/notifications"
                              >
                                See All
                              </Link>
                            </Box>
                          ) : (
                            <Button
                              variant="text"
                              color="primary"
                              startIcon={<LoopRounded />}
                              onClick={() => setLoaded((prev) => prev + 5)}
                            >
                              Load more
                            </Button>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default NotificationsDropdown;
