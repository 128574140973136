import React, { FC } from "react";
import { Box, Grid, Hidden } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import LoginForm from "./components/LoginForm";
import LWBackground from "assets/img/background-lacework.jpeg";
import WhiteLogo from "components/WhiteLogo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "white",
      minHeight: "100vh",
    },
    background: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      background:
        "linear-gradient(114.91deg, #02064A 3.97%, #0E28B0 53.26%, #20FDFD 127.91%)",
      backgroundImage: `url(${LWBackground})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "50%",
    },
  }),
);

interface LoginProps {
  isNewUser?: boolean;
}

const Login: FC<LoginProps> = ({ isNewUser }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} lg={5}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <LoginForm isNewUser={isNewUser} />
        </Box>
      </Grid>
      <Hidden mdDown>
        <Grid item className={classes.background} lg={7}>
          <WhiteLogo width={400} />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default Login;
