import React, { FC, Fragment, useEffect, useState } from "react";
import { Filters } from "react-table";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge as MBadge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { ClearAllRounded, FilterListRounded } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";

import BootstrapBadge from "components/BootstrapBadge/BootstrapBadge";
import SeverityComponent, {
  Severity,
} from "components/SeverityComponent/SeverityComponent";
import { useQueryState } from "hooks/useQueryState";
import { Finding } from "types/assessments";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: { width: "100%" },
  }),
);

interface FilterModalProps {
  setTableFilters(filters: Filters<Finding>): void;
  disabled?: boolean;
  compliances: string[];
}
interface QueryState {
  severity?: string;
  compliance?: string;
  pass?: string;
  autofix?: string;
}

const FilterModal: FC<FilterModalProps> = ({
  setTableFilters,
  disabled,
  compliances,
}) => {
  const { autofixUiEnabled } = useFlags();
  const {
    state: { severity = "", compliance = "", pass = "", autofix = "" },
    setQueryState,
  } = useQueryState<QueryState>("assessment-details");
  const [filters, setFilters] = useState<{
    severity: string[];
    compliance: string[];
    pass: "" | "1" | "2";
    autofix: "" | "1" | "2";
  }>({
    severity: severity?.length > 0 ? severity?.split(",") : [] ?? [],
    compliance: compliance?.length > 0 ? compliance?.split(",") : [] ?? [],
    pass: pass === "" ? pass : pass + "" === "true" ? "1" : "2",
    autofix: autofix === "" ? autofix : autofix + "" === "true" ? "1" : "2",
  });

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const resetFilters = () => {
    setTableFilters([]);
    setQueryState({
      severity: "",
      compliance: "",
      pass: "",
      autofix: "",
    });
    setFilters({ severity: [], compliance: [], pass: "", autofix: "" });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isFilterActive =
    filters.compliance.length > 0 ||
    filters.severity.length > 0 ||
    filters.pass !== "" ||
    filters.autofix !== "";

  useEffect(
    () =>
      setTableFilters([
        {
          id: "compliance",
          value: compliance.length > 0 ? compliance.split(",") : undefined,
        },
        { id: "pass", value: pass === "" ? undefined : pass + "" === "true" },
        {
          id: "severity",
          value: severity.length > 0 ? severity.split(",") : undefined,
        },
        {
          id: "autofix",
          value: autofix,
        },
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled],
  );

  useEffect(() => {
    setTableFilters([
      {
        id: "compliance",
        value: compliance.length > 0 ? compliance.split(",") : undefined,
      },
      { id: "pass", value: pass === "" ? undefined : pass + "" === "true" },
      {
        id: "autofix",
        value: autofix === "" ? undefined : autofix + "" === "true",
      },
      {
        id: "severity",
        value: severity.length > 0 ? severity.split(",") : undefined,
      },
    ]);
    setFilters({
      severity: severity?.length > 0 ? severity?.split(",") : [] ?? [],
      compliance: compliance?.length > 0 ? compliance?.split(",") : [] ?? [],
      pass: pass === "" ? pass : pass + "" === "true" ? "1" : "2",
      autofix: autofix === "" ? autofix : autofix + "" === "true" ? "1" : "2",
    });
  }, [severity, compliance, pass, autofix, setTableFilters]);

  return (
    <Fragment>
      <Tooltip
        title={isFilterActive ? "Active" : "Inactive"}
        arrow
        placement="right"
      >
        <span>
          <IconButton
            disabled={disabled}
            color="primary"
            onClick={handleClickOpen}
          >
            <MBadge color="secondary" variant="dot" invisible={!isFilterActive}>
              <FilterListRounded fontSize="small" />
            </MBadge>
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Box p={3} pb={0} display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            Filter all findings
          </Typography>
          <Tooltip arrow title="Clear all filters">
            <IconButton
              color="primary"
              type="reset"
              size="small"
              onClick={resetFilters}
            >
              <ClearAllRounded />
            </IconButton>
          </Tooltip>
        </Box>
        <DialogContent>
          <FormControl
            className={classes.formControl}
            color="secondary"
            disabled={disabled}
            variant="outlined"
          >
            <InputLabel id="severity-label">Severity</InputLabel>
            <Select
              value={filters.severity}
              labelId="severity-label"
              id="severity"
              onChange={({ target: { value } }) => {
                setQueryState({
                  severity: (value as string[]).join(","),
                });
              }}
              label="Severity"
              renderValue={(selected) => {
                const values = selected as (keyof typeof Severity)[];
                return values.map((val, i) => (
                  <Fragment key={i}>
                    {i !== 0 && ", "}
                    <SeverityComponent severity={Severity[val]} />
                  </Fragment>
                ));
              }}
              multiple
            >
              {(Object.keys(Severity) as (keyof typeof Severity)[]).map(
                (n, i) => (
                  <MenuItem value={n} key={i}>
                    <Checkbox
                      size="small"
                      checked={filters.severity.includes(n)}
                    />
                    <ListItemText
                      primary={
                        <Box mr={2}>
                          <SeverityComponent severity={Severity[n]} />
                        </Box>
                      }
                    />
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
          &nbsp;
          <FormControl
            className={classes.formControl}
            color="secondary"
            disabled={disabled}
            variant="outlined"
          >
            <InputLabel id="notification-source-label">Compliance</InputLabel>
            <Select
              renderValue={(selected) => {
                const values = selected as string[];
                return values.map((val, i) => (
                  <Box display="inline-block" key={i} mr={1}>
                    <BootstrapBadge color="info">{val}</BootstrapBadge>
                  </Box>
                ));
              }}
              value={filters.compliance}
              labelId="notification-source-label"
              id="notification-source"
              onChange={({ target: { value } }) => {
                setQueryState({
                  compliance: (value as string[]).join(","),
                });
              }}
              label="Compliance"
              multiple
            >
              {compliances.length > 0 ? (
                compliances.map((c, i) => (
                  <MenuItem key={i} value={c}>
                    <Checkbox
                      size="small"
                      checked={filters.compliance.includes(c)}
                    />
                    <ListItemText primary={c} />
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled defaultChecked value="">
                  <em>All</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
          &nbsp;
          <FormControl
            className={classes.formControl}
            color="secondary"
            disabled={disabled}
            variant="outlined"
          >
            <InputLabel id="notification-source-label">Status Check</InputLabel>
            <Select
              renderValue={(pass) =>
                pass === "" ? (
                  ""
                ) : (
                  <Box display="inline-flex">
                    {pass === "1" ? (
                      <Fragment>
                        <Box color="success.main" textAlign="center" mr={1}>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </Box>
                        Passed
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Box color="error.main" textAlign="center" mr={1}>
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </Box>
                        Failed
                      </Fragment>
                    )}
                  </Box>
                )
              }
              value={filters.pass}
              labelId="notification-source-label"
              id="notification-source"
              onChange={({ target: { value } }) => {
                setQueryState({
                  pass:
                    value === "" ? undefined : value === "1" ? "true" : "false",
                });
              }}
              label="Status Check"
            >
              <MenuItem value="">
                <em>Any</em>
              </MenuItem>
              <MenuItem value={"1"}>
                <Box color="success.main" textAlign="center" mr={1}>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </Box>
                Passed
              </MenuItem>
              <MenuItem value={"2"}>
                <Box color="error.main" textAlign="center" mr={1}>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </Box>
                Failed
              </MenuItem>
            </Select>
          </FormControl>
          {autofixUiEnabled && (
            <Fragment>
              &nbsp;
              <FormControl
                className={classes.formControl}
                color="secondary"
                disabled={disabled}
                variant="outlined"
              >
                <InputLabel id="autofix-form-label">Auto Fix</InputLabel>
                <Select
                  renderValue={(fix) =>
                    fix === "" ? (
                      ""
                    ) : (
                      <Box display="inline-flex">
                        {fix === "1" ? (
                          <Fragment>
                            <Box color="success.main" textAlign="center" mr={1}>
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </Box>
                            Available
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Box color="error.main" textAlign="center" mr={1}>
                              <FontAwesomeIcon icon={faTimesCircle} />
                            </Box>
                            Not Available
                          </Fragment>
                        )}
                      </Box>
                    )
                  }
                  value={filters.autofix}
                  labelId="autofix-form-label"
                  id="autofix"
                  onChange={({ target: { value } }) => {
                    setQueryState({
                      autofix:
                        value === ""
                          ? undefined
                          : value === "1"
                          ? "true"
                          : "false",
                    });
                  }}
                  label="Auto Fix"
                >
                  <MenuItem value={""}>
                    <em>Any</em>
                  </MenuItem>
                  <MenuItem value={"1"}>
                    <Box color="success.main" textAlign="center" mr={1}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Box>
                    Available
                  </MenuItem>
                  <MenuItem value={"2"}>
                    <Box color="error.main" textAlign="center" mr={1}>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </Box>
                    Not Available
                  </MenuItem>
                </Select>
              </FormControl>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Box>
            <Box mr={2} display="inline-block">
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default FilterModal;
