import { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Typography } from "@material-ui/core";

import PageHeader from "components/PageHeader";
import OrganizationsDataTable from "pages/Support/pages/Organizations/components/OrganizationsDataTable";

const AdminDashboard: FC = () => {
  return (
    <Fragment>
      <PageHeader>Dashboard</PageHeader>
      <Card>
        <Box p={2}>
          <Box pl={1} mb={2}>
            <Typography variant="h4">ORGANIZATIONS</Typography>
          </Box>
          <OrganizationsDataTable dashboard />
          <Box display="flex" justifyContent="center" mb={1}>
            <Link to="/support/organizations">
              Go to Organizations
              <Box ml={1} display="inline-block">
                <FontAwesomeIcon icon={faChevronRight} />
              </Box>
            </Link>
          </Box>
        </Box>
      </Card>
    </Fragment>
  );
};

export default AdminDashboard;
