import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import SuccessAccountDeletion from "./pages/SuccessAccountDeletion/SuccessAccountDeletion";
import SuccessBitBucket from "./pages/SuccessBitBucket/SuccessBitBucket";
import SuccessEmailVerification from "./pages/SuccessEmailVerification/SuccessEmailVerification";
import SuccessGitHub from "./pages/SuccessGitHubP/SuccessGitHub";
import SuccessGitLab from "./pages/SuccessGitLab/SuccessGitLab";
import SuccessSlack from "./pages/SuccessSlack/SuccessSlack";

const SuccessRouter: FC = () => (
  <Routes>
    <Route path="email-verification" element={<SuccessEmailVerification />} />

    <Route path="slack-integration" element={<SuccessSlack />} />

    <Route path="account-deletion" element={<SuccessAccountDeletion />} />

    <Route path="github-app-install" element={<SuccessGitHub />} />

    <Route path="gitlab-app-install" element={<SuccessGitLab />} />

    <Route path="bitbucket-app-install" element={<SuccessBitBucket />} />

    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

export default SuccessRouter;
