import axios from "axios";

import instance from "./axiosInstance";
import { APIResponse } from "types";
import { CliInstallConfig } from "types/cli";

const getCliInstallConfig = () =>
  instance
    .get<APIResponse<CliInstallConfig>>(`/cli/cli-install-config`)
    .then((res) => res.data.data);

const checkAuthStatus = (port: "" | number, code: string) =>
  axios
    .post(`http://localhost:${port}/auth/callback`, { code })
    .then((res) => res.data);

export default {
  getCliInstallConfig,
  checkAuthStatus,
};
