import React, { FC, useCallback, useState } from "react";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import classNames from "classnames";

import { copy } from "helpers/copy";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyButton: {
      position: "absolute",
      right: "0.75rem",
      top: "0.75rem",
    },
    preCollapsed: {
      height: "15ex",
      overflow: "hidden",
      userSelect: "all",
    },
    codeSnipped: {
      background: theme.palette.lightBackground.dark,
      padding: theme.spacing(2),
      position: "relative",
    },
  }),
);

interface CollapsibleCodeSnippedProps {
  children: string;
  onCopy?(): void;
}

const CollapseCodeSnipped: FC<CollapsibleCodeSnippedProps> = ({
  children,
  onCopy,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const toggleCollapsed = useCallback(
    () => setIsCollapsed((isCollapsed) => !isCollapsed),
    [],
  );

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const classes = useStyles();

  const copyCode = useCallback(async () => {
    await copy(children);
    handleTooltipOpen();
    setTimeout(() => handleTooltipClose(), 3000);
    onCopy?.();
  }, [children, onCopy]);

  return (
    <div className={classes.codeSnipped}>
      <pre
        className={classNames({
          [classes.preCollapsed]: isCollapsed,
        })}
      >
        {children}
      </pre>

      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={isTooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copied!"
        placement="top"
        arrow
      >
        <IconButton
          id="CopySnippedButton"
          data-testid="copy-icon-button"
          size="small"
          onClick={copyCode}
          className={classes.copyButton}
        >
          <Typography variant="srOnly">Copy</Typography>
          <FontAwesomeIcon icon={faCopy} />
        </IconButton>
      </Tooltip>

      <Box textAlign="center">
        <Button
          color="primary"
          onClick={toggleCollapsed}
          endIcon={isCollapsed ? <ExpandMore /> : <ExpandLess />}
        >
          {isCollapsed ? "Show More" : "Show Less"}
        </Button>
      </Box>
    </div>
  );
};

export default CollapseCodeSnipped;
