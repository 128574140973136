import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import Resources from "./Resources";
import HasFlagRoute from "router/HasFlagRoute";

const ResourcesRouter: FC<Childless> = () => {
  return (
    <Routes>
      <Route
        path=""
        element={
          <HasFlagRoute flag="resourcesPageEnabled" element={<Resources />} />
        }
      />
    </Routes>
  );
};

export default ResourcesRouter;
