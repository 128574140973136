import React, {
  FC,
  Fragment,
  useCallback,
  // useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { Column } from "react-table";
import {
  Box,
  Breadcrumbs,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  GetApp,
  //  ViewWeek
} from "@material-ui/icons";
import moment from "moment";

import { useCurrentOrgId } from "providers";

// import ColumnSelectorModal, {
//   ColumnSelectorModalRef,
// } from "./components/ColumnSelectorModal";
import DataTable from "components/DataTable";
import NoDataComponent from "components/NoDataComponent";
import PageHeader from "components/PageHeader";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import TimestampFormatter from "components/TimestampFormatter";
import UrnHyperlink from "components/UrnHyperlink";
import { objectArrayToCSV } from "helpers/formatter";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import ReportRowExpanded from "pages/Analytics/Reports/Reports/components/ReportRowExpanded";
import reportService from "services/reportService";
import { FieldMetadata, ReportDetails } from "types/reports";

const ReportDetailsPage: FC = () => {
  const orgId = useCurrentOrgId();
  const { reportName = "" } = useParams<{ reportName: string }>();
  const [query, setQuery] = useState<string>();
  const [selectedFields, setSelectedFields] = useState<
    Pick<FieldMetadata, "name">[]
  >([]);
  const [csv, setCSV] = useState<{ data: string; date: string }>({
    data: "",
    date: "",
  });
  const [noDataMessage, setNoDataMessage] = useState(
    "This query ran successfully and produced no results",
  );
  // const columnSelectorModalRef = useRef<ColumnSelectorModalRef>(null);

  const { data, isLoading, refetch } = useQuery(
    [
      "reportDetail",
      orgId,
      reportName,
      query,
      selectedFields.map((d) => d.name).join(","),
    ],
    () =>
      reportService.getReportDetails(orgId, reportName, query, selectedFields),
    {
      onSuccess(rd: ReportDetails) {
        setSelectedFields(
          rd.metadata.fields.map(({ name }: FieldMetadata) => ({ name })),
        );
      },
      onError: (error) => {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
        setNoDataMessage("Report Failure");
      },
    },
  );

  const { fields = [] } = data?.metadata ?? {};

  const title = data?.metadata.request.queryName ?? reportName;

  const columns: Column<Record<string, string>>[] = fields.map(
    ({ name, displayName, displayHints }) => {
      const isTimestamp = displayHints?.includes("TS");
      const isRelative = displayHints?.includes("RELATIVE_TS");
      const isUrn = displayName.toLowerCase() === "urn";

      return {
        Header: displayName,
        accessor: name,
        Cell: ({
          row: { original },
        }: {
          row: { original: Record<string, string> };
        }) => {
          if (original[name] == null) {
            return "-";
          }
          if (typeof original[name] === "object") {
            return "Data cannot be displayed";
          }

          if (isTimestamp) {
            return (
              <TimestampFormatter
                dateTs={original[name]}
                oneLine
              ></TimestampFormatter>
            );
          }
          if (isRelative) {
            return (
              <RelativeTimeFormatter
                dateTs={original[name]}
              ></RelativeTimeFormatter>
            );
          }
          if (isUrn) {
            return <UrnHyperlink urn={original[name]} />;
          }

          return String(original[name]);
        },
      } as Column<Record<string, string>>;
    },
  );

  const handleSubmit = (filterText: string) => {
    setQuery(filterText);
    refetch();
  };

  // const handleSelection = (selected: Pick<FieldMetadata, "name">[]) => {
  //   setSelectedFields(selected);
  //   refetch();
  // };

  const createCsvData = useCallback(() => {
    if (!data) return;
    const csvData = objectArrayToCSV(data.data);
    const csvDate = moment(new Date(Date.now())).format("YYYYMMDDHHMMSS");
    setCSV({ data: csvData, date: csvDate });
  }, [data]);

  return (
    <Fragment>
      <Box display="flex" justifyContent="space-between">
        <PageHeader>{title}</PageHeader>
        <Breadcrumbs separator="/" aria-label="breadcrumb">
          <Link to="/analytics/reports" color="inherit">
            Reports
          </Link>
          <Typography color="textPrimary">{title}</Typography>
        </Breadcrumbs>
      </Box>
      <Card>
        <Box p={2}>
          <DataTable
            columns={columns}
            data={data?.data ?? []}
            isLoading={isLoading}
            noDataComponent={
              <NoDataComponent>
                {data?.metadata.emptyResultMessage ?? noDataMessage}
              </NoDataComponent>
            }
            pagination
            compact
            searchActions={
              <Tooltip title="Download as CSV" arrow>
                <IconButton
                  component="a"
                  size="small"
                  onClick={createCsvData}
                  download={`${title}-${csv.date}.csv`}
                  href={`data:text/csv;charset=utf-8,${csv.data}`}
                  color="primary"
                >
                  <GetApp />
                </IconButton>
              </Tooltip>
            }
            search
            onSearchSubmit={handleSubmit}
            pageSizeOptions={[50, 100, 200]}
            initialRowsPerPage={50}
            expandOnRowClick
            expandableComponent={(row) => (
              <ReportRowExpanded data={row} metadata={fields} />
            )}
          />
        </Box>
      </Card>
      {/* <ColumnSelectorModal
        ref={columnSelectorModalRef}
        availableFields={fields}
        selectedFields={selectedFields}
        onSelection={handleSelection}
      /> */}
    </Fragment>
  );
};

export default ReportDetailsPage;
