import React, { FC, Fragment, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Collapse,
  createStyles,
  fade,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  ExpandLessRounded,
  ExpandMoreRounded,
  LaunchRounded,
  QueryBuilderRounded,
  SnoozeRounded,
} from "@material-ui/icons";
import classNames from "classnames";
import { startCase } from "lodash";
import moment from "moment";

import { useCurrentOrgId } from "providers";

import JobStatusIcon from "./JobStatusIcon";
import ReactMarkdown from "components/ReactMarkdown/ReactMarkdown";
import repositoryService from "services/repositoryService";
import { GitRepoJobStatus } from "types/repositories";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(2.5),
      borderRadius: theme.spacing(1),
      border: "1px solid lightgray",
      transition: "background-color 0.3s",
    },
    output: {
      borderRadius: `0 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
      border: "1px solid lightgray",
      borderTop: 0,
      marginTop: -theme.spacing(1),
      overflow: "auto",
    },
    completed: {
      background: fade(theme.palette.success.main, 0.04),
    },
    running: {
      background: fade(theme.palette.warning.main, 0.04),
    },
    failed: {
      background: fade(theme.palette.error.main, 0.04),
    },
    skipped: {
      background: fade(theme.palette.info.main, 0.04),
    },
    completedHover: {
      "&:hover": {
        background: fade(theme.palette.success.dark, 0.08),
        cursor: "pointer",
      },
    },
    runningHover: {
      "&:hover": {
        background: fade(theme.palette.warning.dark, 0.08),
        cursor: "pointer",
      },
    },
    failedHover: {
      "&:hover": {
        background: fade(theme.palette.error.dark, 0.08),
        cursor: "pointer",
      },
    },
    skippedHover: {
      "&:hover": {
        background: fade(theme.palette.info.dark, 0.08),
        cursor: "pointer",
      },
    },
    unknown: {},
    unknownHover: {},
    emoji: { "& p": { marginBottom: 0 } },
  }),
);

interface JobStepProps {
  title: string;
  taskId: string;
  isJobRunning: boolean;
}

const JobStep: FC<JobStepProps> = ({ title, taskId, isJobRunning }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState<GitRepoJobStatus>("unknown");
  const orgId = useCurrentOrgId();
  const { data: step, isLoading } = useQuery(
    ["repo-job-status", orgId, taskId, title],
    () => repositoryService.getRepoJobStepStatus(orgId, taskId, title),
    {
      onSuccess: (d) =>
        setStatus((d?.[title]?.toLowerCase() ?? "unknown") as GitRepoJobStatus),
      refetchInterval:
        (status === "unknown" && isJobRunning) || status === "running"
          ? 5000
          : false,
    },
  );

  const titleS =
    title.toLowerCase() === "plananalysis"
      ? "PLAN GENERATION"
      : title.toLowerCase() === "riskanalysis"
      ? "OPA RULE EVALUATION"
      : startCase(title).toUpperCase();

  const statusH = ((step?.[title]?.toLowerCase() ?? "unknown") +
    "Hover") as GitRepoJobStatus;

  const startTs = moment(step?.[title + "StartTs"] ?? moment.invalid());
  const endTs = moment(step?.[title + "EndTs"] ?? moment.invalid());
  const duration =
    status === "running"
      ? moment.duration(moment().diff(startTs))
      : moment.duration(endTs.diff(startTs));
  const durationString =
    startTs.isValid() && (status === "running" || endTs.isValid())
      ? `${
          status === "completed"
            ? "Passed in"
            : status === "failed"
            ? "Failed in"
            : status === "running"
            ? "Running for"
            : ""
        } ${duration.hours() ? duration.hours() + "h " : ""}${
          duration.minutes() ? duration.minutes() + "m " : ""
        }${duration.seconds() + "s"}`
      : undefined;

  return (
    <Fragment>
      <Box
        className={classNames(classes.root, classes[status], {
          [classes[statusH]]: !!step?.stepOutput,
        })}
        onClick={step?.stepOutput ? () => setIsOpen((p) => !p) : undefined}
      >
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            {isLoading ? (
              <Skeleton circle width={28} height={28} />
            ) : (
              <JobStatusIcon status={status} fontSize={28} />
            )}
          </Box>
          <Typography variant="h4">{titleS}</Typography>
          {step?.moreSummary && step.summaryLink === "diff" && (
            <Box ml={1} onClick={(e) => e.stopPropagation()}>
              <IconButton
                size="small"
                color="primary"
                component={Link}
                to={`/zodiac/jobs/${taskId}/diff`}
              >
                <LaunchRounded fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
        {step?.summary && (
          <ReactMarkdown className={classes.emoji} showEmojis>
            {step?.summary}
          </ReactMarkdown>
        )}
        <Box display="flex">
          {isLoading && <Skeleton width={80} />}
          {durationString && (
            <Box display="flex" alignItems="center">
              <Box
                component={
                  status === "skipped" ? SnoozeRounded : QueryBuilderRounded
                }
                width={16}
                mr={1}
              />
              <Typography variant="h6">{durationString}</Typography>
            </Box>
          )}
          {step?.stepOutput ? (
            <Box ml={1}>
              <Box component={isOpen ? ExpandLessRounded : ExpandMoreRounded} />
            </Box>
          ) : (
            <Box ml={5} />
          )}
        </Box>
      </Box>
      <Collapse in={isOpen}>
        <Box className={classNames(classes.root, classes.output)}>
          <ReactMarkdown linkTarget="_blank" unescapeHtml showEmojis>
            {step?.stepOutput}
          </ReactMarkdown>
        </Box>
      </Collapse>
    </Fragment>
  );
};

export default JobStep;
