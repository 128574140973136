import React, { FC } from "react";
import { Link } from "react-router-dom";
import { faListUl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  createStyles,
  lighten,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { ReactComponent as StatsIllustration } from "assets/svg/illustrations/stats.svg";
import {
  DashboardElementData,
  DashboardElementMetadata,
} from "types/dashboard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      background: lighten(theme.palette.primary.light, 0.7),
      color: theme.palette.primary.main,
      padding: theme.spacing(2),
      position: "relative",
    },
    list: {
      paddingInlineStart: "20px",
      "&>li:not(:last-child)": {
        marginBottom: 5,
      },
    },
    background: {
      width: 130,
      height: "auto",
      position: "absolute",
      right: 10,
      bottom: 10,
      [theme.breakpoints.between(
        theme.breakpoints.values.md,
        theme.breakpoints.values.xl,
      )]: {
        opacity: 0,
      },
    },
  }),
);

interface SummaryElementProps {
  metadata: DashboardElementMetadata;
  element?: DashboardElementData;
  isLoading: boolean;
}

enum SingularDictionary {
  Repositories = "Repository",
  Policies = "Policy",
  "Open Alerts" = "Open Alert",
  "AWS Accounts" = "AWS Account",
}

const SummaryElement: FC<SummaryElementProps> = ({
  metadata,
  element,
  isLoading,
}) => {
  const classes = useStyles();

  const singularize = (message: string, count: number) => {
    if (count === 1)
      return (
        SingularDictionary?.[message as keyof typeof SingularDictionary] ??
        message
      );
    else return message;
  };

  return isLoading ? (
    <Card className={classes.root}>
      <Box
        component="h2"
        textAlign="center"
        py={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        -
      </Box>
    </Card>
  ) : element?.data.length ? (
    <Card className={classes.root}>
      <Box mb={2} style={{ textTransform: "uppercase" }}>
        <Typography variant="h4" color="primary" gutterBottom>
          {metadata.title}
        </Typography>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Today's Summary
        </Typography>
      </Box>
      <ul className={classes.list}>
        {element?.data.map((el, ind) => (
          <li key={ind}>
            <Link to={el.link}>
              <Box display="inline" fontWeight="600">{`${
                el.count ?? ""
              } `}</Box>
              {singularize(el.message, Number(el.count) ?? 0)}
            </Link>
          </li>
        ))}
      </ul>
      <StatsIllustration className={classes.background} />
    </Card>
  ) : (
    <Box
      component={Card}
      className={classes.root}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      p={2}
    >
      <Box style={{ opacity: 0.5 }} mb={1}>
        <FontAwesomeIcon icon={faListUl} size="2x" />
      </Box>
      <Typography variant="body2">{metadata.defaultMessage}</Typography>
    </Box>
  );
};

export default SummaryElement;
