import React, { FC } from "react";
import Markdown, { Options } from "react-markdown";
import { PluggableList } from "react-markdown/lib/react-markdown";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGemoji from "remark-gemoji";
import remarkGfm from "remark-gfm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& details": {
        marginBottom: theme.spacing(1),
      },
      "& table": {
        display: "block",
        maxWidth: "100%",
        overflow: "auto",
        width: "max-content",
        borderRadius: "2px",
        marginBottom: theme.spacing(2),
      },
      "& th, & td": {
        border: "1px solid lightgray",
        padding: "6px 13px",
      },
      "& pre": {
        marginBottom: 0,
        backgroundColor: theme.palette.lightBackground.dark,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(0.75),
        "& > code": {
          color: "#475261 !important",
        },

        "&.remove-line": {
          zIndex: 2,
          position: "relative",
          paddingTop: 0,
          paddingBottom: 0,
          margin: 0,
          background: "rgb(255, 238, 238)",
          borderRadius: 0,
        },
        "&.add-line": {
          zIndex: 2,
          position: "relative",
          paddingTop: 0,
          paddingBottom: 0,
          margin: 0,
          background: "rgb(230 254 237)",
          borderRadius: 0,
        },
        "&.first": {
          marginTop: -32,
        },
        "&.last": {
          zIndex: 2,
          position: "relative",
          "& + pre": { paddingTop: 0, marginTop: -16, position: "relative" },
        },
      },
      "& p": {
        whiteSpace: "pre-wrap",
      },
      "& pre + h1, h2, h3, h4, h5": {
        marginTop: "16px",
      },
    },
  }),
);

interface ReactMarkdownCustomProps {
  showEmojis?: boolean;
  sanitize?: boolean;
  unescapeHtml?: boolean;
  children?: string;
}

type ReactMarkdownProps = Omit<Options, "children"> & ReactMarkdownCustomProps;

const ReactMarkdown: FC<ReactMarkdownProps> = ({
  className,
  children = "",
  unescapeHtml,
  sanitize = true,
  showEmojis,
  ...props
}) => {
  const classes = useStyles();
  const remarkPlugins = showEmojis ? [remarkGemoji, remarkGfm] : [remarkGfm];
  const rehypePlugins = unescapeHtml
    ? ([rehypeRaw, sanitize ? rehypeSanitize : null].filter(
        (p) => p !== null,
      ) as PluggableList)
    : undefined;

  return (
    <Markdown
      className={classNames(classes.root, className)}
      remarkPlugins={remarkPlugins}
      rehypePlugins={rehypePlugins}
      children={children}
      rawSourcePos
      {...props}
    />
  );
};

export default ReactMarkdown;
