import React, { FC, useMemo, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { LaunchRounded } from "@material-ui/icons";
import classNames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useCurrentOrgId, useHasScope } from "providers";

import IaCRepoIcon from "./IaCRepoIcon";
import InvokeIaCBotModal from "./InvokeIaCBotModal";
import LanguageIcon from "./LanguageIcon";
import RepositoryFindingsSeverity from "./RepositoryFindingsSeverity";
import CircularProgress from "components/CircularProgress/CircularProgress";
import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import LogoBadge from "components/LogoBadge";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import StarCheckbox from "components/StarCheckbox/StarCheckbox";
import { optimisticDatasetUpdater } from "helpers/queryHelpers";
import JobStatusIcon from "pages/IacBot/pages/JobDetail/components/JobStatusIcon";
import repositoryService from "services/repositoryService";
import { Permissions, Resources } from "types/auth-roles";
import { GitRepoJobStatus, Repository } from "types/repositories";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f8f8f8",
      },
      transition: "background-color 0.2s",
    },
    card: {
      overflow: "visible",
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      position: "relative",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      },
    },
    ellipsis: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      wordBreak: "break-all",

      "@supports (-webkit-line-clamp: 2)": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "initial",
        display: "-webkit-box",
        lineClamp: 2,
        boxOrient: "vertical",
      },
    },
    opacity6: {
      opacity: 0.6,
    },
    actions: {
      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(4),
      },
    },
    checkbox: {
      padding: 0,
      marginTop: 0,
      margin: theme.spacing(1),
    },
    disabled: {
      backgroundColor: theme.palette.grey[600],
      color: "white",
      fontWeight: "bolder",
      verticalAlign: "center",
      fontSize: 10,
      width: 58,
      textAlign: "center",
      position: "absolute",
      top: 0,
      left: 0,
    },
  }),
);

interface RepoCardProps {
  repo: Repository;
  maxItems?: number;
  isRunning?: boolean;
}

const RepositoryCard: FC<RepoCardProps> = ({
  repo,
  maxItems,
  isRunning = false,
}) => {
  const classes = useStyles();
  const orgId = useCurrentOrgId();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { repositoryDetailEnabled, iacbotTerraformEnabled } = useFlags();
  const invokeButton = useRef<HTMLButtonElement>(null);
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );
  const itemsWidth = useMemo(
    () => (maxItems && maxItems < 5 ? maxItems * 27 : 137),
    [maxItems],
  );

  const { data: repoAnalysisData } = useQuery(
    ["repo-analysis-data", orgId, repo.gitRepo],
    () => repositoryService.getRepoAnalysisData(orgId, repo.gitRepo),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: iacbotTerraformEnabled,
    },
  );

  const goToDetail = () => navigate(`/repos/details/${repo.gitRepo}/overview`);

  const stopPropagation = (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
    e.stopPropagation();

  const { mutate: toggleFavorite } = useMutation(
    ({ gitRepo, isFavorite }: { gitRepo: string; isFavorite: boolean }) =>
      repositoryService.toggleFavorite(orgId, gitRepo, isFavorite),
    {
      onMutate: ({ gitRepo, isFavorite }) =>
        optimisticDatasetUpdater<Repository, "gitRepo">(
          ["git-repos", orgId],
          { gitRepo },
          { isFavorite },
        ),
      onError: (error, { gitRepo }, rollback: any) => {
        rollback?.();
        toast.error(
          `Something went wrong toggling ${gitRepo} favorite status. Please try again.`,
        );
      },
      onSuccess: ({ updateTs }, { gitRepo }) => {
        optimisticDatasetUpdater<Repository, "gitRepo">(
          ["git-repos", orgId],
          { gitRepo },
          { updateTs },
        );
      },
    },
  );

  const repoDisplay = repo.gitRepoName.split("/").pop();
  const isCodeCommit = repo.gitProvider.toLowerCase() === "codecommit";

  const findingsStats = {
    critical: repo.metrics?.summary.findingsFailCritical,
    high: repo.metrics?.summary.findingsFailHigh,
    medium: repo.metrics?.summary.findingsFailMedium,
    low: repo.metrics?.summary.findingsFailLow,
    info: repo.metrics?.summary.findingsFailInfo,
  };
  const lastRun = repo.metrics?.summary.createTs;

  const actions: DropdownItem[] = [
    {
      label: "View Insights",
      linkTo: `/repos/details/${repo.gitRepo}/overview`,
    },
    {
      hover: !repo.enabled
        ? "Repository should be in enabled state to invoke IaC Bot."
        : !repo.accessible
        ? `Repository is not accessible by IaC Bot Please use the CLI to trigger assessments for the repository.`
        : !hasOrgWriteScope
        ? "Insufficient Permissions"
        : "",
      disabled: !repo.accessible || !hasOrgWriteScope || !repo.enabled,
      label: "Invoke IaC Bot",
      action: () => invokeButton.current?.click(),
    },
    {
      label: "Go to Repository",
      externalLinkTo: repo.gitRepoUrl,
    },
    {
      label: "Go to Settings",
      linkTo: `/repos/details/${encodeURIComponent(repo.gitRepo)}/settings`,
    },
  ];

  return (
    <Card
      className={classNames(
        { [classes.root]: repositoryDetailEnabled },
        classes.card,
      )}
      onClick={repositoryDetailEnabled ? goToDetail : undefined}
    >
      {!repo.enabled && (
        <Tooltip
          title="This repository is excluded from automatic assessments and scheduled jobs."
          arrow
        >
          <Box className={classes.disabled}>DISABLED</Box>
        </Tooltip>
      )}
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6} lg={5}>
          <Box display="flex">
            <Box mr={1}>
              <LogoBadge logo={repo.gitProvider} width={28} />
            </Box>
            <Box
              mr={1}
              title={repo.gitRepoName}
              fontSize={18}
              className={classes.ellipsis}
            >
              {repoDisplay}
            </Box>
            <IconButton
              color="primary"
              component="a"
              size="small"
              href={repo.gitRepoUrl}
              onClick={stopPropagation}
              rel="noreferrer noopener"
              target="_blank"
            >
              <LaunchRounded fontSize="small" />
            </IconButton>
          </Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Box display="flex" flexWrap="wrap" width={itemsWidth}>
                <IaCRepoIcon hasIac={repo.hasIac} />
                <LanguageIcon hasLanguage={repo.hasLanguage} />
              </Box>
            </Grid>
            {isCodeCommit && repo.account && (
              <Grid item>
                <Box fontSize={13} color="gray">
                  Account
                </Box>
                <Box fontSize={15}>{repo.account}</Box>
              </Grid>
            )}
            <Grid item>
              <Box fontSize={13} color="gray">
                Last Run
              </Box>
              <Box fontSize={15} whiteSpace="nowrap">
                <RelativeTimeFormatter dateTs={lastRun} />
              </Box>
            </Grid>
            {repoAnalysisData?.planAnalysis && (
              <Grid item>
                <Box fontSize={13} color="gray">
                  Plan Status
                </Box>
                <Box
                  fontSize={15}
                  display="flex"
                  alignItems="center"
                  whiteSpace="nowrap"
                >
                  <JobStatusIcon
                    status={
                      repoAnalysisData.planAnalysis.toLowerCase() as GitRepoJobStatus
                    }
                    fontSize={13}
                    mr={0.5}
                  />
                  {repoAnalysisData.planAnalysis}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          style={{
            paddingTop: matches ? 0 : undefined,
            marginTop: matches ? -8 : undefined,
          }}
          item
          xs={12}
          md={5}
          lg={6}
        >
          <Box flex={1} display="flex" alignItems="center" minWidth={0}>
            <Box mr={4}>
              <Box>
                <Box fontSize={13} color="gray">
                  Violations
                </Box>
                <Box fontSize={18}>
                  {repo.metrics?.summary.findingsFail ?? "-"}
                </Box>
              </Box>
            </Box>
            <Box fontWeight={500}>
              {repo.defaultBranch && (
                <Box mt={0.5}>
                  <Typography variant="body2" color="textSecondary">
                    <Box component="span" mr={0.5} fontSize={12}>
                      <FontAwesomeIcon icon={faCodeBranch} />
                    </Box>
                    {repo.defaultBranch}
                  </Typography>
                </Box>
              )}
              <Box minHeight={32}>
                <RepositoryFindingsSeverity stats={findingsStats} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item style={{ padding: matches ? 0 : undefined }} md={1}>
          <Box
            className={classes.actions}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {isRunning && (
              <Tooltip arrow title="Running">
                <Box mr={2}>
                  <CircularProgress color="warning" size={24} />
                </Box>
              </Tooltip>
            )}
            <Box
              display="flex"
              flexDirection="column"
              onClick={stopPropagation}
            >
              <StarCheckbox
                className={classes.checkbox}
                onChange={({ target: { checked } }) => {
                  toggleFavorite({
                    gitRepo: repo.gitRepo,
                    isFavorite: checked,
                  });
                }}
                defaultChecked={!!repo.isFavorite}
                size="medium"
              />
              <DropdownActions items={actions} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box display="none">
        <InvokeIaCBotModal
          accessible={repo.accessible}
          gitRepo={repo.gitRepo}
          ref={invokeButton}
        />
      </Box>
    </Card>
  );
};

export default RepositoryCard;
