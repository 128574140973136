import instance from "./axiosInstance";
import { PaginatedResponse, User } from "types";
import { AuthRoles } from "types/auth-roles";

const getUsers = (orgId: string) =>
  instance
    .get<PaginatedResponse<User>>(`/org/${orgId}/users`)
    .then((res) => res.data.data);

const inviteUser = (orgId: string, email: string) =>
  instance.post(`/org/${orgId}/invite`, { email });

const inviteUserOnboarding = (
  orgId: string,
  payload: { email: string; role: AuthRoles },
) => instance.post(`/org/${orgId}/invites`, { ...payload, onboarding: true });

const removeUser = (orgId: string, userId: string) =>
  instance.delete<void>(`org/${orgId}/users/${userId}`);

const activateUser = (orgId: string, userId: string) =>
  instance.post<void>(`org/${orgId}/users/${userId}`, { status: "active" });

const disableUser = (orgId: string, userId: string) =>
  instance.post<void>(`org/${orgId}/users/${userId}`, { status: "disabled" });

const updateUserRole = (
  orgId: string,
  userId: string,
  { role }: { role: AuthRoles },
) => instance.post<void>(`org/${orgId}/users/${userId}`, { role });

export default {
  getUsers,
  inviteUser,
  inviteUserOnboarding,
  removeUser,
  activateUser,
  disableUser,
  updateUserRole,
};
