export interface AwsAccount {
  account: string;
  lastScanEndTs?: string;
  lastScanStartTs?: string;
  lastScanSuccessTs?: string;
  name: string;
  nameFromOrg: string;
  orgAccount: string;
  orgAccountArn: string;
  orgEmail: string;
  orgJoinedMethod: string;
  orgJoinedTs: string;
  orgStatus: string;
  updateTs: string;
  status: AwsAccountStatus;
}

export enum AwsAccountStatus {
  Ok = "ok",
  Error = "error",
}

export interface AwsAccountStatusDetail {
  orgId: string;
  awsAccount: string;
  assumeRoleArn: string;
  externalId: string;
  getCallerIdentity: GetCallerIdentity;
  status: AwsAccountStatus;
}
export interface GetCallerIdentity {
  account?: string;
  arn?: string;
  error?: string;
  userId?: string;
}

export interface JiraIssue {
  key: string;
  issueUrl: string;
  statusName: string;
  reporterDisplayName: string;
  reporterEmailAddress: string;
  creatorDisplayName: string;
  creatorEmailAddress: string;
  createTs: string;
  updateTs: string;
}

export interface JiraProject {
  instance: string;
  id: string;
  key: string;
  name: string;
  baseUrl: string;
}

export interface IntegrationTestResult {
  status: "unknown" | "ok" | "fail";
  message: string;
}
