import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons";
import {
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Card, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Launch, StarBorderRounded, StarRounded } from "@material-ui/icons";
import { upperFirst } from "lodash";

import {
  useCurrentOrgId,
  useHasScope,
  useIsOnPremIacbotEnabled,
} from "providers";

import IaCRepoIcon from "./components/IaCRepoIcon";
import LanguageIcon from "./components/LanguageIcon";
import RepositoryCard from "./components/RepositoryCard";
import TriggerRediscoveryButton from "./components/TriggerRediscoveryButton";
import { ReactComponent as Empty } from "assets/svg/illustrations/empty-notes.svg";
import DefaultTablePagination from "components/DataTable/components/DefaultTablePagination";
import SearchBar from "components/DataTable/components/SearchBar";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import PageHeader from "components/PageHeader";
import SvgIcon from "components/SvgIcon/SvgIcon";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import { sortBool } from "helpers/tableHelpers";
import { useQueryState } from "hooks/useQueryState";
import iacBotService from "services/iacBotService";
import repositoryService from "services/repositoryService";
import { Permissions, Resources } from "types/auth-roles";
import { Repository } from "types/repositories";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationRoot: {
      display: "flex",
      justifyContent: "center",
    },
    paginationItem: {
      "& > li > button": { backgroundColor: "white", border: 0 },
    },
    search: {
      marginLeft: 0,
    },
    empty: {
      width: 450,
    },
    formControl: {
      minWidth: 120,
    },
    toggleSort: {
      borderRight: 0,
      height: 40,
      borderRadius: 0,
      minWidth: 0,
      width: 40,
    },
  }),
);

const columns: Column<Repository>[] = [
  {
    Header: "Name",
    accessor: "gitRepoName",
  },
  {
    Header: "Repo URL",
    accessor: "gitRepo",
  },
  {
    Header: "Favorite",
    accessor: "isFavorite",
    filter: (rows, _, filterValue) =>
      filterValue === ""
        ? rows
        : filterValue
        ? rows.filter((r) => r.original.isFavorite)
        : rows.filter((r) => !r.original.isFavorite),
    sortInverted: true,
    sortType: sortBool,
  },
  {
    Header: "hasIac",
    accessor: "hasIac",
    filter: "includesSome",
  },
  {
    Header: "hasLanguage",
    accessor: "hasLanguage",
    filter: "includesSome",
  },
  {
    Header: "findingsCount",
    accessor: "findingsCount" as any,
  },
  {
    Header: "enabled",
    accessor: "enabled",
    filter: "equals",
    sortType: sortBool,
  },
];
export interface RepoQueryState {
  page: number;
  query: string;
  hasIac?: string;
  hasLanguage?: string;
  sortBy?: string;
  desc?: boolean;
  enabled?: boolean;
  isFavorite?: boolean;
}

const defaultQuery: Repository[] = [];

const Repositories: FC = () => {
  const classes = useStyles();
  const orgId = useCurrentOrgId();
  const isOnPremIacbotEnabled = useIsOnPremIacbotEnabled();
  const [runningCount, setRunningCount] = useState(0);
  const hasOrgOwnerScope = useHasScope(
    Resources.Organization,
    Permissions.Owner,
  );
  const {
    state: {
      page = 1,
      query = "",
      sortBy = "isFavorite",
      desc = true,
      enabled,
    },
    setQueryState,
  } = useQueryState<RepoQueryState>("git-repos");

  const {
    data = defaultQuery,
    isLoading,
    isSuccess,
  } = useQuery(["git-repos", orgId], () => repositoryService.getRepos(orgId), {
    onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    refetchOnWindowFocus: false,
  });

  const { data: runningRepos = [] } = useQuery(
    ["git-repos-running", orgId],
    () => iacBotService.getIacBotRunningRepos(orgId),
    {
      enabled: isSuccess,
      onSuccess: (d) => setRunningCount(d.length),
      refetchInterval: runningCount > 0 ? 3000 : false,
    },
  );

  const { data: url } = useQuery(
    ["git-repos-manage-url", orgId],
    () => repositoryService.getManageRepoUrl(orgId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: hasOrgOwnerScope && isSuccess,
    },
  );

  const mappedRunningRepos = useMemo(() => {
    let values: Record<string, boolean> = {};
    runningRepos.forEach(
      (r) =>
        (values = {
          ...values,
          [r.repo]: r.running,
        }),
    );
    return values;
  }, [runningRepos]);

  const reposData = useMemo(
    () =>
      data.map((r) => ({
        ...r,
        findingsCount: r.metrics?.summary.findingsFail ?? 0,
      })),
    [data],
  );

  const sortByMEMO = useMemo(
    () => [{ id: sortBy, desc }, { id: "enabled" }],
    [desc, sortBy],
  );

  const tableInstance = useTable(
    {
      data: reposData,
      columns,
      initialState: {
        pageSize: 10,
        globalFilter: query,
        pageIndex: page - 1,
        sortBy: sortByMEMO,
      },
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    page: repos,
    filteredRows,
    setGlobalFilter,
    setSortBy,
    gotoPage,
    state,
  } = tableInstance;

  const tableRef = useRef<DataTableRef<Repository>>({
    tableInstance,
  });

  const handlePageChange = (_: unknown, newPage: number) => {
    gotoPage(newPage);
    setQueryState({ page: newPage === 0 ? undefined : newPage + 1 });
  };

  const handleSearchChange = useCallback(
    (filterValue: string) => {
      setGlobalFilter(filterValue);
      setQueryState({ query: filterValue, page: undefined });
      gotoPage(0);
    },
    [setGlobalFilter, gotoPage, setQueryState],
  );

  const languages = useMemo(
    () => [
      ...new Set(
        data
          .map((r) => r.hasLanguage)
          .flat()
          .sort((a, b) => (a < b ? -1 : 1)),
      ),
    ],
    [data],
  );

  const iac = useMemo(
    () => [
      ...new Set(
        data
          .map((r) => r.hasIac)
          .flat()
          .sort((a, b) => (a < b ? -1 : 1)),
      ),
    ],
    [data],
  );

  const maxIconsLength = useMemo(
    () =>
      Math.max(
        ...repos.map(
          (r) => r.original.hasIac.length + r.original.hasLanguage.length,
        ),
      ),
    [repos],
  );

  useEffect(() => {
    setSortBy(sortByMEMO);
  }, [setSortBy, sortByMEMO]);

  return (
    <Fragment>
      <PageHeader mb={1.5}>
        {enabled === false ? "Disabled " : ""}Repositories
      </PageHeader>
      {(data.length !== 0 || isLoading) && (
        <Box
          display="flex"
          mb={1.5}
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box display="flex" alignItems="center">
              <SearchBar
                className={classes.search}
                disabled={isLoading}
                onChange={handleSearchChange}
                initialValue={query}
                color="primary"
              />
            </Box>
            <Box display="flex" alignItems="center">
              {!isLoading && (
                <FiltersModal
                  twoWay
                  tableRef={tableRef}
                  fields={[
                    {
                      displayName: "Favorite",
                      key: "isFavorite",
                      type: "select",
                      displayEmptyOption: true,
                      renderValue: (val) =>
                        val ? (
                          <Fragment>
                            <Box component={StarRounded} color="gold" mr={2} />
                            Favorite
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Box
                              component={StarBorderRounded}
                              color="gold"
                              mr={2}
                            />
                            Not Favorite
                          </Fragment>
                        ),
                      values: [
                        {
                          displayName: "Favorite",
                          value: true,
                        },
                        {
                          displayName: "Non-favorite",
                          value: false,
                        },
                      ],
                    },
                    {
                      displayName: "IaC",
                      key: "hasIac",
                      type: "multiselect",
                      values: iac.map((s) => ({ displayName: s, value: s })),
                      renderValue: (val) => (
                        <Box display="inline-flex" alignItems="center">
                          <IaCRepoIcon hasIac={[val].flat()} />
                          <Box ml={0.5}>{upperFirst(val)}</Box>
                        </Box>
                      ),
                    },

                    {
                      displayName: "Language",
                      key: "hasLanguage",
                      type: "multiselect",
                      values: languages.map((l) => ({
                        displayName: l,
                        value: l,
                      })),
                      renderValue: (val) => (
                        <Box display="inline-flex" alignItems="center">
                          <LanguageIcon hasLanguage={[val].flat()} />
                          <Box ml={0.5}>{upperFirst(val)}</Box>
                        </Box>
                      ),
                    },
                    {
                      displayName: "Status",
                      key: "enabled",
                      type: "select",
                      renderValue: (v) => (v ? "Enabled" : "Disabled"),
                      values: [
                        {
                          displayName: "Enabled",
                          value: true,
                        },
                        {
                          displayName: "Disabled",
                          value: false,
                        },
                      ],
                    },
                  ]}
                  title="Filter Repositories"
                  persistInUrl
                />
              )}
              <TriggerRediscoveryButton />
              {url?.appUrl && hasOrgOwnerScope && !isOnPremIacbotEnabled && (
                <Box mx={1}>
                  <Button
                    component="a"
                    color="primary"
                    variant="outlined"
                    href={url.appUrl ?? ""}
                    disabled={isLoading}
                    target="_blank"
                    rel="noopener noreferrer"
                    endIcon={<Launch />}
                  >
                    Manage Repositories
                  </Button>
                </Box>
              )}
              <Box ml={1}>
                <Button
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={faGitAlt} />}
                  onClick={() => {
                    tableRef.current.tableInstance.gotoPage(0);
                    setQueryState(
                      { enabled: enabled === false ? undefined : false },
                      { replace: true },
                    );
                  }}
                >
                  View {enabled === false ? "All" : "Disabled"}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box
              ml={1}
              display="flex"
              flexWrap="nowrap"
              bgcolor="white"
              height={40}
            >
              <Tooltip title="Reverse sort order" arrow>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setQueryState({ desc: !desc });
                  }}
                  className={classes.toggleSort}
                >
                  {desc ? (
                    <FontAwesomeIcon icon={faSortAmountUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortAmountDown} />
                  )}
                </Button>
              </Tooltip>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <InputLabel id="sort-by-label">Sort By</InputLabel>
                <Select
                  value={sortBy}
                  onChange={(val) => {
                    setQueryState({ sortBy: val.target.value + "" });
                  }}
                  labelId="sort-by-label"
                  id="sort-by"
                  label="Sort By"
                >
                  <MenuItem value="isFavorite">Favorite</MenuItem>
                  <MenuItem value="findingsCount">Findings</MenuItem>
                  <MenuItem value="gitRepoName">Name</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <DefaultTablePagination
              pageIndex={state.pageIndex}
              count={filteredRows.length}
              onChangePage={handlePageChange}
              pageSize={10}
              pageSizeOptions={[]}
            />
          </Box>
        </Box>
      )}
      <Grid container spacing={2}>
        {isLoading
          ? [...Array(10)].map((_e, i) => (
              <Grid key={i} item xs={12}>
                <Box component={Card} p={3}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={7} md={6} lg={5}>
                      <Skeleton width="65%" />
                      <Skeleton width="80%" />
                    </Grid>
                    <Grid item xs={12} sm={3} md={4} lg={5}>
                      <Skeleton width="80%" />
                    </Grid>
                    <Grid item xs={12} sm={2} lg={2}>
                      <Skeleton width="40%" />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))
          : repos.map((repo) => (
              <Grid key={repo.original.gitRepo} item xs={12}>
                <RepositoryCard
                  maxItems={maxIconsLength}
                  repo={repo.original}
                  isRunning={!!mappedRunningRepos?.[repo.original.gitRepo]}
                />
              </Grid>
            ))}
        {!isLoading &&
          (data.length === 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              textAlign="center"
              my={3}
            >
              <Empty className={classes.empty} />
              <TriggerRediscoveryButton />
              <Typography variant="subtitle1" gutterBottom>
                No repositories found.
              </Typography>
              {url?.appUrl && hasOrgOwnerScope && !isOnPremIacbotEnabled && (
                <Box my={1}>
                  <Button
                    component="a"
                    color="primary"
                    variant="outlined"
                    href={url.appUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Manage Repositories
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            repos.length === 0 && (
              <Box width="100%" textAlign="center">
                <SvgIcon
                  width={80}
                  height={80}
                  color="lightgray"
                  icon="gitRepos"
                />
                <Typography align="center">
                  There are no repositories that match the current query.
                </Typography>
              </Box>
            )
          ))}
      </Grid>
      <Box mt={3}>
        <DefaultTablePagination
          pageIndex={state.pageIndex}
          count={filteredRows.length}
          onChangePage={handlePageChange}
          pageSize={10}
          pageSizeOptions={[]}
        />
      </Box>
    </Fragment>
  );
};

export default Repositories;
