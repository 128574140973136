import React, { FC, useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Collapse } from "@material-ui/core";
import List from "@material-ui/core/List";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import classNames from "classnames";

import { useRoutes } from "components/AppSidebar/routes";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { Route } from "types";

import styles from "./AppMenu.module.scss";

interface AppMenuProps {
  isCollapsed?: boolean;
  onClick?(): void;
}

const AppMenu: FC<AppMenuProps> = ({ isCollapsed, onClick }) => {
  const routes = useRoutes();
  const [subMenuOpen, setSubMenuOpen] = useState<number | null>(null);
  const toggleOpened = useCallback(
    (index: number | null) => {
      setSubMenuOpen(subMenuOpen === index ? null : index);
    },
    [setSubMenuOpen, subMenuOpen],
  );

  const closeCollapse = useCallback(() => setSubMenuOpen(null), []);

  useEffect(() => {
    if (isCollapsed) closeCollapse();
  }, [closeCollapse, isCollapsed]);

  useEffect(() => {
    if (isCollapsed) return;
    let openedDropdown: null | number = null;
    routes.forEach((route, index) => {
      if (window.location.pathname.indexOf(route.to) === 0)
        openedDropdown = index;
    });
    setSubMenuOpen(openedDropdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List className={styles.root} component="nav" disablePadding>
      {routes.map((item, index) => {
        const open = index === subMenuOpen;
        return item.children ? (
          <div
            className={classNames(styles.itemParent, {
              [styles.itemCollapse]: isCollapsed,
            })}
            key={index}
            onPointerEnter={isCollapsed ? () => toggleOpened(index) : undefined}
            onPointerLeave={isCollapsed ? () => toggleOpened(null) : undefined}
          >
            <div
              className={classNames(styles.item, {
                [styles.itemCollapse]: isCollapsed && !open,
              })}
              onClick={isCollapsed ? undefined : () => toggleOpened(index)}
            >
              <span className={styles.itemLabel}>
                {item.icon && (
                  <div
                    className={classNames(styles.itemIcon, {
                      [styles.itemIconCollapse]: isCollapsed,
                    })}
                  >
                    <SvgIcon icon={item.icon} />
                  </div>
                )}
                <span
                  className={classNames({
                    [styles.labelCollapse]: isCollapsed,
                  })}
                >
                  {item.name}
                </span>
              </span>
              <span
                className={classNames(styles.expandIcon, {
                  [styles.expandIconCollapse]: isCollapsed,
                })}
              >
                {!open && <IconExpandMore />}
                {open && <IconExpandLess />}
              </span>
            </div>
            <Collapse
              in={open}
              timeout={200}
              classes={{
                container: styles.itemInnerWrapper,
              }}
            >
              {item.children.map((innerItem, innerIndex) => (
                <NavItem
                  onClick={onClick}
                  isCollapsed={isCollapsed}
                  inner
                  key={innerIndex}
                  route={innerItem}
                />
              ))}
            </Collapse>
          </div>
        ) : (
          <NavItem
            isCollapsed={isCollapsed}
            key={index}
            onClick={() => {
              onClick?.();
              toggleOpened(null);
            }}
            route={item}
          />
        );
      })}
    </List>
  );
};

interface NavItemProps {
  onClick?(): void;
  route: Route;
  inner?: boolean;
  isCollapsed?: boolean;
}

const NavItem: FC<NavItemProps> = ({ onClick, route, inner, isCollapsed }) => {
  return route.isExternal ? (
    <a
      className={classNames(styles.item, {
        [styles.itemCollapse]: isCollapsed,
        [styles.itemInner]: inner,
      })}
      href={route.to}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className={styles.itemLabel}>
        {route.icon && (
          <div
            className={classNames(styles.itemIcon, {
              [styles.itemIconCollapse]: isCollapsed,
            })}
          >
            <SvgIcon icon={route.icon} />
          </div>
        )}
        <span>{route.name}</span>
      </span>
    </a>
  ) : (
    <NavLink
      onClick={onClick}
      className={({ isActive }) =>
        classNames(styles.item, {
          [styles.itemCollapse]: !inner && isCollapsed,
          [styles.itemInner]: inner,
          [styles.itemInnerCollapse]: inner && isCollapsed,
          [styles.itemActive]: isActive,
        })
      }
      to={route.to}
    >
      <span className={styles.itemLabel}>
        {route.icon && (
          <div
            className={classNames(styles.itemIcon, {
              [styles.itemIconCollapse]: isCollapsed,
            })}
          >
            <SvgIcon icon={route.icon} />
          </div>
        )}
        <span>{route.name}</span>
      </span>
    </NavLink>
  );
};

export default AppMenu;
