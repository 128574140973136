import React, { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { Box, Hidden } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import Logo from "assets/svg/logos/lw-logo.svg";
import SocialButtons from "components/SocialButtons/SocialButtons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "34rem",

      [theme.breakpoints.up("lg")]: {
        border: "1px solid lightgray",
        margin: theme.spacing(3),
      },
    },
  }),
);

interface LoginFormProps {
  isNewUser?: boolean;
}

const LoginForm: FC<LoginFormProps> = ({ isNewUser }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} width="100%" py={6} px={5}>
      <Hidden lgUp>
        <Box mb={4} textAlign="center">
          <img width={325} src={Logo} alt="lacework-iac-security-logo" />
        </Box>
      </Hidden>

      <Fragment>
        <Box mb={3}>
          <Box component="h2" mb={6} textAlign="center">
            Infrastructure as Code Security
          </Box>
          <SocialButtons isNewUser={isNewUser} />
        </Box>
        <Box mb={3} textAlign="center">
          <Box mr={1} display="inline-block" color="text.secondary">
            {isNewUser ? "Already have an account?" : "Don't have an account?"}
          </Box>
          {isNewUser ? (
            <Box display="inline-block" color="primary.main" fontWeight="bold">
              <Link to="/">Log in</Link>
            </Box>
          ) : (
            <Box display="inline-block" color="primary.main" fontWeight="bold">
              <Link to="/register"> Create Account</Link>
            </Box>
          )}
        </Box>
      </Fragment>
      <Box textAlign="center">
        <a
          href="mailto:support@lacework.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <small>Need help?</small>
        </a>
      </Box>
    </Box>
  );
};

export default LoginForm;
