import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps, useTheme } from "@material-ui/core";
import { CancelRounded, CheckCircleRounded } from "@material-ui/icons";

import {
  facLoadingCircle,
  facSkipCircle,
} from "assets/svg/icons/custom-fa-icons";
import { GitRepoJobStatus } from "types/repositories";

interface JobStatusIconProps extends BoxProps {
  status: GitRepoJobStatus;
}

const JobStatusIcon: FC<JobStatusIconProps> = ({
  status,
  fontSize,
  ...rest
}) => {
  const theme = useTheme();

  const iconL = {
    completed: (
      <Box
        color={theme.palette.success.main}
        component={CheckCircleRounded}
        fontSize={fontSize + 5}
        margin="-2px"
        {...rest}
      />
    ),
    failed: (
      <Box
        color={theme.palette.error.main}
        fontSize={fontSize + 5}
        margin="-2px"
        component={CancelRounded}
      />
    ),
    running: (
      <Box
        color={theme.palette.warning.main}
        margin="-6px 0"
        fontSize={fontSize}
        {...rest}
      >
        <FontAwesomeIcon icon={facLoadingCircle} spin />
      </Box>
    ),
    skipped: (
      <Box
        color={theme.palette.info.main}
        margin="-6px 0"
        fontSize={fontSize}
        {...rest}
      >
        <FontAwesomeIcon icon={facSkipCircle} />
      </Box>
    ),
    unknown: null,
  };

  return iconL[status] ?? null;
};

export default JobStatusIcon;
