import React, { FC, Fragment, useState } from "react";
import { faBitbucket, faGitlab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { upperFirst } from "lodash";

import { ReactComponent as WorkInProgressIllustration } from "assets/svg/illustrations/work-in-progress.svg";
import { useQueryParams } from "hooks/useQueryParams";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gitlab: {
      backgroundColor: "#C3432A",
      color: "white",
      borderRadius: 7,
    },
    bitbucket: {
      backgroundColor: "#375175",
      color: "white",
      borderRadius: 7,
    },
  }),
);

const ComingSoonProviderModal: FC = () => {
  const { "coming-soon": comingSoon } = useQueryParams<{
    "coming-soon": "gitlab" | "bitbucket" | "github";
  }>();

  const isOpen = comingSoon === "gitlab" || comingSoon === "bitbucket";
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogContent>
        <Box textAlign="center" py={3} maxWidth={500} height={300}>
          <WorkInProgressIllustration width="100%" height="100%" />
        </Box>
        <Typography variant="h3" gutterBottom>
          COMING SOON
        </Typography>
        <Typography variant="body1">
          We're working hard to add support for{" "}
          <Fragment>
            <Box
              component="span"
              p={0.6}
              py={0.3}
              mr={0.5}
              className={
                comingSoon === "gitlab" ? classes.gitlab : classes.bitbucket
              }
            >
              <FontAwesomeIcon
                size="xs"
                icon={comingSoon === "gitlab" ? faGitlab : faBitbucket}
              />
            </Box>
            {upperFirst(comingSoon)}
          </Fragment>
          .
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComingSoonProviderModal;
