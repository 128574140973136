import { createContext } from "react";

import { AssessmentDetail } from "types/assessments";

export const AssessmentDetailsContext = createContext<{
  assessmentDetail?: AssessmentDetail;
  isLoading: boolean;
  isFetched: boolean;
  refetch: () => void;
}>({ isLoading: false, isFetched: false, refetch: () => {} });
