import React, { FC, Fragment, useMemo, useRef } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  createStyles,
  Fade,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";

import AccessTokensDataTable, {
  AccessTokensDataTableRef,
} from "./components/AccessTokensDataTable";
import AgentTokensDataTable from "./components/AgentTokensDataTable";
import CreateAccessTokenModal, {
  CreateAccessTokenModalRef,
} from "./components/CreateAccessTokenModal";
import PageHeader from "components/PageHeader";
import { TokensTabs } from "types/tabs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabLink: {
      "&:hover, &:focus, &:visited, &:link, &:active": {
        textDecoration: "none",
      },
    },
  }),
);

const Tokens: FC = () => {
  const { tokenType = "access" } = useParams<{ tokenType: string }>();
  const classes = useStyles();
  const accessTokenRef = useRef<AccessTokensDataTableRef>(null);
  const createAccessTokenModalRef = useRef<CreateAccessTokenModalRef>(null);

  const openCreateAccessTokenModal = () => {
    createAccessTokenModalRef.current?.open();
  };

  const refreshAccessTokenData = () => {
    accessTokenRef.current?.refreshAccessTokenData();
  };

  const isTabValid = useMemo(
    () =>
      tokenType === TokensTabs.AccessTokens ||
      tokenType === TokensTabs.AgentTokens,
    [tokenType],
  );

  if (!isTabValid) {
    return <Navigate to="/admin/tokens/access" />;
  }

  return (
    <Fragment>
      <PageHeader>Tokens</PageHeader>
      <Card>
        <Box p={2}>
          <Tabs value={tokenType} indicatorColor="primary" textColor="primary">
            <Tab
              className={classes.tabLink}
              component={Link}
              to="/admin/tokens/access"
              value={TokensTabs.AccessTokens}
              label="ACCESS TOKENS"
            />
            <Tab
              className={classes.tabLink}
              component={Link}
              to="/admin/tokens/agent"
              value={TokensTabs.AgentTokens}
              label="AGENT TOKENS"
            />
            <Fade in={tokenType === TokensTabs.AccessTokens}>
              <Box ml="auto" alignSelf="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openCreateAccessTokenModal}
                >
                  Generate Access Token
                </Button>
              </Box>
            </Fade>
          </Tabs>
          <Box component="hr" mt={0} mb={1} />
          <TabContext value={tokenType}>
            <TabPanel value={TokensTabs.AccessTokens}>
              <AccessTokensDataTable
                isActive={tokenType === TokensTabs.AccessTokens}
                ref={accessTokenRef}
              />
            </TabPanel>
            <TabPanel value={TokensTabs.AgentTokens}>
              <AgentTokensDataTable
                isActive={tokenType === TokensTabs.AgentTokens}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
      <CreateAccessTokenModal
        ref={createAccessTokenModalRef}
        onRefreshTable={refreshAccessTokenData}
      />
    </Fragment>
  );
};

export default Tokens;
