import React, {
  forwardRef,
  RefForwardingComponent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import validator from "validator";

import { useCurrentOrgId } from "providers";

import CollapseCodeSnipped from "components/CollapseCodeSnipped";
import { normalizeFormError } from "helpers/normalizeFormErrors";
import integrationService from "services/integrationService";

export interface AddAwsAccountModalRef {
  open(): void;
  close(): void;
}

interface AddAwsAccountModalProps {
  onSubmit(form: AddAwsAccountForm): void;
  onClosed(): void;
  error?: string;
  defaultValue?: AddAwsAccountForm;
}

export interface AddAwsAccountForm {
  account: string;
}

interface AddAwsAccountModal
  extends RefForwardingComponent<
    AddAwsAccountModalRef,
    AddAwsAccountModalProps
  > {}

const AddAwsAccountModal: AddAwsAccountModal = (
  { onSubmit, onClosed, error, defaultValue },
  ref,
) => {
  const orgId = useCurrentOrgId();
  const [isOpen, setIsOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const isConnecting = !!defaultValue;
  const { register, handleSubmit, errors, reset, formState } =
    useForm<AddAwsAccountForm>({
      mode: "onChange",
    });

  const { data, isLoading } = useQuery(
    ["users", orgId],
    () => integrationService.getAwsSetupScript(orgId),
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    reset(defaultValue);
  }, [reset, defaultValue]);

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    setIsAlertOpen(!!error);
  }, [error]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleClosed = useCallback(() => {
    onClosed();
    reset({});
  }, [reset, onClosed]);

  return (
    <Dialog open={isOpen} onExited={handleClosed} maxWidth="xs" fullWidth>
      <DialogTitle>
        {isConnecting ? "Connect Account" : "Add Account"}
      </DialogTitle>
      <DialogContent>
        <Collapse in={isAlertOpen}>
          <Box mb={2}>
            <Alert style={{ borderRadius: 0 }} severity="error">
              {error}
            </Alert>
          </Box>
        </Collapse>
        <Box component="h6">Terminal Activation</Box>
        <p>Copy the commands below to run in your terminal</p>

        <CollapseCodeSnipped>
          {isLoading ? (
            <Box textAlign="center" p={4}>
              <CircularProgress />
            </Box>
          ) : (
            data
          )}
        </CollapseCodeSnipped>
        <Box
          component="form"
          id="aws-account"
          mt={2}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            variant="outlined"
            color="primary"
            fullWidth
            id="account"
            label="AWS Account Number"
            disabled={isConnecting}
            name="account"
            inputRef={register({
              required: "Please enter an AWS account number",
              maxLength: {
                value: 12,
                message: "The AWS account number should be 12 numbers long",
              },
              minLength: {
                value: 12,
                message: "The AWS account number should be 12 numbers long",
              },
              validate: (value) =>
                validator.isNumeric(value ?? "") ||
                "Please enter a valid AWS account number.",
            })}
          />
          <Fade in={!!errors.account}>
            <Box component="small" color="error.main" pb={1}>
              {normalizeFormError(errors.account)}
            </Box>
          </Fade>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          form="aws-account"
          disabled={!formState.isValid}
          variant="contained"
        >
          Test Connection
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(AddAwsAccountModal);
