import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";

import ErrorCard from "./components/ErrorCard";
import { ReactComponent as ErrorIllustration } from "assets/svg/illustrations/error.svg";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import { useQueryParams } from "hooks/useQueryParams";

const ErrorPage: FC = () => {
  const {
    title = "Something went wrong",
    message = "Please try again later",
    url = "/",
  } = useQueryParams();
  const navigate = useNavigate();
  const action = () => navigate(url);

  return (
    <NavbarTopLayout white>
      <Grid container alignItems="center" justify="center">
        <Grid item sm={10} md={8} lg={6} xl={4}>
          <Box mb={4} mt={5} textAlign="center">
            <ErrorIllustration width={360} height={250} />
          </Box>
          <ErrorCard
            title={title}
            message={message}
            onAction={action}
            delay={10}
          />
        </Grid>
      </Grid>
    </NavbarTopLayout>
  );
};

export default ErrorPage;
