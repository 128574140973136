import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    textNoWrap: {
      whiteSpace: "nowrap",
    },
    cursorPointer: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    tabLink: {
      "&:hover, &:focus, &:visited, &:link, &:active": {
        textDecoration: "none",
      },
    },
    tabPanel: {
      padding: "0 !important",
      width: "100%",
    },
    wordBreakAll: {
      wordBreak: "break-all",
    },
    hoverPointer: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    textCapitalize: {
      textTransform: "capitalize",
    },
    textUpper: {
      textTransform: "uppercase",
    },
    textBreak: {
      wordBreak: "break-word",
      overflow: "break-word",
    },
  }),
);

export default useCommonStyles;
