import axios from "./axiosInstance";
import {
  APIResponse,
  Organization,
  OrganizationActivity,
  OrganizationDescription,
  OrgConfig,
} from "types";
import { AuthConfig } from "types/auth-config";

const getOrganizations = () =>
  axios
    .get<APIResponse<{ organizations: OrganizationDescription[] }>>(
      `/internal/orgs`,
    )
    .then((res) => res.data.data.organizations);

const getOrganization = (orgId: string) =>
  axios
    .get<APIResponse<Organization>>(`/org/${orgId}`)
    .then((res) => res.data.data);

const getOrganizationActivity = (orgId: string) =>
  axios
    .get<APIResponse<{ activities: OrganizationActivity[] }>>(
      `/internal/org/${orgId}/activity`,
    )
    .then((res) => res.data.data.activities);

const getAuthConfig = (orgId: string) =>
  axios
    .get<APIResponse<AuthConfig>>(`/org/${orgId}/auth-config`)
    .then((res) => res.data.data);

const getOrgConfig = (orgId: string) =>
  axios
    .get<APIResponse<OrgConfig>>(`/org/${orgId}/config`)
    .then((res) => res.data.data);

const updateOrganizationInfo = (orgId: string, body: Partial<object>) =>
  axios.post<void>(`/org/${orgId}`, body);

const updateOrganizationConfig = (orgId: string, body: Partial<object>) =>
  axios
    .post<APIResponse<OrgConfig>>(`/org/${orgId}/config`, body)
    .then((res) => res.data.data);

const setProviderConfig = (orgId: string, body: Partial<AuthConfig>) =>
  axios.post<void>(`/org/${orgId}/auth-config`, body);

const configOrg = (orgId: string, body: { active: boolean; comment: string }) =>
  axios.post<void>(`/internal/org/${orgId}/config`, body);

export default {
  getOrganization,
  getOrganizations,
  getOrgConfig,
  getOrganizationActivity,
  updateOrganizationInfo,
  updateOrganizationConfig,
  setProviderConfig,
  getAuthConfig,
  configOrg,
};
