import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";
import { Column, Row } from "react-table";
import { toast } from "react-toastify";
import {
  Box,
  Card,
  Drawer,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { PolicyOutlined } from "@material-ui/icons";
import { upperFirst } from "lodash";

import { useCurrentOrgId, useHasScope } from "providers";

import PolicyDrawer from "./components/PolicyDrawer";
import PolicyToggleModal from "./components/PolicyToggleModal";
import ProviderIconLabel from "./components/ProviderIconLabel";
import Badge from "components/BootstrapBadge/BootstrapBadge";
import BootstrapBadge from "components/BootstrapBadge/BootstrapBadge";
import ConfirmationModal, {
  ConfirmationModalRef,
} from "components/ConfirmationModal/ConfirmationModal";
import CopyText from "components/CopyText/CopyText";
import DataTable from "components/DataTable";
import DropdownActions from "components/DataTable/components/DropdownActions";
import SearchBar from "components/DataTable/components/SearchBar";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import PageHeader from "components/PageHeader";
import SeverityComponent, {
  Severity,
} from "components/SeverityComponent/SeverityComponent";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
  optimisticDatasetUpdater,
} from "helpers/queryHelpers";
import {
  handleOnSortToggle,
  sortBool,
  sortSeverity,
} from "helpers/tableHelpers";
import { useQueryState } from "hooks/useQueryState";
import policiesService from "services/policiesService";
import { Permissions, Resources } from "types/auth-roles";
import { Policy } from "types/policies";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      height: "80%",
    },
  }),
);
interface ColumnActions {
  onViewPolicy(policy: Policy): void;
  onTogglePolicy(policy: Policy): void;
  onSeverityChange(policy: Policy, severity: keyof typeof Severity): void;
}

const columnsDef = (
  hasOrgOwnerScope: boolean,
  { onTogglePolicy, onSeverityChange, onViewPolicy }: ColumnActions,
): Column<Policy>[] => [
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ row: { original: policy } }) => (
      <Box display="flex" alignItems="center">
        <Box flex={1}>{policy.title}</Box>
        {policy.custom && (
          <BootstrapBadge color="primary">CUSTOM</BootstrapBadge>
        )}
      </Box>
    ),
  },
  {
    Header: "Severity",
    accessor: "severity",
    filter: "includesSome",
    sortType: sortSeverity,
    collapse: true,
    Cell: ({ row: { original: policy } }) =>
      hasOrgOwnerScope ? (
        <Box onClick={(e) => e.stopPropagation()}>
          <Select
            variant="outlined"
            fullWidth
            onChange={({ target: { value } }) => {
              const severity = value as keyof typeof Severity;
              onSeverityChange(policy, severity);
            }}
            value={policy.severity ? upperFirst(policy.severity) : undefined}
            classes={{ root: "custom-select" }}
            renderValue={(selected) => (
              <SeverityComponent
                severity={Severity[selected as keyof typeof Severity]}
              />
            )}
          >
            <MenuItem value={undefined}>-</MenuItem>
            {(Object.keys(Severity) as (keyof typeof Severity)[]).map(
              (n, i) => (
                <MenuItem value={n} key={i}>
                  <ListItemText
                    primary={
                      <Box mr={2}>
                        <SeverityComponent severity={Severity[n]} />
                      </Box>
                    }
                  />
                </MenuItem>
              ),
            )}
          </Select>
        </Box>
      ) : policy.severity ? (
        <SeverityComponent
          severity={
            Severity[upperFirst(policy.severity) as keyof typeof Severity]
          }
        />
      ) : (
        "-"
      ),
  } as Column<Policy>,
  {
    Header: "Category",
    accessor: "category",
    filter: "includesSome",
    collapse: true,
    Cell: ({ row: { original: policy } }) => (
      <Badge color="secondary">{policy.category}</Badge>
    ),
  },
  {
    Header: "Compliance",
    accessor: "compliance",
    filter: "includesSome",
    collapse: true,
    Cell: ({ row: { original: policy } }) =>
      !policy.compliance.includes("No Compliance") &&
      policy.compliance?.length > 0 && (
        <Box display="flex" flexWrap="wrap" width={200} minWidth={100}>
          {policy.compliance.map((v, i) => (
            <Box display="inline-block" key={i} mr={0.5}>
              <Badge color="info">{v}</Badge>
            </Box>
          ))}
        </Box>
      ),
  },
  {
    Header: "Provider",
    accessor: "provider",
    collapse: true,
    filter: "includesSome",
  },
  {
    Header: "Check Type",
    accessor: "checkType",
    collapse: true,
    filter: "includesSome",
  },
  {
    Header: "Custom",
    accessor: "custom",
    filter: "equals",
  },
  {
    Header: "Enabled",
    accessor: "enabled",
    filter: "equals",
    collapse: true,
    isButton: true,
    sortType: sortBool,
    Cell: ({ row: { original: policy } }) => (
      <Tooltip
        arrow
        title={
          !hasOrgOwnerScope
            ? "Insufficient permissions."
            : policy.enabled
            ? "Disabling the policy suppresses the corresponding findings in all Assessments."
            : "Enabling the policy un-suppresses the corresponding findings in all Assessments."
        }
      >
        <span id={policy.sid}>
          <Switch
            checked={policy.enabled}
            onChange={(event) => {
              onTogglePolicy(policy);
            }}
            disabled={!hasOrgOwnerScope}
            id="policy-toggle-switch"
            name="policy-toggle"
            color="primary"
          />
        </span>
      </Tooltip>
    ),
  },
  {
    Header: "Actions",
    accessor: "updateTs",
    collapse: true,
    Cell: ({ row: { original: policy } }) => (
      <Box onClick={(e) => e.stopPropagation()}>
        <DropdownActions
          items={[
            {
              label: "View",
              action: () => onViewPolicy(policy),
            },
            {
              label: "Show Violations",
              linkTo: `/violations?query=${policy.sid}`,
            },
            {
              label: (
                <CopyText
                  text={`${window.location.origin}/policies?selected=${policy.sid}`}
                >
                  Copy link to policy
                </CopyText>
              ),
            },
          ]}
        />
      </Box>
    ),
  },
];

interface QueryState {
  sortBy: string;
  sortDirection: "asc" | "desc";
  page: number;
  pageSize: number;
  query: string;
  e: any;
  selected: string;
}

const SUCCESS_EMPTY_QUERY =
  "This query ran successfully and produced no results";
const SUCCESS_EMPTY_REQUEST = "The are no policies yet";
const ERROR_MESSAGE =
  "We couldn't get the policies list. You can try again in a few minutes";

const Policies: FC = () => {
  const orgId = useCurrentOrgId();

  const hasOrgOwnerScope = useHasScope(
    Resources.Organization,
    Permissions.Owner,
  );
  const [noDataMessage, setNoDataMessage] = useState(SUCCESS_EMPTY_REQUEST);
  const [policyDrawer, setPolicyDrawer] = useState<{
    policy?: Policy;
    open: boolean;
  }>({ open: false });
  const [processingPolicy, setProcessingPolicy] = useState<Policy>();
  const confirmationModalRef = useRef<ConfirmationModalRef>(null);
  const tableRef = useRef<DataTableRef<Policy>>(null);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { state, setQueryState } = useQueryState<QueryState>("policies");
  const {
    sortBy,
    sortDirection,
    page = 1,
    pageSize = 50,
    query = "",
    e = false,
    selected,
  } = state;

  const {
    data: policies = [],
    isSuccess,
    isLoading,
    refetch,
  } = useQuery(
    ["policies", orgId, query],
    () => policiesService.getPolicies(orgId, query),
    {
      onError(error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
        setNoDataMessage(ERROR_MESSAGE);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const modifiedPolicies = useMemo(
    () =>
      policies.map((p) => ({
        ...p,
        compliance: p.compliance?.length ? p.compliance : ["No Compliance"],
        custom: !!p.custom,
        checkType: [p.checkType ?? ""].flat(),
      })),
    [policies],
  );

  const handleSubmit = useCallback(
    (filterText: string) => {
      setQueryState({ query: filterText, page: 1, e: undefined });
      refetch();
    },
    [refetch, setQueryState],
  );

  const handleOnSortChange = useCallback(
    (id: string, isSorted: boolean, isSortDesc?: boolean) => {
      setQueryState(handleOnSortToggle(id, isSorted, isSortDesc));
    },
    [setQueryState],
  );

  const { mutate: handleOnSeverityChange } = useMutation(
    ({
      policy: { sid },
      severity,
    }: {
      policy: Policy;
      severity: keyof typeof Severity;
    }) => policiesService.configPolicies({ orgId, sid }, { severity }),
    {
      onMutate: ({ policy: { sid }, severity }) => {
        return {
          rollback: optimisticDatasetUpdater<Policy, "sid">(
            ["policies", orgId, query],
            { sid },
            { severity },
          ),
        };
      },
      onError: (_, _p, rollback: any) => {
        rollback?.();
        toast.error(
          `Something went wrong changing the severity of the Policy. Please try again.`,
        );
      },
      onSuccess: ({ data: { updateTs } }, { policy: { sid } }) => {
        optimisticDatasetUpdater<Policy, "sid">(
          ["policies", orgId, query],
          { sid },
          { updateTs },
        );
      },
    },
  );

  const firstExpanded = useMemo(
    () => (e === "" || !!e ? { "0": true } : undefined),
    [e],
  );

  const providers = useMemo(
    () =>
      [
        ...new Set(
          policies.map((p) => p.provider).sort((a, b) => (a < b ? -1 : 1)),
        ),
      ].filter((p) => !!p),
    [policies],
  );

  const checkTypes = useMemo(
    () =>
      [
        ...new Set(
          policies
            .map((p) => p.checkType)
            .flat()
            .filter((c) => c !== "undefined" && c !== "")
            .sort((a, b) => (a < b ? -1 : 1)),
        ),
      ].filter((c) => !!c),
    [policies],
  );

  const compliances = useMemo(
    () =>
      [
        ...new Set(
          policies
            .map((p) => p.compliance)
            .filter((c) => c)
            .flat()
            .sort((a, b) => (a < b ? -1 : 1)),
        ),
      ].filter((c) => !!c),
    [policies],
  );

  const categories = useMemo(
    () =>
      [
        ...new Set(
          policies
            .map((c) => c.category + "")
            .filter((c) => c !== "undefined" && c !== "")
            .sort((a, b) => (a < b ? -1 : 1)),
        ),
      ].filter((c) => !!c),

    [policies],
  );

  const onSeverityChange = useCallback(
    (policy: Policy, severity: keyof typeof Severity) => {
      confirmationModalRef.current?.openModal({
        title: "Update Policy Severity",
        description: (
          <Box>
            <Box p={2} mb={2} bgcolor="lightBackground.main" borderRadius={4}>
              {policy.title}
            </Box>
            <Box mt={1}>
              Are you sure you want to change the severity of this Policy from{" "}
              <SeverityComponent severity={Severity[policy.severity]} /> to{" "}
              <SeverityComponent severity={Severity[severity]} /> ?
            </Box>
          </Box>
        ),
        action: () => {
          handleOnSeverityChange({ policy, severity });
        },
        confirm: "Confirm",
        cancel: "Cancel",
      });
    },
    [handleOnSeverityChange],
  );

  const onTogglePolicy = useCallback(
    (policy: Policy) => setProcessingPolicy(policy),
    [],
  );
  const policyExtendedProps = useMemo(() => {
    const rowId =
      tableRef.current?.tableInstance.filteredRows.findIndex(
        (r) => r.original.sid === policyDrawer.policy?.sid,
      ) ?? 0;
    const total = tableRef.current?.tableInstance.filteredRows.length ?? 0;
    return {
      rowId,
      total,
    };
  }, [policyDrawer]);

  const onSelectPolicy = useCallback((rowId: number) => {
    tableRef.current?.tableInstance.toggleAllRowsSelected(false);
    tableRef.current?.tableInstance.toggleRowSelected(
      tableRef.current.tableInstance.filteredRows[rowId].id,
    );
  }, []);

  const scrollIntoView = useCallback((id?: string, next?: boolean) => {
    if (!id) return;
    const yOffset = -200;
    const element = document.getElementById(id);
    if (!element) {
      window.scrollTo(
        next
          ? { top: 0, behavior: "smooth" }
          : { top: document.body.scrollHeight, behavior: "smooth" },
      );
      return;
    }
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }, []);

  const onPolicyDrawerNextClick = useCallback(async () => {
    if (policyExtendedProps.rowId === policyExtendedProps.total - 1) {
      return;
    }
    await setPolicyDrawer((prev) => {
      const policy =
        tableRef.current?.tableInstance.filteredRows[
          policyExtendedProps.rowId + 1
        ].original ?? prev.policy;
      scrollIntoView(policy?.sid, true);
      return {
        open: true,
        policy,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyExtendedProps.rowId, policyExtendedProps.total, scrollIntoView]);

  const onPolicyDrawerPreviousClick = useCallback(() => {
    if (policyExtendedProps.rowId === 0) {
      return;
    }
    setPolicyDrawer((prev) => {
      const policy =
        tableRef.current?.tableInstance.filteredRows[
          policyExtendedProps.rowId - 1
        ].original ?? prev.policy;
      scrollIntoView(policy?.sid);
      return {
        open: true,
        policy,
      };
    });
  }, [policyExtendedProps.rowId, scrollIntoView]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowRight":
          onPolicyDrawerNextClick();
          break;
        case "ArrowLeft":
          onPolicyDrawerPreviousClick();
          break;
        default:
          break;
      }
    },
    [onPolicyDrawerNextClick, onPolicyDrawerPreviousClick],
  );

  const handleCloseDrawer = useCallback(() => {
    setPolicyDrawer({ policy: undefined, open: false });
    setQueryState({ selected: undefined });
  }, [setQueryState]);

  const handleRowClick = useCallback(
    (row: Row<Policy>) => {
      tableRef.current?.tableInstance.toggleAllRowsSelected(false);
      tableRef.current?.tableInstance.toggleRowSelected(row.id);
      setPolicyDrawer({ open: true, policy: row.original });
      setQueryState({ selected: row.original.sid });
    },
    [setQueryState],
  );

  const columns = useMemo(
    () =>
      columnsDef(hasOrgOwnerScope, {
        onViewPolicy: (policy) => {
          setPolicyDrawer({ open: true, policy });
          setQueryState({ selected: policy.sid });
        },
        onTogglePolicy,
        onSeverityChange,
      }),
    [hasOrgOwnerScope, onSeverityChange, onTogglePolicy, setQueryState],
  );

  // HANDLES INITIAL LOAD AND POLICIES DATA MODIFICATIONS UPDATES

  useEffect(() => {
    if (selected && modifiedPolicies.length > 0)
      setPolicyDrawer(() => {
        const policy = modifiedPolicies.find((p) => p.sid === selected);
        scrollIntoView(policy?.sid);
        return {
          open: !!policy,
          policy,
        };
      });
    else if (firstExpanded && modifiedPolicies.length > 0) {
      setPolicyDrawer(() => ({
        open: true,
        policy: modifiedPolicies[0],
      }));
    }
    if (policyDrawer.open) {
      setPolicyDrawer((prev) => ({
        open: true,
        policy: modifiedPolicies.find((p) => p.sid === prev.policy?.sid),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedPolicies]);

  useEffect(() => {
    if (isSuccess) {
      return;
    }

    setNoDataMessage(query ? SUCCESS_EMPTY_QUERY : SUCCESS_EMPTY_REQUEST);
  }, [query, isSuccess]);

  // HANDLES THE FOLLOWING ON SELECTED POLICY CHANGE:
  // ROW SELECTION STATE
  // PAGE NUMBER
  // SELECTED QUERY PARAM

  useEffect(() => {
    if (!policyDrawer.open) return;

    const rowId =
      tableRef.current?.tableInstance.filteredRows.findIndex(
        (p) => p.original.sid === policyDrawer.policy?.sid,
      ) ?? 0;

    const correctPageIndex = Math.floor(rowId / pageSize) + 1;
    if (page !== correctPageIndex) {
      tableRef.current?.tableInstance.gotoPage(correctPageIndex - 1);

      setQueryState({
        page: correctPageIndex,
        selected: policyDrawer.policy?.sid,
      });
    } else {
      setQueryState({
        selected: policyDrawer.policy?.sid,
      });
    }

    onSelectPolicy(rowId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyDrawer]);

  return (
    <Fragment>
      <PageHeader>Policies</PageHeader>
      <Card>
        <Box p={2}>
          <DataTable
            autoResetFilters={false}
            autoResetGlobalFilter={false}
            columns={columns}
            compact
            data={modifiedPolicies}
            defaultSortField={sortBy as keyof Policy}
            disableSelectActions
            hiddenColumns={["checkType", "category", "provider", "custom"]}
            initialPageIndex={page}
            initialRowsPerPage={pageSize}
            isLoading={isLoading}
            onChangePage={(page) => setQueryState({ page })}
            onChangeRowsPerPage={(pageSize) =>
              setQueryState({ pageSize, page: 1 })
            }
            onRowClick={handleRowClick}
            onSortChange={handleOnSortChange}
            pageSizeOptions={[50, 100, 200]}
            pagination
            searchActions={
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Box display="flex">
                  <SearchBar
                    initialValue={query}
                    onSubmit={handleSubmit}
                    onChange={() => {}}
                    disabled={isLoading}
                  />
                </Box>
                <FiltersModal
                  fields={[
                    {
                      displayName: "Severity",
                      type: "multiselect",
                      key: "severity",
                      renderValue: (val) => (
                        <SeverityComponent
                          severity={Severity[val as keyof typeof Severity]}
                        />
                      ),
                      values: Object.keys(Severity).map((s) => ({
                        displayName: s + "",
                        value: s + "",
                      })),
                    },
                    {
                      displayName: "Compliance",
                      type: "multiselect",
                      key: "compliance",
                      values: [
                        ...compliances.map((c) => ({
                          displayName: c,
                          value: c,
                        })),
                        {
                          displayName: "No Compliance",
                          value: "No Compliance",
                        },
                      ],
                      renderValue: (val) => <Badge color="info">{val}</Badge>,
                    },
                    {
                      displayName: "Category",
                      type: "multiselect",
                      key: "category",
                      values: categories.map((c) => ({
                        displayName: c,
                        value: c,
                      })),
                      renderValue: (val) => (
                        <Badge color="secondary">{val}</Badge>
                      ),
                    },
                    {
                      displayName: "Check Type",
                      type: "multiselect",
                      key: "checkType",
                      values: checkTypes.map((c) => ({
                        displayName: c,
                        value: c,
                      })),
                    },
                    {
                      displayName: "Provider",
                      type: "multiselect",
                      key: "provider",
                      values: providers.map((p) => ({
                        displayName: p,
                        value: p,
                      })),
                      renderValue: (val) => (
                        <ProviderIconLabel provider={val} />
                      ),
                    },
                    {
                      displayName: "Status",
                      type: "select",
                      key: "enabled",
                      values: [
                        { displayName: "Enabled", value: true },
                        { displayName: "Disabled", value: false },
                      ],
                      renderValue: (val) => (
                        <Box display="flex">
                          <Switch
                            checked={val}
                            disabled
                            color="primary"
                            size="small"
                          />
                          <Box ml={1}>{val ? "Enabled" : "Disabled"}</Box>
                        </Box>
                      ),
                    },
                    {
                      displayName: "Source",
                      type: "select",
                      key: "custom",
                      values: [
                        { displayName: "Custom", value: true },
                        { displayName: "Built-In", value: false },
                      ],
                      renderValue: (val) => (
                        <Box display="flex">
                          <BootstrapBadge color="primary">
                            {val ? "CUSTOM" : "BUILT-IN"}
                          </BootstrapBadge>
                        </Box>
                      ),
                    },
                  ]}
                  title="Filter Policies"
                  disabled={isLoading}
                  tableRef={tableRef}
                  persistInUrl
                />
              </Box>
            }
            ref={tableRef}
            rowHover
            sortByDesc={sortDirection === "desc"}
            noDataComponent={
              <Box
                py={4}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ opacity: 0.4 }}
              >
                <Box mb={3}>
                  <Typography variant="h4" gutterBottom>
                    {noDataMessage}
                  </Typography>
                </Box>
                <Box component={PolicyOutlined} fontSize={60} />
              </Box>
            }
          />
        </Box>
      </Card>
      <PolicyToggleModal
        policy={processingPolicy}
        query={query}
        onClose={() => setProcessingPolicy(undefined)}
      />
      <ConfirmationModal ref={confirmationModalRef} />
      <Drawer
        onKeyDown={handleKeyDown as any}
        classes={{ paperAnchorBottom: classes.drawer }}
        anchor={matches ? "bottom" : "right"}
        open={policyDrawer.open}
        onClose={handleCloseDrawer}
      >
        {policyDrawer.policy && (
          <PolicyDrawer
            policy={{ ...policyDrawer.policy, ...policyExtendedProps }}
            handlers={{
              handleOnPolicyToggle: onTogglePolicy,
              handleOnSeverityChange: onSeverityChange,
              handlePolicyDrawerNextClick: onPolicyDrawerNextClick,
              handlePolicyDrawerPreviousClick: onPolicyDrawerPreviousClick,
            }}
          />
        )}
      </Drawer>
    </Fragment>
  );
};

export default Policies;
