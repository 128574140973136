import React, { FC, Fragment, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { ChartDataset } from "chart.js";

import ChartWrapper from "components/ChartWrapper/ChartWrapper";
import { generatePastelColorFromString } from "helpers/colorHelpers";
import {
  DashboardElementMetadata,
  DashboardMultiLineElementData,
} from "types/dashboard";

interface MultiLineChartElementProps {
  metadata: DashboardElementMetadata;
  element?: DashboardMultiLineElementData;
  isLoading: boolean;
}

const MultiLineChartElement: FC<MultiLineChartElementProps> = ({
  metadata,
  element,
  isLoading,
}) => {
  const lineLabels = useMemo(
    () => (element ? (element.metadata.displayHints.xAxis as string[]) : []),
    [element],
  );

  const datasets: ChartDataset[] = useMemo(
    () =>
      element
        ? element.data.map((e, index) => ({
            data: e ?? [],
            label: element?.labels?.[index],
            borderColor: generatePastelColorFromString(
              element?.labels?.[index] ?? "",
            ),
            borderWidth: 3,
            fill: false,
          }))
        : [],
    [element],
  );

  return (
    <Fragment>
      {isLoading ? (
        <Box
          px={2}
          py={1}
          pb={3}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Skeleton width="50%" />
          <Skeleton width="75%" />
          <Skeleton width="43%" />
        </Box>
      ) : datasets?.length && element ? (
        <Box
          height={"100%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={2}
        >
          <ChartWrapper
            height={240}
            type="line"
            data={{
              datasets,
              labels: lineLabels,
            }}
            options={{
              plugins: {
                legend: {
                  position: "right",
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  grid: {
                    drawOnChartArea: false,
                  },
                  ticks: {
                    precision: 0,
                  },
                  min: 0,
                },

                x: {
                  grid: {
                    drawOnChartArea: false,
                  },
                },
              },
            }}
          />
        </Box>
      ) : (
        <Box p={2} textAlign="center">
          <Box mb={1} style={{ opacity: 0.25 }}>
            <FontAwesomeIcon icon={faChartLine} size="2x" />
          </Box>
          <Typography variant="body2">{metadata.defaultMessage}</Typography>
        </Box>
      )}
    </Fragment>
  );
};

export default MultiLineChartElement;
