import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import ConsolidatedAssessmentDetails from "./pages/ConsolidatedAssessmentDetails/ConsolidatedAssessmentDetails";
import Repositories from "./pages/Repositories/Repositories";
import RepositoryParamHandler from "./pages/RepositoryDetail/components/RepositoryParamHandler";
import RepositoryDetail from "./pages/RepositoryDetail/RepositoryDetail";
import RepositorySettings from "./pages/RepositorySettings/RepositorySettings";
import HasFlagRoute from "router/HasFlagRoute";

const RepositoriesRouter: FC<Childless> = () => {
  return (
    <Routes>
      <Route
        path="details/:gitRepoName/assessments"
        element={<ConsolidatedAssessmentDetails />}
      />
      <Route
        path="details/:gitRepoName/assessments/:findingId"
        element={<ConsolidatedAssessmentDetails />}
      />
      <Route
        path="details/:gitRepoName/assessments/:findingId/:tab"
        element={<ConsolidatedAssessmentDetails />}
      />
      <Route
        path="details/:gitRepoName/settings"
        element={<RepositorySettings />}
      />
      <Route
        path="details/:gitRepoName/:rTab"
        element={
          <HasFlagRoute
            flag="repositoryDetailEnabled"
            element={<RepositoryDetail />}
          />
        }
      />

      <Route path="" element={<Repositories />} />
      <Route path="*" element={<RepositoryParamHandler />} />
    </Routes>
  );
};

export default RepositoriesRouter;
