import React, {
  forwardRef,
  Fragment,
  MutableRefObject,
  RefForwardingComponent,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { useCurrentOrgId, useHasScope } from "providers";

import SuppressedFindingRow from "./SuppressedFindingsRow";
import assessmentService from "services/assessmentService";
import policiesService from "services/policiesService";
import { AssessmentDetail, Finding } from "types/assessments";
import { Permissions, Resources } from "types/auth-roles";
import { Violation } from "types/violations";
interface DeleteSuppressionModalProps {
  gitRepo?: string;
  finding?: Finding | Violation;
  assessmentDetail?: AssessmentDetail;
  onClose?(): void;
  refetch?(): void;
  hideButton?: boolean;
  onDeleteSuppression?(): void;
  onMutate(): any;
  ref?: MutableRefObject<HTMLButtonElement>;
}
interface DeleteSuppressionModalForm {
  comment: string;
}

const DeleteSuppressionModal: RefForwardingComponent<
  {},
  DeleteSuppressionModalProps
> = (
  {
    gitRepo,
    finding,
    assessmentDetail,
    onClose,
    refetch,
    hideButton,
    onDeleteSuppression,
    onMutate,
  },
  ref,
) => {
  const orgId = useCurrentOrgId();
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findingM = useMemo(() => finding, [open]);
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );
  const { control, handleSubmit, errors } = useForm<DeleteSuppressionModalForm>(
    {
      defaultValues: { comment: "" },
    },
  );

  const handleClose = () => {
    setOpen(false);
  };

  const { mutate: onUnsuppressFindingPolicyLevel } = useMutation(
    (comment: string) =>
      policiesService.configPolicies(
        { sid: findingM?.sid ?? "", orgId },
        { enabled: true, comment },
      ),
    {
      onMutate,
      onError: (_, _v, rollback: any) => {
        rollback?.();
        toast.error(
          `An error ocurred trying to delete the suppression, please try again.`,
        );
      },
      onSuccess: refetch,
    },
  );

  const { mutate: onUnsuppressFindingResourceLevel } = useMutation(
    (comment: string) =>
      assessmentService.setAssessmentFindingResourceLevelSuppression(
        orgId,
        findingM?.resource ?? "",
        {
          suppressed: false,
          comment,
        },
      ),
    {
      onMutate,
      onError: (_, _v, rollback: any) => {
        rollback?.();
        toast.error(
          `An error ocurred trying to delete the suppression, please try again.`,
        );
      },
      onSuccess: refetch,
    },
  );

  const { mutate: onUnsuppressFindingRepoLevel } = useMutation(
    (comment: string) => {
      const repoName = gitRepo ?? assessmentDetail?.gitRepo ?? "";
      const assessmentId =
        assessmentDetail?.assessmentId ?? findingM?.assessmentId ?? "";

      return assessmentService.setAssessmentFindingRepoLevelSuppression(
        orgId,
        assessmentId,
        findingM?.id ?? "",
        {
          gitRepo: repoName,
          suppressed: false,
          comment,
        },
      );
    },
    {
      onMutate,
      onError: (_, _v, rollback: any) => {
        rollback?.();
        toast.error(
          `An error ocurred trying to delete the suppression, please try again.`,
        );
      },
      onSuccess: refetch,
    },
  );

  const onSubmit = (form: DeleteSuppressionModalForm) => {
    switch (findingM?.suppressionLevel) {
      case "policy":
        onUnsuppressFindingPolicyLevel(form.comment);
        break;
      case "resource":
        onUnsuppressFindingResourceLevel(form.comment);
        break;
      case "repository":
        onUnsuppressFindingRepoLevel(form.comment);
        break;
      default:
        break;
    }
    handleClose();
    onDeleteSuppression?.();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const isFileLevelSuppression = finding?.suppressionLevel === "file";

  return (
    <Fragment>
      <Box visibility={hideButton ? "hidden" : "visible"}>
        <Tooltip
          title={
            isFileLevelSuppression
              ? "File level suppression is controlled from config file in the repository."
              : !hasOrgWriteScope
              ? "Insufficient permissions"
              : ""
          }
        >
          <span>
            <Button
              innerRef={ref}
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
              disabled={!hasOrgWriteScope || isFileLevelSuppression}
            >
              Delete Suppression
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        onExited={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Box p={3} pb={0} display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            Delete Suppression
          </Typography>
        </Box>
        <DialogContent>
          <Box mb={2} p={1} bgcolor="lightBackground.main" borderRadius={4}>
            {findingM && (
              <SuppressedFindingRow
                finding={findingM}
                showResource={
                  assessmentDetail?.showResource ?? !!findingM.resource
                }
                gitRepo={gitRepo}
              />
            )}
          </Box>
          <Box mb={2}>
            {finding?.suppressionLevel === "repository"
              ? `Deleting a finding suppression publishes this particular finding to this assessment.
              This will add the finding back to the assessment metrics.`
              : finding?.suppressionLevel === "policy"
              ? `This is a policy level suppression. Deleting this suppression finding will enable the policy that caused it.`
              : "File level suppression is controlled from config file in the repository."}
          </Box>
          <form id="suppressions" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              rules={{ required: true }}
              as={TextField}
              label="Comment *"
              name="comment"
              error={!!errors.comment}
              helperText={!!errors.comment && "Please enter a comment."}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Box mr={2} display="inline-block">
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </Box>

            <Button
              color="primary"
              form="suppressions"
              variant="contained"
              type="submit"
            >
              Delete Suppression
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default forwardRef(DeleteSuppressionModal);
