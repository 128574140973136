import React, { FC, Fragment } from "react";
import { Tooltip } from "@material-ui/core";

import LogoBadge from "components/LogoBadge";

interface LanguageIconProps {
  hasLanguage?: string[];
}

const LanguageIcon: FC<LanguageIconProps> = ({ hasLanguage }) => {
  return (
    <Fragment>
      {hasLanguage?.includes("go") && (
        <Tooltip title="Go" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "white",
              border: "1px solid #00acd7",
              color: "white",
            }}
          >
            <LogoBadge logo="go" width={16} />
          </div>
        </Tooltip>
      )}
      {hasLanguage?.includes("java") && (
        <Tooltip title="Java" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "white",
              border: "1px solid #f8981d",
              color: "white",
            }}
          >
            <LogoBadge logo="java" width={18} />
          </div>
        </Tooltip>
      )}
      {hasLanguage?.includes("node") && (
        <Tooltip title="Node" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "white",
              border: "1px solid #74aa61",
              color: "white",
            }}
          >
            <LogoBadge logo="node" width={16} />
          </div>
        </Tooltip>
      )}
      {hasLanguage?.includes("python") && (
        <Tooltip title="Python" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "white",
              border: "1px solid #ffd43b",
              color: "white",
            }}
          >
            <LogoBadge logo="python" width={16} />
          </div>
        </Tooltip>
      )}
      {hasLanguage?.includes("ruby") && (
        <Tooltip title="Ruby" arrow>
          <div
            style={{
              height: 22,
              width: 22,
              marginRight: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "white",
              border: "1px solid #b11205",
              color: "white",
            }}
          >
            <LogoBadge logo="ruby" width={22} />
          </div>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default LanguageIcon;
