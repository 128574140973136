import React, { FC, useCallback, useLayoutEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { useChangeOrg, useUser } from "providers";

import { useQueryState } from "hooks/useQueryState";
import Loader from "pages/Loader";

const ChangeOrg: FC<Childless> = () => {
  const user = useUser();
  const changeOrg = useChangeOrg();
  const [isForbidden, setIsForbidden] = useState(false);
  const { state, setQueryState } = useQueryState<{ orgId: string }>();
  const orgId = state.orgId + "";

  const changeOrgA = useCallback(async () => {
    await changeOrg(orgId);
    setQueryState({ ...state, orgId: undefined });
  }, [changeOrg, orgId, setQueryState, state]);

  useLayoutEffect(() => {
    if (user.organizations.find((org) => org.orgId === orgId)) {
      changeOrgA();
    } else {
      setIsForbidden(true);
      setQueryState({ ...state, orgId: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isForbidden)
    return <Navigate to="/error/unauthorized" state={{ orgId }} />;

  return <Loader delay={0} />;
};

export default ChangeOrg;
