import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

import { useQueryStateContext } from "providers/QueryStateProvider";

import { useQueryParams } from "./useQueryParams";

interface SetQueryStateOptions {
  replace: boolean;
}

type GetUrlByState<T> = (state?: Partial<T>) => string;

type SetQueryState<T> = (
  state?: Partial<T>,
  options?: Partial<SetQueryStateOptions>,
) => void;

interface QueryState<T> {
  state: Partial<T>;
  setQueryState: SetQueryState<T>;
  getUrlByState: GetUrlByState<T>;
}

export function useQueryState<T>(key?: string): QueryState<T> {
  const context = useQueryStateContext();
  const queryParams = useQueryParams<Partial<T>>();
  const [state, setState] = useState<Partial<T>>(queryParams);
  const lastState = useRef<Partial<T>>({});
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getUrlByState = useCallback<GetUrlByState<T>>(
    (state) => {
      if (!state || isEmpty(state)) {
        return `${pathname}`;
      }

      // Remove properties with undefined values.
      const record: Record<string, string> = omitBy(
        state,
        (val: any) => isNil(val) || val === "",
      );

      const searchParams = new URLSearchParams(record);

      return `${pathname}?${searchParams}`;
    },
    [pathname],
  );

  const setQueryState = useCallback<SetQueryState<T>>(
    (state, options) => {
      const url = getUrlByState({
        ...(!options?.replace && lastState.current),
        ...state,
      });

      navigate(`${url}`, { replace: true });
    },
    [getUrlByState, navigate],
  );

  useEffect(() => {
    if (isEqual(lastState.current, queryParams)) {
      return;
    }

    setState(queryParams as any);
  }, [queryParams]);

  useEffect(() => {
    lastState.current = state;
  });

  useEffect(() => {
    if (!key) {
      return;
    }

    const current = context.getValue(key) ?? {};

    if (isEmpty(state)) {
      return;
    }

    if (isEqual(state, current)) {
      return;
    }

    context.setValue(key, state);
  }, [context, state, key]);

  const QueryState = useMemo<QueryState<T>>(
    () => ({
      state,
      setQueryState,
      getUrlByState,
    }),
    [getUrlByState, setQueryState, state],
  );

  return QueryState;
}
