import React, { FC, useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";

import { AppContext, AppContextState } from "./AppContext";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import authService from "services/authService";

const AppProvider: FC = ({ children }) => {
  const queryClient = useQueryClient();
  const {
    data: user,
    refetch: getUser,
    isFetching: isLogging,
  } = useQuery("user-auth", () => authService.userProfile(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });

  const logout = useCallback(async () => {
    await authService.logout().catch(() => {});
    queryClient.setQueryData("user-auth", void 0);
  }, [queryClient]);

  const changeOrg = useCallback(
    async (orgId: string) => {
      try {
        await authService.changeOrg(orgId);
        getUser();
      } catch (error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      }
    },
    [getUser],
  );

  const state: AppContextState = {
    user,
    isLogging,
    getUser,
    logout,
    changeOrg,
  };

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

export default AppProvider;
