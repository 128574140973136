import instance, { unauthenticated } from "./axiosInstance";
import { APIResponse, AuthUser } from "types";

const logout = () => unauthenticated.post<void>("/auth/logout");

const changeOrg = (orgId: string) =>
  instance.post<void>("/users/current-org", { orgId });

const userProfile = () =>
  unauthenticated
    .get<APIResponse<AuthUser>>("/users/profile")
    .then((res) => res.data.data);

const sessionHeartbeat = () =>
  unauthenticated.get<void>("/auth/validate-token", { withCredentials: true });

export default {
  logout,
  changeOrg,
  userProfile,
  sessionHeartbeat,
};
