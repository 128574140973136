import React, { FC, Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import { faChevronRight, faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useDocsRoute } from "providers";

import DataTable from "components/DataTable";
import AssessmentRow, {
  AssessmentRowProps,
} from "pages/Assessments/pages/Assessments/components/AssessmentRow";
import { Assessment } from "types/assessments";
import {
  DashboardElementData,
  DashboardElementMetadata,
} from "types/dashboard";

interface AssessmentsTabularElementProps {
  metadata: DashboardElementMetadata;
  element?: DashboardElementData;
  isLoading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& > table > tbody > tr > td": {
        border: 0,
      },
    },
  }),
);

const AssessmentsTabularElement: FC<AssessmentsTabularElementProps> = ({
  metadata,
  element,
  isLoading,
}) => {
  const [processingRows, setProcessingRows] = useState<string[]>([]);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const docsRoute = useDocsRoute();
  const columnsDef = (
    rowProps: Omit<AssessmentRowProps, "assessment">,
  ): Column<Assessment>[] => [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row: { original: row } }) => (
        <AssessmentRow {...rowProps} assessment={row} />
      ),
    },
  ];

  const columns = useMemo(
    () => columnsDef({ processingRows, setProcessingRows, query: "" }),
    [processingRows],
  );

  const data = element?.data ? (element.data as any[]) : [];

  return (
    <Fragment>
      {isLoading ? (
        <Box component="h2" textAlign="center" py={5}>
          -
        </Box>
      ) : (
        <Fragment>
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            px={matches ? 0 : 2}
            py={2}
          >
            <DataTable
              columns={columns}
              data={data}
              isLoading={isLoading}
              pagination={false}
              containerClassname={classes.table}
              compact
              hideTableHead
              noDataComponent={
                <Box p={4} textAlign="center">
                  <Box textAlign="center">
                    <Box mb={1} fontSize={52} style={{ opacity: 0.25 }}>
                      <FontAwesomeIcon icon={faClipboard} />
                    </Box>
                    <Typography align="center" gutterBottom variant="body1">
                      There are no assessments available.
                    </Typography>
                  </Box>
                  <a
                    href={`${docsRoute}/getting-started-with-iac-security/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn how to add policy checks to your code
                  </a>
                </Box>
              }
            />
            {metadata.hyperlink && (
              <Box alignSelf="center" mt="auto" pt={2}>
                <Link to={metadata.hyperlink}>
                  View all
                  <Box ml={1} display="inline-block">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Box>
                </Link>
              </Box>
            )}
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AssessmentsTabularElement;
