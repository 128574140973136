export interface ActionCallback<T = any> {
  (row: T): void;
}

export interface TokensTabsRowActions {
  onRevokeToken: ActionCallback;
  onDisableToken: ActionCallback;
  onEnableToken: ActionCallback;
}

export enum TokensTabs {
  AccessTokens = "access",
  AgentTokens = "agent",
}
