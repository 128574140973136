import React, { FC, Fragment, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  Collapse,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
  optimisticObjectUpdater,
} from "helpers/queryHelpers";
import repositoryService from "services/repositoryService";

interface RepoSettingsForm {
  repoEnabled: boolean;
}

const RepositorySettings: FC = () => {
  const orgId = useCurrentOrgId();
  const { gitRepoName = "" } = useParams<{ gitRepoName: string }>();
  const gitRepo = decodeURIComponent(gitRepoName);

  const { data, isLoading, refetch } = useQuery(
    ["repository-settings", orgId, gitRepoName],
    () => repositoryService.getRepoSettings(orgId, gitRepoName),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      refetchOnMount: "always",
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSettled: (d) => {
        reset({
          repoEnabled: !!d?.repoEnabled,
        });
      },
    },
  );

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
    register,
  } = useForm<RepoSettingsForm>({
    defaultValues: {
      repoEnabled: !!data?.repoEnabled,
    },
  });

  const { mutate: saveSettings } = useMutation(
    (form: RepoSettingsForm) =>
      repositoryService.postRepoSettings(orgId, gitRepo, {
        repoEnabled: form.repoEnabled,
      }),
    {
      onMutate: (s) =>
        optimisticObjectUpdater<RepoSettingsForm>(
          ["repository-settings", orgId, gitRepoName],
          { repoEnabled: s.repoEnabled },
        ),
      onSuccess: (_d, f) => {
        refetch();
        reset(f);
      },
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    },
  );

  const onSubmit = useCallback(
    async (form: RepoSettingsForm) => {
      await saveSettings(form);
    },
    [saveSettings],
  );

  return (
    <Fragment>
      <PageHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          Repository Settings
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to="/repos/">
              Repositories
            </Link>
            <Link color="inherit" to={`/repos/details/${gitRepo}`}>
              {gitRepo}
            </Link>
            <Typography color="textPrimary">Settings</Typography>
          </Breadcrumbs>
        </Box>
      </PageHeader>
      <Card>
        {isLoading ? (
          <Box p={4} lineHeight={2}>
            <Skeleton count={9} />
          </Box>
        ) : (
          <Box p={3}>
            <Box
              component="form"
              pb={2}
              id="repo-settings"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      inputRef={register}
                      name="repoEnabled"
                      render={({ onChange, value }) => (
                        <Switch
                          color="primary"
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                          disabled={isSubmitting}
                        />
                      )}
                    />
                  }
                  label="Enabled"
                />
                <Typography variant="body2" gutterBottom>
                  Disabling Repositories exclude them from running automatic
                  assessments, scheduled jobs and the repositories main list
                  view.
                </Typography>
              </FormGroup>
            </Box>
            <Collapse in={isDirty}>
              <Box display="flex" justifyContent="flex-end">
                <Fragment>
                  <Box mr={2}>
                    <Button
                      onClick={() => {
                        reset();
                      }}
                      disabled={isSubmitting}
                      variant="text"
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Tooltip title={!isDirty ? "No changes" : ""} arrow>
                    <span>
                      <Button
                        variant="contained"
                        form="repo-settings"
                        color="primary"
                        type="submit"
                        disabled={!isDirty || isSubmitting}
                      >
                        {isSubmitting ? (
                          <CircularProgress
                            style={{ width: 24 }}
                            color="primary"
                            size="small"
                          />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                </Fragment>
              </Box>
            </Collapse>
          </Box>
        )}
      </Card>
    </Fragment>
  );
};

export default RepositorySettings;
