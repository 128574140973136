import instance from "./axiosInstance";
import { PaginatedResponse } from "types";
import { Announcement } from "types/announcement";

const getAnnouncements = (orgId: string) =>
  instance
    .get<PaginatedResponse<Announcement>>(`/org/${orgId}/latest-announcements`)
    .then((a) => a.data.data);

export default {
  getAnnouncements,
};
