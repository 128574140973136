import { FC } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Card, Grid, Typography } from "@material-ui/core";

import { ReactComponent as CheckCircleIcon } from "assets/svg/icons/check-circle.svg";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import { useQueryParams } from "hooks/useQueryParams";

const SlackAuthorization: FC = () => {
  const { orgId, ref } = useQueryParams();

  return (
    <NavbarTopLayout white>
      <Grid
        container
        style={{ height: "100%" }}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={11} md={9} lg={5} xl={4}>
          <Card variant="outlined">
            <Box pt={5} px={5} pb={0}>
              <Typography variant="h6" gutterBottom>
                Hello,
              </Typography>
              <p>
                By clicking authorize, you are linking your Slack identity to
                your "Lacework IaC Security identity. This will allow you to
                interact with Lacework IaC Security by issuing commands in
                Slack.
              </p>
              <Box mb={2}>
                <Box display="inline-block" mr={1}>
                  <Button
                    component="a"
                    href={`/api/v1/org/${orgId}/slack/link?ref=${ref}&orgId=${orgId}`}
                    color="primary"
                    variant="contained"
                  >
                    Authorize
                  </Button>
                </Box>
                <Button
                  component={Link}
                  to="/error?message=Unable to add Slack to Lacework IaC Security&url=/integrations/config/Slack"
                  color="primary"
                  variant="outlined"
                >
                  Deny
                </Button>
              </Box>
            </Box>

            <hr />
            <Box px={6} pb={3}>
              <div>Lacework IaC Security Slack App</div>
              <div>
                by{" "}
                <a
                  href="https://docs.lacework.com/configure-iacbot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lacework IaC Security
                </a>{" "}
                <Box color="primary.main" display="inline-block">
                  <CheckCircleIcon height={14} />
                </Box>
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </NavbarTopLayout>
  );
};

export default SlackAuthorization;
