import React, { FC, Fragment, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";

import ChartWrapper from "components/ChartWrapper/ChartWrapper";
import {
  DashboardElementData,
  DashboardElementMetadata,
} from "types/dashboard";

interface LineChartElementProps {
  metadata: DashboardElementMetadata;
  element?: DashboardElementData;
  isLoading: boolean;
}

const LineChartElement: FC<LineChartElementProps> = ({
  metadata,
  element,
  isLoading,
}) => {
  const lineData = useMemo(
    () =>
      element
        ? element.data.map((e) =>
            e[element.labels[1]] === null ? null : Number(e[element.labels[1]]),
          )
        : [],
    [element],
  );
  const lineLabels = useMemo(
    () => (element ? element.data.map((e) => e[element.labels[0]] ?? "") : []),
    [element],
  );

  return (
    <Fragment>
      {isLoading ? (
        <Box
          px={2}
          py={1}
          pb={3}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Skeleton width="50%" />
          <Skeleton width="75%" />
          <Skeleton width="43%" />
        </Box>
      ) : lineData.length && element ? (
        <Box
          height={"100%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={2}
        >
          <ChartWrapper
            height={240}
            type="line"
            data={{
              datasets: [
                {
                  data: lineData,
                  label: metadata.title,
                  borderColor: "#8884d8",
                  borderWidth: 3,
                  fill: false,
                },
              ],
              labels: lineLabels,
            }}
            options={{
              plugins: {
                legend: {
                  position: "right",
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  grid: {
                    drawOnChartArea: false,
                  },
                  ticks: {
                    precision: 0,
                  },
                  min: 0,
                },

                x: {
                  grid: {
                    drawOnChartArea: false,
                  },
                },
              },
            }}
          />
        </Box>
      ) : (
        <Box p={2} textAlign="center">
          <Box mb={1} style={{ opacity: 0.25 }}>
            <FontAwesomeIcon icon={faChartLine} size="2x" />
          </Box>
          <Typography variant="body2">{metadata.defaultMessage}</Typography>
        </Box>
      )}
    </Fragment>
  );
};

export default LineChartElement;
