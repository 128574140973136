export enum COLORS_FAILURES {
  Critical = "#8c101c",
  High = "#e71d32",
  Medium = "#ff7832",
  Low = "#fdd600",
  Info = "#5aaafa",
}

export enum PIE_COLORS_DASHBOARD {
  Critical = "#8c101c",
  High = "#e71d32",
  Medium = "#ff7832",
  Warn = "#f5b942",
  Low = "#fdd600",
  Info = "#5aaafa",
  cloudsploit = "#0a839d",
}
