import React, { FC, Fragment } from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { startCase } from "lodash";
import useCommonStyles from "theme/useCommonStyles";

import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import { IacBotJob } from "types/iacBot";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    label: { fontWeight: 500 },
    pd: {
      padding: "0 2px",
    },
  }),
);

interface IacBotJobExpandedProps {
  job: IacBotJob;
}

const IacBotJobExpanded: FC<IacBotJobExpandedProps> = ({ job }) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  return (
    <Grid className={classes.root} container spacing={1}>
      <Grid className={classes.label} item xs={4} sm={3} lg={1}>
        Requested
      </Grid>
      <Grid
        className={classNames(classes.pd, commonStyles.wordBreakAll)}
        item
        xs={8}
        sm={9}
        lg={11}
      >
        <RelativeTimeFormatter dateTs={job.requestTs} />
      </Grid>
      {job.startTs && (
        <Fragment>
          <Grid className={classes.label} item xs={4} sm={3} lg={1}>
            Started
          </Grid>
          <Grid
            className={classNames(classes.pd, commonStyles.wordBreakAll)}
            item
            xs={8}
            sm={9}
            lg={11}
          >
            <RelativeTimeFormatter dateTs={job.startTs} />
          </Grid>
        </Fragment>
      )}
      {job.endTs && (
        <Fragment>
          <Grid className={classes.label} item xs={4} sm={3} lg={1}>
            Completed
          </Grid>
          <Grid
            className={classNames(classes.pd, commonStyles.wordBreakAll)}
            item
            xs={8}
            sm={9}
            lg={11}
          >
            <RelativeTimeFormatter dateTs={job.endTs} />
          </Grid>
        </Fragment>
      )}
      {job.trigger && (
        <Fragment>
          <Grid className={classes.label} item xs={4} sm={3} lg={1}>
            Trigger
          </Grid>
          <Grid
            className={classNames(classes.pd, commonStyles.wordBreakAll)}
            item
            xs={8}
            sm={9}
            lg={11}
          >
            {startCase(job.trigger)}
          </Grid>
        </Fragment>
      )}
      {job.gitEventType && (
        <Fragment>
          <Grid className={classes.label} item xs={4} sm={3} lg={1}>
            Event Type
          </Grid>
          <Grid
            className={classNames(classes.pd, commonStyles.wordBreakAll)}
            item
            xs={8}
            sm={9}
            lg={11}
          >
            {job.gitEventType}
          </Grid>
        </Fragment>
      )}
      {job.commitBy && (
        <Fragment>
          <Grid className={classes.label} item xs={4} sm={3} lg={1}>
            Commit By
          </Grid>
          <Grid
            className={classNames(classes.pd, commonStyles.wordBreakAll)}
            item
            xs={8}
            sm={9}
            lg={11}
          >
            {job.commitBy}
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default IacBotJobExpanded;
