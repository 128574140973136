import React, { FC, useMemo } from "react";
import { useQuery } from "react-query";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useCurrentOrgId } from "providers";

import DetailBody from "./components/DetailBody";
import DetailHeader from "./components/DetailHeader";
import { AssessmentDetailsContext } from "./context/AssessmentDetailsContext";
import { useQueryParams } from "hooks/useQueryParams";
import assessmentService from "services/assessmentService";

interface QueryParams {
  diag?: string;
}

const AssessmentDetail: FC = () => {
  const { assessmentId = "" } = useParams<{ assessmentId: string }>();
  const { diag } = useQueryParams<QueryParams>();

  const orgId = useCurrentOrgId();
  const { data, isLoading, refetch, isFetched } = useQuery(
    ["assessmentDetail", orgId, assessmentId],
    () => assessmentService.getAssessmentDetail(orgId, assessmentId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  );

  const assessmentDetail = useMemo(
    () =>
      data
        ? {
            ...data,
            findings: data?.findings?.map((f) => ({
              ...f,
              autofix: f.pass ? false : !!f.autofix,
              compliance:
                f.compliance?.length === 0 || f.compliance === undefined
                  ? ["No Compliance"]
                  : f.compliance,
            })),
            suppressedFindings: data?.suppressedFindings?.map((f) => ({
              ...f,
              autofix: f.pass ? false : !!f.autofix,
              compliance:
                f.compliance?.length === 0 || f.compliance === undefined
                  ? ["No Compliance"]
                  : f.compliance,
            })),
          }
        : undefined,
    [data],
  );

  if (!isLoading && !assessmentDetail) {
    toast.error("Unable to find Assessment with specified ID.");
    return <Navigate to={"/assessments"} />;
  }

  if ((diag || diag === "") && isFetched) {
    if (!assessmentDetail?.diagnosticPath) {
      toast.warn("No diagnostic URL found.");
    } else {
      window.location.href = assessmentDetail.diagnosticPath;
    }
  }

  return (
    <AssessmentDetailsContext.Provider
      value={{ assessmentDetail, isLoading, refetch, isFetched }}
    >
      <DetailHeader assessmentId={assessmentId} />
      <DetailBody assessmentId={assessmentId} />
    </AssessmentDetailsContext.Provider>
  );
};

export default AssessmentDetail;
