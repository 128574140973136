import instance from "./axiosInstance";
import { APIResponse } from "types";
import { UserProfile } from "types/user-profile";

const getEmailPreference = async (userId: string) =>
  instance
    .get<APIResponse<UserProfile>>(`/users/${userId}/preferences`)
    .then((data) => data.data.data);

const acceptToS = async () => instance.post(`/users/profile/accept-tos`, {});

const addEmail = async (
  userId: string,
  { orgId, email }: { orgId: string; email: string },
) =>
  instance
    .post(`/users/${userId}/email/update`, { orgId, email })
    .then((data) => data.data);

const updateEmailPreference = async (
  userId: string,
  payload: { emailMarketing: boolean; emailDigest: boolean },
) => instance.post<void>(`/users/${userId}/preferences`, payload);

const deleteAccount = async (userId: string) =>
  instance.delete(`/users/${userId}`);

export default {
  getEmailPreference,
  acceptToS,
  addEmail,
  updateEmailPreference,
  deleteAccount,
};
