import React, { FC, Fragment, useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  IconButtonProps,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FindReplace } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useCurrentOrgId, useHasScope } from "providers";

import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import repositoryService from "services/repositoryService";
import { Permissions, Resources } from "types/auth-roles";

const TriggerRediscoveryButton: FC<IconButtonProps> = ({ ...props }) => {
  const orgId = useCurrentOrgId();
  const [isLoading, setIsLoading] = useState(false);
  const [force, setIsForce] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { repositoriesForceDiscoveryUiEnabled } = useFlags();
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );

  const closeModal = () => setIsModalOpen(false);

  const triggerDiscovery = async () => {
    try {
      setIsLoading(true);
      setIsModalOpen(false);
      setIsForce(false);
      await repositoryService.triggerDiscovery(orgId, force);
      toast.success(
        "Successfully invoked discovery of new repositories. This operation will take a while, please revisit after some time.",
      );
    } catch (err) {
      errorToastHandler(FALLBACK_ERROR_MESSAGE)(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Tooltip
        arrow
        title={
          hasOrgWriteScope
            ? "Discover Repositories"
            : "Insufficient Permissions"
        }
      >
        <span>
          <IconButton
            disabled={isLoading || !hasOrgWriteScope}
            onClick={() => setIsModalOpen(true)}
            color="primary"
            {...props}
          >
            {isLoading ? <CircularProgress size={24} /> : <FindReplace />}
          </IconButton>
        </span>
      </Tooltip>
      <Dialog onClose={closeModal} fullWidth maxWidth="sm" open={isModalOpen}>
        <DialogTitle>Discover Repositories</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            The system will search for any new repository and run assessments
            for all found.
          </Typography>
          {repositoriesForceDiscoveryUiEnabled && (
            <Box mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedF"
                    checked={force}
                    onChange={({ target: { checked } }) => setIsForce(checked)}
                    color="primary"
                  />
                }
                label="Force Discovery"
              />
              <Typography>
                When Force Discovery is selected, the system will also re-run
                assessments on all repositories found on their respective
                default branch.
              </Typography>
            </Box>
          )}
          <Box mt={3}>
            <Typography gutterBottom variant="subtitle2">
              This operation will take a while, please revisit after some time.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={triggerDiscovery}
            color="primary"
            variant="contained"
          >
            Discover
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default TriggerRediscoveryButton;
