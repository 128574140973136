import instance from "./axiosInstance";
import { Event, PaginatedResponse } from "types";

const getEvents = (orgId: string) =>
  instance
    .get<PaginatedResponse<Event>>(`/org/${orgId}/events`)
    .then((res) => res.data.data);

export default {
  getEvents,
};
