/* istanbul ignore file */
import React, { FC, useCallback, useState } from "react";
import {
  createStyles,
  FormControl,
  IconButton,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { ClearRounded, Search } from "@material-ui/icons";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(1),
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexDirection: "row",
    },
    input: {
      backgroundColor: "white",
      width: 210,
    },
    inputRoot: {
      paddingRight: 38,
      textOverflow: "ellipsis",
    },
    button: {
      marginLeft: -38,
      padding: 6,
    },
  }),
);

interface SearchBarProps {
  initialValue: string;
  onChange(filterValue: string): void;
  onSubmit?(filterValue: string): void;
  className?: string;
  disabled?: boolean;
  color?: "primary" | "secondary";
}

const SearchBar: FC<SearchBarProps> = ({
  initialValue,
  onChange,
  onSubmit,
  className,
  disabled,
  color = "primary",
}) => {
  const [value, setValue] = useState(initialValue);
  const [lastSubmitted, setLastSubmitted] = useState<string>("");

  const classes = useStyles();

  const handleChange = ({
    target: { value: eValue },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(eValue);
    if (!onSubmit) {
      onChange(eValue);
    }
  };

  const handleSubmit = () => {
    onSubmit?.(value);
    setLastSubmitted(value);
  };

  const handleReset = useCallback(() => {
    setValue("");
    onSubmit ? onSubmit("") : onChange("");
  }, [onSubmit, onChange]);

  const isResetable = !!value;
  const isSubmitResetable = lastSubmitted === value && value !== "";

  return (
    <FormControl
      className={classNames(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <TextField
        className={classNames(classes.input)}
        inputProps={{
          className: classes.inputRoot,
        }}
        id="outlined-search"
        disabled={disabled}
        label="Search"
        type="text"
        variant="outlined"
        color={color}
        size="small"
        value={value}
        onChange={handleChange}
        onKeyDown={({ key }) => key === "Enter" && handleSubmit()}
      />
      {onSubmit ? (
        <IconButton
          type="submit"
          className={classNames(classes.button)}
          onClick={isSubmitResetable ? handleReset : handleSubmit}
        >
          {isSubmitResetable ? <ClearRounded /> : <Search />}
        </IconButton>
      ) : (
        <IconButton
          className={classNames(classes.button)}
          onClick={handleReset}
          disabled={!isResetable}
        >
          {isResetable ? <ClearRounded /> : <Search />}
        </IconButton>
      )}
    </FormControl>
  );
};

export default SearchBar;
