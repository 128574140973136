import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AssessmentDetail from "./pages/AssessessmentDetails";
import Assessments from "./pages/Assessments/Assessments";

const AssessmentsRouter: FC<Childless> = () => {
  return (
    <Routes>
      <Route path="/details/:assessmentId" element={<AssessmentDetail />} />
      <Route
        path="/details/:assessmentId/:findingId"
        element={<AssessmentDetail />}
      />
      <Route
        path="/details/:assessmentId/:findingId/:tab"
        element={<AssessmentDetail />}
      />

      <Route path="" element={<Assessments />} />

      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default AssessmentsRouter;
