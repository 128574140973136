import { IdType } from "react-table";
import { isUndefined } from "lodash";

import {
  Severity,
  SeverityValue,
} from "components/SeverityComponent/SeverityComponent";
import { AwsAccountStatus } from "types/integration";

interface Row<T> {
  original: T;
}

export const handleOnSortToggle = (
  id: string,
  isSorted: boolean,
  isSortDesc?: boolean,
): {
  sortBy?: string;
  sortDirection?: "asc" | "desc";
  page: number;
} => {
  const willAsc = isSortDesc;
  const willDesc = !isSortDesc;

  return {
    sortBy: id,
    sortDirection: willAsc ? "asc" : willDesc ? "desc" : undefined,
    page: 1,
  };
};

export const sortBool = <D extends object>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: IdType<D>,
  desc?: boolean,
) =>
  !!rowA.original[columnId as keyof D] === !!rowB.original[columnId as keyof D]
    ? 0
    : !!rowA.original[columnId as keyof D]
    ? -1
    : 1;

export const sortSeverity = <T extends Record<string, string>>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: IdType<T>,
  desc?: boolean,
) => {
  const valA =
    SeverityValue[rowA.original[columnId as keyof T] as keyof typeof Severity];
  const valB =
    SeverityValue[rowB.original[columnId as keyof T] as keyof typeof Severity];
  if (valA > valB) return 1;
  if (valB > valA) return -1;
  return 0;
};

export const sortOkStatus = <T extends Record<string, any>>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: IdType<T>,
  desc?: boolean,
) => {
  const valA = rowA.original[columnId] === AwsAccountStatus.Ok ? -1 : 1;
  const valB = rowB.original[columnId] === AwsAccountStatus.Ok ? -1 : 1;

  return valA > valB ? -1 : 1;
};

export const sortUndefinedBool =
  (undefinedTruthy: boolean) =>
  <D extends object>(
    rowA: Row<D>,
    rowB: Row<D>,
    columnId: IdType<D>,
    desc?: boolean,
  ) => {
    const rawA = rowA.original[columnId as keyof D];
    const rawB = rowB.original[columnId as keyof D];

    const valA = isUndefined(rawA) ? undefinedTruthy : rawA;
    const valB = isUndefined(rawB) ? undefinedTruthy : rawB;
    return !!valA === !!valB ? 0 : !!valA ? -1 : 1;
  };
