import React, { FC, useMemo } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import useCommonStyles from "theme/useCommonStyles";

import SeverityComponent, {
  Severity,
} from "components/SeverityComponent/SeverityComponent";
import { Finding } from "types/assessments";
import { Violation } from "types/violations";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dList: {
      margin: 0,
    },
    dTitle: {
      margin: 0,
      marginRight: theme.spacing(1),
    },
    md: {
      "& p": {
        margin: 0,
      },
    },
  }),
);
interface FindingsRowProps {
  finding: Finding | Violation;
  showResource?: boolean;
  gitRepo?: string;
}

const FindingsRow: FC<FindingsRowProps> = ({
  finding,
  showResource = true,
  gitRepo,
}) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.up("xl"));
  const lines =
    finding.isModuleReference &&
    finding.callerLine &&
    finding.callerLineRangeEnd
      ? finding.callerLine === finding.callerLineRangeEnd
        ? ` ${finding.callerLine}`
        : `s ${finding.callerLine} - ${finding.callerLineRangeEnd}`
      : finding.line === finding.lineRangeEnd
      ? ` ${finding.line}`
      : `s ${finding.line} - ${finding.lineRangeEnd}`;
  const filePath = useMemo(() => {
    const str =
      finding.isModuleReference && finding.callerFilePath
        ? finding.callerFilePath
        : finding.filePath ?? "";

    return str?.length >= (matchesLg ? 47 : 23)
      ? `~${str.substring(str.length - (matchesLg ? 45 : 22))}`
      : str;
  }, [
    finding.callerFilePath,
    finding.filePath,
    finding.isModuleReference,
    matchesLg,
  ]);

  return (
    <Box position="relative">
      <Box className={commonStyles.wordBreakAll}>{finding.title}</Box>
      <Box className={classes.dTitle} component="dl">
        {finding.resource && showResource && (
          <Box display="flex">
            <Typography className={classes.dTitle} variant="h6" component="dt">
              Resource
            </Typography>
            <Typography
              className={commonStyles.wordBreakAll}
              variant="body2"
              component="dd"
            >
              {finding.resource}
            </Typography>
          </Box>
        )}
        <div>
          <Typography
            display="inline"
            className={classes.dTitle}
            variant="h6"
            component="dt"
          >
            Severity
          </Typography>
          <Typography display="inline" variant="body2" component="dd">
            <SeverityComponent severity={Severity[finding.severity]} />
          </Typography>
        </div>
        {gitRepo && (
          <div>
            <Typography
              display="inline"
              className={classes.dTitle}
              variant="h6"
              component="dt"
            >
              Repository
            </Typography>
            <Typography
              className={commonStyles.wordBreakAll}
              display="inline"
              variant="body2"
              component="dd"
            >
              {gitRepo}
            </Typography>
          </div>
        )}
      </Box>
      {finding.filePath && (
        <Box display="flex" alignItems="flex-start">
          <Box className={classes.dTitle} component="dt">
            <DescriptionOutlined fontSize="small" />
          </Box>
          {finding.gitFilePermaLink || finding.gitCallerFilePermaLink ? (
            <Box>
              <Box className={commonStyles.wordBreakAll}>
                Found in &#8204;
                <a
                  href={
                    finding.isModuleReference
                      ? finding.gitCallerFilePermaLink
                      : finding.gitFilePermaLink
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {`${filePath} : `}
                  <span style={{ wordBreak: "initial" }}>{`Line${lines}`}</span>
                </a>
              </Box>
            </Box>
          ) : (
            <Typography
              className={commonStyles.wordBreakAll}
              variant="body2"
              component="dd"
            >
              {`Found in ${
                finding.isModuleReference && finding.callerFilePath
                  ? finding.callerFilePath
                  : finding.filePath
              }`}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FindingsRow;
