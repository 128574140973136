import React, { FC } from "react";
import {
  faBitbucket,
  faGithub,
  faGitlab,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonProps,
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    github: {
      backgroundColor: "#444444",
      borderColor: "#444444",
      color: "white",
      "&:hover": {
        color: "white",
        backgroundColor: lighten("#444444", 0.1),
      },
    },
    gitlab: {
      backgroundColor: "#C3432A",
      borderColor: "#C3432A",
      color: "white",
      "&:hover": {
        color: "white",
        backgroundColor: lighten("#C3432A", 0.1),
      },
    },
    bitbucket: {
      backgroundColor: "#375175",
      borderColor: "#375175",
      color: "white",
      "&:hover": {
        color: "white",
        backgroundColor: lighten("#375175", 0.1),
      },
    },
    label: {
      justifyContent: "flex-start",
      "& > .MuiButton-startIcon": {
        marginRight: 20,
        marginLeft: 16,
      },
    },
  }),
);
interface GitProviderButtonProps extends ButtonProps {
  provider: "github" | "gitlab" | "bitbucket";
}

const GitProviderButton: FC<GitProviderButtonProps> = ({
  provider,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const className = classes[provider];
  const icon =
    provider === "github"
      ? faGithub
      : provider === "bitbucket"
      ? faBitbucket
      : faGitlab;
  return (
    <Button
      {...rest}
      startIcon={<FontAwesomeIcon icon={icon} />}
      variant="contained"
      classes={{
        label: classes.label,
      }}
      className={className}
    >
      {children}
    </Button>
  );
};

export default GitProviderButton;
