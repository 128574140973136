import React, { FC } from "react";
import { Box, BoxProps, Typography } from "@material-ui/core";

interface PageHeaderProps extends BoxProps {
  className?: string;
  children: React.ReactNode;
}

const PageHeader: FC<PageHeaderProps> = ({
  className,
  children,
  ...boxProps
}) => (
  <Box mb={2} px={0.5} {...boxProps}>
    <Typography variant="h2">{children}</Typography>
  </Box>
);

export default PageHeader;
