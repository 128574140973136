import React, { FC, Fragment, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { useCurrentOrgId, useUser } from "providers";

import integrationsService from "services/integrationsService";

const GitLabRedirector: FC = () => {
  const orgId = useCurrentOrgId();
  const { provider, tosAccepted, emailVerified } = useUser();
  const navigate = useNavigate();
  const isGitLabProvider = provider?.toLocaleLowerCase() === "gitlab";

  const { data: completed, isSuccess } = useQuery(
    ["gitlab-integration-status", orgId],
    () => integrationsService.getGitLabIntegrationStatus(orgId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: isGitLabProvider,
    },
  );

  useEffect(
    () => {
      if (
        isGitLabProvider &&
        isSuccess &&
        !completed?.completed &&
        tosAccepted &&
        emailVerified
      ) {
        navigate("/connect-gitlab");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess, tosAccepted, emailVerified],
  );

  return <Fragment />;
};

export default GitLabRedirector;
