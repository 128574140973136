import React, { FC, Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Box, Card, Grid, LinearProgress, Typography } from "@material-ui/core";
import { AssessmentOutlined, Cancel } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useCurrentOrgId } from "providers";

import ZodiacBuildDiffReport from "./components/ZodiacBuildDiffReport";
import ZodiacBuildHeader from "./components/ZodiacBuildHeader";
import ZodiacBuildMetadata from "./components/ZodiacBuildMetadata";
import ZodiacBuildSummary from "./components/ZodiacBuildSummary";
import ReactMarkdown from "components/ReactMarkdown/ReactMarkdown";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import zodiacService from "services/zodiacService";

const ZodiacBuild: FC = () => {
  const { ciBuildId = "" } = useParams<{ ciBuildId: string }>();
  const orgId = useCurrentOrgId();
  const { zodiacUiDiffReportEnabled } = useFlags();
  const [isRunning, setIsRunning] = useState(false);
  const navigate = useNavigate();
  const { data: status } = useQuery(
    ["job-build-status", orgId, ciBuildId],
    () => zodiacService.getZodiacBuildStatus(orgId, ciBuildId),
    {
      onError(error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
        navigate("/zodiac");
      },
      onSuccess(data) {
        setIsRunning(data.state.toLowerCase() === "running");
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: isRunning ? 5000 : false,
    },
  );

  const { data: jobBuildMetadata, isLoading: isMetadataLoading } = useQuery(
    ["job-build-metadata", orgId, ciBuildId],
    () => zodiacService.getZodiacBuildAssessmentsMetadata(orgId, ciBuildId),
    {
      onError(error) {
        errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      },
      enabled: status?.state.toLowerCase() === "success",
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Fragment>
      <ZodiacBuildHeader ciBuildId={ciBuildId} />
      {status?.state.toLowerCase() === "failed" ? (
        <Box p={2} component={Card}>
          <Box display="flex" mb={2}>
            <Box component={Cancel} mr={1} color="error.main" />
            <Typography variant="h3" gutterBottom>
              Failed
            </Typography>
          </Box>
          <ReactMarkdown>{status?.message}</ReactMarkdown>
        </Box>
      ) : status?.state.toLowerCase() === "running" ? (
        <Box p={2} component={Card}>
          <Typography variant="h3" gutterBottom>
            Running Assessments
          </Typography>
          <Box my={4}>
            <LinearProgress color="secondary" />
          </Box>
        </Box>
      ) : status?.state.toLowerCase() === "success" ? (
        <Fragment>
          <Box mb={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ZodiacBuildSummary ciBuildId={ciBuildId} />
              </Grid>
              <Grid item xs={12} md={6}>
                <ZodiacBuildMetadata
                  metadata={jobBuildMetadata}
                  isLoading={isMetadataLoading}
                />
              </Grid>
            </Grid>
          </Box>
          {zodiacUiDiffReportEnabled && (
            <Box mb={2}>
              <ZodiacBuildDiffReport ciBuildId={ciBuildId} />
            </Box>
          )}
          <Box bgcolor="white" p={2}>
            <Link color="primary" to={`/zodiac/jobs/${ciBuildId}/assessments/`}>
              <Box display="flex" alignItems="center">
                <Box component={AssessmentOutlined} mr={2} />
                <Typography variant="h5" display="inline">
                  VIEW FINDINGS
                </Typography>
              </Box>
            </Link>
          </Box>
        </Fragment>
      ) : (
        <Box component={Card} p={2}>
          <LinearProgress color="secondary" />
        </Box>
      )}
    </Fragment>
  );
};

export default ZodiacBuild;
