import React, { FC, Fragment, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { faBookOpen, faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  createStyles,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  FileCopyOutlined,
} from "@material-ui/icons";
import { TabContext, TabPanel } from "@material-ui/lab";
import { groupBy } from "lodash";
import moment from "moment";

import { useCurrentOrgId } from "providers";

import PolicyActivitySeverity from "./PolicyActivitySeverity";
import PolicyActivityToggle from "./PolicyActivityToggle";
import PolicyConfig from "./PolicyConfig";
import BootstrapBadge from "components/BootstrapBadge/BootstrapBadge";
import CopyText from "components/CopyText/CopyText";
import ReactMarkdown from "components/ReactMarkdown/ReactMarkdown";
import { Severity } from "components/SeverityComponent/SeverityComponent";
import { getLocaleDateString } from "helpers/formatter";
import assessmentService from "services/assessmentService";
import policiesService from "services/policiesService";
import { Policy } from "types/policies";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      padding: theme.spacing(2),
    },
  }),
);

interface PolicyC extends Policy {
  rowId: number;
  total: number;
}
interface PolicyDrawerProps {
  policy: PolicyC;
  handlers: {
    handleOnSeverityChange: (
      policy: Policy,
      severity: keyof typeof Severity,
    ) => void;
    handleOnPolicyToggle: (policy: Policy) => void;
    handlePolicyDrawerNextClick: () => void;
    handlePolicyDrawerPreviousClick: () => void;
  };
}

const PolicyDrawer: FC<PolicyDrawerProps> = ({ policy, handlers }) => {
  const [tab, setTab] = useState("0");
  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    data: guidelines,
    isLoading: isGuidelinesLoading,
    isError,
  } = useQuery(
    ["policy-guidelines", orgId, policy.sid],
    () => assessmentService.getAssessmentFindingGuidelines(orgId, policy.sid),
    {
      enabled: tab === "0",
    },
  );

  const mGuidelines = useMemo(() => {
    const guidelinesBlocks = (guidelines + "").split("```") ?? [];
    const codeContainingIndices: number[] = [];
    for (let index = 0; index < guidelinesBlocks.length; index++) {
      if (index % 2 !== 0) codeContainingIndices.push(index);
    }
    codeContainingIndices.forEach((i) => {
      const codeBlockLines = guidelinesBlocks[i].split("\n");
      const changedLines: { index: number; add: boolean }[] = [];
      for (let j = 0; j < codeBlockLines.length; j++) {
        const codeBlockLine = codeBlockLines[j];
        const toAdd =
          codeBlockLine[0] === "+"
            ? true
            : // PREVENTS HIGLIGHTS OF DOUBLE DASH --
            codeBlockLine[0] === "-" && codeBlockLine[1] !== "-"
            ? false
            : null;
        if (toAdd !== null) {
          changedLines.push({ index: j, add: toAdd });
        }
      }
      const changedLinesIndices = changedLines.map((f) => f.index);
      changedLines.forEach((line) => {
        const hasPreviousElement = changedLinesIndices.includes(line.index - 1);
        const hasFollowingElement = changedLinesIndices.includes(
          line.index + 1,
        );
        return (codeBlockLines[line.index] = `${
          hasPreviousElement ? "" : "\n ```\n"
        }<pre class="${line.add ? "add" : "remove"}-line ${
          hasPreviousElement ? "" : "first"
        } ${!hasFollowingElement ? "last" : ""}"><code>${
          codeBlockLines[line.index]
        }</code></pre>\n ${hasFollowingElement ? "" : "``` \n"}`);
      });
      guidelinesBlocks[i] = codeBlockLines.join("\n");
    });

    return guidelinesBlocks.join("```");
  }, [guidelines]);

  const { data: activity = [], isLoading: isActivityLoading } = useQuery(
    ["policy-activity", orgId, policy.sid],
    () => policiesService.getPolicyActivity(orgId, policy.sid),
    {
      enabled: tab === "1",
    },
  );

  const groupedActivity = useMemo(() => {
    const groupedResults = groupBy(activity, (result) => {
      const date = moment(result.createTs, moment.ISO_8601);
      const formattedDate = date.calendar(Date.now(), {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse: getLocaleDateString(),
      });
      return formattedDate;
    });
    return groupedResults;
  }, [activity]);

  const groupedActivityKeys = useMemo(
    () => Object.keys(groupedActivity),
    [groupedActivity],
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue + "");
  };

  return (
    <Fragment>
      <Box
        position="fixed"
        width={matches ? "100%" : 750}
        zIndex={theme.zIndex.tooltip}
        pt={2}
        px={3}
        bgcolor="lightBackground.main"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h5">{policy.title}</Typography>
          <CopyText text={window.location.href}>
            <Tooltip title="Copy link to policy" arrow>
              <IconButton size="small">
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          </CopyText>
        </Box>
        {policy.custom && (
          <Box>
            <BootstrapBadge color="primary">CUSTOM</BootstrapBadge>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent={"space-between"}
          flexWrap="wrap-reverse"
        >
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            <Tab value="0" label="GUIDELINES" />
            <Tab value="1" label="ACTIVITY" />
            <Tab value="2" label="CONFIG" />
          </Tabs>
          <Box mx={matches ? "auto" : 0} display="flex" flexWrap="nowrap">
            <Box mr={1}>
              <Tooltip title="←">
                <span>
                  <IconButton
                    disabled={policy.rowId === 0}
                    onClick={handlers.handlePolicyDrawerPreviousClick}
                  >
                    <ArrowBackIosRounded />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
            <Tooltip title="→">
              <span>
                <IconButton
                  disabled={policy.rowId + 1 === policy.total}
                  onClick={handlers.handlePolicyDrawerNextClick}
                >
                  <ArrowForwardIosRounded />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box mt={13} p={2} width={matches ? "100%" : 750}>
        <TabContext value={tab}>
          <TabPanel className={classes.tabPanel} value={"0"}>
            <Box pt={matches ? 2 : 0} height="100%" overflow="auto">
              {isGuidelinesLoading ? (
                <Box lineHeight={2}>
                  <Skeleton count={20} />
                </Box>
              ) : isError ? (
                <Box pt={8} textAlign="center">
                  <Typography
                    style={{ opacity: 0.4 }}
                    variant="h1"
                    gutterBottom
                  >
                    <FontAwesomeIcon icon={faCogs} />
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    There are no guidelines available.
                  </Typography>
                </Box>
              ) : (
                <ReactMarkdown
                  unescapeHtml
                  sanitize={false}
                  linkTarget="_blank"
                >
                  {mGuidelines}
                </ReactMarkdown>
              )}
            </Box>
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={"1"}>
            <Box height="100%">
              {isActivityLoading ? (
                <Box lineHeight={2}>
                  <Skeleton count={10} />
                </Box>
              ) : isError || activity.length === 0 ? (
                <Box pt={8} textAlign="center">
                  <Typography color="textSecondary" variant="h1" gutterBottom>
                    <FontAwesomeIcon icon={faBookOpen} />
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    There is no activity recorded.
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Box textAlign="right">
                    <Chip
                      color="secondary"
                      label={`TOTAL :  ${activity?.length}`}
                    />
                  </Box>

                  {groupedActivityKeys.map((day, i) => (
                    <Fragment key={i}>
                      <Box mt={i === 0 ? 0 : 2.5} mb={2.5}>
                        <Typography variant="h4">{day}</Typography>
                      </Box>
                      {groupedActivity[day].map((j, y) =>
                        j.from ? (
                          <PolicyActivitySeverity key={y} activity={j} />
                        ) : (
                          <PolicyActivityToggle key={y} activity={j} />
                        ),
                      )}
                    </Fragment>
                  ))}
                </Box>
              )}
            </Box>
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={"2"}>
            <PolicyConfig policy={policy} handlers={handlers} />
          </TabPanel>
        </TabContext>
      </Box>
    </Fragment>
  );
};

export default PolicyDrawer;
