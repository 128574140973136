import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@material-ui/core";

import { useLogout } from "providers";

import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
  optimisticObjectUpdater,
} from "helpers/queryHelpers";
import { useQueryParams } from "hooks/useQueryParams";
import profileService from "services/profileService";
import { AuthUser } from "types";

import "./ToS.scss";

const ToS: FC = () => {
  const { resumeUrl = "/dashboard" } = useQueryParams();
  const navigate = useNavigate();
  const logout = useLogout();

  const onAcceptClick = useCallback(async () => {
    try {
      await profileService.acceptToS();
      optimisticObjectUpdater<AuthUser>("user-auth", { tosAccepted: true });
      navigate(resumeUrl);
    } catch (error) {
      errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
    }
  }, [navigate, resumeUrl]);

  return (
    <NavbarTopLayout className="terms-of-service" transparent>
      <Dialog disableBackdropClick hideBackdrop disableEscapeKeyDown open>
        <DialogTitle>Terms of Service</DialogTitle>
        <DialogContent>
          <Box py={3}>
            <Typography variant="body1" gutterBottom>
              Use of Lacework is subject to{" "}
              <Link
                className="terms-of-service-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://lacework.com/terms/"
              >
                terms
              </Link>
              , unless your company has a separate agreement with Lacework.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout} color="primary">
            Log out
          </Button>
          <Button onClick={onAcceptClick} color="primary" variant="contained">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </NavbarTopLayout>
  );
};

export default ToS;
