import React, { FC, Fragment, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { groupBy, upperFirst } from "lodash";
import moment from "moment";

import { useCurrentOrgId } from "providers";

import { getLocaleDateString } from "helpers/formatter";
import assessmentService from "services/assessmentService";
import { FindingActivity } from "types/assessments";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    comment: {
      "&:after": {
        content: `""`,
        position: "absolute",
        height: "20px",
        width: "20px",
        top: "-20px",
        left: "15px",
        backgroundColor: theme.palette.lightBackground.dark,
        clipPath: "polygon(50% 30%, 0% 100%, 100% 100%)",
      },
    },
  }),
);
interface FindingDetailActivityProps {
  findingId: string;
  assessmentId: string;

  isActive: boolean;
}

const FindingDetailActivity: FC<FindingDetailActivityProps> = ({
  assessmentId,
  findingId,
  isActive,
}) => {
  const orgId = useCurrentOrgId();
  const {
    data: activity = [],
    isLoading,
    isError,
  } = useQuery(
    [`finding-detail-activity-${findingId}`, orgId, assessmentId, findingId],
    () =>
      assessmentService.getAssessmentFindingsActivity(
        orgId,
        assessmentId,
        findingId,
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      enabled: isActive,
    },
  );

  const groupedActivity = useMemo(() => {
    const sortedActivity = activity.sort((a, b) =>
      moment(a.createTs).isBefore(moment(b.createTs)) ? 1 : -1,
    );
    const groupedResults = groupBy(sortedActivity, (result) => {
      const date = moment(result.createTs, moment.ISO_8601);
      const formattedDate = date.calendar(Date.now(), {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse: getLocaleDateString(),
      });
      return formattedDate;
    });
    return groupedResults;
  }, [activity]);

  const groupedActivityKeys = useMemo(
    () => Object.keys(groupedActivity),
    [groupedActivity],
  );

  return (
    <Box>
      {isLoading ? (
        <Skeleton count={10} />
      ) : activity.length === 0 || isError ? (
        <Box>
          <Box pt={8} textAlign="center">
            <Typography style={{ opacity: 0.4 }} variant="h1" gutterBottom>
              <FontAwesomeIcon icon={faBookOpen} />
            </Typography>
            <Typography variant="body2" gutterBottom>
              There is no activity recorded.
            </Typography>
          </Box>
        </Box>
      ) : (
        groupedActivityKeys.map((day, i) => (
          <Fragment key={i}>
            <Box mt={i === 0 ? 0 : 2.5} mb={2.5}>
              <Typography variant="h4">{day}</Typography>
            </Box>
            {groupedActivity[day].map((j, y) => (
              <FindingDetailActivityRow key={y} activity={j} />
            ))}
          </Fragment>
        ))
      )}
    </Box>
  );
};

export default FindingDetailActivity;

interface FindingDetailActivityRowProps {
  activity: FindingActivity;
}

const FindingDetailActivityRow: FC<FindingDetailActivityRowProps> = ({
  activity,
}) => {
  const classes = useStyles();
  const suppression =
    activity.suppression.toLowerCase() === "repository"
      ? "resource"
      : activity.suppression;
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={3} sm={2}>
          {moment(activity.createTs, moment.ISO_8601).format("hh:mm:ss A")}
        </Grid>
        <Grid item xs={9} sm={10}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Box fontWeight="fontWeightMedium" display="inline">
                {activity.user}
              </Box>{" "}
              {`${
                activity.suppressed
                  ? `suppressed this finding at a ${upperFirst(
                      suppression,
                    )} level.`
                  : `deleted the ${upperFirst(
                      suppression,
                    )} level suppression for this finding.`
              }`}
            </Box>
          </Box>
          <Box
            position="relative"
            mt={2}
            className={classes.comment}
            p={1}
            bgcolor="lightBackground.main"
            maxWidth={600}
          >
            {activity.comment}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
