import axios from "./axiosInstance";
import { PaginatedResponse, Service } from "types";

const getServices = (orgId: string) =>
  axios
    .get<PaginatedResponse<Service>>(`/org/${orgId}/products`)
    .then((res) => res.data.data);

export default {
  getServices,
};
