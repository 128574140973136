import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@material-ui/core";

import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import { useInterval } from "hooks/useInterval";

const SuccessAccountDeletion: FC = () => {
  const [seconds, setSeconds] = useState(10);
  const navigate = useNavigate();

  useInterval(() => setSeconds((prev) => prev - 1), 1000);

  useEffect(() => {
    if (seconds === 0) navigate("/");
  }, [seconds, navigate]);

  return (
    <NavbarTopLayout white>
      <Grid
        container
        style={{ height: "100%" }}
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          direction="column"
          xs={12}
          md={6}
          xl={4}
        >
          <Box color="success.main" mt={1} mb={7}>
            <FontAwesomeIcon
              style={{ width: 110, height: 110 }}
              icon={faCheckCircle}
            />
          </Box>
          <Box textAlign="center" color="success.main" component="h2">
            Account deleted successfully
          </Box>
          <Box textAlign="center" mb={4}>
            We're sorry to see you go!
          </Box>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            You will be redirected in {seconds}s ...
          </Typography>
        </Grid>
      </Grid>
    </NavbarTopLayout>
  );
};

export default SuccessAccountDeletion;
