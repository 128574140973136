import React, { FC } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useIsLogged, useIsLogging, useIsSupportOrg } from "./providers";

import BitBucketRedirector from "components/BitBucketRedirector/BitBucketRedirector";
import EmailRedirector from "components/EmailRedirector/EmailRedirector";
import GitLabRedirector from "components/GitLabRedirector/GitLabRedirector";
import AppLayout from "components/Layout/AppLayout";
import OrganizationLogin from "components/OrganizationLogin";
import { useQueryParams } from "hooks/useQueryParams";
import AdminRouter from "pages/Admin/AdminRouter";
import AnalyticsRouter from "pages/Analytics";
import AssessmentsRouter from "pages/Assessments/AssessmentsRouter";
import ChangeOrg from "pages/ChangeOrg";
import PipelinesRouter from "pages/CiCdPipelines/PipelinesRouter";
import CLIRouter from "pages/CLI/CLIRouter";
import ConnectBitbucket from "pages/ConnectBitbucket";
import ConnectGitLab from "pages/ConnectGitLab";
import DashboardRouter from "pages/Dashboard";
import ErrorRouter from "pages/Error";
import Events from "pages/Events/Events";
import GetStartedRouter from "pages/GetStarted/GetStartedRouter";
import GetStarted from "pages/GetStarted/pages/GetStarted";
import IntegrationsRouter from "pages/Integrations";
import Loader from "pages/Loader";
import Login from "pages/Login/Login";
import NoMatch from "pages/NoMatch/NoMatch";
import NotificationsRouter from "pages/Notifications/NotificationsRouter";
import Policies from "pages/Policies/Policies";
import ProfileRouter from "pages/Profile/ProfileRouter";
import Register from "pages/Register";
import RepositoriesRouter from "pages/Repositories/RepositoriesRouter";
import ResourcesRouter from "pages/Resources/ResourcesRouter";
import ServicesRouter from "pages/Services";
import SlackAuthorization from "pages/SlackAuthorization";
import SuccessRouter from "pages/Success";
import SupportRouter from "pages/Support/SupportRouter";
import ToS from "pages/ToS/ToS";
import Usage from "pages/Usage/Usage";
import Violations from "pages/Violations";
import AddZodiac from "pages/Zodiac/pages/AddZodiac/AddZodiac";
import ZodiacRouter from "pages/Zodiac/ZodiacRouter";
import HasFlagRoute from "router/HasFlagRoute";

import "react-toastify/dist/ReactToastify.min.css";

const HandleUnauthorizedRedirect = () => {
  const { pathname, search } = useLocation();
  const { orgId } = useQueryParams<{ orgId: string }>();

  if (orgId) {
    const paramsObject = new URLSearchParams(search);
    paramsObject.delete("orgId");
    const paramsString = paramsObject.toString();
    const filteredSearch = paramsString.length > 0 ? `?${paramsString}` : "";

    return (
      <Navigate
        to={`/login/${orgId}`}
        state={{
          pathname,
          search: filteredSearch,
        }}
      />
    );
  }

  return <Navigate to="/" state={{ pathname, search }} />;
};

const HandleAuthorizedRedirect = () => {
  const { state } = useLocation();
  const { pathname, search } = state || {};

  if (pathname) {
    return <Navigate to={{ pathname, search }} />;
  }

  return <Navigate to="/dashboard" replace />;
};

const App: FC = () => {
  const isLogging = useIsLogging();
  const isLogged = useIsLogged();
  const { emailRedirectorUiEnabled } = useFlags();
  const { orgId } = useQueryParams<{ orgId: string }>();
  const isSupportOrg = useIsSupportOrg();

  if (isLogging) {
    return <Loader>Fetching User Data</Loader>;
  }

  // Authenticated user
  if (isLogged) {
    if (orgId) {
      return <ChangeOrg />;
    }
    return (
      <div className="App">
        <ToastContainer
          className="toastify-override"
          closeButton={false}
          transition={Slide}
        />
        <AppLayout>
          <Routes>
            <Route path="/dashboard" element={<DashboardRouter />} />

            {!isSupportOrg && (
              <Route path="/services/*" element={<ServicesRouter />} />
            )}

            <Route path="/admin/*" element={<AdminRouter />} />

            <Route path="/integrations/*" element={<IntegrationsRouter />} />

            <Route path="/analytics/*" element={<AnalyticsRouter />} />

            <Route path="/add-zodiac" element={<AddZodiac />} />

            {!isSupportOrg && (
              <Route path="/zodiac/*" element={<ZodiacRouter />} />
            )}

            <Route path="/success/*" element={<SuccessRouter />} />

            <Route path="/get-started/*" element={<GetStartedRouter />} />

            <Route path="/notifications/*" element={<NotificationsRouter />} />

            <Route path="/slack-user-auth" element={<SlackAuthorization />} />

            <Route
              path="/events"
              element={
                <HasFlagRoute flag="eventsPageEnabled" element={<Events />} />
              }
            />

            {!isSupportOrg && (
              <Route
                path="/policies"
                element={
                  <HasFlagRoute flag="policyEnabled" element={<Policies />} />
                }
              />
            )}

            <Route
              path="/resources/*"
              element={
                <HasFlagRoute
                  flag="resourcesPageEnabled"
                  element={<ResourcesRouter />}
                />
              }
            />

            {!isSupportOrg && (
              <Route
                path="/violations"
                element={
                  <HasFlagRoute
                    flag="violationsPageEnabled"
                    element={<Violations />}
                  />
                }
              />
            )}

            <Route
              path="/assessments/*"
              element={
                <HasFlagRoute
                  flag="assessmentsEnabled"
                  element={<AssessmentsRouter />}
                />
              }
            />

            <Route
              path="/profile/*"
              element={
                <HasFlagRoute
                  flag="profileProfilePageEnabled"
                  element={<ProfileRouter />}
                />
              }
            />

            <Route
              path="/tos"
              element={
                <HasFlagRoute
                  flag="onboardingTermsOfServicesPage"
                  element={<ToS />}
                />
              }
            />

            <Route path="/cli/*" element={<CLIRouter />} />

            {!isSupportOrg && (
              <Route
                path="/repos/*"
                element={
                  <HasFlagRoute
                    flag="gitReposEnabled"
                    element={<RepositoriesRouter />}
                  />
                }
              />
            )}

            {!isSupportOrg && (
              <Route
                path="/pipelines/*"
                element={
                  <HasFlagRoute
                    flag="ciCdPipelinesEnabled"
                    element={<PipelinesRouter />}
                  />
                }
              />
            )}

            {!isSupportOrg && (
              <Route
                path="/usage"
                element={
                  <HasFlagRoute flag="usagePageEnabled" element={<Usage />} />
                }
              />
            )}
            {isSupportOrg && (
              <Route path="/support/*" element={<SupportRouter />} />
            )}

            <Route path="/connect-gitlab" element={<ConnectGitLab />} />
            <Route path="/connect-bitbucket" element={<ConnectBitbucket />} />
            <Route path="/login" element={<Navigate to="/dashboard" />} />
            <Route path="/register" element={<Navigate to="/dashboard" />} />
            <Route path="/error/*" element={<ErrorRouter />} />
            <Route path="/404" element={<NoMatch />} />
            <Route path="/" element={<HandleAuthorizedRedirect />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
          <GitLabRedirector />
          <BitBucketRedirector />
          {emailRedirectorUiEnabled && <EmailRedirector />}
        </AppLayout>
      </div>
    );
  }

  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/login/:orgId" element={<OrganizationLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/success/*" element={<SuccessRouter />} />
        <Route path="/error/*" element={<ErrorRouter />} />
        {/* This will match anything and redirect to the login page */}
        <Route path="*" element={<HandleUnauthorizedRedirect />} />
      </Routes>
    </div>
  );
};

export default App;
