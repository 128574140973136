/**
 * Test whether the input value is URL friendly. Specifically this test is the string:
 * - Have only english letters, numbers and hyphen symbol.
 * - Start with a letter
 * - End with a letter or digit
 *
 * @param value The string to be validated.
 */
export const isURLFriendly = (value: string) => {
  const isStartingWithLetters = /^[a-zA-Z]/.test(value);
  const doesContainsOnlyValidCharacters = /^[a-zA-Z0-9-]+$/.test(value);
  const isEndingWithLettersOrNumbers = /[a-zA-Z0-9]$/.test(value);

  return {
    isStartingWithLetters,
    doesContainsOnlyValidCharacters,
    isEndingWithLettersOrNumbers,
  };
};

/**
 * Test whether the input value is a valid domain.
 *
 * @param value The string to be validated.
 */
export const isValidDomain = (value: string) =>
  /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/.test(value);

export const isValidEnumValue = <T extends Record<string, string | number>>(
  enumLike: T,
  value?: string | number,
): value is T[keyof T] => {
  return Object.keys(enumLike)
    .map((key) => enumLike[key])
    .some((type) => type === value);
};

export const isValidEnumKey = <
  T extends Record<string, string | number>,
  K extends keyof T,
>(
  enumLike: T,
  value: keyof any,
): value is K => {
  return Object.keys(enumLike).some((key) => key === value);
};
