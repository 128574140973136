import { FC, Fragment, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import { toast } from "react-toastify";
import { Box, Card, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

import { useCurrentOrgId } from "providers";

import RepositoryBranchAssessment from "./components/RepositoryBranchAssessment";
import DefaultTablePagination from "components/DataTable/components/DefaultTablePagination";
import SearchBar from "components/DataTable/components/SearchBar";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import SvgIcon from "components/SvgIcon/SvgIcon";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import repositoryService from "services/repositoryService";
import { BranchAssessment, BranchAssessmentMain } from "types/assessments";

interface BranchAssessmentR {
  branch: keyof BranchAssessmentMain;
  commit: string[];
  title: string[];
  assesments: BranchAssessment[];
}

const columns: Column<BranchAssessmentR>[] = [
  {
    Header: "Branch",
    accessor: "branch",
  },
  {
    Header: "Commit",
    accessor: "commit",
    filter: "text",
  },
  {
    Header: "title",
    accessor: "title",
    filter: "text",
  },
];

const RepositoryBranches: FC = () => {
  const orgId = useCurrentOrgId();

  const { gitRepoName = "" } = useParams<{ gitRepoName: string }>();

  const { data, isLoading, isError, refetch } = useQuery(
    ["repository-branch-assessments", orgId, gitRepoName],
    () => repositoryService.getRepoAssessments(orgId, gitRepoName),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const refetchData = () => {
    toast.promise(
      refetch,
      {
        pending: "Refreshing data...",
        success: "Refreshed successfully!",
        error:
          "An error ocurred while refreshing the data. Please try again later.",
      },
      {
        autoClose: 2000,
      },
    );
  };

  const branches = useMemo(
    () => Object.keys(data?.data ?? {}) as (keyof BranchAssessmentMain)[],
    [data],
  );

  const branchAssessments = useMemo(() => {
    return branches.map((b) => ({
      branch: b,
      commit: data?.data[b].map((c) => c.gitCommit) ?? [],
      title:
        data?.data[b].map((c) =>
          c.gitPrTitle.length > 0
            ? c.gitPrTitle
            : c.title + " " + c.assessmentTs,
        ) ?? [],
      assesments: data?.data[b] ?? [],
    }));
  }, [branches, data?.data]);

  const tableInstance = useTable(
    {
      data: branchAssessments,
      columns,
      initialState: {
        pageSize: 10,
      },
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    usePagination,
  );

  const { page, setGlobalFilter, gotoPage, state, rows } = tableInstance;

  const tableRef = useRef<DataTableRef<BranchAssessmentR>>({
    tableInstance,
  });

  const handleSearchChange = (filterValue: string) => {
    setGlobalFilter(filterValue);
    gotoPage(0);
  };

  const handlePageChange = (_: unknown, newPage: number) => {
    gotoPage(newPage);
  };

  return (
    <Box component={Card} p={2}>
      {isLoading ? (
        [...Array(10)].map((_, i) => (
          <Box key={i} mb={2} p={2} border="1px solid lightgray">
            <Skeleton />
          </Box>
        ))
      ) : (
        <Fragment>
          <Box display="flex" alignItems="center" mb={2}>
            <Box display="inline-flex" ml={-1}>
              <SearchBar
                onChange={handleSearchChange}
                initialValue=""
                disabled={isLoading}
              />
            </Box>
            <Tooltip title="Refresh data" arrow>
              <IconButton onClick={refetchData} color="primary">
                <Refresh />
              </IconButton>
            </Tooltip>
            <FiltersModal
              tableRef={tableRef}
              fields={[
                {
                  key: "title",
                  displayName: "Title",
                  type: "textfield",
                },
                {
                  key: "branch",
                  displayName: "Branch",
                  type: "select",
                  values: branches.map((b) => ({ displayName: b, value: b })),
                },
                {
                  key: "commit",
                  displayName: "Commit",
                  type: "textfield",
                },
              ]}
              disabled={isLoading}
              title="Filter Branches"
            />
            <Box ml="auto">
              <DefaultTablePagination
                pageIndex={state.pageIndex}
                count={rows.length}
                pageSize={state.pageSize}
                onChangePage={handlePageChange}
                pageSizeOptions={[]}
              />
            </Box>
          </Box>
          {page.length > 0 ? (
            page.map(({ original: bA }) => (
              <Box key={bA.branch} mb={2}>
                <RepositoryBranchAssessment
                  data={{
                    name: bA.branch,
                    assessments: bA.assesments,
                  }}
                />
              </Box>
            ))
          ) : (
            <Box width="100%" textAlign="center">
              <SvgIcon
                width={50}
                height={50}
                color="lightgray"
                icon="gitRepos"
              />
              <Typography align="center">
                {isError ? (
                  <Fragment>
                    An error ocurred while getting the data. <br />
                    Please try again later.
                  </Fragment>
                ) : (
                  "No assessments found."
                )}
              </Typography>
            </Box>
          )}
          <DefaultTablePagination
            pageIndex={state.pageIndex}
            count={rows.length}
            onChangePage={handlePageChange}
            pageSize={10}
            pageSizeOptions={[]}
          />
        </Fragment>
      )}
    </Box>
  );
};

export default RepositoryBranches;
