import instance from "./axiosInstance";
import { APIResponse, PaginatedResponse } from "types";
import { Assessment, AssessmentDetail } from "types/assessments";
import {
  ZodiacBuildAssessmentsDiff,
  ZodiacBuildMetadata,
  ZodiacBuildStatus,
  ZodiacInstance,
  ZodiacJobEvent,
  ZodiacJobEventDetail,
  ZodiacLog,
  ZodiacManifest,
  ZodiacSummary,
} from "types/zodiac";

const getZodiacInstances = (orgId: string) =>
  instance
    .get<PaginatedResponse<ZodiacInstance>>(`/org/${orgId}/zodiac/instances`)
    .then((res) => res.data.data);

const getZodiacInstanceLogs = (orgId: string, instanceId: string) =>
  instance
    .get<APIResponse<ZodiacLog>>(
      `/org/${orgId}/zodiac/instances/${instanceId}/log`,
    )
    .then((res) => res.data.data);

const getZodiacJobEventLogs = (orgId: string, jobName: string) =>
  instance
    .get<APIResponse<ZodiacLog>>(`/org/${orgId}/zodiac/jobs/${jobName}/log`)
    .then((res) => res.data.data);

const getZodiacJobEvents = (orgId: string) =>
  instance
    .get<PaginatedResponse<ZodiacJobEvent>>(`/org/${orgId}/zodiac/job/events`)
    .then((res) => res.data.data);

const getZodiacJobEvent = (orgId: string, jobEventId: string) =>
  instance
    .get<APIResponse<ZodiacJobEventDetail>>(
      `/org/${orgId}/zodiac/job/events/${jobEventId}`,
    )
    .then((res) => res.data.data);

const getZodiacBuildAssessments = (orgId: string, ciBuildId: string) =>
  instance
    .get<PaginatedResponse<Assessment>>(
      `/org/${orgId}/zodiac/build/${ciBuildId}/assessments`,
    )
    .then((res) => res.data.data);

const getZodiacBuildAssessmentsSummary = (orgId: string, ciBuildId: string) =>
  instance
    .get<PaginatedResponse<ZodiacSummary>>(
      `/org/${orgId}/zodiac/build/${ciBuildId}/assessments/summary`,
    )
    .then((res) => res.data.data);

const getZodiacBuildAssessmentsMetadata = (orgId: string, ciBuildId: string) =>
  instance
    .get<APIResponse<ZodiacBuildMetadata>>(
      `/org/${orgId}/zodiac/build/${ciBuildId}/assessments/metadata`,
    )
    .then((res) => res.data.data);

const getZodiacBuildAssessmentsDiff = (orgId: string, ciBuildId: string) =>
  instance
    .get<PaginatedResponse<ZodiacBuildAssessmentsDiff>>(
      `/org/${orgId}/zodiac/build/${ciBuildId}/assessments/diff`,
    )
    .then((res) => res.data.data);

const getZodiacBuildAssessmentsConsolidated = (
  orgId: string,
  ciBuildId: string,
) =>
  instance
    .get<APIResponse<AssessmentDetail>>(
      `/org/${orgId}/zodiac/build/${ciBuildId}/assessments/consolidated`,
    )
    .then((res) => res.data.data);

const getZodiacBuildStatus = (orgId: string, ciBuildId: string) =>
  instance
    .get<APIResponse<ZodiacBuildStatus>>(
      `/org/${orgId}/zodiac/build/${ciBuildId}/status`,
    )
    .then((res) => res.data.data);

const getGithubZodiacManifest = (orgId: string) =>
  instance
    .get<APIResponse<ZodiacManifest>>(`/org/${orgId}/github/zodiac-manifest`)
    .then((res) => res.data.data);

const restartZodiac = (orgId: string) =>
  instance.post(`/org/${orgId}/zodiac/restart`).then((res) => res.data);

export default {
  getGithubZodiacManifest,
  getZodiacBuildAssessments,
  getZodiacBuildAssessmentsConsolidated,
  getZodiacBuildAssessmentsDiff,
  getZodiacBuildAssessmentsMetadata,
  getZodiacBuildAssessmentsSummary,
  getZodiacBuildStatus,
  getZodiacInstanceLogs,
  getZodiacInstances,
  getZodiacJobEvent,
  getZodiacJobEventLogs,
  getZodiacJobEvents,
  restartZodiac,
};
