import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useUser } from "providers";

import Integrations from "./Integrations";
import AwsConfiguration from "./pages/AwsConfiguration";
import Configuration from "./pages/Configuration";
import HasFlagRoute from "router/HasFlagRoute";

const IntegrationsRouter: FC<Childless> = () => {
  const isGitLabProvider = useUser().provider?.toLocaleLowerCase() === "gitlab";

  if (!isGitLabProvider) return <Navigate to="/404" />;

  return (
    <Routes>
      <Route
        path="config/aws"
        element={
          <HasFlagRoute
            flag="adapterAwsEnabled"
            element={<AwsConfiguration />}
          />
        }
      />

      <Route path="config/:configType" element={<Configuration />} />

      <Route path="/" element={<Integrations />} />
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default IntegrationsRouter;
