import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

const RepositoryParamHandler: FC = () => {
  const { pathname } = useLocation();
  const gParam = pathname.split("/").pop();
  const param = gParam?.includes("overview")
    ? "/overview"
    : gParam?.includes("settings")
    ? "/settings"
    : gParam?.includes("assessments")
    ? "/assessments"
    : gParam?.includes("jobs")
    ? "/jobs"
    : null;

  if (!param) {
    return <Navigate to={pathname + "/overview"} replace />;
  }

  const url = pathname.slice(15, pathname.lastIndexOf(param));

  return (
    <Navigate
      to={`${pathname.replace(url, encodeURIComponent(url))}`}
      replace
    />
  );
};

export default RepositoryParamHandler;
