import React, {
  forwardRef,
  Fragment,
  MutableRefObject,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { uniqueId } from "lodash";

import { useCurrentOrgId } from "providers";

import FindingsRow from "./FindingsRow";
import ButtonGroupDropdown from "components/ButtonGroupDropdown/ButtonGroupDropdown";
import ConfirmationModal, {
  ConfirmationModalRef,
} from "components/ConfirmationModal/ConfirmationModal";
import CustomDiffComponent from "components/CustomDiffComponent/CustomDiffComponent";
import {
  errorToastHandler,
  optimisticObjectUpdater,
} from "helpers/queryHelpers";
import assessmentService from "services/assessmentService";
import { AssessmentDetail, Finding } from "types/assessments";
import { Violation } from "types/violations";

interface FixFindingModalProps {
  gitRepo?: string;
  finding?: Finding | Violation;
  assessmentDetail?: AssessmentDetail;
  onClose?(): void;
  hideButton?: boolean;
  ref?: MutableRefObject<HTMLButtonElement>;
}

const FixFindingModal: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  FixFindingModalProps
> = ({ gitRepo, finding, assessmentDetail, onClose, hideButton }, ref) => {
  const orgId = useCurrentOrgId();
  const theme = useTheme();
  const isGitLabProvider =
    assessmentDetail?.gitProvider?.toLocaleLowerCase() === "gitlab";
  const [open, setOpen] = useState(false);
  const [buttonKey, setButtonKey] = useState(uniqueId());
  const [statusFetchCount, setStatusFetchCount] = useState(0);
  const [isRetrying, setIsRetrying] = useState(false);
  const confirmationModalRef = useRef<ConfirmationModalRef>(null);

  const queryClient = useQueryClient();

  // INITIATE REMEDIATION (IDEMPOTENT API)
  const { refetch: reinitiateRemediation } = useQuery(
    ["finding-remediation-start-req", orgId, assessmentDetail?.id, finding?.id],
    () =>
      assessmentService.postAutoFixInitiation(
        orgId,
        assessmentDetail?.id ?? finding?.assessmentId ?? "",
        finding?.id ?? "",
      ),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 3,
      // CALLS REMEDIATION ON:
      // 1) OPEN OF FIX FINDING MODAL.
      // 2) MOUNT OF FIX FINDING MODAL WHEN HIDE BUTTON PROPERTY IS FALSE (WHEN FINDING DETAIL IS CLICKED).
      enabled: open || !hideButton,
    },
  );

  const { data: status, refetch: refetchStatus } = useQuery(
    [
      "finding-remediation-status",
      orgId,
      assessmentDetail?.id ?? "",
      finding?.id ?? "",
    ],
    () =>
      assessmentService.getRemediationStatus(
        orgId,
        assessmentDetail?.id ?? finding?.assessmentId ?? "",
        finding?.id ?? "",
      ),
    {
      onSuccess: (data) => {
        if (data.statusCode === 102) {
          setStatusFetchCount((prev) => prev + 1);
        }
      },
      refetchInterval: (data) =>
        // 50 * 3000 = 2.5 minutes
        data?.statusCode === 102 && statusFetchCount < 50 ? 3000 : 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retryDelay: 3000,
      retry: 6,
      enabled: open,
    },
  );

  const { refetch: openPr, isLoading: isOpeningPr } = useQuery(
    ["finding-remediation-open-pr", orgId, assessmentDetail?.id, finding?.id],
    () =>
      assessmentService.postOpenAutoFixPr(
        orgId,
        assessmentDetail?.id ?? finding?.assessmentId ?? "",
        finding?.id ?? "",
      ),
    {
      refetchInterval: false,
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        errorToastHandler(
          `An error ocurred trying to open the ${
            isGitLabProvider ? "merge request" : "pull request"
          }. Please try again later.`,
        )(err);
      },
      onSuccess: () => {
        optimisticObjectUpdater(
          [
            "finding-remediation-status",
            orgId,
            assessmentDetail?.id ?? "",
            finding?.id ?? "",
          ],
          {
            prUrl: " ",
          },
        );
        refetchStatus();
      },
      enabled: false,
    },
  );

  const { data: originalFile = null } = useQuery(
    ["finding-original-file", orgId, assessmentDetail?.id, finding?.id],
    () =>
      assessmentService.getRemediationFile(
        orgId,
        assessmentDetail?.id ?? finding?.assessmentId ?? "",
        finding?.id ?? "",
        "original",
      ),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 3,
      enabled: !!(open && !!assessmentDetail && status?.completed),
    },
  );

  const { data: remediatedFile = null } = useQuery(
    ["finding-remediated-file", orgId, assessmentDetail?.id, finding?.id],
    () =>
      assessmentService.getRemediationFile(
        orgId,
        assessmentDetail?.id ?? finding?.assessmentId ?? "",
        finding?.id ?? "",
        "remediated",
      ),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 3,
      enabled: !!(open && !!assessmentDetail && status?.completed),
    },
  );

  const { refetch: regenerateFix, isFetching: isRegenerating } = useQuery(
    [
      "finding-remediation-open-pr-regen",
      orgId,
      assessmentDetail?.id,
      finding?.id,
    ],
    () =>
      assessmentService.postRegenerateAutoFix(
        orgId,
        assessmentDetail?.id ?? finding?.assessmentId ?? "",
        finding?.id ?? "",
      ),
    {
      onError: (err) => {
        errorToastHandler(
          `An error ocurred trying to regenerate the fix. Please try again later.`,
        )(err);
      },
      onSuccess: async () => {
        await queryClient.setQueryData(
          ["finding-remediated-file", orgId, assessmentDetail?.id, finding?.id],
          null,
        );
        refetchStatus();
        setButtonKey(uniqueId());
      },
      enabled: false,
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findingM = useMemo(() => finding, [open]);

  const handleOnRetryClick = async () => {
    setIsRetrying(true);
    await reinitiateRemediation();
    await refetchStatus();
    setStatusFetchCount(0);
    setIsRetrying(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRegenerateClick = () => {
    confirmationModalRef.current?.openModal({
      title: "Regenerate Fix",
      description:
        "Are you sure you want to regenerate the fix for this finding?",
      confirm: "Regenerate",
      cancel: "Cancel",
      action: regenerateFix,
    });
  };

  return (
    <Fragment>
      <Box
        display="inline-block"
        visibility={hideButton ? "hidden" : "visible"}
      >
        <Button
          innerRef={ref}
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          Fix Now
        </Button>
      </Box>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={handleDialogClose}
        onExited={onClose}
        aria-labelledby="form-dialog-title"
      >
        <Box
          minWidth={theme.breakpoints.values.md}
          p={3}
          pb={0}
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h3" gutterBottom>
            Review Fix
          </Typography>
        </Box>
        <DialogContent>
          <Box mb={2} p={1} bgcolor="lightBackground.main" borderRadius={4}>
            {findingM && (
              <Fragment>
                <FindingsRow
                  finding={findingM}
                  gitRepo={gitRepo ?? assessmentDetail?.gitRepo}
                />
              </Fragment>
            )}
          </Box>

          {status?.statusCode === 500 || statusFetchCount >= 50 ? (
            <Box p={3} textAlign="center">
              <Tooltip title={isRetrying ? "Retrying" : "Retry"} arrow>
                <IconButton
                  disabled={isRetrying}
                  onClick={handleOnRetryClick}
                  color="primary"
                >
                  {isRetrying ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <Refresh />
                  )}
                </IconButton>
              </Tooltip>
              <Typography variant="body1" gutterBottom>
                {status?.statusCode === 500
                  ? "Failed to create a remediation"
                  : "The remediation creation has timed out."}
              </Typography>
            </Box>
          ) : (
            <Fragment>
              <Box mb={2}>
                {status?.prUrl
                  ? "Pull "
                  : `Open a ${isGitLabProvider ? "merge" : "pull"} `}
                request to fix this finding in your repository's default branch:
              </Box>
              {status?.prUrl ? (
                <Box
                  mb={2}
                  p={1}
                  bgcolor="lightBackground.main"
                  borderRadius={4}
                >
                  <a
                    href={status?.prUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {status?.prUrl}
                  </a>
                </Box>
              ) : isOpeningPr ? (
                <Box my={3} mt={5}>
                  <LinearProgress color="secondary" />
                </Box>
              ) : (
                <CustomDiffComponent
                  hideLineNumbers
                  isLoading={originalFile === null || remediatedFile === null}
                  oldValue={originalFile ?? ""}
                  newValue={remediatedFile ?? ""}
                  disableWordDiff
                  wrapperProps={{
                    maxHeight: "50vh",
                    overflow: "scroll",
                  }}
                />
              )}
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Box mr={2} display="inline-block">
              <Button onClick={handleDialogClose} color="primary">
                Close
              </Button>
            </Box>
            <ButtonGroupDropdown
              key={buttonKey}
              options={[
                status?.prUrl
                  ? {
                      action: () => window.open(status.prUrl, "_blank"),
                      label: `Go to ${
                        isGitLabProvider ? "Merge" : "Pull"
                      } Request`,
                    }
                  : {
                      action: openPr,
                      label: `Open ${
                        isGitLabProvider ? "Merge" : "Pull"
                      } Request`,
                    },
                {
                  action: handleRegenerateClick,
                  label: (
                    <Box display="flex" alignItems="center">
                      {isRegenerating && (
                        <Box mr={1} pt={0.5}>
                          <CircularProgress color="inherit" size={16} />
                        </Box>
                      )}
                      Regenerate Fix
                    </Box>
                  ),
                },
              ]}
              disabled={
                !status?.completed ||
                isOpeningPr ||
                status?.statusCode === 500 ||
                isRegenerating ||
                remediatedFile === null
              }
            />
          </Box>
        </DialogActions>
        <ConfirmationModal ref={confirmationModalRef} />
      </Dialog>
    </Fragment>
  );
};

export default forwardRef(FixFindingModal);
