import instance from "./axiosInstance";
import { APIResponse } from "types";
import { UsageReport } from "types/usage";

const getUsageReport = (orgId: string) =>
  instance
    .get<APIResponse<UsageReport>>(`/org/${orgId}/usage/report`)
    .then((res) => res.data.data);

export default {
  getUsageReport,
};
