import React, { FC, Fragment, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Card,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";

import { useCurrentOrgId } from "providers";

import JobDetailHeader from "./components/JobDetailHeader";
import JobDetailTabs from "./components/JobDetailTabs";
import JobSteps from "./components/JobSteps";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import repositoryService from "services/repositoryService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      padding: 0,
      width: "100%",
    },
  }),
);
export enum JobTabs {
  Steps = "steps",
}

const JobDetail: FC = () => {
  const classes = useStyles();
  const { tab = "steps", ciBuildId = "" } = useParams<{
    ciBuildId: string;
    tab: string;
  }>();
  const orgId = useCurrentOrgId();
  const [isRunning, setIsRunning] = useState(false);
  const { data: metadata, isLoading } = useQuery(
    ["job-status", orgId, ciBuildId],
    () => repositoryService.getRepoJobStatus(orgId, ciBuildId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      onSuccess: (d) => setIsRunning(d.status.toLowerCase() === "running"),
      refetchInterval: isRunning ? 5000 : false,
    },
  );

  return (
    <Fragment>
      <PageHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          Job {ciBuildId}
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to={`/zodiac/jobs`}>
              Jobs
            </Link>
            <Typography color="textPrimary">{ciBuildId}</Typography>
          </Breadcrumbs>
        </Box>
      </PageHeader>
      <Box p={3} component={Card}>
        {isLoading ? (
          <Box>
            <Skeleton width={300} />
            <Box mb={4} />
            <Skeleton width="100%" height={30} />
            <Box mb={5} />
          </Box>
        ) : (
          <Fragment>
            <Box mt={2} mb={5}>
              <JobDetailHeader metadata={metadata} />
            </Box>
          </Fragment>
        )}
        <JobDetailTabs tab={tab} />
        <TabContext value={tab}>
          <TabPanel className={classes.tabPanel} value={JobTabs.Steps}>
            {isLoading ? (
              <Box lineHeight={4}>
                <Skeleton count={4} height={35} />
              </Box>
            ) : (
              <JobSteps
                steps={metadata?.steps ?? []}
                isJobRunning={isRunning}
              />
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </Fragment>
  );
};

export default JobDetail;
