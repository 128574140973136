import React, { FC, Fragment } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Box, Grid, LinearProgress } from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import DashboardElement from "./components/DashboardElement";
import PageHeader from "components/PageHeader";
import dashboardService from "services/dashboardService";
import { DashboardElementMetadata } from "types/dashboard";

const defaultQuery: DashboardElementMetadata[] = [];

const Dashboard: FC = () => {
  const orgId = useCurrentOrgId();

  const { data: elements = defaultQuery, isLoading } = useQuery(
    ["dashboard-elements", orgId],
    () => dashboardService.getDashboardElements(orgId),
    {
      onError: () =>
        toast.error(
          "Failed to load the dashboard elements. Please try again in a few minutes.",
        ),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Fragment>
      <PageHeader>Dashboard</PageHeader>
      {isLoading ? (
        <Box p={4}>
          <LinearProgress color="primary" />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {elements.map((element, ind) => (
            <DashboardElement
              getterFunction={dashboardService.getDashboardElementData}
              key={element.id ?? ind}
              element={element}
            />
          ))}
        </Grid>
      )}
    </Fragment>
  );
};

export default Dashboard;
