import React, { FC, Fragment, useMemo } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import {
  Box,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";

import { JobTabs } from "../JobDetail";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabLink: {
      "&:hover, &:focus, &:visited, &:link, &:active": {
        textDecoration: "none",
      },
    },
  }),
);

interface JobDetailTabsProps {
  tab: string;
}

const JobDetailTabs: FC<JobDetailTabsProps> = ({ tab }) => {
  const classes = useStyles();
  const { ciBuildId } = useParams<{ ciBuildId: string }>();

  const isTabValid = useMemo(() => tab === JobTabs.Steps, [tab]);

  if (!isTabValid) {
    return <Navigate to={`/zodiac/jobs/${ciBuildId}`} />;
  }

  return (
    <Fragment>
      <Tabs value={tab} indicatorColor="primary" textColor="primary">
        <Tab
          className={classes.tabLink}
          component={Link}
          to={`/zodiac/jobs/${ciBuildId}`}
          value={JobTabs.Steps}
          label="STEPS"
        />
      </Tabs>
      <Box component="hr" mt={0} mb={1} />
    </Fragment>
  );
};

export default JobDetailTabs;
