const common = {
  API: "/api/v1",
};

const development = {
  ENVIRONMENT: "development",
  LOCAL_ROOT_URL: "https://localhost.soluble.cloud:5443",
  API: "https://localhost.soluble.cloud:5443/api/v1",
};

const envs = {
  development: development,
};

export default {
  ...common,
  ...envs[process.env.REACT_APP_NODE_ENV],
};
