import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Home } from "@material-ui/icons";

import { ReactComponent as Denied } from "assets/svg/illustrations/access-denied.svg";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";

const Unauthorized: FC = () => {
  const { state } = useLocation();
  const orgId = state?.orgId ?? null;
  return (
    <NavbarTopLayout white>
      <Grid container alignItems="center" justify="center">
        <Grid item sm={10} md={8} lg={6} xl={4}>
          <Box textAlign="center">
            <Box component={Denied} maxWidth={330} my={4} mx="auto" />
            <Typography variant="h1" color="primary" gutterBottom>
              Unauthorized Access
            </Typography>
            <Typography gutterBottom>
              You are not authorized to access this organization
              {`${orgId ? ` (${orgId})` : ""}`}.
            </Typography>
            <Box component="hr" />
            <Box mb={2}>
              If you think this is a mistake please contact an organization
              administrator.
            </Box>
            <Button
              startIcon={<Home />}
              component={Link}
              to="/"
              size="small"
              color="primary"
              variant="outlined"
            >
              Take me home
            </Button>
          </Box>
        </Grid>
      </Grid>
    </NavbarTopLayout>
  );
};

export default Unauthorized;
