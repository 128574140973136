import { AxiosResponse } from "axios";

import instance from "./axiosInstance";
import { APIResponse, PaginatedResponse } from "types";
import {
  AwsAccount,
  AwsAccountStatusDetail,
  IntegrationTestResult,
  JiraProject,
} from "types/integration";
import { CommonMetadata, MetadataPayload } from "types/integration-metadata";

/**
 * Metadata
 */

const getMetadataDetails = ({
  orgId,
  configType,
}: {
  orgId: string;
  configType: string;
}) =>
  instance.get<CommonMetadata>(`/org/${orgId}/adapter-metadata/${configType}`);

const updateMetadata = (
  { orgId, configType, instanceName }: UpdateMetadataProps,
  payload: MetadataPayload,
  patch?: boolean,
) =>
  patch
    ? instance.patch<void>(
        `/org/${orgId}/adapter-config/${configType}/${instanceName}`,
        payload,
      )
    : instance.post<void>(
        `/org/${orgId}/adapter-config/${configType}/${instanceName}`,
        payload,
      );

const testConfiguration = (
  { orgId, configType, instanceName }: UpdateMetadataProps,
  payload: MetadataPayload,
) =>
  instance
    .post<APIResponse<IntegrationTestResult>>(
      `/org/${orgId}/adapter-config/${configType}/${instanceName}/test`,
      payload,
    )
    .then((res) => res.data.data);

/**
 * AWS
 */

const getAwsSetupScript = (orgId: string) =>
  instance.get(`/org/${orgId}/aws/iam-setup-script`).then((res) => res.data);

const getAwsAccounts = (orgId: string) =>
  instance
    .get<PaginatedResponse<AwsAccount>>(`/org/${orgId}/aws/accounts`)
    .then((res) => res.data.data);

const getAwsAccountStatus = async (orgId: string, account: string) =>
  instance
    .get<APIResponse<AwsAccountStatusDetail>>(
      `/org/${orgId}/aws/accounts/${account}/status`,
    )
    .then((res) => res.data.data);

const removeAwsAccount = (orgId: string, account: string) =>
  instance.delete<void>(`/org/${orgId}/aws/accounts/${account}`);

interface ConnectAwsAccountPayload {
  account: string;
}

const connectAwsAccount = (orgId: string, payload: ConnectAwsAccountPayload) =>
  instance.post<void>(`/org/${orgId}/aws/accounts`, payload);

/**
 * GITLAB
 */

const addGitLabAccessToken = (orgId: string, accessToken: string) =>
  instance.post<void>(`/org/${orgId}/adapter-config/GitLab/default`, {
    accessToken,
  });

interface UpdateMetadataProps {
  orgId: string;
  configType: string;
  instanceName: string;
}

/**
 * Jira
 */
const getJiraProjects = async (orgId: string) =>
  instance
    .get<AxiosResponse<JiraProject[]>>(`/org/${orgId}/jira/projects`)
    .then((res) => res);

export default {
  connectAwsAccount,
  getAwsAccountStatus,
  getAwsAccounts,
  getAwsSetupScript,
  getJiraProjects,
  getMetadataDetails,
  removeAwsAccount,
  testConfiguration,
  updateMetadata,
  addGitLabAccessToken,
};
