import { createContext } from "react";

import { AuthUser } from "types/auth-user";

export interface AppContextState {
  user?: AuthUser;
  isLogging: boolean;

  getUser(): void;
  logout(): Promise<void>;
  changeOrg(orgId: string): Promise<void>;
}

export const AppContext = createContext<AppContextState>({
  isLogging: false,
  getUser() {},
  changeOrg() {
    return new Promise<void>((resolve, reject) => {});
  },
  logout() {
    return new Promise<void>((resolve, reject) => {});
  },
});
