import React, { FC } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faAws,
  faGithub,
  faGoogle,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import { faDocker } from "@fortawesome/free-brands-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import classNames from "classnames";

import { facKubernetes, facOracle } from "assets/svg/icons/custom-fa-icons";
import Badge from "components/BootstrapBadge/BootstrapBadge";
import { Policy } from "types/policies";

import styles from "./ProviderIconLabel.module.scss";

interface ProviderIconLabelProps {
  provider?: Policy["provider"];
}

const Icons: Record<Policy["provider"], IconDefinition> = {
  AWS: faAws,
  Azure: faMicrosoft,
  Docker: faDocker,
  GCP: faGoogle,
  GitHub: faGithub,
  Kubernetes: facKubernetes,
  Oracle: facOracle,
  Unknown: faCloud,
};

const ProviderIconLabel: FC<ProviderIconLabelProps> = ({
  provider = "Unknown",
}) => {
  return (
    <Badge
      style={{ width: 100 }}
      color="secondary"
      pill
      className={classNames(styles[provider])}
    >
      <Box display="inline-block" mr={0.5}>
        <FontAwesomeIcon icon={Icons[provider]} />
      </Box>{" "}
      {provider}
    </Badge>
  );
};

export default ProviderIconLabel;
