import React, { FC, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AddCircleOutlineRounded,
  AssessmentOutlined,
  QueryBuilderRounded,
  RemoveCircleRounded,
} from "@material-ui/icons";
import classNames from "classnames";
import moment from "moment";
import useCommonStyles from "theme/useCommonStyles";

import IacBotJobExpanded from "./IacBotJobExpanded";
import GitRepoInformation from "components/GitRepoInformation/GitRepoInformation";
import ZodiacStatusChip from "pages/Zodiac/pages/Zodiac/components/ZodiacStatusChip";
import { IacBotJob as IacBotJobI } from "types/iacBot";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        background: theme.palette.lightBackground.main,
      },
    },
    iconButton: {
      opacity: 0.7,
      padding: 0,
    },
    opacity7: {
      opacity: 0.7,
    },
    bold: {
      fontWeight: 500,
    },
    cursorPointer: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  }),
);

interface IacBotJobProps {
  job: IacBotJobI;
}

const IacBotJob: FC<IacBotJobProps> = ({ job }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

  const requestTs = moment(job?.requestTs ?? moment.invalid());
  const endTs = moment(job?.endTs ?? moment.invalid());
  const duration = moment.duration(endTs.diff(requestTs));
  const durationString =
    job.success !== undefined && requestTs.isValid() && endTs.isValid()
      ? `${job.success ? "Passed in" : "Failed in"} ${
          duration.hours() ? duration.hours() + "h " : ""
        }${duration.minutes() ? duration.minutes() + "m " : ""}${
          duration.seconds() + "s"
        }`
      : undefined;

  const toggleExpanded = () => setExpanded((prev) => !prev);

  return (
    <Fragment>
      <Box
        borderTop="1px solid lightgray"
        display="flex"
        width="100%"
        flexWrap={matchesMd ? "wrap" : "nowrap"}
        py={1.5}
        alignItems="flex-start"
        onClick={toggleExpanded}
        className={classNames(classes.root, classes.cursorPointer)}
      >
        <Box className={classes.bold} mr={matches ? 2 : 6} display="flex">
          <Box mr={matches ? 2 : 5}>
            <IconButton className={classes.iconButton} size="small">
              {expanded ? <RemoveCircleRounded /> : <AddCircleOutlineRounded />}
            </IconButton>
          </Box>
          {moment(job.createTs, moment.ISO_8601).format("hh:mm:ss A")}
        </Box>
        <Box
          flex={1}
          flexBasis={360}
          display="flex"
          flexDirection="column"
          width="100%"
          minWidth={0}
        >
          <Box
            className={classes.cursorPointer}
            display="flex"
            mb={1}
            width="100%"
          >
            <Box
              mr={2}
              minWidth={0}
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              className={classNames(classes.bold, commonStyles.wordBreakAll)}
            >
              <Tooltip
                title={job.commitMsg.length > 70 ? job.commitMsg : ""}
                arrow
              >
                <Link to={`/zodiac/jobs/${job.jobNum}`}>
                  {job.jobNum}
                  {job.commitMsg
                    ? ` | ${
                        job.commitMsg.length > 70
                          ? job.commitMsg.substring(0, 70) + "..."
                          : job.commitMsg
                      }`
                    : ""}
                </Link>
              </Tooltip>
            </Box>
            <ZodiacStatusChip status={job.status} />
          </Box>
          <Box className={classes.opacity7}>
            <GitRepoInformation {...job} />
          </Box>
          <Collapse in={expanded}>
            <Box width="100%" mt={2} pl={2} borderLeft="3px solid lightgray">
              <IacBotJobExpanded job={job} />
            </Box>
          </Collapse>
        </Box>
        <Box ml={1}>
          {durationString && (
            <Box display="flex" alignItems="center">
              <Box component={QueryBuilderRounded} width={16} mr={1} />
              <Typography variant="h6">{durationString}</Typography>
            </Box>
          )}
          {job.status === "COMPLETED" && (
            <Link color="primary" to={`/zodiac/jobs/${job.jobNum}/assessments`}>
              <Box display="flex" alignItems="center">
                <Box component={AssessmentOutlined} mr={1} />
                <Typography variant="h5" display="inline">
                  VIEW FINDINGS
                </Typography>
              </Box>
            </Link>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default IacBotJob;
