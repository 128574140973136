import React, { FC, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";

import JobStep from "./JobStep";

interface JobStepsProps {
  steps: string[];
  isJobRunning: boolean;
}

const JobSteps: FC<JobStepsProps> = ({ steps, isJobRunning }) => {
  const { ciBuildId = "" } = useParams<{
    ciBuildId: string;
  }>();

  return (
    <Fragment>
      {steps.map((s, i) => (
        <Box key={i} mb={2}>
          <JobStep taskId={ciBuildId} title={s} isJobRunning={isJobRunning} />
        </Box>
      ))}
    </Fragment>
  );
};

export default JobSteps;
