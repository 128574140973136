import instance from "./axiosInstance";
import { APIResponse, PaginatedResponse } from "types";
import { AssessmentDetail, PipelineAssessment } from "types/assessments";
import { Pipeline } from "types/pipelines";

const getPipelines = (orgId: string) =>
  instance
    .get<PaginatedResponse<Pipeline>>(`/org/${orgId}/pipelines`)
    .then((res) => res.data.data);

const getPipelineRuns = (orgId: string, ciPipelineId: string) =>
  instance
    .get<PaginatedResponse<PipelineAssessment>>(`/org/${orgId}/pipeline/runs`, {
      params: {
        ciPipelineId,
      },
    })
    .then((res) => res.data.data);

const getPipelineConsolidatedAssessments = (
  orgId: string,
  run: {
    ciPipelineName: string;
    ciBuildUrl: string;
  },
) =>
  instance
    .get<APIResponse<AssessmentDetail>>(
      `/org/${orgId}/pipeline/run/assessments/consolidated`,
      {
        params: {
          ...run,
        },
      },
    )
    .then((res) => res.data.data);

const toggleFavorite = (
  orgId: string,
  pipeline: Pipeline,
  isFavorite: boolean,
) =>
  instance
    .patch<APIResponse<Pipeline>>(`/org/${orgId}/pipelines/favorite`, {
      ciPipelineName: pipeline.ciPipelineName,
      ciPipelineUrl: pipeline.ciPipelineUrl,
      ciPlatform: pipeline.ciPlatform,
      isFavorite,
    })
    .then((res) => res.data.data);

export default {
  getPipelines,
  getPipelineConsolidatedAssessments,
  getPipelineRuns,
  toggleFavorite,
};
