import React, { FC, Fragment } from "react";
import { Box, Tooltip } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { isUndefined, upperFirst } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    severity: {
      color: "white",
      padding: `2px 5px`,
      marginTop: 2,
    },
    criticalValue: {
      background: "white",
      color: "#8c101c",
      border: "2px solid #8c101c",
      borderRadius: "4px 0 0 4px",
    },
    critical: {
      backgroundColor: "#8c101c",
      borderRadius: "0 4px 4px 0",
    },
    highValue: {
      background: "white",
      color: "#e71d32",
      border: "2px solid #e71d32",
      borderRadius: "4px 0 0 4px",
    },
    high: {
      backgroundColor: "#e71d32",
      borderRadius: "0 4px 4px 0",
    },
    mediumValue: {
      background: "white",
      color: "#ff7832",
      border: "2px solid #ff7832",
      borderRadius: "4px 0 0 4px",
    },
    medium: {
      backgroundColor: "#ff7832",
      borderRadius: "0 4px 4px 0",
    },
    lowValue: {
      background: "white",
      color: "#fdd600",
      border: "2px solid #fdd600",
      borderRadius: "4px 0 0 4px",
    },
    low: {
      backgroundColor: "#fdd600",
      borderRadius: "0 4px 4px 0",
    },
    infoValue: {
      background: "white",
      color: "#5aaafa",
      border: "2px solid #5aaafa",
      borderRadius: "4px 0 0 4px",
    },
    info: {
      backgroundColor: "#5aaafa",
      borderRadius: "0 4px 4px 0",
    },
    muted: {
      backgroundColor: "lightgray",
      borderRadius: "0 4px 4px 0",
    },
    mutedValue: {
      background: "white",
      color: "lightgray",
      border: "2px solid lightgray",
      borderRadius: "4px 0 0 4px",
    },
  }),
);

interface RepositoryFindingsSeverityProps {
  stats?: {
    critical?: number;
    high?: number;
    medium?: number;
    low?: number;
    info?: number;
  };
}

const RepositoryFindingsSeverity: FC<RepositoryFindingsSeverityProps> = ({
  stats,
}) => {
  const classes = useStyles();

  if (!stats) return <Fragment />;

  const values = Object.keys(stats ?? {}) as (keyof typeof stats)[];

  return (
    <Box display="flex" flexWrap="wrap">
      {values.map((v, i) => {
        const valueClassName = (v + "Value") as keyof typeof classes;
        return (
          !isUndefined(stats[v]) && (
            <Tooltip title={upperFirst(v)} arrow key={i}>
              <Box mr={i === values.length - 1 ? 0 : 1} display="flex">
                <Box
                  display="flex"
                  alignItems="center"
                  className={classNames(
                    classes.severity,
                    stats[v] === 0
                      ? classes.mutedValue
                      : classes[valueClassName],
                  )}
                >
                  {stats[v]}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  className={classNames(
                    classes.severity,
                    stats[v] === 0 ? classes.muted : classes[v],
                  )}
                >
                  {v[0].toUpperCase()}
                </Box>
              </Box>
            </Tooltip>
          )
        );
      })}
    </Box>
  );
};

export default RepositoryFindingsSeverity;
