import React, { FC } from "react";
import { faFrown } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import useCommonStyles from "theme/useCommonStyles";

interface FailedFetchElementProps {
  onRefetchClick: () => void;
}

const FailedFetchElement: FC<FailedFetchElementProps> = ({
  onRefetchClick,
}) => {
  const classes = useCommonStyles();

  return (
    <Box
      onClick={onRefetchClick}
      className={classes.cursorPointer}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      py={2}
    >
      <Box style={{ opacity: 0.25 }} component="h1">
        <FontAwesomeIcon icon={faFrown} />
      </Box>
      <Typography variant="body1">No data received.</Typography>
    </Box>
  );
};

export default FailedFetchElement;
