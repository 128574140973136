import React, { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@material-ui/core";

import PageHeader from "components/PageHeader";

interface ZodiacBuildHeaderProps {
  ciBuildId: string;
}

const ZodiacBuildHeader: FC<ZodiacBuildHeaderProps> = ({ ciBuildId }) => {
  return (
    <Fragment>
      <PageHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          Static Analysis Summary
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to="/zodiac">
              Jobs
            </Link>
            <Link color="inherit" to={`/zodiac/jobs/${ciBuildId}`}>
              {ciBuildId}
            </Link>
            <Typography color="textPrimary">Static Analysis Summary</Typography>
          </Breadcrumbs>
        </Box>
      </PageHeader>
    </Fragment>
  );
};

export default ZodiacBuildHeader;
