import React, { FC, Fragment, useState } from "react";
import { faNetworkWired, faServer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AddCircleOutlineRounded,
  RemoveCircleRounded,
} from "@material-ui/icons";
import classNames from "classnames";
import moment from "moment";
import useCommonStyles from "theme/useCommonStyles";

import ZodiacInstanceExpanded from "./ZodiacInstanceExpanded";
import ZodiacInstanceLogsModal from "./ZodiacInstanceLogsModal";
import { facIpAddress } from "assets/svg/icons/custom-fa-icons";
import ColoredChip from "components/ColoredChip/ColoredChip";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import { ZodiacInstance as ZodiacInstanceE } from "types/zodiac";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      opacity: 0.7,
      padding: 0,
    },
    opacity7: {
      opacity: 0.7,
    },
    bold: {
      fontWeight: 500,
    },
    cursorPointer: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  }),
);

interface ZodiacInstanceProps {
  instance: ZodiacInstanceE;
}

const ZodiacInstance: FC<ZodiacInstanceProps> = ({ instance }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleExpanded = () => setExpanded((prev) => !prev);

  const buildVersion = instance.buildVersion ?? "Queued";
  const buildColor =
    buildVersion === "Queued" || buildVersion === "Queued" ? "warning" : "info";

  return (
    <Fragment>
      <Box display="flex">
        <Box
          onClick={toggleExpanded}
          className={classNames(classes.bold, classes.cursorPointer)}
          mr={matches ? 2 : 6}
          display="flex"
        >
          <Box mr={matches ? 2 : 5}>
            <IconButton className={classes.iconButton} size="small">
              {expanded ? <RemoveCircleRounded /> : <AddCircleOutlineRounded />}
            </IconButton>
          </Box>
          {moment(instance.createTs, moment.ISO_8601).format("hh:mm:ss A")}
        </Box>
        <Box flex={1} display="flex" flexDirection="column">
          <Box
            onClick={toggleExpanded}
            className={classes.cursorPointer}
            display="flex"
            mb={1}
          >
            <Box mr={2}>
              <ColoredChip
                className={classes.bold}
                color={buildColor}
                size="small"
                label={buildVersion}
              />
            </Box>
            <Box className={classes.bold} mr={1}>
              Last seen
            </Box>
            <RelativeTimeFormatter dateTs={instance.lastSeenTs} />
          </Box>
          <Box className={classes.opacity7}>
            {instance.remoteAddr && (
              <Fragment>
                <Tooltip title="IP address" arrow>
                  <Box component="span" mx={0.5}>
                    <FontAwesomeIcon icon={facIpAddress} />
                  </Box>
                </Tooltip>
                <span className={commonStyles.wordBreakAll}>
                  {instance.remoteAddr}
                </span>
              </Fragment>
            )}
            {instance.remoteAddr && (
              <Fragment>
                <Tooltip title="host" arrow>
                  <Box component="span" mr={0.5} ml={1}>
                    <FontAwesomeIcon icon={faServer} />
                  </Box>
                </Tooltip>
                <span className={commonStyles.wordBreakAll}>
                  {instance.host}
                </span>
              </Fragment>
            )}
            {instance.clusterId && (
              <Fragment>
                <span className={commonStyles.wordBreakAll}>
                  <Tooltip title="cluster" arrow>
                    <Box component="span" mr={0.5} ml={1}>
                      <FontAwesomeIcon icon={faNetworkWired} />
                    </Box>
                  </Tooltip>
                  {instance.clusterId}
                </span>
              </Fragment>
            )}
          </Box>
          <Collapse in={expanded}>
            <Box mt={2} pl={2} borderLeft="3px solid lightgray">
              <ZodiacInstanceExpanded instance={instance} />
            </Box>
          </Collapse>
        </Box>
        <Box display="flex">
          <ZodiacInstanceLogsModal instanceId={instance.instanceId} />
        </Box>
      </Box>
    </Fragment>
  );
};

export default ZodiacInstance;
