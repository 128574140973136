import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { ErrorOutline, VpnKey as LoginIcon } from "@material-ui/icons";

import NavbarTopLayout from "components/Layout/NavbarTopLayout";

const ErrorGoogleSignIn: FC = () => {
  return (
    <NavbarTopLayout white>
      <Grid container alignItems="center" justify="center">
        <Grid item sm={10} md={8} lg={6} xl={4}>
          <Box textAlign="center">
            <Box
              style={{ opacity: 0.4 }}
              component={ErrorOutline}
              fontSize={120}
              my={5}
            />
            <Typography variant="h2" color="primary" gutterBottom>
              Google Login is not supported
            </Typography>
            <Typography gutterBottom>
              Please log in with GitHub or GitLab.
            </Typography>
            <Box mb={4} />
            <Button
              startIcon={<LoginIcon />}
              component={Link}
              to="/login"
              size="small"
              color="primary"
              variant="outlined"
            >
              Back to Login
            </Button>
          </Box>
        </Grid>
      </Grid>
    </NavbarTopLayout>
  );
};

export default ErrorGoogleSignIn;
