import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import MissingEmail from "./pages/MissingEmail/MissingEmail";
import Profile from "./pages/Profile/Profile";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";

const ProfileRouter: FC<Childless> = () => {
  return (
    <Routes>
      <Route path="/verify-email" element={<VerifyEmail />} />

      <Route path="/add-email" element={<MissingEmail />} />

      <Route path="" element={<Profile />} />

      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default ProfileRouter;
