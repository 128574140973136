import React, { FC, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { faTag, faVial } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Breadcrumbs,
  Card,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { LaunchRounded, Settings } from "@material-ui/icons";

import { useCurrentOrgId } from "providers";

import InvokeIaCBotModal from "../../Repositories/components/InvokeIaCBotModal";
import GitHubBadgesModal from "./GitHubBadgesModal";
import RepositoryConfigYamlModal from "./RepositoryConfigYamlModal";
import GitRepoInformation from "components/GitRepoInformation/GitRepoInformation";
import PageHeader from "components/PageHeader";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import { getGitPlatformIcon } from "helpers/iconHelper";
import { errorToastHandler } from "helpers/queryHelpers";
import repositoryService from "services/repositoryService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      backgroundColor: theme.palette.grey[600],
      color: "white",
      fontWeight: "bolder",
      verticalAlign: "center",
      fontSize: 10,
      width: 58,
      textAlign: "center",
      position: "absolute",
      top: 0,
      left: 0,
    },
  }),
);

interface RepositoryDetailHeaderProps {
  gitRepo: string;
  status?: "failed" | "warning" | "success";
}

const RepositoryDetailHeader: FC<RepositoryDetailHeaderProps> = ({
  gitRepo,
  status,
}) => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const repo = gitRepo.split("/").pop()?.toUpperCase() ?? gitRepo.toUpperCase();

  const { data: settings = { repoEnabled: true } } = useQuery(
    ["repository-settings", orgId, gitRepo],
    () => repositoryService.getRepoSettings(orgId, gitRepo),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: metadata, isLoading } = useQuery(
    ["gitRepoMetadata", orgId, gitRepo],
    () => repositoryService.getRepoMetadata(orgId, gitRepo),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: errorToastHandler(
        "An unexpected error ocurred while loading the repo insights. Please try again later.",
      ),
    },
  );

  return (
    <Fragment>
      <PageHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          Insights
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to="/repos/">
              Repositories
            </Link>
            <Typography color="textPrimary">{gitRepo}</Typography>
          </Breadcrumbs>
        </Box>
      </PageHeader>

      <Box position="relative" component={Card} p={2} mb={2}>
        {!settings.repoEnabled && (
          <Tooltip
            title="This repository is excluded from automatic assessments and scheduled jobs."
            arrow
          >
            <Box className={classes.disabled}>DISABLED</Box>
          </Tooltip>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <FontAwesomeIcon
                  size="2x"
                  icon={getGitPlatformIcon(metadata?.gitProvider)}
                />
              </Box>
              <Typography variant="h4">{repo}</Typography>
              <Box ml={1}>
                <IconButton
                  size="small"
                  color="primary"
                  component="a"
                  disabled={!metadata?.gitRepoUrl}
                  href={metadata?.gitRepoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LaunchRounded fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            {isLoading ? (
              <Box display="flex">
                <Box mr={1}>
                  <Skeleton height={20} width={120} />
                </Box>
                <Box mr={1}>
                  <Skeleton height={20} width={90} />
                </Box>
                <Box mr={1}>
                  <Skeleton height={20} width={70} />
                </Box>
                <Box mr={1}>
                  <Skeleton height={20} width={100} />
                </Box>
              </Box>
            ) : (
              <Box display="flex" flexWrap="wrap" mt={0.5}>
                <GitRepoInformation
                  spacer={false}
                  gitBranch={metadata?.gitBranch}
                  gitCommit={metadata?.gitCommit}
                  gitCommitUrl={metadata?.gitCommitUrl}
                />
                {metadata?.gitTag && (
                  <Box display="flex">
                    <Box mx={1} style={{ opacity: 0.7 }}>
                      <FontAwesomeIcon icon={faTag} />
                    </Box>
                    {`${metadata.gitTag}`}
                  </Box>
                )}
                {metadata?.createTs && (
                  <Box display="flex">
                    <Box mx={1} style={{ opacity: 0.7 }}>
                      <FontAwesomeIcon icon={faVial} />
                    </Box>
                    {`Last analyzed `}
                    <RelativeTimeFormatter dateTs={metadata.createTs} />
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <InvokeIaCBotModal
              repoEnabled={settings.repoEnabled}
              accessible={metadata?.accessible}
              gitRepo={gitRepo}
            />
            <Box mx={1}>
              <GitHubBadgesModal repo={gitRepo} dateTs={metadata?.createTs} />
            </Box>
            <RepositoryConfigYamlModal repo={gitRepo} />
            <Tooltip title="Settings" arrow>
              <IconButton
                component={Link}
                color="primary"
                to={`/repos/details/${gitRepo}/settings`}
              >
                <Settings />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default RepositoryDetailHeader;
