import React, { FC, Fragment, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { Box, Button, Card, TextField } from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import zodiacService from "services/zodiacService";

interface Form {
  githubOrgName: string;
}

const CreateGHZodiacApp: FC = () => {
  const orgId = useCurrentOrgId();
  const formRef = useRef<HTMLFormElement>(null);
  const [manifestValue, setManifestValue] = useState("");
  const [org, setOrg] = useState("");
  const { data: manifest } = useQuery(
    ["zodiac-manifest", orgId],
    () => zodiacService.getGithubZodiacManifest(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const { control, errors, handleSubmit } = useForm<Form>({
    defaultValues: {
      githubOrgName: "",
    },
  });

  const onSubmit = ({ githubOrgName }: Form) => {
    setOrg(githubOrgName);
    setManifestValue(JSON.stringify(manifest?.manifest));
    formRef.current?.submit();
  };

  return (
    <Fragment>
      <PageHeader>Create Github App for Zodiac</PageHeader>
      <Card>
        <Box p={2} py={3}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate target="_top">
            <Box my={2} width={280}>
              <Controller
                as={TextField}
                control={control}
                fullWidth
                rules={{
                  required: "Please enter your Organization's Github name.",
                }}
                error={!!errors.githubOrgName}
                variant="outlined"
                required
                name="githubOrgName"
                label="Github Organization name"
                helperText={errors.githubOrgName?.message ?? ""}
              />
            </Box>
            <Button type="submit" variant="contained" color="primary">
              Create Github App
            </Button>
          </form>
        </Box>
        <Box display="none">
          <form
            ref={formRef}
            action={`https://github.com/organizations/${org}/settings/apps/new`}
            method="POST"
          >
            <input
              value={manifestValue}
              type="text"
              name="manifest"
              id="manifest"
            ></input>
          </form>
        </Box>
      </Card>
    </Fragment>
  );
};

export default CreateGHZodiacApp;
