import instance from "./axiosInstance";
import { PaginatedResponse } from "types";
import { Resource } from "types/resources";

const getResources = (orgId: string) =>
  instance
    .get<PaginatedResponse<Resource>>(`/org/${orgId}/cloud/resources`)
    .then((res) => res.data.data);

const getResourcesLookup = (orgId: string, arn: string) =>
  instance
    .get<Resource>(`/org/${orgId}/cloud/resource/lookup?arn=${arn}`)
    .then((res) => res.data);

export default {
  getResources,
  getResourcesLookup,
};
