import { useEffect, useRef } from "react";

const NOOP = () => {};

/**
 *  Executes callback each time an amount of ms elapses
 *
 * @param callback A function that will be called every delay milliseconds.
 * @param delay A number representing the delay in ms. Set to null to "pause" the interval.
 */
export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(NOOP);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
