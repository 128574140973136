import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import OrganizationDetail from "./pages/OrganizationDetail/OrganizationDetail";
import Organizations from "./pages/Organizations/Organizations";

const SupportRouter: FC<Childless> = () => {
  return (
    <Routes>
      <Route path="organizations" element={<Organizations />} />
      <Route path="organizations/:orgId" element={<OrganizationDetail />} />
      <Route
        path="organizations/:orgId/:tab"
        element={<OrganizationDetail />}
      />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default SupportRouter;
