import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";

import { ReactComponent as Success } from "assets/svg/illustrations/celebration.svg";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import { useInterval } from "hooks/useInterval";
import { useQueryParams } from "hooks/useQueryParams";

const SuccessSlack: FC = () => {
  const [seconds, setSeconds] = useState(10);
  const navigate = useNavigate();
  const {
    title = "You have successfully configured Lacework IaC Security for Slack!",
    team,
    channel,
  } = useQueryParams();

  useInterval(() => {
    setSeconds((prev) => prev - 1);
  }, 1000);

  useEffect(() => {
    if (seconds === 0) navigate("/integrations");
  }, [seconds, navigate]);

  return (
    <NavbarTopLayout white>
      <Box textAlign="center" p={0.5}>
        <Box component={Success} mb={7} width={450} />
        <Box color="primary.main" component="h3" mb={2}>
          {title}
        </Box>
        <Box mb={4}>
          To see the test message from Lacework IaC Security
          <Box ml={1} display="inline-block">
            <a href={`slack://channel?team=${team}&id=${channel}`}>
              Open Slack
            </a>
          </Box>
        </Box>
        <Typography variant="body2" color="textSecondary">
          You will be redirected in {seconds}s ...
        </Typography>
      </Box>
    </NavbarTopLayout>
  );
};

export default SuccessSlack;
