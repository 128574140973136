import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { faListUl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { startCase } from "lodash";

import {
  DashboardElementData,
  DashboardElementMetadata,
} from "types/dashboard";

interface ListElementProps {
  element?: DashboardElementData;
  metadata: DashboardElementMetadata;
  isLoading: boolean;
}

const ListElement: FC<ListElementProps> = ({
  element,
  metadata,
  isLoading,
}) => {
  return isLoading ? (
    <Box px={2} py={1}>
      <Skeleton width="70%" count={2} />
    </Box>
  ) : element?.data.length ? (
    <Box component="ul" py={1.5}>
      {element?.data.map((el, ind) => (
        <li key={ind}>{startCase(el + "")}</li>
      ))}
    </Box>
  ) : (
    <Box p={2} textAlign="center">
      <Box mb={1} style={{ opacity: 0.25 }}>
        <FontAwesomeIcon icon={faListUl} size="2x" />
      </Box>
      <Typography variant="body2">{metadata.defaultMessage}</Typography>
    </Box>
  );
};

export default ListElement;
