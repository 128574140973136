import React, { FC, Fragment } from "react";
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visible: {
      display: "block",
      height: "100%",
    },
    invisible: {
      height: "100%",
      display: "none",
    },
  }),
);

interface CollapsibleButtonsProps {
  items: DropdownItem[];
  collapsed: boolean;
  buttons: React.ReactNode;
  isLoading?: boolean;
}

const CollapsibleButtons: FC<CollapsibleButtonsProps> = ({
  buttons,
  collapsed,
  items,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={collapsed ? classes.invisible : classes.visible}>
        {buttons}
      </div>
      <div className={collapsed ? classes.visible : classes.invisible}>
        {isLoading ? (
          <CircularProgress color="primary" size={22} />
        ) : (
          <DropdownActions items={items} />
        )}
      </div>
    </Fragment>
  );
};

export default CollapsibleButtons;
