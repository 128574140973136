import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AxiosError } from "axios";

import { inOperator } from "helpers/inOperator";

function isAxiosError(error: unknown): error is AxiosError {
  return !!(
    typeof error === "object" &&
    error &&
    inOperator("isAxiosError", error) &&
    error.isAxiosError
  );
}

export const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        if (isAxiosError(error)) {
          return (error.response?.status ?? 500) >= 500 && failureCount <= 3;
        }
        return failureCount <= 3;
      },
    },
  },
});

const ReactQueryConfigProvider: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryConfigProvider;
