import React, { FC, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { Location } from "history";
import { QueryParamProvider } from "use-query-params";

import LaunchDarklyProvider from "./LaunchDarklyProvider";
import MuiTheme from "components/MuiThemeProvider/MuiThemeProvider";
const ExternalProviders: FC = ({ children }) => {
  return (
    <MuiTheme>
      <CssBaseline>
        <LaunchDarklyProvider>
          <QueryParamProvider ReactRouterRoute={RouteAdapter}>
            {children}
          </QueryParamProvider>
        </LaunchDarklyProvider>
      </CssBaseline>
    </MuiTheme>
  );
};

export default ExternalProviders;

const RouteAdapter: React.FC = ({ children }) => {
  const reactRouterNavigate = useNavigate();
  const reactRouterLocation = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      // can disable eslint for parts here, location.state can be anything
      replace(location: Location) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        reactRouterNavigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        reactRouterNavigate(location, {
          replace: false,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          state: location.state,
        });
      },
    }),
    [reactRouterNavigate],
  );
  // https://github.com/pbeshai/use-query-params/issues/196
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return children({ history: adaptedHistory, reactRouterLocation });
};
