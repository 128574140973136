import React, { FC } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Card,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import AssessmentsTabularElement from "./AssessmentsTabularElement";
import DoughnutChartElement from "./DoughnutChartElement";
import ElementHeader from "./ElementHeader";
import FailedFetchElement from "./FailedFetchElement";
import LineChartElement from "./LineChartElement";
import ListElement from "./ListElement";
import MultiLineChartElement from "./MultiLineChartElement";
import NumericalElement from "./NumericalElement";
import RepositoriesElement from "./RepositoriesTabularElement";
import SummaryElement from "./SummaryElement";
import TabularElement from "./TabularElement";
import { APIResponse } from "types";
import {
  DashboardElementData,
  DashboardElementMetadata,
  DEFAULT_SIZES,
} from "types/dashboard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridComponent: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: 36,
      },
    },
  }),
);

interface DashboardElementProps {
  element: DashboardElementMetadata;
  getterFunction: (
    orgId: string,
    apiEndpoint: string,
  ) => Promise<APIResponse<DashboardElementData>>;
  id?: string;
  sizes?: typeof DEFAULT_SIZES;
}

const DashboardElement: FC<DashboardElementProps> = ({
  element,
  getterFunction,
  id = "",
  sizes = DEFAULT_SIZES,
}) => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const { data, isLoading, isError, refetch } = useQuery(
    ["dashboard-element-data-" + id, orgId, element.apiEndpoint],
    () => getterFunction(orgId, element.apiEndpoint),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: element.type !== "grid",
    },
  );
  const elementData = data?.data;

  switch (element.type) {
    case "numerical":
      return (
        <Grid item id={element.id} {...sizes.numerical}>
          <Box
            component={Card}
            height="100%"
            display="flex"
            flexDirection="column"
            position="relative"
          >
            <ElementHeader metadata={element} tooltip />
            {isError ? (
              <FailedFetchElement onRefetchClick={refetch} />
            ) : (
              <NumericalElement
                isLoading={isLoading}
                metadata={element}
                element={elementData}
              />
            )}
          </Box>
        </Grid>
      );
    case "chart":
      return (
        <Grid item id={element.id} {...sizes.chart}>
          <Box
            component={Card}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <ElementHeader metadata={element} tooltip />
            {isError ? (
              <FailedFetchElement onRefetchClick={refetch} />
            ) : (
              <LineChartElement
                metadata={element}
                element={elementData}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Grid>
      );
    case "pie":
      return (
        <Grid item id={element.id} {...sizes.pie}>
          <Box
            component={Card}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <ElementHeader metadata={element} tooltip />
            {isError ? (
              <FailedFetchElement onRefetchClick={refetch} />
            ) : (
              <DoughnutChartElement
                metadata={element}
                element={elementData}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Grid>
      );
    case "tabular":
      return (
        <Grid item id={element.id} {...sizes.tabular}>
          <Box
            component={Card}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <ElementHeader metadata={element} tooltip />
            {isError ? (
              <FailedFetchElement onRefetchClick={refetch} />
            ) : (
              <TabularElement
                metadata={element}
                element={elementData}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Grid>
      );
    case "tabular_assessments":
      return (
        <Grid item id={element.id} {...sizes.tabular_assessments}>
          <Box
            component={Card}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <ElementHeader metadata={element} tooltip />
            {isError ? (
              <FailedFetchElement onRefetchClick={refetch} />
            ) : (
              <AssessmentsTabularElement
                metadata={element}
                element={elementData}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Grid>
      );
    case "tabular_repositories":
      return (
        <Grid item id={element.id} {...sizes.tabular_repositories}>
          <Box
            component={Card}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <ElementHeader metadata={element} tooltip />
            {isError ? (
              <FailedFetchElement onRefetchClick={refetch} />
            ) : (
              <RepositoriesElement
                data={elementData as any}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Grid>
      );
    case "list":
      return (
        <Grid item id={element.id} {...sizes.list}>
          <Box component={Card} height="100%">
            <ElementHeader metadata={element} tooltip />
            {isError ? (
              <FailedFetchElement onRefetchClick={refetch} />
            ) : (
              <ListElement
                metadata={element}
                element={elementData}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Grid>
      );
    case "summary":
      return (
        <Grid item id={element.id} {...sizes.summary}>
          <SummaryElement
            metadata={element}
            element={elementData}
            isLoading={isLoading}
          />
        </Grid>
      );
    case "grid":
      return (
        <Grid
          className={
            element.elements.length > 1 ? classes.gridComponent : undefined
          }
          item
          id={element.id}
          {...sizes.grid}
        >
          {element?.elements?.map((el, index) => (
            <Grid
              key={index}
              container
              spacing={2}
              style={{
                height:
                  element.elements.length === 1
                    ? "calc(100% + 16px)"
                    : element.elements.length === 2
                    ? "53.5%"
                    : undefined,
                marginBottom:
                  element.elements.length - 1 === index ||
                  element.elements.length === 1
                    ? undefined
                    : 8,
              }}
            >
              {el.map((innerEl) => (
                <DashboardElement
                  key={innerEl.id}
                  element={innerEl}
                  getterFunction={getterFunction}
                  sizes={{
                    ...DEFAULT_SIZES,
                    numerical: {
                      xs: 12,
                      md: 4,
                    },
                  }}
                />
              ))}
            </Grid>
          ))}
        </Grid>
      );
    case "multi-line":
      return (
        <Grid item id={element.id} {...sizes.chart}>
          <Box
            component={Card}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <ElementHeader metadata={element} tooltip />
            {isError ? (
              <FailedFetchElement onRefetchClick={refetch} />
            ) : (
              <MultiLineChartElement
                metadata={element}
                element={elementData}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Grid>
      );
    default:
      return null;
  }
};

export default DashboardElement;
