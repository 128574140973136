import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Filters } from "react-table";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ClearAllRounded, FilterListRounded } from "@material-ui/icons";

import { DataTableRef } from "components/DataTable/DataTable";
import { useQueryState } from "hooks/useQueryState";

interface BaseField<T> {
  displayName: string;
  preventDefault?: boolean;
  key: keyof T & string;
}
interface TextField<T> extends BaseField<T> {
  type: "textfield";
}

interface SelectFieldValue {
  displayName: string;
  value: any;
}

interface SelectField<T> extends BaseField<T> {
  type: "select" | "multiselect";
  values: SelectFieldValue[];
  renderValue?(value: any): React.ReactNode;
  displayEmptyOption?: boolean;
}

type Field<T> = TextField<T> | SelectField<T>;

interface FiltersModalProps<T> {
  disabled?: boolean;
  twoWay?: boolean;
  fields: Field<T>[];
  persistInUrl?: boolean;
  title: string;
  tableRef: React.RefObject<DataTableRef<T>>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
      marginTop: 8,
      marginBottom: 8,
    },
    dNone: {
      display: "none",
    },
  }),
);

function FiltersModal<T extends object>({
  disabled,
  fields,
  persistInUrl,
  title,
  tableRef,
  twoWay,
}: FiltersModalProps<T>) {
  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { state, setQueryState } = useQueryState<Record<keyof T, any>>(title);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState<Record<keyof T, any>>(
    Object.fromEntries(
      fields.map((d) => [d.key, d.type === "multiselect" ? [] : ""]),
    ) as Record<keyof T, any>,
  );
  const handleToggle = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setIsOpen(false);
  };

  const isFilterActive = useMemo(
    () => Object.values(filters).some((d) => d + "" !== ""),
    [filters],
  );

  const persistFilterStateInUrl = useCallback(
    (columnId: keyof T, filterValue: any) => {
      if (typeof filterValue === "object") {
        setQueryState({
          [columnId]: (filterValue as string[]).join(","),
          page: undefined,
        } as Partial<Record<keyof T, any>>);
      } else {
        setQueryState({
          [columnId]: filterValue,
          page: undefined,
        } as Partial<Record<keyof T, any>>);
      }
    },
    [setQueryState],
  );

  const setTableFilters = useCallback(
    (field: BaseField<T>, filterValue: any) => {
      if (!field.preventDefault) {
        tableRef.current?.tableInstance.setFilter(field.key + "", filterValue);
      }

      setFilters((prev) => ({ ...prev, [field.key]: filterValue }));
      tableRef.current?.tableInstance.gotoPage(0);
      if (persistInUrl) {
        persistFilterStateInUrl(field.key, filterValue);
      }
    },
    [persistFilterStateInUrl, persistInUrl, tableRef],
  );

  const resetFilters = useCallback(() => {
    tableRef.current?.tableInstance.setAllFilters([]);
    tableRef.current?.tableInstance.gotoPage(0);
    setFilters(
      Object.fromEntries(
        fields.map((d) => [d.key, d.type === "multiselect" ? [] : ""]),
      ) as Record<keyof T, any>,
    );
    if (persistInUrl) {
      const fieldsKeys = fields.map((f) => f.key);
      const emptyFilters = fieldsKeys.reduce(
        (acc: Partial<Record<keyof T, any>>, curr) => (
          // eslint-disable-next-line no-sequences
          (acc[curr] = undefined), acc
        ),
        {},
      );
      setQueryState(emptyFilters);
    }
  }, [fields, persistInUrl, setQueryState, tableRef]);

  // Restores URL State

  useEffect(() => {
    if (!persistInUrl && !disabled) return;

    const persistedState = {} as Record<keyof T, any>;
    const fieldKeys = fields
      .filter((f) => !f.preventDefault)
      .map((f) => f.key) as (keyof T)[];
    const stateKeys = Object.keys(state) as (keyof T)[];

    fields.forEach((f) => {
      if (!stateKeys.includes(f.key)) return;
      persistedState[f.key] =
        f.type === "multiselect" ? state[f.key].split(",") : state[f.key];
    });

    const initialFilters = Object.fromEntries(
      fields.map((d) => [d.key, d.type === "multiselect" ? [] : ""]),
    );

    const persistedFilters = {
      ...initialFilters,
      ...persistedState,
    };

    setFilters(persistedFilters);
    const filtersArray = fieldKeys
      .map((key) =>
        (persistedFilters[key] + "").length === 0
          ? null
          : {
              id: key,
              value: persistedFilters[key],
            },
      )
      .filter((d) => d !== null) as Filters<T>;
    tableRef.current?.tableInstance.setAllFilters(filtersArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, twoWay ? state : null]);

  return (
    <div>
      <Tooltip
        title={isFilterActive ? "Active" : "Inactive"}
        arrow
        placement="right"
      >
        <span>
          <IconButton
            aria-label="filter"
            disabled={disabled}
            onClick={handleToggle}
            color="primary"
            ref={anchorRef}
          >
            <Badge color="secondary" variant="dot" invisible={!isFilterActive}>
              <FilterListRounded fontSize="small" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Box p={3} pb={0} display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          <Tooltip arrow title="Clear all filters">
            <IconButton color="primary" size="small" onClick={resetFilters}>
              <ClearAllRounded />
            </IconButton>
          </Tooltip>
        </Box>
        <DialogContent>
          <Box display="flex" flexWrap="wrap">
            {fields.map((f, i) => (
              <FormControl
                key={i}
                color="secondary"
                disabled={disabled}
                className={classes.formControl}
                variant="outlined"
              >
                {f.type === "select" ? (
                  <Fragment>
                    <InputLabel id={`${f.key}-label`}>
                      {f.displayName}
                    </InputLabel>
                    <Select
                      value={filters[f.key]}
                      id={f.key + ""}
                      labelId={`${f.key}-label`}
                      onChange={({ target: { value } }) =>
                        setTableFilters(f, value)
                      }
                      label={f.displayName}
                      renderValue={(v: any) =>
                        f.renderValue ? f.renderValue(v) : v
                      }
                    >
                      <MenuItem
                        className={
                          f.displayEmptyOption ? undefined : classes.dNone
                        }
                        value=""
                      >
                        Any
                      </MenuItem>
                      {f.values.map((fV, j) => (
                        <MenuItem key={j} value={fV.value}>
                          {f.renderValue
                            ? f.renderValue(fV.value)
                            : fV.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Fragment>
                ) : f.type === "multiselect" ? (
                  <Fragment>
                    <InputLabel id={`${f.key}-label`}>
                      {f.displayName}
                    </InputLabel>
                    <Select
                      multiple
                      value={filters[f.key]}
                      renderValue={(value) => {
                        const v = value as string[];
                        return f.renderValue
                          ? v.map((rV, j) => (
                              <span key={j}>
                                {j === 0 ? "" : ", "}
                                {f?.renderValue?.(rV)}
                              </span>
                            ))
                          : v.join(", ");
                      }}
                      id={f.key + ""}
                      labelId={`${f.key}-label`}
                      onChange={({ target: { value } }) =>
                        setTableFilters(f, value)
                      }
                      label={f.displayName}
                    >
                      {f.values.map((fV, j) => (
                        <MenuItem key={j} value={fV.value}>
                          <Box display="flex">
                            <Checkbox
                              size="small"
                              checked={filters[f.key].includes(fV.value)}
                            />
                            <ListItemText
                              primary={
                                f.renderValue
                                  ? f.renderValue([fV.value])
                                  : fV.displayName
                              }
                            />
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </Fragment>
                ) : (
                  <TextField
                    id={`${f.key}-label`}
                    color="secondary"
                    fullWidth
                    value={filters[f.key]}
                    onChange={({ target: { value } }) => {
                      setTableFilters(f, value);
                    }}
                    label={f.displayName}
                    variant="outlined"
                  />
                )}
              </FormControl>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FiltersModal;
