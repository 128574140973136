import React, { FC, Fragment } from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import capitalize from "lodash/capitalize";

export enum Severity {
  Critical = "critical",
  High = "high",
  Medium = "medium",
  Low = "low",
  Info = "info",
}

export enum SeverityValue {
  Info,
  Low,
  Medium,
  High,
  Critical,
}

interface SeverityComponentProps {
  severity: Severity;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "0.5rem",
      width: "0.5rem",
      marginRight: theme.spacing(0.5),
      display: "inline-block",
      borderRadius: "50%",
      "&::after": {
        content: "none !important",
      },
    },
    critical: {
      backgroundColor: theme.palette.severities?.critical,
    },
    high: {
      backgroundColor: theme.palette.severities?.high,
    },
    medium: {
      backgroundColor: theme.palette.severities?.medium,
    },
    low: {
      backgroundColor: theme.palette.severities?.low,
    },
    info: {
      backgroundColor: theme.palette.severities?.info,
    },
  }),
);

const SeverityComponent: FC<SeverityComponentProps> = ({ severity }) => {
  const classes = useStyles();

  if (!severity) return <Fragment>-</Fragment>;

  const severityClass = classes[severity.toLowerCase() as Severity];

  return (
    <Box display="inline-flex" alignItems="baseline" flexWrap="nowrap">
      <Box
        component="i"
        className={classNames(classes.root, severityClass)}
      ></Box>
      {capitalize(severity)}
    </Box>
  );
};

export default SeverityComponent;
