import React, { FC, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Card, fade, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import classNames from "classnames";

import AssessmentConfigYamlModal from "./AssessmentConfigYamlModal";
import AssessmentDescription from "./AssessmentDescription";
import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import { Assessment } from "types/assessments";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      flexDirection: "column",
      margin: `0 -${theme.spacing(1)}px `,
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
      },
      justifyContent: "space-between",
      transition: "background-color 0.3s",
      "&:hover": {
        background: theme.palette.lightBackground.main,
        cursor: "pointer",
      },
      "&:active": {
        background: theme.palette.lightBackground.dark,
      },
    },
    fail: {
      backgroundColor: fade(theme.palette.error.main, 0.04),
      "&:hover": {
        backgroundColor: fade(theme.palette.error.dark, 0.08),
        cursor: "pointer",
      },
      "&:active": {
        backgroundColor: fade(theme.palette.error.dark, 0.12),
      },
    },
    success: {
      backgroundColor: fade(theme.palette.success.main, 0.04),
      "&:hover": {
        backgroundColor: fade(theme.palette.success.dark, 0.08),
        cursor: "pointer",
      },
      "&:active": {
        backgroundColor: fade(theme.palette.success.dark, 0.12),
      },
    },
    metrics: {
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-end",
      [theme.breakpoints.up("md")]: {
        alignSelf: "flex-start",
      },
    },
    icon: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(3),
      },
    },
  }),
);

export interface AssessmentRowProps {
  assessment: Assessment;
}

const AssessmentRow: FC<AssessmentRowProps> = ({ assessment }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const assessmentConfigRef = useRef<HTMLButtonElement>(null);

  const goToDetail = () =>
    navigate(
      `/assessments/details/${assessment.idNum ?? assessment.assessmentId}`,
    );

  const dropdownActions: DropdownItem[] = [
    {
      label: "View",
      linkTo: `/assessments/details/${
        assessment.idNum ?? assessment.assessmentId
      }`,
    },
    {
      action: () => assessmentConfigRef.current?.click(),
      label: "Show Config",
    },
  ];

  const hasMetrics = assessment?.metrics?.findingsFail !== undefined;
  const hasViolations = assessment?.metrics?.findingsFail !== 0;

  const stopPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => event.stopPropagation();

  return (
    <Fragment>
      <Card
        variant="outlined"
        className={classNames(classes.root, {
          [classes.fail]: hasViolations && hasMetrics,
          [classes.success]: !hasViolations && hasMetrics,
        })}
        onClick={goToDetail}
      >
        <Box display="flex" alignItems="center">
          {hasMetrics &&
            (hasViolations ? (
              <Box
                my={-1}
                fontSize={30}
                className={classes.icon}
                color="error.main"
              >
                <Cancel fontSize="inherit" />
              </Box>
            ) : (
              <Box
                my={-1}
                fontSize={30}
                className={classes.icon}
                color="success.main"
              >
                <CheckCircle fontSize="inherit" />
              </Box>
            ))}
          <Box>
            <Typography variant="h6">{assessment.title}</Typography>
            <AssessmentDescription assessment={assessment} />
          </Box>
        </Box>
        <Box className={classes.metrics}>
          <Box textAlign="right">
            <div>
              {hasMetrics &&
                `${assessment.metrics?.findingsFail} ${
                  assessment.category === "imageScan"
                    ? "Vulnerabilities"
                    : "Violations"
                }`}
            </div>
            <Box fontWeight="500">
              <RelativeTimeFormatter
                dateTs={assessment.createTs ?? assessment.updateTs}
              />
            </Box>
          </Box>

          <Box ml={4} onClick={stopPropagation}>
            <DropdownActions items={dropdownActions} />
          </Box>
        </Box>
      </Card>
      <Box display="none">
        <AssessmentConfigYamlModal
          assessmentId={
            assessment.assessmentId ?? assessment.idNum ?? assessment.id
          }
          assessmentTitle={assessment.title}
          ref={assessmentConfigRef}
          hideButton
        />
      </Box>
    </Fragment>
  );
};

export default AssessmentRow;
