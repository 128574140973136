import { GridSize } from "@material-ui/core";

import { Repository } from "./repositories";

export type ElementType =
  | "chart"
  | "grid"
  | "list"
  | "numerical"
  | "pie"
  | "summary"
  | "tabular"
  | "tabular_assessments"
  | "tabular_repositories"
  | "multi-line";

export const DEFAULT_SIZES: Record<
  ElementType,
  {
    xs?: GridSize;
    md?: GridSize;
    lg?: GridSize;
    xl?: GridSize;
  }
> = {
  numerical: {
    xs: 6,
    md: 3,
  },
  chart: {
    xs: 12,
    md: 6,
  },
  pie: {
    xs: 12,
    md: 6,
  },
  tabular: {
    xs: 12,
    md: 6,
  },
  tabular_assessments: {
    xs: 12,
  },
  list: {
    xs: 6,
    md: 3,
  },
  grid: {
    xs: 12,
    md: 8,
  },
  summary: {
    xs: 12,
    md: 4,
  },
  "multi-line": {
    xs: 12,
    md: 6,
  },
  tabular_repositories: {
    xs: 12,
  },
};

export interface DashboardElementMetadata {
  id: string;
  title: string;
  toolstip: string;
  apiEndpoint: string;
  defaultMessage: string;
  type: ElementType;
  link: string;
  hyperlink: string;
  elements: DashboardElementMetadata[][];
}

export interface DashboardMetaData {
  elements: DashboardElementMetadata[];
}

interface DashboardCommonElementData {
  data: Record<string, string>[];
  total: number;
  dynamicUrl?: string;
  labels: string[];
  metadata: {
    displayHints: Record<
      string,
      { hints?: string[]; displayName?: string; link?: string }
    >;
  };
}
interface DashboardSummaryElementData {
  data: { count: number; link: "string"; message: "string" }[];
  total: number;
  labels: string[];
  metadata: {
    displayHints: Record<
      string,
      { hints?: string[]; displayName?: string; link?: string }
    >;
  };
}
export interface DashboardRepositoriesElementData {
  data: Repository[];
  total: number;
}
export interface DashboardMultiLineElementData {
  data: (number | null)[][];
  total: number;
  labels: string[];
  metadata: {
    displayHints: { xAxis?: string[] };
  };
}

export type DashboardElementData = DashboardCommonElementData &
  DashboardSummaryElementData &
  DashboardMultiLineElementData &
  DashboardRepositoriesElementData;
