export interface Notification {
  id: string;
  createTs: string;
  updateTs: string;
  title: string;
  body: string;
  type: NotificationType;
  url?: string;
  source?: string;
  resource?: string;
}
export interface NotificationDetail {
  id: string;
  createTs: string;
  updateTs: string;
  title: string;
  body: string;
  type: NotificationType;
  source?: string;
  raw: string;
  resource?: string;
}

export enum NotificationType {
  Critical = "Critical",
  Error = "Error",
  Warning = "Warning",
  Info = "Info",
}
