import instance from "./axiosInstance";
import { APIResponse } from "types";
import { FieldMetadata, ReportDetails, ReportResponse } from "types/reports";

const getReports = (orgId: string) =>
  instance
    .get<ReportResponse>(`/org/${orgId}/queries`)
    .then((res) => res.data.data);

const getReportDetails = (
  orgId: string,
  reportName: string,
  q?: string,
  fields?: Pick<FieldMetadata, "name">[],
) =>
  instance
    .post<APIResponse<ReportDetails>>(`/org/${orgId}/queries/${reportName}`, {
      ...(q && { q }),
      ...(fields && { fields }),
    })
    .then((data) => data.data.data);

export default {
  getReports,
  getReportDetails,
};
