import React, {
  forwardRef,
  Fragment,
  RefForwardingComponent,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useMutation, useQuery } from "react-query";
import { Column } from "react-table";

import ConfirmationModal, {
  ConfirmationModalRef,
} from "components/ConfirmationModal/ConfirmationModal";
import DataTable from "components/DataTable";
import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import IdTruncator from "components/IdTruncator";
import NoDataComponent from "components/NoDataComponent";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import StatusBadge from "components/StatusBadge";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
  optimisticDatasetRemover,
  optimisticDatasetUpdater,
} from "helpers/queryHelpers";
import tokenService from "services/tokenService";
import { AccessToken } from "types";
import { TokensTabsRowActions } from "types/tabs";

const columnsDef = ({
  onRevokeToken,
  onDisableToken,
  onEnableToken,
}: TokensTabsRowActions): Column<AccessToken>[] => [
  {
    Header: "User",
    accessor: "userDisplayName",
  },
  {
    Header: "Created",
    accessor: "createTs",

    Cell: ({ row: { original: row } }) => (
      <RelativeTimeFormatter dateTs={row.createTs}></RelativeTimeFormatter>
    ),
  },
  {
    Header: "Expires",
    accessor: "expirationTs",

    Cell: ({ row: { original: row } }) => (
      <RelativeTimeFormatter dateTs={row.expirationTs}></RelativeTimeFormatter>
    ),
  },
  {
    Header: "Last Usage",
    accessor: "lastUseTs",

    Cell: ({ row: { original: row } }) => (
      <RelativeTimeFormatter dateTs={row.lastUseTs}></RelativeTimeFormatter>
    ),
  },
  {
    Header: "Status",
    accessor: "status",

    Cell: ({ row: { original: row } }) => (
      <StatusBadge status={row.status}></StatusBadge>
    ),
  },
  {
    Header: "Actions",
    accessor: "id",
    collapse: true,
    disableSortBy: true,
    isButton: true,
    Cell: ({ row: { original: row } }) => {
      if (row.status === "revoked") {
        return <DropdownActions items={[]}></DropdownActions>;
      }

      const dropdownActions: DropdownItem[] = [
        {
          label: "Revoke Token",
          action: () => onRevokeToken(row),
        },
      ];

      const enableTokenAction: DropdownItem = {
        label: "Enable Token",
        action: () => onEnableToken(row),
      };
      const disableTokenAction: DropdownItem = {
        label: "Disable Token",
        action: () => onDisableToken(row),
      };

      row.status === "active" && dropdownActions.unshift(disableTokenAction);
      row.status === "disabled" && dropdownActions.unshift(enableTokenAction);

      return <DropdownActions items={dropdownActions} />;
    },
  },
];

interface AccessTokensDataTableProps {
  isActive: boolean;
}

export interface AccessTokensDataTableRef {
  refreshAccessTokenData(): void;
}

type AccessTokensDataTable = RefForwardingComponent<
  AccessTokensDataTableRef,
  AccessTokensDataTableProps
>;

const defaultQuery: AccessToken[] = [];

const AccessTokensDataTable: AccessTokensDataTable = ({ isActive }, ref) => {
  const confirmationModal = useRef<ConfirmationModalRef>(null);
  const {
    data: accessTokens = defaultQuery,
    isLoading,
    refetch,
  } = useQuery(["accessTokens"], tokenService.getAccessTokens, {
    onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    enabled: isActive,
  });

  const fetchAccessTokens = () => {
    refetch();
  };

  useImperativeHandle(ref, () => ({
    refreshAccessTokenData() {
      refetch();
    },
  }));

  const { mutate: enableToken } = useMutation(
    ({ id }: Pick<AccessToken, "id">) => tokenService.enableAccessToken(id),
    {
      onMutate: ({ id }) =>
        optimisticDatasetUpdater<AccessToken, "id">(
          ["accessTokens"],
          { id },
          { status: "active" },
        ),
      onError: errorToastHandler(
        "There was an error enabling the Access Token",
      ),
      onSettled: fetchAccessTokens,
    },
  );

  const { mutate: disableToken } = useMutation(
    ({ id }: Pick<AccessToken, "id">) => tokenService.disableAccessToken(id),
    {
      onMutate: ({ id }) =>
        optimisticDatasetUpdater<AccessToken, "id">(
          ["accessTokens"],
          { id },
          { status: "disabled" },
        ),
      onError: errorToastHandler(
        "There was an error disabling the Access Token",
      ),
      onSettled: fetchAccessTokens,
    },
  );

  const { mutate: revokeToken } = useMutation(
    ({ id }: Pick<AccessToken, "id">) => tokenService.revokeAccessToken(id),
    {
      onMutate: ({ id }) =>
        optimisticDatasetRemover<AccessToken, "id">(["accessTokens"], { id }),
      onError: errorToastHandler(
        "There was an error revoking the Access Token",
      ),
      onSettled: fetchAccessTokens,
    },
  );

  const revokeTokenHandler = useCallback(
    ({ id }: AccessToken) => {
      confirmationModal.current?.openModal({
        title: "Revoke Access Token",
        description: (
          <Fragment>
            Are you sure you want to revoke the token with id{" "}
            <IdTruncator id={id}></IdTruncator>
          </Fragment>
        ),
        confirm: "Revoke",
        cancel: "Cancel",
        action() {
          confirmationModal.current?.closeModal();
          revokeToken({ id });
        },
      });
    },
    [revokeToken],
  );

  const columns = useMemo(
    () =>
      columnsDef({
        onEnableToken: enableToken,
        onDisableToken: disableToken,
        onRevokeToken: revokeTokenHandler,
      }),
    [disableToken, enableToken, revokeTokenHandler],
  );

  return (
    <Fragment>
      <DataTable
        columns={columns}
        defaultSortField="lastUseTs"
        data={accessTokens}
        isLoading={isLoading}
        sortByDesc
        pagination
        compact
        noDataComponent={
          <NoDataComponent>There are no access tokens yet</NoDataComponent>
        }
      />
      <ConfirmationModal ref={confirmationModal} />
    </Fragment>
  );
};

export default forwardRef(AccessTokensDataTable);
