import React, { FC, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import useCommonStyles from "theme/useCommonStyles";

import { useCurrentOrgId, useHasScope } from "providers";

import BootstrapBadge from "components/BootstrapBadge/BootstrapBadge";
import LogoBadge from "components/LogoBadge";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import integrationsService from "services/integrationsService";
import { Permissions, Resources } from "types/auth-roles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pill: {
      position: "absolute",
      right: 0,
      top: 0,
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
);

const Integrations: FC = () => {
  const orgId = useCurrentOrgId();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { data: integrationsData = [], isLoading } = useQuery(
    ["adaptersMetadata", orgId],
    () => integrationsService.getIntegrations(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const hasAdapterWriteScope = useHasScope(
    Resources.Adapter,
    Permissions.Write,
  );

  return (
    <Fragment>
      <PageHeader>
        IntegrateLacework IaC Security with other products
      </PageHeader>
      {isLoading ? (
        <Grid container spacing={3}>
          {[...Array(4)].map((i) => (
            <Grid key={i} item xs={12} sm={6} lg={4} xl={3}>
              <Box component={Card} p={2} lineHeight={3}>
                <Skeleton width="40%" />
                <Skeleton count={2} />
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={3}>
          {integrationsData.map((item, index) => {
            const isSlackConfig = item.type.toLowerCase() === "slack";
            const isAwsConfig = item.type.toLowerCase() === "aws";

            const description = isAwsConfig
              ? `Allow Lacework IaC Security to monitor your AWS infrastructure`
              : item.description;
            return (
              <Grid item key={index} xs={12} sm={6} lg={4} xl={3}>
                <Box component={Card} height="100%" key={index}>
                  <Box
                    display="flex"
                    height="100%"
                    flexDirection="column"
                    position="relative"
                    p={3}
                  >
                    <LogoBadge logo={item.type} className={classes.pill} />
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classNames({
                        [commonClasses.textUpper]: isAwsConfig,
                      })}
                    >
                      {item.type}
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      {description}
                    </Typography>
                    <Box mb={1} />

                    {item.enabled ? (
                      item.configured ? (
                        <Box mt="auto" display="flex" width="100%">
                          <Button
                            fullWidth
                            component={Link}
                            to={`config/${item.type}`}
                            color="primary"
                            variant="outlined"
                          >
                            View
                          </Button>
                        </Box>
                      ) : !hasAdapterWriteScope ? (
                        <Box mt={1}>
                          <Typography variant="body1">
                            Contact an administrator about this integration
                          </Typography>
                        </Box>
                      ) : isSlackConfig ? (
                        <Box mt="auto" width="100%">
                          <Button
                            fullWidth
                            component="a"
                            href="/api/v1/slack/authorize"
                            color="primary"
                            variant="contained"
                          >
                            Configure
                          </Button>
                        </Box>
                      ) : (
                        <Box mt="auto" width="100%">
                          <Button
                            fullWidth
                            component={Link}
                            to={`config/${item.type}`}
                            color="primary"
                            variant="contained"
                          >
                            Configure
                          </Button>
                        </Box>
                      )
                    ) : (
                      <Box component="span" mb={1}>
                        <Box position="absolute" bottom={16} right={16}>
                          <BootstrapBadge color="soft-info">
                            coming soon
                          </BootstrapBadge>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Fragment>
  );
};

export default Integrations;
