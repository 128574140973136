import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import upperFirst from "lodash/upperFirst";

import { useCurrentOrgId, useHasScope } from "providers";

import PageHeader from "components/PageHeader";
import { normalizeFormError } from "helpers/normalizeFormErrors";
import integrationService from "services/integrationService";
import { Permissions, Resources } from "types/auth-roles";
import { CommonInstance, MetadataProperty } from "types/integration-metadata";

const ENCRYPTED_FIELD = "***encrypted***";

const Configuration: FC = () => {
  const orgId = useCurrentOrgId();
  const navigate = useNavigate();
  const { configType = "" } = useParams<{ configType: string }>();
  const { handleSubmit, register, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [configured, setIsConfigured] = useState(false);
  const [instance, setInstance] = useState<CommonInstance>();
  const [properties, setProperties] = useState<MetadataProperty[]>();
  const [error, setError] = useState<string>();
  const [submit, setSubmit] = useState(true);

  const isSlackConfig = configType.toLowerCase() === "slack";
  const hasAdapterWriteScope = useHasScope(
    Resources.Adapter,
    Permissions.Write,
  );

  const onTestClick = () => setSubmit(false);

  const onSubmit = useCallback(
    async (model: Record<string, string>) => {
      const instanceName = instance?.name ?? "default";

      setError(void 0);
      setIsLoading(true);
      try {
        await integrationService.updateMetadata(
          {
            orgId,
            configType: upperFirst(configType),
            instanceName,
          },
          model,
          configured,
        );
        toast.success(`The configuration for ${instanceName} has been updated`);
        navigate("/integrations");
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    },
    [configType, configured, navigate, instance?.name, orgId],
  );

  const onTestSubmit = useCallback(
    async (model: Record<string, string>) => {
      if (!instance) {
        return;
      }
      setError(void 0);
      setIsTesting(true);
      try {
        const response = await integrationService.testConfiguration(
          {
            orgId,
            configType: upperFirst(configType),
            instanceName: instance.name,
          },
          model,
        );
        switch (response.status) {
          case "ok":
            toast.success(response.message);
            break;
          case "unknown":
            toast.info(response.message);
            break;
          case "fail":
            toast.error(response.message);
            break;
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmit(true);
        setIsTesting(false);
      }
    },
    [configType, instance, orgId],
  );

  const fetchConfig = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await integrationService.getMetadataDetails({
        orgId,
        configType: upperFirst(configType),
      });
      setInstance(response.data.instances[0]);
      setProperties(response.data.properties);
      setIsConfigured(response.data.configured);
    } catch (error) {
      setError(error?.message);
    }
    setIsLoading(false);
  }, [configType, orgId]);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  return (
    <Fragment>
      <PageHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          {`${upperFirst(configType)} Configuration`}
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to="/integrations">
              Integrations
            </Link>
            <Typography color="textPrimary">
              {`${upperFirst(configType)} Configuration`}
            </Typography>
          </Breadcrumbs>
        </Box>
      </PageHeader>
      <Card>
        <Box p={2}>
          {error && (
            <Box pb={9}>
              <Box mt={2}>
                <Alert style={{ borderRadius: 0 }} color="warning">
                  <p>{error}</p>
                </Alert>
              </Box>
            </Box>
          )}

          {isLoading ? (
            <Box lineHeight={3.5}>
              <Skeleton count={3} />
            </Box>
          ) : (
            <Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <form
                    id="configForm"
                    onSubmit={handleSubmit(submit ? onSubmit : onTestSubmit)}
                  >
                    {properties?.map((item, index) => {
                      const inputValue = instance?.[item.name];
                      const visible = item.visible ?? true;
                      if (visible)
                        return (
                          <Box key={index}>
                            <Typography variant="h5" gutterBottom>
                              {item.displayName}
                              {item.required && (
                                <Box component="small" color="error.main">
                                  *
                                </Box>
                              )}
                            </Typography>
                            <TextField
                              variant="outlined"
                              fullWidth
                              helperText={
                                !!errors[item.name] ? (
                                  normalizeFormError(errors[item.name])
                                ) : (
                                  <Fragment>
                                    {item.description}{" "}
                                    {item.docUrl && (
                                      <a
                                        href={item.docUrl}
                                        title="Click here for documentation"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                      </a>
                                    )}
                                  </Fragment>
                                )
                              }
                              color="primary"
                              name={item.name}
                              id={item.name}
                              disabled={!hasAdapterWriteScope ?? item.readOnly}
                              defaultValue={
                                inputValue === ENCRYPTED_FIELD
                                  ? void 0
                                  : inputValue
                              }
                              type={
                                inputValue === ENCRYPTED_FIELD
                                  ? "password"
                                  : "text"
                              }
                              placeholder={
                                inputValue === ENCRYPTED_FIELD
                                  ? "******"
                                  : inputValue
                              }
                              inputRef={register({
                                required: {
                                  value: item.required,
                                  message: `${item.displayName} is required`,
                                },
                                pattern: {
                                  value: new RegExp(item.regex),
                                  message: item.errorMessage,
                                },
                              })}
                              error={!!errors[item.name]}
                            />
                            <Box mb={1} />
                          </Box>
                        );
                      return null;
                    })}
                  </form>
                </Grid>
              </Grid>

              <Grid container justify="flex-end">
                {instance && (
                  <Box mt={1} mr={1} display="inline-flex">
                    <Tooltip
                      title={
                        hasAdapterWriteScope ? "" : "Insufficient Permissions."
                      }
                      arrow
                    >
                      <span>
                        <Button
                          form="configForm"
                          type="submit"
                          disabled={isTesting || !hasAdapterWriteScope}
                          onClick={onTestClick}
                          color="primary"
                          variant="outlined"
                        >
                          {isTesting ? (
                            <Box
                              mx={7}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <CircularProgress size={22} />
                            </Box>
                          ) : (
                            "Test Configuration"
                          )}
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                )}
                {isSlackConfig ? (
                  <Box mt={1}>
                    <Tooltip
                      title={
                        hasAdapterWriteScope ? "" : "Insufficient Permissions."
                      }
                      arrow
                    >
                      <span>
                        <Button
                          component="a"
                          href="/api/v1/slack/authorize"
                          color="primary"
                          variant="contained"
                          disabled={isTesting || !hasAdapterWriteScope}
                        >
                          {instance
                            ? "Update Configuration"
                            : "Save Configuration"}
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                ) : (
                  <Box mt={1}>
                    <Tooltip
                      title={
                        hasAdapterWriteScope ? "" : "Insufficient Permissions."
                      }
                      arrow
                    >
                      <span>
                        <Button
                          disabled={isTesting || !hasAdapterWriteScope}
                          form="configForm"
                          color="primary"
                          type="submit"
                          variant="contained"
                        >
                          {instance
                            ? "Update Configuration"
                            : "Save Configuration"}
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                )}
              </Grid>
            </Fragment>
          )}
        </Box>
      </Card>
    </Fragment>
  );
};

export default Configuration;
