import React, { forwardRef, Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  IconButtonProps,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { useCurrentOrgId, useHasScope } from "providers";

import { facPlayRounded } from "assets/svg/icons/custom-fa-icons";
import {
  errorToastHandler,
  optimisticDatasetUpdater,
} from "helpers/queryHelpers";
import iacBotService from "services/iacBotService";
import { Permissions, Resources } from "types/auth-roles";
import { RepoRunStatus } from "types/iacBot";
import { ZodiacTriggerPayload as Payload } from "types/zodiac";

interface InvokeIaCBotModalProps extends IconButtonProps {
  gitRepo: string;
  accessible?: boolean;
  repoEnabled?: boolean;
  gitBranch?: string;
  gitCommit?: string;
}

const InvokeIaCBotModal: React.RefForwardingComponent<
  HTMLButtonElement,
  InvokeIaCBotModalProps
> = (
  {
    gitRepo,
    gitBranch = "",
    gitCommit = "",
    accessible = true,
    repoEnabled = true,
    ...rest
  },
  ref,
) => {
  const orgId = useCurrentOrgId();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );

  const { control, handleSubmit, register } = useForm<Payload>({
    defaultValues: { gitRepo, gitBranch, gitCommit },
    shouldUnregister: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { refetch } = useQuery(
    ["git-repos-running", orgId],
    () => iacBotService.getIacBotRunningRepos(orgId),
    {
      enabled: false,
    },
  );

  const { mutate: invokeIaCBot } = useMutation(
    ({ payload }: { payload: Payload }) => {
      setIsLoading(true);
      const payloadS = {
        ...payload,
        gitBranch: payload.gitBranch === "" ? undefined : payload.gitBranch,
        gitCommit: payload.gitCommit === "" ? undefined : payload.gitCommit,
      };
      return iacBotService.invokeIacBot(orgId, payloadS);
    },
    {
      onSettled: () => {
        setIsLoading(false);
      },
      onMutate: () =>
        optimisticDatasetUpdater<RepoRunStatus, "repo">(
          ["git-repos-running", orgId],
          { repo: gitRepo },
          { running: true },
        ),
      onError: errorToastHandler(),
      onSuccess: () => {
        toast.success(
          "We successfully sent the message to invoke the IaC Bot with the specified options.",
        );
        refetch();
        handleClose();
      },
    },
  );

  return (
    <Fragment>
      <Box onClick={(e) => e.stopPropagation()}>
        <Tooltip
          arrow
          title={
            !repoEnabled
              ? "Repository should be in enabled state to invoke IaC Bot."
              : !accessible
              ? "Repository is not accessible by IaC Bot. Please use the CLI to trigger assessments for the repository."
              : !hasOrgWriteScope
              ? "Insufficient Permissions"
              : "Invoke IaC Bot"
          }
        >
          <span>
            <IconButton
              {...rest}
              onClick={handleOpen}
              color="primary"
              disabled={!repoEnabled || !hasOrgWriteScope || !accessible}
              innerRef={ref}
            >
              <FontAwesomeIcon icon={facPlayRounded} />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="form-dialog-title"
      >
        <Box p={3} pb={0} display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            Invoke IaC Bot
          </Typography>
        </Box>
        <DialogContent>
          <form
            id="invoke-iac-bot"
            onSubmit={handleSubmit((payload) => invokeIaCBot({ payload }))}
          >
            {isLoading ? (
              <Box p={4} textAlign="center">
                <CircularProgress />
              </Box>
            ) : (
              <Fragment>
                <Controller
                  fullWidth
                  label="Repository"
                  rules={{ required: true }}
                  required
                  control={control}
                  name="gitRepo"
                  innerRef={register}
                  as={TextField}
                  variant="outlined"
                  disabled
                />
                <Box mb={2} />
                <Controller
                  fullWidth
                  control={control}
                  label="Branch"
                  name="gitBranch"
                  innerRef={register}
                  as={TextField}
                  variant="outlined"
                />
                <Box mb={2} />
                <Controller
                  fullWidth
                  control={control}
                  label="Commit"
                  name="gitCommit"
                  innerRef={register}
                  as={TextField}
                  variant="outlined"
                />
              </Fragment>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Box mr={2} display="inline-block">
              <Button
                disabled={isLoading}
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </Button>
            </Box>
            <Button
              color="primary"
              form="invoke-iac-bot"
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              Invoke Now
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default forwardRef(InvokeIaCBotModal);
