import { FC, Fragment, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Column } from "react-table";
import { Box, Button } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { isUndefined, upperFirst } from "lodash";

import Badge from "components/BootstrapBadge/BootstrapBadge";
import DataTable from "components/DataTable";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import { handleOnSortToggle, sortBool } from "helpers/tableHelpers";
import { useQueryState } from "hooks/useQueryState";
import organizationService from "services/organizationService";
import { OrganizationDescription } from "types";

const columns: Column<OrganizationDescription>[] = [
  {
    Header: "Display Name",
    accessor: "displayName",
  },
  {
    Header: "Git Org",
    accessor: "gitOrgName",
  },
  {
    Header: "LW Account",
    accessor: "lwAccountName",
  },
  {
    Header: "Org ID",
    accessor: "orgId",
  },
  {
    Header: "Account Type",
    accessor: "orgType",
  },
  {
    Header: "Status",
    accessor: "accountStatus",
    Cell: ({ row: { original } }) =>
      original.accountStatus ? (
        <Badge
          color={
            original.accountStatus.toLowerCase() === "customer"
              ? "soft-success"
              : "soft-info"
          }
        >
          {upperFirst(original.accountStatus)}
        </Badge>
      ) : (
        "-"
      ),
  },
  {
    Header: "Created",
    accessor: "createTs",
    Cell: ({ row: { original } }) => (
      <RelativeTimeFormatter dateTs={original.createTs} invalidDateText="-" />
    ),
  },
  {
    Header: "Active",
    sortType: sortBool,
    accessor: "active",
    filter: "equals",
    maxWidth: 60,
    width: 60,
    Cell: ({ row: { original } }) =>
      original.active ? (
        <Box color="success.main" component={CheckCircle} />
      ) : (
        <Box color="error.main" component={Cancel} />
      ),
  },
  {
    Header: "Actions",
    accessor: "lastActivityTs",
    isButton: true,
    disableSortBy: true,
    collapse: true,
    Cell: ({ row: { original } }) => (
      <Button
        component={Link}
        color="primary"
        variant="outlined"
        to={`/support/organizations/${original.orgId}`}
        size="small"
      >
        View
      </Button>
    ),
  },
];

interface OrganizationsDataTableProps {
  dashboard?: boolean;
}

export interface OrgsQueryState {
  page: number;
  pageSize: number;
  query: string;
  sortBy: string;
  sortDirection: "asc" | "desc";
}

const OrganizationsDataTable: FC<OrganizationsDataTableProps> = ({
  dashboard,
}) => {
  const navigate = useNavigate();
  const { data = [], isLoading } = useQuery(
    "organizations",
    () => organizationService.getOrganizations(),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  const tableRef = useRef<DataTableRef<OrganizationDescription>>(null);
  const {
    state: {
      page = 1,
      query = "",
      sortBy = "createTs",
      sortDirection = "desc",
      pageSize = 10,
    },
    setQueryState,
  } = useQueryState<OrgsQueryState>("organizations");

  const orgs = useMemo(
    () =>
      data.map((org) => ({
        ...org,
        active: isUndefined(org.active) ? true : org.active,
      })),
    [data],
  );

  return (
    <Fragment>
      <DataTable
        search
        ref={tableRef}
        autoResetFilters={false}
        autoResetGlobalFilter={false}
        columns={columns}
        compact={dashboard}
        data={orgs}
        searchActions={
          <FiltersModal
            fields={[
              {
                displayName: "Status",
                type: "select",
                key: "accountStatus",
                renderValue: (v) => (
                  <Badge
                    color={v === "customer" ? "soft-success" : "soft-info"}
                  >
                    {upperFirst(v)}
                  </Badge>
                ),
                values: [
                  { displayName: "Customer", value: "customer" },
                  { displayName: "Trial", value: "trial" },
                ],
              },
              {
                displayName: "Account Type",
                type: "select",
                key: "orgType",
                values: [
                  { displayName: "User", value: "User" },
                  { displayName: "Organization", value: "Organization" },
                ],
              },
              {
                displayName: "Active",
                type: "select",
                key: "active",
                values: [
                  { displayName: "Active", value: true },
                  { displayName: "Inactive", value: false },
                ],
                renderValue: (v) => (
                  <Box display="flex" alignItems="center">
                    {v ? (
                      <Fragment>
                        <Box
                          component={CheckCircle}
                          mr={1}
                          color="success.main"
                        />
                        Active
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Box component={Cancel} mr={1} color="error.main" />
                        Inactive
                      </Fragment>
                    )}
                  </Box>
                ),
              },
            ]}
            title="Filter Organizations"
            disabled={isLoading}
            tableRef={tableRef}
            persistInUrl={!dashboard}
          />
        }
        isLoading={isLoading}
        pagination
        defaultSortField={
          dashboard ? "createTs" : (sortBy as keyof OrganizationDescription)
        }
        sortByDesc={dashboard ? true : sortDirection === "desc"}
        rowHover
        initialPageIndex={dashboard ? undefined : page}
        initialRowsPerPage={dashboard ? undefined : pageSize}
        query={dashboard ? undefined : query}
        onRowClick={({ original }) =>
          navigate(`/support/organizations/${original.orgId}`)
        }
        onSearchChange={
          dashboard
            ? undefined
            : (val) => setQueryState({ query: val, page: 1 })
        }
        onSortChange={
          dashboard
            ? undefined
            : (id, isSorted, isSortDesc) =>
                setQueryState({
                  ...handleOnSortToggle(id, isSorted, isSortDesc),
                  page: 1,
                })
        }
        onChangePage={dashboard ? undefined : (page) => setQueryState({ page })}
        onChangeRowsPerPage={
          dashboard
            ? undefined
            : (pageSize) => setQueryState({ pageSize, page: 1 })
        }
      />
    </Fragment>
  );
};

export default OrganizationsDataTable;
