import React, { FC, Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import classNames from "classnames";

import { useCurrentOrgId } from "providers";

import { facPlayRounded } from "assets/svg/icons/custom-fa-icons";
import { ReactComponent as CompletedSetupIllustration } from "assets/svg/illustrations/done-checking.svg";
import { ReactComponent as EmptyIllustration } from "assets/svg/illustrations/empty-box.svg";
import { ReactComponent as SetupIllustration } from "assets/svg/illustrations/setup.svg";
import NavbarTopLayout from "components/Layout/NavbarTopLayout";
import iacBotService from "services/iacBotService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootSmall: {
      border: 0,
      padding: theme.spacing(4),
    },
  }),
);

const LandingPageRepositories: FC = () => {
  const orgId = useCurrentOrgId();
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [status, setStatus] = useState({
    isFetching: true,
    isAnalyzing: false,
    isDone: false,
  });

  const { isLoading } = useQuery(
    ["iac-bot-status", orgId],
    () => iacBotService.getIacBotStatus(orgId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: status.isDone ? false : 3000,
      onSuccess: (data) =>
        setStatus({
          isFetching: data.repoCount === 0,
          isAnalyzing: data.repoCount > 0 && data.assessmentCount === 0,
          isDone: data.repoCount > 0 && data.assessmentCount > 0,
        }),
    },
  );

  setTimeout(() => {
    if (!isDone) setIsTimedOut(true);
  }, 180000);

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { isFetching, isAnalyzing, isDone } = status;

  const refresh = () => window.location.reload();

  return (
    <NavbarTopLayout white>
      <Box
        height="90%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container justify="center">
          <Grid item xs={12} md={8} lg={6} xl={5}>
            <Card
              variant="outlined"
              className={classNames({
                [classes.rootSmall]: matches,
              })}
            >
              <Box
                p={4}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Box
                  component={
                    isTimedOut
                      ? EmptyIllustration
                      : isDone
                      ? CompletedSetupIllustration
                      : SetupIllustration
                  }
                  width={400}
                  height="auto"
                  mb={isDone ? 8 : 6}
                />

                <Typography variant="h2" gutterBottom>
                  {isTimedOut
                    ? "No Repositories Found"
                    : isFetching || isLoading
                    ? "Fetching Repositories"
                    : isAnalyzing
                    ? "Analyzing Repositories"
                    : "Successfully imported your repositories."}
                </Typography>
                {isDone ? (
                  <Box my={4}>
                    <Link to="/repos">
                      <Button variant="contained" color="primary" size="large">
                        SEE RESULTS NOW
                      </Button>
                    </Link>
                  </Box>
                ) : isTimedOut ? (
                  <Box my={2} textAlign="center">
                    <Typography
                      align="left"
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                    >
                      Sorry, we didn’t find any repos with Terraform,
                      CloudFormation or Kubernetes manifests. If you think this
                      is a mistake, <Link to="/repos">click here</Link> and
                      verify that the repo containing the IaC shows up. If it
                      does, you can trigger a new assessment by pressing the
                      play (<FontAwesomeIcon icon={facPlayRounded} size="sm" />)
                      button. If you don't see it please return to
                      {"  "}
                      <a
                        href="https://github.com/apps/iacbot/installations/new"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        github.com/apps/iacbot/installations/new
                      </a>
                      {"  "}
                      and verify that you have granted iacbot access to the repo
                      that you intended.
                    </Typography>
                    <Box mt={3} mb={2}>
                      <Button
                        onClick={refresh}
                        variant="outlined"
                        color="primary"
                        size="large"
                      >
                        Try again
                      </Button>
                    </Box>
                    <Button
                      component={Link}
                      to="/dashboard"
                      color="primary"
                      size="large"
                    >
                      Go to dashboard
                    </Button>
                  </Box>
                ) : (
                  <Fragment>
                    <Box my={2}>
                      <CircularProgress />
                    </Box>
                    <Typography variant="h3" color="textSecondary" gutterBottom>
                      This may take several minutes
                    </Typography>
                  </Fragment>
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </NavbarTopLayout>
  );
};

export default LandingPageRepositories;
