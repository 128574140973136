import React, { forwardRef, Fragment, Ref, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import SyntaxHighlighter from "react-syntax-highlighter";
import xonokai from "react-syntax-highlighter/dist/cjs/styles/prism/xonokai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { useCurrentOrgId, useDocsRoute } from "providers";

import { facSeeConfig } from "assets/svg/icons/custom-fa-icons";
import repositoryService from "services/repositoryService";

interface RepositoryConfigYamlModalProps {
  repo: string;
  hideButton?: boolean;
  disabled?: boolean;
  ref?: Ref<HTMLButtonElement>;
}

const RepositoryConfigYamlModal: React.RefForwardingComponent<
  HTMLButtonElement,
  RepositoryConfigYamlModalProps
> = ({ repo, hideButton, disabled }, ref) => {
  const orgId = useCurrentOrgId();
  const [open, setOpen] = useState(false);
  const docsRoute = useDocsRoute();

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const { data: yaml, isLoading } = useQuery(
    ["gitRepoConfigYaml", orgId, encodeURIComponent(repo)],
    () => repositoryService.getRepoConfigYaml(orgId, encodeURIComponent(repo)),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: open,
    },
  );

  const isEmpty = useMemo(() => yaml?.slice(0, 6) === "--- {}", [yaml]);

  return (
    <Fragment>
      <Box visibility={hideButton ? "hidden" : "visible"}>
        <Tooltip arrow title="See config Yaml">
          <IconButton
            disabled={disabled}
            innerRef={ref}
            onClick={handleClickOpen}
            color="primary"
          >
            <FontAwesomeIcon icon={facSeeConfig} />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClickClose}
        aria-labelledby="form-dialog-title"
      >
        <Box p={3} pb={0} display="flex" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            Lacework IaC Security Config
          </Typography>
        </Box>
        <DialogContent>
          {isLoading ? (
            <Card variant="outlined">
              <Box p={2}>
                <Skeleton count={7} />
              </Box>
            </Card>
          ) : isEmpty ? (
            <Box bgcolor="lightBackground.dark" py={3} px={2} borderRadius={6}>
              <Box mb={2}> No config available in the repository.</Box>
              <a
                href={`${docsRoute}/configure-iacbot`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
              </a>
            </Box>
          ) : (
            <SyntaxHighlighter language="yaml" style={xonokai}>
              {yaml}
            </SyntaxHighlighter>
          )}
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Box mr={2} display="inline-block">
              <Button onClick={handleClickClose} color="primary">
                Close
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default forwardRef(RepositoryConfigYamlModal);
