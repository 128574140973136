import React, { FC, Fragment, useEffect, useState } from "react";
import { Controller, useForm, ValidationRules } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useCurrentOrgId, useHasScope, useUser } from "providers";

import CopyText from "components/CopyText/CopyText";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
  optimisticObjectUpdater,
} from "helpers/queryHelpers";
import { isURLFriendly, isValidDomain } from "helpers/validators";
import organizationService from "services/organizationService";
import { AuthConfig, AuthProvider } from "types/auth-config";
import { Permissions, Resources } from "types/auth-roles";

interface Form {
  name: string;
  displayName: string;
  domain: string;
  provider: AuthProvider;
  configMode: string;
  prCommentsOnPassedChecks: boolean;
}

const OrganizationPage: FC = () => {
  const hasSsoWriteScope = useHasScope(Resources.SSO, Permissions.Write);
  const hasOrgOwnerScope = useHasScope(
    Resources.Organization,
    Permissions.Owner,
  );
  const { provider } = useUser();
  const isGoogleProvider = provider === "google";
  const orgId = useCurrentOrgId();
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentProvider, setCurrentProvider] = useState("none");
  const { orgConfigSettingsUiEnabled } = useFlags();

  const {
    data: authConfig,
    isLoading: isAuthConfigLoading,
    refetch: refetchAuth,
  } = useQuery(
    ["organization-auth-config", orgId],
    () => organizationService.getAuthConfig(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  const { data: orgConfig, isLoading: isOrgConfigLoading } = useQuery(
    ["organization-config", orgId],
    () => organizationService.getOrgConfig(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: organization,
    refetch,
    isLoading: isOrgLoading,
  } = useQuery(
    ["organization-details", orgId],
    () => organizationService.getOrganization(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const isLoading = isOrgConfigLoading || isOrgLoading || isAuthConfigLoading;

  const { control, watch, getValues, handleSubmit, reset, formState } =
    useForm<Form>({
      defaultValues: {
        name: organization?.name,
        displayName: organization?.displayName,
        provider: authConfig?.provider ?? "none",
        domain: authConfig?.domain ?? "",
        configMode: orgConfig?.pr_status_force_pass_enabled
          ? "audit"
          : "enforce",
        prCommentsOnPassedChecks:
          orgConfig?.pr_comments_on_passed_check_enabled,
      },
      mode: "onChange",
    });

  const { isDirty, dirtyFields, isValid, errors } = formState;

  const rules: {
    displayName: ValidationRules;
    name: ValidationRules;
    domain: ValidationRules;
  } = {
    displayName: {
      required: "Display name is required",
    },
    domain: {
      validate: (value) => {
        if (value === "") return;
        if (!isValidDomain(value)) {
          return "Please enter a valid domain.";
        }
      },
    },
    name: {
      minLength: {
        value: 3,
        message: "The name should be at least 3 characters long",
      },
      validate: (value) => {
        const {
          doesContainsOnlyValidCharacters,
          isEndingWithLettersOrNumbers,
          isStartingWithLetters,
        } = isURLFriendly(value);
        if (value === "" || value === undefined) return;
        if (!doesContainsOnlyValidCharacters) {
          return "The name can only contain letters, numbers and the hyphen symbol.";
        }
        if (!isEndingWithLettersOrNumbers) {
          return "The name cannot end with an hyphen symbol.";
        }

        if (!isStartingWithLetters) {
          return "The name should start with a letter.";
        }
      },
    },
  };

  const onUpdateAuthConfig = async (model: Partial<AuthConfig>) => {
    if (!dirtyFields.domain && !dirtyFields.provider) {
      return;
    }
    const updatedObject = {
      provider: model.provider,
      domain:
        model.provider !== "google" || model.domain === ""
          ? undefined
          : model.domain,
    };
    const rollbackAuth = optimisticObjectUpdater(
      ["organization-auth-config", orgId],
      updatedObject,
    );
    try {
      await organizationService.setProviderConfig(orgId, updatedObject);
    } catch (error) {
      errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      rollbackAuth();
    } finally {
      refetchAuth();
    }
  };

  const onUpdateOrgInfo = async (model: Form) => {
    if (!dirtyFields.name && !dirtyFields.displayName) return;
    const updatedObject = {
      ...(dirtyFields.name && { name: model.name }),
      ...(dirtyFields.displayName && { displayName: model.displayName }),
    };

    const rollback = optimisticObjectUpdater(
      ["organization-details", orgId],
      updatedObject,
    );
    try {
      await organizationService.updateOrganizationInfo(orgId, updatedObject);
    } catch (error) {
      errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      rollback();
    } finally {
      refetch();
    }
  };

  const onUpdateOrgConfig = async (model: Form) => {
    if (!dirtyFields.configMode && !dirtyFields.prCommentsOnPassedChecks)
      return;

    const updatedObject = {
      ...(dirtyFields.configMode && {
        pr_status_force_pass_enabled: model.configMode === "audit",
      }),
      ...(dirtyFields.prCommentsOnPassedChecks && {
        pr_comments_on_passed_check_enabled: model.prCommentsOnPassedChecks,
      }),
    };

    const rollback = optimisticObjectUpdater(
      ["organization-config", orgId],
      updatedObject,
    );
    try {
      await organizationService.updateOrganizationConfig(orgId, updatedObject);
    } catch (error) {
      errorToastHandler(FALLBACK_ERROR_MESSAGE)(error);
      rollback();
    }
  };

  const onSubmit = async (model: Form) => {
    setIsProcessing(true);
    try {
      await Promise.all([
        onUpdateOrgInfo(model),
        onUpdateAuthConfig(model),
        onUpdateOrgConfig(model),
      ]);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    reset({
      name: organization?.name,
      displayName: organization?.displayName,
      provider: authConfig?.provider ?? "none",
      domain: authConfig?.domain ?? "",
      configMode: orgConfig?.pr_status_force_pass_enabled ? "audit" : "enforce",
      prCommentsOnPassedChecks: orgConfig?.pr_comments_on_passed_check_enabled,
    });
    setCurrentProvider(authConfig?.provider ?? "none");
  }, [
    authConfig,
    orgConfig?.pr_comments_on_passed_check_enabled,
    orgConfig?.pr_status_force_pass_enabled,
    organization,
    reset,
  ]);

  const watchProvider = watch("provider");

  useEffect(() => {
    setCurrentProvider(getValues("provider") ?? authConfig?.provider);
  }, [getValues, watchProvider, authConfig]);

  return (
    <Fragment>
      <PageHeader>Organization</PageHeader>
      <Box component={Card} px={2} py={4}>
        {isLoading ? (
          <Box my={1} lineHeight={2.5}>
            {[...Array(4)].map((i, j) => (
              <Grid key={j} container spacing={3}>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Skeleton width="30%" />
                  <Skeleton width="100%" />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Skeleton width="30%" />
                  <Skeleton width="100%" />
                </Grid>
              </Grid>
            ))}
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} wrap="wrap">
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Typography variant="h5" gutterBottom>
                      Organization ID
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {orgId}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h5" gutterBottom>
                      Name
                    </Typography>
                    {hasSsoWriteScope ? (
                      <Controller
                        control={control}
                        name="name"
                        rules={rules.name}
                        as={TextField}
                        fullWidth
                        disabled={isProcessing}
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        autoComplete="off"
                      />
                    ) : (
                      <Typography variant="body1" gutterBottom>
                        {organization?.name ?? "-"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Box mb={1} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h5" gutterBottom>
                      Display Name
                    </Typography>
                    {hasSsoWriteScope ? (
                      <Controller
                        rules={rules.displayName}
                        control={control}
                        name="displayName"
                        as={TextField}
                        fullWidth
                        disabled={isProcessing}
                        variant="outlined"
                        error={!!errors.displayName}
                        helperText={errors.displayName?.message}
                        autoComplete="off"
                      />
                    ) : (
                      <Typography variant="body1" gutterBottom>
                        {organization?.displayName ?? "-"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Box mb={1} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Box display="flex" alignItems="flex-start">
                      <Typography variant="h5">Login URL</Typography>
                      <Box mr={3} />
                      <CopyText text={authConfig?.loginUrl ?? ""}>
                        <IconButton size="small" color="primary">
                          <FileCopyOutlined fontSize="small" />
                        </IconButton>
                      </CopyText>
                    </Box>
                    <Typography variant="body1" gutterBottom>
                      {authConfig?.loginUrl}
                    </Typography>
                  </Grid>
                </Grid>
                <Box mb={1} />
                {isGoogleProvider && (
                  <Fragment>
                    {hasSsoWriteScope ? (
                      <Fragment>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} lg={4} xl={3}>
                            <Typography variant="h5" gutterBottom>
                              Login Provider
                            </Typography>
                            <Controller
                              control={control}
                              name="provider"
                              as={
                                <RadioGroup row aria-label="provider">
                                  <FormControlLabel
                                    value="none"
                                    control={<Radio color="primary" />}
                                    disabled={isProcessing}
                                    label="Allow any"
                                  />
                                  <FormControlLabel
                                    value="google"
                                    control={<Radio color="primary" />}
                                    disabled={isProcessing}
                                    label="Google"
                                  />
                                  <FormControlLabel
                                    value="github"
                                    control={<Radio color="primary" />}
                                    disabled={isProcessing}
                                    label="GitHub"
                                  />
                                  <FormControlLabel
                                    value="gitlab"
                                    control={<Radio color="primary" />}
                                    disabled={isProcessing}
                                    label="Gitlab"
                                  />
                                </RadioGroup>
                              }
                            />
                          </Grid>
                        </Grid>
                        <Collapse in={currentProvider === "google"}>
                          <Grid container item xs={12} spacing={3}>
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                              <Typography variant="h5" gutterBottom>
                                Provider Domain
                              </Typography>
                              <Controller
                                control={control}
                                rules={rules.domain}
                                name="domain"
                                as={TextField}
                                fullWidth
                                disabled={isProcessing}
                                variant="outlined"
                                error={!!errors.domain}
                                helperText={errors.domain?.message}
                                autoComplete="off"
                              />
                            </Grid>
                          </Grid>
                        </Collapse>
                      </Fragment>
                    ) : (
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                          <Typography variant="h5" gutterBottom>
                            Provider
                          </Typography>
                          {`${authConfig?.provider} ${
                            authConfig?.domain ? ": " + authConfig?.domain : ""
                          }`}
                        </Grid>
                      </Grid>
                    )}
                  </Fragment>
                )}
              </Grid>
              {orgConfigSettingsUiEnabled && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">Configuration Mode</Typography>
                  <Tooltip
                    arrow
                    placement="left"
                    title={
                      hasOrgOwnerScope
                        ? ""
                        : "Configuration mode can only be edited by organization owners."
                    }
                  >
                    <div>
                      <Controller
                        name="configMode"
                        control={control}
                        as={
                          <RadioGroup aria-label="config-mode">
                            <FormControlLabel
                              disabled={isProcessing || !hasOrgOwnerScope}
                              value="audit"
                              control={<Radio color="primary" />}
                              label="Audit Mode"
                            />
                            <Typography variant="caption">
                              Does not block pipelines/pull (merge) requests,
                              posts comments to PRs if violations are found
                              during Lacework IaC’s repository scans
                            </Typography>
                            <FormControlLabel
                              value="enforce"
                              disabled={isProcessing || !hasOrgOwnerScope}
                              control={<Radio color="primary" />}
                              label="Enforce Status Check"
                            />
                            <Typography variant="caption">
                              Blocks pipelines/pull (merge) requests and posts
                              comments to PRs if violations are found during
                              Lacework IaC’s repository scans.
                            </Typography>
                          </RadioGroup>
                        }
                      />
                      <Box mb={2} />
                      <Controller
                        name="prCommentsOnPassedChecks"
                        control={control}
                        render={({ onChange, value }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                name="prCommentsOnPassedChecks"
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                disabled={isProcessing || !hasOrgOwnerScope}
                              />
                            }
                            label="PR Comments On Passed Checks"
                          />
                        )}
                      />
                      <Box>
                        <Typography variant="caption">
                          Enable comments on Pull / Merge requests for all
                          violations in the PR
                        </Typography>
                      </Box>
                    </div>
                  </Tooltip>
                  <Box mb={2} />
                </Grid>
              )}
            </Grid>
            <Collapse in={isDirty}>
              <Box pt={2} display="flex" justifyContent="flex-end">
                <Button color="primary" onClick={() => reset()}>
                  Cancel
                </Button>
                <Box mr={1} />
                <Button
                  type="submit"
                  disabled={!isValid}
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </Collapse>
          </form>
        )}
      </Box>
    </Fragment>
  );
};

export default OrganizationPage;
