import React, { FC } from "react";

import AppProvider from "./AppProvider";
import ExternalProviders from "./ExternalProviders";
import ReactQueryConfigProvider from "./ExternalProviders/ReactQueryConfigProvider";
import QueryStateProvider from "./QueryStateProvider";

const Providers: FC = ({ children }) => {
  return (
    <ReactQueryConfigProvider>
      <AppProvider>
        <ExternalProviders>
          <QueryStateProvider>{children}</QueryStateProvider>
        </ExternalProviders>
      </AppProvider>
    </ReactQueryConfigProvider>
  );
};

export default Providers;
