import React, { FC, Fragment } from "react";
import { useQuery } from "react-query";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import { useAppContext } from "providers";

import Loader from "pages/Loader";
import launchdarklyService from "services/launchdarklyService";

const LaunchDarklyProvider: FC = ({ children }) => {
  const { user: userO, isLogging } = useAppContext();

  const fetchLDProvider = async (orgId?: string) => {
    const {
      data: { clientSideID },
    } = await launchdarklyService.getKey();
    const user = orgId ? { key: orgId } : undefined;
    const LDProviderR = await asyncWithLDProvider({
      clientSideID,
      user,
    });
    return <LDProviderR>{children}</LDProviderR>;
  };

  const { data: LDProvider = null, isError } = useQuery(
    ["launch-darkly-provider", userO?.currentOrgId],
    () => fetchLDProvider(userO?.currentOrgId),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  if (isError) {
    return <Fragment>{children}</Fragment>;
  }

  if (LDProvider == null || isLogging) {
    return <Loader>Fetching User Data</Loader>;
  }

  return LDProvider;
};

export default LaunchDarklyProvider;
