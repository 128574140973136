import {
  faBitbucket,
  faGitAlt,
  faGithub,
  faGitlab,
  faJenkins,
} from "@fortawesome/free-brands-svg-icons";

import {
  facAtlantis,
  facAzureRepos,
  facBuildkiteOutlined,
  facCircleCI,
  facCloudFormation,
  facLacework,
  facPipe,
  facTerraform,
  facTravis,
  facZodiac,
} from "assets/svg/icons/custom-fa-icons";
import { CiPlatform } from "types/pipelines";

export const getGitPlatformIcon = (gitPlatform?: string) => {
  const platform = gitPlatform?.toLowerCase();

  switch (platform) {
    case "github":
      return faGithub;
    case "gitlab":
      return faGitlab;
    case "bitbucket":
      return faBitbucket;
    case "codecommit":
      return facCloudFormation;
    case "azurerepos":
      return facAzureRepos;
    default:
      return faGitAlt;
  }
};
export const getPipelineIcon = (platform?: CiPlatform) => {
  switch (platform) {
    case "atlantis":
      return facAtlantis;
    case "bitbucket":
      return faBitbucket;
    case "buildkite":
      return facBuildkiteOutlined;
    case "circleCi":
      return facCircleCI;
    case "codebuild":
      return facCloudFormation;
    case "github":
      return faGithub;
    case "gitlab":
      return faGitlab;
    case "iacbot":
      return facLacework;
    case "jenkins":
      return faJenkins;
    case "travis":
      return facTravis;
    case "terraform_cloud":
      return facTerraform;
    case "zodiac":
      return facZodiac;
    default:
      return facPipe;
  }
};
