import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import OrganizationPage from "./pages/Organization/Organization";
import Tokens from "./pages/Tokens";
import Users from "./pages/Users/Users";
import HasScopeRoute from "router/HasScopeRoute";
import { Permissions, Resources } from "types/auth-roles";

const AdminRouter: FC<Childless> = () => {
  return (
    <Routes>
      <Route path="users" element={<Users />} />
      <Route
        path="org"
        element={
          <HasScopeRoute
            resource={Resources.SSO}
            permission={Permissions.Read}
            element={<OrganizationPage />}
          />
        }
      />
      <Route
        path="tokens"
        element={<Navigate to="/admin/tokens/access" replace />}
      />
      <Route path="tokens/:tokenType" element={<Tokens />} />

      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default AdminRouter;
