import React, { FC, Fragment, useCallback } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Column, Row } from "react-table";
import { Box, Button, Card } from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import DataTable from "components/DataTable";
import NoDataComponent from "components/NoDataComponent";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import { handleOnSortToggle } from "helpers/tableHelpers";
import { useQueryState } from "hooks/useQueryState";
import reportService from "services/reportService";
import { Report } from "types/reports";

const columns: Column<Report>[] = [
  {
    Header: "Name",
    accessor: "displayName",
  },
  {
    Header: "Description",
    accessor: "description",
    width: 300,
  },
  {
    Header: "Action",
    accessor: "description",
    id: "actions",
    collapse: true,
    disableSortBy: true,
    isButton: true,
    Cell: ({ row: { original: row } }) => (
      <Button
        component={Link}
        to={`/analytics/reports/${row.name}`}
        color="primary"
        variant="outlined"
        size="small"
      >
        View
      </Button>
    ),
  },
];

interface QueryState {
  sortBy: string;
  sortDirection: "asc" | "desc";
  page: number;
  pageSize: number;
  query: string;
}

const defaultQuery: Report[] = [];

const Reports: FC = () => {
  const orgId = useCurrentOrgId();
  const navigate = useNavigate();
  const { data: reports = defaultQuery, isLoading } = useQuery(
    ["reports", orgId],
    () => reportService.getReports(orgId),
    {
      onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    },
  );

  const {
    state: {
      sortBy = "displayName",
      sortDirection = "asc",
      page = 1,
      pageSize = 10,
      query,
    },
    setQueryState,
  } = useQueryState<QueryState>("reports");

  const handleOnSortChange = useCallback(
    (id: string, isSorted: boolean, isSortDesc?: boolean) => {
      setQueryState(handleOnSortToggle(id, isSorted, isSortDesc));
    },
    [setQueryState],
  );

  const handleRowClick = useCallback(
    (row: Row<Report>) => {
      navigate(`/analytics/reports/${row.original.name}`);
    },
    [navigate],
  );

  const handleSearchChange = useCallback(
    (filterText: string) => {
      setQueryState({ query: filterText });
    },
    [setQueryState],
  );

  return (
    <Fragment>
      <PageHeader>Reports</PageHeader>
      <Card>
        <Box p={2}>
          <DataTable
            columns={columns}
            data={reports}
            isLoading={isLoading}
            pagination
            rowHover
            search
            onSearchChange={handleSearchChange}
            query={query}
            onRowClick={handleRowClick}
            noDataComponent={
              <NoDataComponent>
                There are no reports available at this time.
              </NoDataComponent>
            }
            defaultSortField={sortBy as keyof Report}
            sortByDesc={sortDirection === "desc"}
            initialPageIndex={page}
            pageSizeOptions={[10, 25, 50, 100]}
            initialRowsPerPage={pageSize}
            onChangePage={(page) => setQueryState({ page })}
            onChangeRowsPerPage={(pageSize) =>
              setQueryState({ pageSize, page: 1 })
            }
            onSortChange={handleOnSortChange}
          />
        </Box>
      </Card>
    </Fragment>
  );
};

export default Reports;
