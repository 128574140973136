import { useContext } from "react";

import { AppContext } from "./AppContext";
import { Permissions, Resources } from "types/auth-roles";

export const useAppContext = () => useContext(AppContext);

export const useGetUser = () => useAppContext().getUser;

export const useLogout = () => useAppContext().logout;

export const useIsLogging = () => useAppContext().isLogging;

export const useChangeOrg = () => useAppContext().changeOrg;

export const useIsLogged = () => !!useAppContext().user;

export const useUser = () => {
  const { user } = useAppContext();
  if (!user) {
    throw new Error(
      "There is no user logged. Maybe you are trying to use the user in a public route?",
    );
  }

  return user;
};

export const useOrganizations = () => useUser().organizations ?? [];

export const useCurrentOrgId = () => useUser().currentOrgId;

export const useIsSupportOrg = () => {
  try {
    return useUser().currentOrgId === "900000000000";
  } catch {}
  return false;
};

export const useIsOnPremIacbotEnabled = () =>
  useCurrentOrg().onPremIacbotEnabled;

export const useDocsRoute = () => "https://docs.lacework.com";

export const useCurrentOrg = () => {
  const organizations = useOrganizations();
  const currentOrg = organizations.find((org) => org.current);

  if (organizations.length === 0 && !currentOrg) {
    throw Error("Current user does not belong to any organization", {
      cause: {
        name: "userMissingOrgs",
        message: "Current user does not belong to any organization",
      },
    });
  }
  if (!currentOrg) {
    console.warn(
      "There is no a organization in the organizations array selected as current. Returning the first one",
    );
    return organizations[0];
  }
  return currentOrg;
};

export const useHasScope = (resource: Resources, permission: Permissions) => {
  const currentOrg = useCurrentOrg();
  const scope = `${resource}:${permission}`;

  return currentOrg.scopes.includes(scope);
};
