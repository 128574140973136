import { FC, Fragment, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Breadcrumbs,
  Card,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { isEmpty } from "lodash";

import OrganizationDetailActivity from "./components/OrganizationDetailActivity";
import OrganizationDetailConfig from "./components/OrganizationDetailConfig";
import PageHeader from "components/PageHeader";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
} from "helpers/queryHelpers";
import organizationService from "services/organizationService";
import { OrganizationDescription } from "types";

const OrganizationDetail: FC = () => {
  const { orgId, tab = "config" } = useParams<{ orgId: string; tab: string }>();
  const navigate = useNavigate();

  const {
    data = [],
    isLoading,
    isSuccess,
  } = useQuery("organizations", () => organizationService.getOrganizations(), {
    onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const orgDetail = useMemo(() => {
    const orgs = data.filter((o) => o.orgId === orgId);
    if (orgs.length === 0) return undefined;
    return orgs[0];
  }, [data, orgId]);

  if (orgDetail === undefined && isSuccess) {
    toast.error("Invalid Org ID.");
    return <Navigate to="/support/organizations" replace />;
  }
  const title =
    isEmpty(orgDetail?.name) && isEmpty(orgDetail?.displayName)
      ? orgId
      : orgDetail?.name ?? orgDetail?.displayName;

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) =>
    navigate(
      `/support/organizations/${orgId}/${
        newValue === "activity" ? "activity" : ""
      }`,
      { replace: true },
    );

  return (
    <Fragment>
      <PageHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          {title}
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to="/support/organizations">
              Organizations
            </Link>
            <Typography color="textPrimary">{orgId}</Typography>
          </Breadcrumbs>
        </Box>
      </PageHeader>
      <Box component={Card} p={2}>
        {isLoading ? (
          <Skeleton count={5} />
        ) : (
          <Fragment>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              <Tab value="config" label="CONFIG" />
              <Tab value="activity" label="ACTIVITY" />
            </Tabs>
            <TabContext value={tab}>
              <TabPanel value="config">
                <OrganizationDetailConfig
                  orgDetail={orgDetail as OrganizationDescription}
                  orgId={orgId + ""}
                />
              </TabPanel>
              <TabPanel value="activity">
                <OrganizationDetailActivity orgId={orgId + ""} />
              </TabPanel>
            </TabContext>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default OrganizationDetail;
