import React, { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { upperCase } from "lodash";

import ColoredChip from "components/ColoredChip/ColoredChip";
import { JobStatus } from "types/repositories";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 98,
      fontWeight: 500,
    },
  }),
);

interface ZodiacStatusChipProps {
  status: JobStatus;
}

const ZodiacStatusChip: FC<ZodiacStatusChipProps> = ({ status }) => {
  const classes = useStyles();
  const statusColor =
    status === "FAILED"
      ? "error"
      : status === "RUNNING"
      ? "warning"
      : status === "QUEUED"
      ? "default"
      : status === "STARTED"
      ? "info"
      : status === "COMPLETED"
      ? "success"
      : "default";

  return (
    <ColoredChip
      className={classes.root}
      color={statusColor}
      label={upperCase(status)}
      size="small"
    />
  );
};

export default ZodiacStatusChip;
