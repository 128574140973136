import instance from "./axiosInstance";
import { PaginatedResponse } from "types";
import { Policy, PolicyActivity } from "types/policies";

const getPolicies = (orgId: string, q?: string) =>
  instance
    .get<PaginatedResponse<Policy>>(
      `/org/${orgId}/policy/policies${q ? `?q=${q}` : ""}`,
    )
    .then((res) => res.data.data);

const getPolicyActivity = (orgId: string, sid: string) =>
  instance
    .get<PaginatedResponse<PolicyActivity>>(
      `/org/${orgId}/policies/${sid}/activity`,
    )
    .then((res) => res.data.data);

const configPolicies = (
  { orgId, sid }: ConfigPoliciesUrlParams,
  payload: ConfigPoliciesPayload,
) =>
  instance.post<Policy>(`org/${orgId}/policy/policies/${sid}/config`, payload);

export default {
  getPolicyActivity,
  getPolicies,
  configPolicies,
};

interface ConfigPoliciesUrlParams {
  orgId: string;
  sid: string;
}

interface ConfigPoliciesPayload extends Partial<Policy> {
  comment?: string;
}
