import React, { FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import InitialsAvatar from "components/InitialsAvatar";

interface LogoBadgeProps {
  logo: string;
  className?: string;
  width?: number;
}

const LogoBadge: FC<LogoBadgeProps> = ({ logo, className, width = 50 }) => {
  logo = logo?.toLowerCase();
  const [imgSrc, setImgSrc] = useState("");
  const [isValidLogo, setIsValidLogo] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    import(`assets/svg/logos/${logo}.svg`)
      .then((img) => {
        setImgSrc(img.default);
        setIsValidLogo(true);
      })
      .catch(() => setIsValidLogo(false))
      .finally(() => setIsLoading(false));
  }, [logo]);

  return isLoading ? (
    <Skeleton className={className} circle height={width} width={width} />
  ) : isValidLogo ? (
    <img
      className={className}
      width={width}
      alt={`${logo}-logo`}
      src={imgSrc}
    />
  ) : (
    <InitialsAvatar width={width} text={logo} className={className} />
  );
};
export default LogoBadge;
