import React, { FC, Fragment } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Card,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import useCommonStyles from "theme/useCommonStyles";

import { servicesDetails } from "./servicesDetails";
import BootstrapBadge from "components/BootstrapBadge/BootstrapBadge";
import LogoBadge from "components/LogoBadge";
import PageHeader from "components/PageHeader";
import { isValidEnumValue } from "helpers/validators";

enum ServiceDetailTabs {
  Description = "description",
}

const ServiceDetail: FC = () => {
  const { service = "", tab = "description" } = useParams<{
    service: string;
    tab?: ServiceDetailTabs;
  }>();

  const classes = useCommonStyles();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const selectedService = servicesDetails[service];
  const isTabValid = isValidEnumValue(ServiceDetailTabs, tab);

  if (!selectedService) {
    return <Navigate to="/services" />;
  }

  if (!isTabValid) {
    return (
      <Navigate
        to={`/services/details/${service}/${ServiceDetailTabs.Description}`}
      />
    );
  }

  return (
    <Fragment>
      <PageHeader mb={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={1}
        >
          {selectedService.title}
          <Breadcrumbs separator="/" aria-label="breadcrumb">
            <Link color="inherit" to="/services">
              Services
            </Link>
            <Typography color="textPrimary">{selectedService.title}</Typography>
          </Breadcrumbs>
        </Box>
      </PageHeader>

      <Box component={Card} p={2}>
        <BootstrapBadge pill color="soft-info">
          <small>Beta</small>
        </BootstrapBadge>
        <Grid container>
          <Grid
            item
            justify={matchesSm ? "center" : "flex-end"}
            container
            sm={12}
            md={4}
          >
            <LogoBadge width={110} logo={selectedService.code} />
          </Grid>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            xs={false}
            md={1}
          ></Grid>
          <Grid item sm={12} md={6}>
            <Grid container>
              <Box component="h4" my={2}>
                {selectedService.title}
              </Box>
            </Grid>
            <Grid container>
              <span>{selectedService.shortDescription}</span>
            </Grid>
          </Grid>
        </Grid>
        <Tabs value={tab} indicatorColor="primary" textColor="primary">
          <Tab
            className={classes.tabLink}
            to={`/services/details/${service}/${ServiceDetailTabs.Description}`}
            component={Link}
            value={ServiceDetailTabs.Description}
            label="DESCRIPTION"
            replace
          />
        </Tabs>

        <TabContext value={tab}>
          <TabPanel value={ServiceDetailTabs.Description}>
            {selectedService.description}
          </TabPanel>
        </TabContext>
      </Box>
    </Fragment>
  );
};

export default ServiceDetail;
