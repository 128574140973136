import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import GetStarted from "./pages/GetStarted";
import LandingPageRepositories from "./pages/LandingPageRepositories/LandingPageRepositories";

const GetStartedRouter: FC = () => {
  return (
    <Routes>
      <Route path="complete" element={<LandingPageRepositories />} />

      <Route path="" element={<GetStarted />} />

      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default GetStartedRouter;
