import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { AccountCircleOutlined, CloudOutlined } from "@material-ui/icons";

import GitRepoInformation from "components/GitRepoInformation/GitRepoInformation";
import { Assessment } from "types/assessments";

interface AssessmentDescriptionProps {
  assessment: Assessment;
}

const AssessmentDescription: FC<AssessmentDescriptionProps> = ({
  assessment,
}) => {
  const isInfrascan = assessment.category === "infraScan";

  return isInfrascan ? (
    <div>
      {assessment.idNum && (
        <Box component="span" fontWeight="500">{`#${assessment.idNum}`}</Box>
      )}
      {assessment?.provider && (
        <span>
          {`  |  `}
          <Box component="span" mx={0.5}>
            <Box component={CloudOutlined} display="inline" width={14} />
          </Box>
          {`${assessment.provider.toUpperCase()}`}
        </span>
      )}
      {assessment?.account && (
        <span>
          {`  |  `}
          <Box component="span" mx={0.5}>
            <Box
              component={AccountCircleOutlined}
              display="inline"
              width={14}
            />
          </Box>
          {`${assessment.account}`}
        </span>
      )}
    </div>
  ) : (
    <div>
      {assessment.idNum && (
        <Box component="span" fontWeight="500">
          {`#${assessment.idNum} `}&nbsp;
        </Box>
      )}
      <GitRepoInformation {...assessment} />
    </div>
  );
};

export default AssessmentDescription;
