/**
 * Type safely function to the `in` operator.
 *
 * @param property The property that will be looked up with `in`.
 * @param object The object that will be checked.
 */
export function inOperator<K extends string, T>(
  property: K,
  object: T,
): object is T & Record<K, unknown> {
  return property in object;
}
