import React, { FC, Fragment, useCallback, useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { groupBy } from "lodash";
import moment from "moment";

import { useDocsRoute } from "providers";

import IacBotJob from "./IacBotJob";
import DefaultTablePagination from "components/DataTable/components/DefaultTablePagination";
import SearchBar from "components/DataTable/components/SearchBar";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { getLocaleDateString } from "helpers/formatter";
import ZodiacStatusChip from "pages/Zodiac/pages/Zodiac/components/ZodiacStatusChip";
import { IacBotJob as IacBotJobI } from "types/iacBot";

interface IacBotJobsProps {
  jobs: IacBotJobI[];
  isLoading: boolean;
  refetch: () => void;
  repoSpecific?: boolean;
}

const IacBotJobs: FC<IacBotJobsProps> = ({
  jobs,
  isLoading,
  refetch,
  repoSpecific,
}) => {
  const docsRoute = useDocsRoute();
  const columns = useMemo<Column<IacBotJobI>[]>(
    () => [
      {
        Header: "Name",
        accessor: "requestId",
      },
      {
        Header: "Job Num",
        accessor: "jobNum",
      },
      {
        Header: "Created",
        accessor: "createTs",
      },
      {
        Header: "GitRepo",
        accessor: "gitRepo",
      },
      {
        Header: "Commit By",
        accessor: "commitBy",
      },
      {
        Header: "Commit Message",
        accessor: "commitMsg",
      },
      {
        Header: "Committer",
        accessor: "committer",
      },
      {
        Header: "Trigger",
        accessor: "trigger",
      },
      {
        Header: "Git Branch",
        accessor: "gitBranch",
      },
      {
        Header: "GitCommit",
        accessor: "gitCommit",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      data: jobs,
      columns,
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    usePagination,
  );
  const {
    page: paginatedJobEvents,
    state,
    rows,
    gotoPage,
    setPageSize,
    setGlobalFilter,
  } = tableInstance;

  const tableRef = useRef<DataTableRef<IacBotJobI>>({
    tableInstance,
  });

  const handlePageChange = useCallback(
    (_, pageIndex: number) => gotoPage(pageIndex),
    [gotoPage],
  );

  const handleRowsPerPageChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setPageSize(Number(value));
      gotoPage(0);
    },
    [gotoPage, setPageSize],
  );

  const handleSearchChange = useCallback(
    (filterValue: string) => {
      setGlobalFilter(filterValue);
      gotoPage(0);
    },
    [setGlobalFilter, gotoPage],
  );

  const groupedPaginatedJobEvents = useMemo(() => {
    const zEvents = paginatedJobEvents.map((d) => d.original);
    const groupedResults = groupBy(zEvents, (result) => {
      const date = moment(result.createTs, moment.ISO_8601);
      const formattedDate = date.calendar(Date.now(), {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse: getLocaleDateString(),
      });
      return formattedDate;
    });
    return groupedResults;
  }, [paginatedJobEvents]);

  const filterFields = [
    {
      displayName: "Status",
      key: "status",
      type: "select",
      renderValue: (value: any) => <ZodiacStatusChip status={value} />,
      values: [
        { displayName: "QUEUED", value: "QUEUED" },
        { displayName: "STARTED", value: "STARTED" },
        { displayName: "RUNNING", value: "RUNNING" },
        { displayName: "COMPLETED", value: "COMPLETED" },
        { displayName: "FAILED", value: "FAILED" },
      ],
    },
    !repoSpecific && {
      displayName: "Repository",
      type: "textfield",
      key: "gitRepo",
    },
    {
      displayName: "Branch",
      type: "textfield",
      key: "gitBranch",
    },
    {
      displayName: "Commit",
      type: "textfield",
      key: "gitCommit",
    },
  ].filter((f) => !!f);

  return (
    <div>
      <Box display="flex" alignItems="center" mb={2}>
        <Box display="inline-flex" ml={-1}>
          <SearchBar
            onChange={handleSearchChange}
            initialValue=""
            disabled={isLoading}
          />
        </Box>
        <Tooltip title="Refresh data" arrow>
          <IconButton onClick={refetch} color="primary">
            <Refresh />
          </IconButton>
        </Tooltip>
        <FiltersModal
          tableRef={tableRef}
          fields={filterFields as any}
          disabled={isLoading}
          title="Filter Jobs"
          persistInUrl
        />
        <Box ml="auto">
          <DefaultTablePagination
            pageIndex={state.pageIndex}
            count={rows.length}
            pageSize={state.pageSize}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Box>
      </Box>
      {isLoading ? (
        [...Array(10)].map((_, ind) => (
          <Box display="flex" flexDirection="column" key={ind} lineHeight={2.5}>
            <Skeleton width={"60%"} />
            <Skeleton width={"40%"} />
          </Box>
        ))
      ) : paginatedJobEvents.length === 0 ? (
        <Box
          py={4}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <SvgIcon width={80} height={80} fill="lightgray" icon="robot" />
          <Box mb={3}>
            <Typography align="center">
              There are no jobs available that match the current query.
            </Typography>
          </Box>
        </Box>
      ) : jobs.length === 0 ? (
        <Box
          py={4}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <SvgIcon width={80} height={80} fill="lightgray" icon="robot" />
          <Box mb={3}>
            <Typography align="center">
              There are no job events available.
            </Typography>
          </Box>
          <a
            href={`${docsRoute}/getting-started-with-iac-security`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn how to enable security assessments for all your IaC code
            changes.
          </a>
        </Box>
      ) : (
        <Box px={2}>
          {Object.keys(groupedPaginatedJobEvents).map((day, i) => (
            <Fragment key={i}>
              <Box mb={2.5} mt={i === 0 ? 0 : 3}>
                <Typography variant="h4">{day}</Typography>
              </Box>
              {groupedPaginatedJobEvents[day].map((j, y) => (
                <Box key={y}>
                  <IacBotJob job={j} />
                </Box>
              ))}
              <Box borderBottom="1px solid lightgray" />
            </Fragment>
          ))}
        </Box>
      )}
      <DefaultTablePagination
        pageIndex={state.pageIndex}
        count={rows.length}
        pageSize={state.pageSize}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    </div>
  );
};

export default IacBotJobs;
