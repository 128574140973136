import React, { FC, useState } from "react";
import { Tooltip } from "@material-ui/core";

import { copy } from "helpers/copy";

interface CopyTextProps {
  text: string;
}

const CopyText: FC<CopyTextProps> = ({ text, children }) => {
  const [open, setOpen] = useState(false);
  const handleOnClick = () => {
    setOpen(true);
    copy(text);
    setTimeout(() => setOpen(false), 2000);
  };
  return (
    <Tooltip title="Copied!" arrow open={open}>
      <span onClick={handleOnClick}>{children}</span>
    </Tooltip>
  );
};

export default CopyText;
