import instance from "./axiosInstance";
import { APIResponse, PaginatedResponse } from "types";
import { Notification, NotificationDetail } from "types/notifications";

const getLatestNotifications = (orgId: string) =>
  instance
    .get<PaginatedResponse<Notification>>(`/org/${orgId}/latest-notifications`)
    .then((res) => res.data.data);

const getNotifications = (orgId: string) =>
  instance
    .get<PaginatedResponse<Notification>>(`/org/${orgId}/notifications/all`)
    .then((res) => res.data.data);

const getNotificationDetail = (orgId: string, notificationId: string) =>
  instance
    .get<APIResponse<NotificationDetail>>(
      `/org/${orgId}/notifications/${notificationId}`,
    )
    .then((res) => res.data.data);

const getNotificationSources = (orgId: string) =>
  instance
    .get<APIResponse<string[]>>(`/org/${orgId}/notifications/source`)
    .then((res) => res.data.data);

const getNotificationResources = (orgId: string) =>
  instance
    .get<APIResponse<string[]>>(`/org/${orgId}/notifications/resource`)
    .then((res) => res.data.data);

export default {
  getLatestNotifications,
  getNotificationDetail,
  getNotificationResources,
  getNotificationSources,
  getNotifications,
};
