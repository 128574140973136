import React, { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-flex",
      alignContent: "center",
      justifyContent: "center",
      width: "2em",
      height: "2em",
      padding: "0.5em 0",
      margin: "0.25rem",
      lineHeight: "1em",
      color: "#fff",
      borderRadius: "1em",
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

const StepNumber: FC = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.root}>{children}</span>;
};

export default StepNumber;
