import React, { FC } from "react";

import { useIsSupportOrg } from "providers";

import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import Dashboard from "./pages/Dashboard/Dashboard";

const DashboardRouter: FC<Childless> = () => {
  const isSupportOrg = useIsSupportOrg();
  return isSupportOrg ? <AdminDashboard /> : <Dashboard />;
};

export default DashboardRouter;
