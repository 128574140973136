import React, { FC, Fragment, useState } from "react";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Collapse, darken, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { ExpandLessRounded, ExpandMoreRounded } from "@material-ui/icons";

import BranchAssessmentRow from "./BranchAssessmentRow";
import { BranchAssessment } from "types/assessments";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        cursor: "pointer",
        background: darken(theme.palette.lightBackground.main, 0.025),
      },
      transition: "background-color 0.2s",
    },
  }),
);

interface RepositoryBranchAssessmentProps {
  data: { name: string; assessments: BranchAssessment[] };
}

const RepositoryBranchAssessment: FC<RepositoryBranchAssessmentProps> = ({
  data: branchAssessment,
}) => {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => setIsCollapsed((prev) => !prev);

  return (
    <Fragment>
      <Box
        className={classes.root}
        onClick={toggleCollapse}
        p={2}
        border="1px solid lightgray"
        bgcolor="lightBackground.main"
      >
        <Box display="flex" alignItems="center">
          <Box mr={1.5}>
            <FontAwesomeIcon size="lg" icon={faCodeBranch} />
          </Box>
          <Typography variant="h5">{branchAssessment.name}</Typography>
          <Box
            ml="auto"
            component={isCollapsed ? ExpandLessRounded : ExpandMoreRounded}
          />
        </Box>
      </Box>
      <Box border="1px solid lightgray" borderTop={0} mt="-1px">
        <Collapse in={isCollapsed}>
          <Box p={2}>
            {branchAssessment.assessments.map((assessment, i) => (
              <Box
                key={i}
                mb={i + 1 === branchAssessment.assessments.length ? 0 : 1}
              >
                <BranchAssessmentRow assessment={assessment as any} />
              </Box>
            ))}
          </Box>
        </Collapse>
      </Box>
    </Fragment>
  );
};

export default RepositoryBranchAssessment;
