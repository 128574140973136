import React, { FC } from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
);

const NoDataComponent: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Alert className={classes.root} icon={false} color="info">
      <Box role="alert">{children}</Box>
    </Alert>
  );
};

export default NoDataComponent;
