import React, {
  FC,
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, Typography } from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useCurrentOrgId, useHasScope } from "providers";

import UpdateUserRoleModal, {
  UpdateUserRoleForm,
} from "./components/UpdateUserRoleModal";
import UserDataTableOptionMenu, {
  UserDataTableActions,
} from "./components/UsersDataTableOptionMenu";
import ConfirmationModal, {
  ConfirmationModalRef,
} from "components/ConfirmationModal/ConfirmationModal";
import DataTable from "components/DataTable";
import InviteUserModal, {
  InviteUserModalRef,
} from "components/InviteUserModal";
import PageHeader from "components/PageHeader";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import StatusBadge from "components/StatusBadge";
import {
  errorToastHandler,
  FALLBACK_ERROR_MESSAGE,
  optimisticDatasetRemover,
  optimisticDatasetUpdater,
} from "helpers/queryHelpers";
import { useQueryState } from "hooks/useQueryState";
import userService from "services/userService";
import { User } from "types";
import { getLabel, Permissions, Resources } from "types/auth-roles";

const columnsDef = (
  actions: UserDataTableActions,
  hasUserWriteScope: boolean,
): Column<User>[] =>
  [
    {
      Header: "Display Name",
      accessor: "displayName",
    } as Column<User>,
    {
      Header: "Last Login",
      minWidth: 120,
      accessor: "lastLoginTs",
      Cell: ({
        row: {
          original: { lastLoginTs },
        },
      }) => (
        <RelativeTimeFormatter dateTs={lastLoginTs}></RelativeTimeFormatter>
      ),
    } as Column<User>,
    {
      Header: "Role",
      accessor: "role",
      Cell: ({
        row: {
          original: { role },
        },
      }) => getLabel(role),
    } as Column<User>,
    {
      Header: "Status",
      accessor: "status",
      Cell: ({
        row: {
          original: { status },
        },
      }) => <StatusBadge status={status}></StatusBadge>,
    } as Column<User>,
    hasUserWriteScope &&
      ({
        Header: "Actions",
        accessor: "userId",
        isButton: true,
        collapse: true,
        disableSortBy: true,
        Cell: ({ row: { original: row } }) => (
          <UserDataTableOptionMenu
            data={row}
            {...actions}
          ></UserDataTableOptionMenu>
        ),
      } as Column<User>),
  ].filter((column): column is Column<User> => !!column);

const defaultQuery: User[] = [];

const Users: FC = () => {
  const orgId = useCurrentOrgId();
  const {
    setQueryState,
    state: { query, page },
  } = useQueryState<{ query: string; page: number }>();
  const hasUserWriteScope = useHasScope(Resources.User, Permissions.Write);
  const [selectedUser, setSelectedUser] = useState<User>();
  const inviteUserModalRef = useRef<InviteUserModalRef>(null);
  const confirmationModal = useRef<ConfirmationModalRef>(null);
  const closeUpdateUserRoleModal = useCallback(
    () => setSelectedUser(void 0),
    [],
  );

  const { inviteUserEnabled } = useFlags();

  const {
    data: users = defaultQuery,
    isLoading,
    refetch,
  } = useQuery(["users", orgId], () => userService.getUsers(orgId), {
    onError: errorToastHandler(FALLBACK_ERROR_MESSAGE),
  });

  const fetchUsers = () => {
    refetch();
  };

  const { mutate: disableUser } = useMutation(
    ({ userId }: Pick<User, "userId">) =>
      userService.disableUser(orgId, userId),
    {
      onMutate: ({ userId }) =>
        optimisticDatasetUpdater<User, "userId">(
          ["users", orgId],
          { userId },
          { status: "disabled" },
        ),
      onError: errorToastHandler("There was an error disabling the user"),
      onSettled: fetchUsers,
    },
  );

  const { mutate: activateUser } = useMutation(
    ({ userId }: Pick<User, "userId">) =>
      userService.activateUser(orgId, userId),
    {
      onMutate: ({ userId }) =>
        optimisticDatasetUpdater<User, "userId">(
          ["users", orgId],
          { userId },
          { status: "active" },
        ),
      onError: errorToastHandler("There was an error activating the user"),
      onSettled: fetchUsers,
    },
  );

  const { mutate: removeUser } = useMutation(
    ({ userId }: Pick<User, "userId">) => userService.removeUser(orgId, userId),
    {
      onMutate: ({ userId }) =>
        optimisticDatasetRemover<User, "userId">(["users", orgId], { userId }),
      onError: errorToastHandler("There was an error removing the user."),
      onSettled: fetchUsers,
    },
  );

  const { mutate: updateUserRole } = useMutation(
    ({ userId, role }: Pick<User, "userId" | "role" | "displayName">) =>
      userService.updateUserRole(orgId, userId, { role }),
    {
      onMutate: ({ userId, role }) =>
        optimisticDatasetUpdater<User, "userId">(
          ["users", orgId],
          { userId },
          { role },
        ),
      onError: errorToastHandler(
        "There was an error updating the user role. Please try again.",
      ),
      onSuccess(res, { displayName, role }) {
        toast.success(
          `The role of the user ${displayName} has been updated to ${getLabel(
            role,
          ).toLocaleUpperCase()}`,
        );
      },
      onSettled: fetchUsers,
    },
  );

  const onDisableClick = useCallback(
    ({ email, displayName, userId }: User) => {
      confirmationModal.current?.openModal({
        description: `Are you sure you want to disable ${
          displayName ?? email
        }'s account?`,
        title: "Disable user",
        confirm: "Disable",
        cancel: "Cancel",
        action() {
          disableUser({ userId });
          confirmationModal.current?.closeModal();
        },
      });
    },
    [disableUser],
  );

  const onActivateClick = useCallback(
    ({ userId }: User) => {
      activateUser({ userId });
    },
    [activateUser],
  );

  const onRemoveClick = useCallback(
    ({ email, displayName, userId }: User) => {
      confirmationModal.current?.openModal({
        description: `Are you sure you want to remove ${
          displayName ?? email
        }'s account? This action cannot be undone.`,
        title: "Remove user",
        confirm: "Remove",
        cancel: "Cancel",
        color: "error",
        action: () => {
          removeUser({ userId });
          confirmationModal.current?.closeModal();
        },
      });
    },
    [removeUser],
  );

  const onEditRoleClick = useCallback((user: User) => {
    setSelectedUser(user);
  }, []);

  const columns = useMemo(
    () =>
      columnsDef(
        {
          onDisableClick,
          onActivateClick,
          onRemoveClick,
          onEditRoleClick,
        },
        hasUserWriteScope,
      ),
    [
      onDisableClick,
      onActivateClick,
      onRemoveClick,
      onEditRoleClick,
      hasUserWriteScope,
    ],
  );

  const openInviteModal = useCallback(
    () => inviteUserModalRef.current?.open(),
    [],
  );

  const updateUserRoleHandler = useCallback(
    ({ role }: UpdateUserRoleForm) => {
      if (!selectedUser) {
        return;
      }
      const { userId, displayName } = selectedUser;

      updateUserRole({ displayName, role, userId });
      closeUpdateUserRoleModal();
    },
    [selectedUser, updateUserRole, closeUpdateUserRoleModal],
  );

  return (
    <Fragment>
      <PageHeader>Users</PageHeader>
      <Card>
        <Box p={2}>
          <DataTable
            columns={columns}
            query={query}
            initialPageIndex={page}
            onSearchChange={(q) => setQueryState({ query: q })}
            onChangePage={(p) => setQueryState({ page: p })}
            sortByDesc
            defaultSortField="lastLoginTs"
            data={users}
            isLoading={isLoading}
            noDataComponent={
              <Box>
                <Box pt={8} textAlign="center">
                  <Typography
                    style={{ opacity: 0.4 }}
                    variant="h1"
                    gutterBottom
                  >
                    <FontAwesomeIcon icon={faUsers} />
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    There are no records available.
                  </Typography>
                </Box>
              </Box>
            }
            pagination
            search
            searchActions={
              inviteUserEnabled && (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={openInviteModal}
                  >
                    Invite User
                  </Button>
                </Box>
              )
            }
            compact
          />
        </Box>
      </Card>
      {selectedUser && (
        <UpdateUserRoleModal
          onModalClose={closeUpdateUserRoleModal}
          onSubmit={updateUserRoleHandler}
          user={selectedUser}
        />
      )}
      <InviteUserModal ref={inviteUserModalRef} />
      <ConfirmationModal ref={confirmationModal} />
    </Fragment>
  );
};

export default Users;
