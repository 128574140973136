import React, { FC, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { useHasScope } from "providers";

import { User } from "types";
import { AuthRoles, getLabel, Permissions, Resources } from "types/auth-roles";

export interface UpdateUserRoleForm {
  role: AuthRoles;
}

interface UpdateUserRoleModalProps {
  user: User;
  onModalClose(): void;
  onSubmit(model: UpdateUserRoleForm): void;
}

const UpdateUserRoleModal: FC<UpdateUserRoleModalProps> = ({
  onModalClose,
  onSubmit,
  user,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<UpdateUserRoleForm>();
  const [isOpen, setIsOpen] = useState(true);

  const close = () => setIsOpen(false);

  const hasOrgOwnerScope = useHasScope(
    Resources.Organization,
    Permissions.Owner,
  );

  const roles = useMemo(
    () =>
      hasOrgOwnerScope
        ? AuthRoles
        : { Write: AuthRoles.Write, Read: AuthRoles.Read },
    [hasOrgOwnerScope],
  );

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onExited={onModalClose}>
      <DialogTitle>Update user role</DialogTitle>
      <DialogContent>
        <Grid>
          <Grid item xs={12}>
            <p>
              {user.displayName}'s account currently has{" "}
              <strong>{getLabel(user.role).toLocaleUpperCase()}</strong> role.
            </p>
          </Grid>
        </Grid>
        <form
          id="updateRole"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputLabel id="role">Role</InputLabel>
          <Controller
            control={control}
            render={(f) => (
              <Select variant="outlined" color="primary" fullWidth {...f}>
                {Object.entries(roles).map(([label, value], index) => (
                  <MenuItem value={value} key={index}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
            id="role"
            name="role"
            data-testid="role"
            defaultValue={user.role}
          ></Controller>
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="text" onClick={close}>
          Cancel
        </Button>
        <Button
          type="submit"
          form="updateRole"
          color="primary"
          variant="contained"
          disabled={!isDirty}
        >
          Update Role
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUserRoleModal;
