import React, { FC, useCallback, useMemo, useRef } from "react";

import { QueryStateContext } from "./QueryStateContext";

const QueryStateProvider: FC = ({ children }) => {
  const mapRef = useRef(new Map<string, Record<string, any>>());

  const getValue = useCallback((key: string) => mapRef.current.get(key), []);
  const setValue = useCallback((key: string, value: Record<string, any>) => {
    mapRef.current.set(key, value);
  }, []);

  const state = useMemo(
    () => ({
      getValue,
      setValue,
    }),
    [getValue, setValue],
  );

  return (
    <QueryStateContext.Provider value={state}>
      {children}
    </QueryStateContext.Provider>
  );
};

export default QueryStateProvider;
