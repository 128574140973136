import React, {
  createRef,
  FC,
  Fragment,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Column, Row } from "react-table";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";

import { useHasScope } from "providers";

import { AssessmentDetailsContext } from "../context/AssessmentDetailsContext";
import DeleteSuppressionModal from "./DeleteSuppressionModal";
import SuppressedFindingRow from "./SuppressedFindingsRow";
import DataTable from "components/DataTable";
import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import { DataTableRef } from "components/DataTable/DataTable";
import { ArrayQueryKey, optimisticObjectUpdater } from "helpers/queryHelpers";
import { AssessmentDetail, Finding } from "types/assessments";
import { Permissions, Resources } from "types/auth-roles";

interface AssessmentDetailsTableActions {
  onDeleteSuppression(finding: Finding): void;
}

const columnsDef = (
  showFindingAction: boolean,
  showResource: boolean,
  hasOrgWriteScope: boolean,
  { onDeleteSuppression }: AssessmentDetailsTableActions,
): Column<Finding>[] => [
  {
    Header: "Severity",
    accessor: "severity",
    filter: "includesSome",
  } as Column<Finding>,
  {
    Header: "Compliance",
    accessor: "compliance",
    filter: "includesSome",
  } as Column<Finding>,
  {
    Header: "SuppressionLevel",
    accessor: "suppressionLevel",
  },
  {
    Header: "SuppressedTs",
    accessor: "suppressedTs",
  },
  {
    Header: "Autofix",
    accessor: "autofix",
    filter: (rows, columnIds, filterValue) =>
      filterValue === ""
        ? rows
        : filterValue
        ? rows.filter((r) => r.original.autofix)
        : rows.filter((r) => !r.original.autofix),
  },
  {
    Header: "Resource",
    accessor: "resource",
  },
  {
    Header: "SID",
    accessor: "sid",
  },
  {
    Header: "File",
    accessor: "fileName",
  },
  {
    Header: "File Path",
    accessor: "filePath",
  },
  {
    Header: "Pass",
    accessor: "pass",
    filter: (rows, columnIds, filterValue) =>
      filterValue === ""
        ? rows
        : filterValue
        ? rows.filter((r) => r.original.pass)
        : rows.filter((r) => !r.original.pass),
  },
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ row: { original: row } }) => (
      <SuppressedFindingRow finding={row} showResource={showResource} />
    ),
  } as Column<Finding>,
  {
    Header: "Actions",
    accessor: "id",
    isButton: true,
    collapse: true,
    disableSortBy: true,
    Cell: ({ row: { original: row } }) => {
      const shouldShowPolicy = row.category
        ? row.category === "iac" ||
          row.category === "infraScan" ||
          row.category === "secrets"
        : showFindingAction;

      const isPolicyLevel = row.suppressionLevel === "policy";
      const isFileLevel = row.suppressionLevel === "file";
      const dropdownActions: DropdownItem[] = [];
      const showPolicy = {
        label: "Show Policy",
        linkTo: `/policies?selected=${row.sid}`,
      };
      const togglePolicy = {
        label: "Delete Suppression",
        hover: !hasOrgWriteScope
          ? "Insufficient permissions."
          : isPolicyLevel
          ? "This is a policy level suppression. Deleting this suppression finding will enable the policy that caused it."
          : isFileLevel
          ? "File level suppression is controlled from config file in the repository."
          : "Publishes this particular finding to this assessment. This will add the finding back to the assessment metrics.",
        action: () => onDeleteSuppression(row),
        disabled: !hasOrgWriteScope || isFileLevel,
      };

      // tslint:disable-next-line: no-unused-expression
      shouldShowPolicy && dropdownActions.push(showPolicy);

      dropdownActions.push(togglePolicy);
      return (
        <Box
          height="100%"
          minHeight={65}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <DropdownActions items={dropdownActions} />
          {row.pass ? (
            <Box color="success.main">
              <Typography variant="h5" color="inherit">
                <FontAwesomeIcon icon={faCheckCircle} />
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="h5" color="error">
                <FontAwesomeIcon icon={faTimesCircle} />
              </Typography>
            </Box>
          )}
        </Box>
      );
    },
  },
];

interface SuppressedFindingsTableProps {
  gitRepo?: string;
  onFindingSelect(finding: Row<Finding>): void;
  tableRef?: RefObject<DataTableRef<Finding>>;
  queryKey: ArrayQueryKey;
  initialPageIndex?: number;
}

const SuppressedFindingsTable: FC<SuppressedFindingsTableProps> = ({
  gitRepo,
  onFindingSelect,
  tableRef,
  queryKey,
  initialPageIndex,
}) => {
  const { assessmentDetail, refetch, isLoading } = useContext(
    AssessmentDetailsContext,
  );
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );
  const [suppressionToDelete, setSuppressionToDelete] = useState<Finding>();
  const clearFinding = () => setSuppressionToDelete(undefined);
  const deleteSuppressionRef = createRef<HTMLButtonElement>();

  const openDeleteSuppressionModal = useCallback(
    (finding: Finding) => {
      setSuppressionToDelete(finding);
      deleteSuppressionRef.current?.click();
    },
    [deleteSuppressionRef],
  );

  const columns = useMemo(
    () =>
      columnsDef(
        assessmentDetail?.category === "iac" ||
          assessmentDetail?.category === "secrets" ||
          assessmentDetail?.category === "infraScan",
        !!assessmentDetail?.showResource,
        hasOrgWriteScope,
        {
          onDeleteSuppression: openDeleteSuppressionModal,
        },
      ),
    [
      assessmentDetail?.category,
      assessmentDetail?.showResource,
      hasOrgWriteScope,

      openDeleteSuppressionModal,
    ],
  );

  const data = assessmentDetail?.suppressedFindings ?? [];

  const getDeleteSuppressionCondition = useCallback(
    (f: Finding) => {
      switch (suppressionToDelete?.suppressionLevel) {
        case "policy":
          return (
            f.sid === suppressionToDelete?.sid &&
            f.suppressionLevel === "policy"
          );
        case "resource":
          return (
            f.resource === suppressionToDelete?.resource &&
            f.suppressionLevel === "resource"
          );
        default:
          return f.id === suppressionToDelete?.id;
      }
    },
    [
      suppressionToDelete?.id,
      suppressionToDelete?.resource,
      suppressionToDelete?.sid,
      suppressionToDelete?.suppressionLevel,
    ],
  );

  const optimisticDeleteSuppression = useCallback(() => {
    const movedFindings = assessmentDetail?.suppressedFindings
      .filter((f) => getDeleteSuppressionCondition(f))
      .map((f) => ({ ...f, suppressedTs: undefined }));
    return optimisticObjectUpdater<AssessmentDetail>(queryKey, {
      suppressedFindings: assessmentDetail?.suppressedFindings.filter(
        (f) => !getDeleteSuppressionCondition(f),
      ),
      findings: [
        ...(assessmentDetail?.findings ?? []),
        ...(movedFindings ?? []),
      ],
    });
  }, [
    assessmentDetail?.suppressedFindings,
    assessmentDetail?.findings,
    queryKey,
    getDeleteSuppressionCondition,
  ]);

  return (
    <Fragment>
      <DataTable
        ref={tableRef}
        hiddenColumns={[
          "severity",
          "pass",
          "suppressedTs",
          "resource",
          "suppressionLevel",
          "compliance",
          "sid",
          "fileName",
          "filePath",
          "autofix",
        ]}
        columns={columns}
        autoResetFilters={false}
        onRowClick={onFindingSelect}
        rowHover
        searchActions={
          data.length ? (
            <Box
              position="absolute"
              width="99%"
              borderBottom="1px solid Gainsboro"
            >
              &nbsp;
            </Box>
          ) : null
        }
        noDataComponent="No suppressed findings."
        isLoading={isLoading}
        disableSelectActions
        data={data}
        hideTableHead
        initialRowsPerPage={5}
        initialPageIndex={initialPageIndex}
      />
      <DeleteSuppressionModal
        onMutate={optimisticDeleteSuppression}
        gitRepo={gitRepo}
        assessmentDetail={assessmentDetail}
        refetch={refetch}
        finding={suppressionToDelete}
        onClose={clearFinding}
        ref={deleteSuppressionRef}
        hideButton
      />
    </Fragment>
  );
};

export default SuppressedFindingsTable;
