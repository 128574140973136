import React, { FC } from "react";
import { Link } from "react-router-dom";

interface UrnHyperlinkProps {
  urn: string;
  label?: string;
}

const UrnHyperlink: FC<UrnHyperlinkProps> = ({ urn, label }) => {
  return (
    <Link data-testid="urnHyperlink" to={`/kube/resources/${urn}`}>
      {label ?? "Show YAML"}
    </Link>
  );
};

export default UrnHyperlink;
