import React, { FC, Fragment, useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, usePagination, useTable } from "react-table";
import {
  Box,
  Chip,
  Collapse,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ExpandLessRounded, ExpandMoreRounded } from "@material-ui/icons";
import classNames from "classnames";

import { useCurrentOrgId, useHasScope } from "providers";

import ViolationResource from "./ViolationResource";
import Badge from "components/BootstrapBadge/BootstrapBadge";
import DefaultTablePagination from "components/DataTable/components/DefaultTablePagination";
import DropdownActions, {
  DropdownItem,
} from "components/DataTable/components/DropdownActions";
import tableStyles from "components/DataTable/tableStyles";
import SeverityComponent, {
  Severity,
} from "components/SeverityComponent/SeverityComponent";
import { optimisticDatasetUpdater } from "helpers/queryHelpers";
import DeleteSuppressionModal from "pages/Assessments/pages/AssessessmentDetails/components/DeleteSuppressionModal";
import SuppressionModal from "pages/Assessments/pages/AssessessmentDetails/components/SuppressionModal";
import { Permissions, Resources } from "types/auth-roles";
import { Violation } from "types/violations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    collapse: {
      overflow: "scroll",
    },
    textCenter: {
      textAlign: "center",
    },
    tableRoot: {
      overflow: "scroll",
    },
    resourceCell: {
      [theme.breakpoints.down("xs")]: { padding: theme.spacing(1) },
    },
    noBorderBottom: {
      borderBottom: 0,
    },
  }),
);

const columns: Column<Violation>[] = [
  {
    Header: "Name",
    accessor: "gitRepoName",
  },
];

interface ViolationRowProps {
  count: number;
  violations: Violation[];
}

const ViolationRow: FC<ViolationRowProps> = ({ count, violations }) => {
  const [processingFinding, setProcessingFinding] = useState<Violation>();
  const clearFinding = () => setProcessingFinding(undefined);
  const suppressRef = useRef<HTMLButtonElement>(null);
  const orgId = useCurrentOrgId();
  const deleteSuppressionRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const tableClasses = tableStyles(theme);
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );

  const { page, state, gotoPage, setPageSize } = useTable(
    {
      data: violations,
      columns,
      initialState: {
        pageSize: 25,
      },
    },
    usePagination,
  );

  const goToFinding = (violation: Violation) =>
    navigate(
      `/assessments/details/${violation.assessmentId}/${violation.id}/summary`,
    );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    gotoPage(newPage);
  };

  const optimisticSuppress = useCallback(
    (suppressionLevel: string) => {
      if (suppressionLevel.toLowerCase() === "policy")
        return optimisticDatasetUpdater<Violation, "sid">(
          ["violations", orgId],
          { sid: processingFinding?.sid ?? "" },
          { status: "Suppressed" },
        );

      return optimisticDatasetUpdater<Violation, "id">(
        ["violations", orgId],
        { id: processingFinding?.id ?? "" },
        { status: "Suppressed", suppressionLevel },
      );
    },
    [orgId, processingFinding?.id, processingFinding?.sid],
  );

  const optimisticDeleteSuppression = useCallback(() => {
    if (processingFinding?.suppressionLevel.toLowerCase() === "policy")
      return optimisticDatasetUpdater<Violation, "sid">(
        ["violations", orgId],
        { sid: processingFinding?.sid ?? "" },
        { status: "Open" },
      );

    return optimisticDatasetUpdater<Violation, "id">(
      ["violations", orgId],
      { id: processingFinding?.id ?? "" },
      { status: "Open" },
    );
  }, [
    orgId,
    processingFinding?.id,
    processingFinding?.sid,
    processingFinding?.suppressionLevel,
  ]);

  const toggleCollapse = () => setCollapsed((prev) => !prev);

  const dropdownActions = (violation: Violation): DropdownItem[] => {
    const isPolicyLevel = violation.suppressionLevel === "policy";
    const isFileLevel = violation.suppressionLevel === "file";

    const suppress = {
      label: "Suppress",
      action: async () => {
        await setProcessingFinding(violation);
        suppressRef.current?.click();
      },
      disabled:
        !hasOrgWriteScope || !(processingFinding?.allowSuppression ?? true),
      hover: !hasOrgWriteScope
        ? "Insufficient permissions"
        : !(processingFinding?.allowSuppression ?? true)
        ? "This finding only supports file-based suppresssion."
        : "",
    };
    const deleteSuppression = {
      label: "Delete Suppression",
      hover: !hasOrgWriteScope
        ? "Insufficient permissions"
        : isPolicyLevel
        ? "This is a policy level suppression. Deleting this suppression finding will enable the policy that caused it."
        : isFileLevel
        ? "File level suppression is controlled from config file in the repository."
        : "Publishes this particular finding to this assessment. This will add the finding back to the assessment metrics.",
      action: async () => {
        await setProcessingFinding(violation);
        deleteSuppressionRef.current?.click();
      },
      disabled: !hasOrgWriteScope || isFileLevel,
    };

    const actions: DropdownItem[] = [
      violation.status === "Open" ? suppress : deleteSuppression,
      {
        label: "Show Finding",
        linkTo: `/assessments/details/${violation.assessmentId}/${violation.id}`,
      },
      {
        label: "Show Guidelines",
        linkTo: `/assessments/details/${violation.assessmentId}/${violation.id}/guidelines`,
      },
      {
        label: "Show Policy",
        linkTo: `/policies?selected=${violation.sid}`,
      },
    ];
    return actions;
  };

  return (
    <Fragment>
      <Box
        className={classes.root}
        display="flex"
        borderTop="1px solid #E0E0E0"
        alignItems="center"
        p={matches ? 1 : 2}
        onClick={toggleCollapse}
      >
        <Box
          component={collapsed ? ExpandLessRounded : ExpandMoreRounded}
          mr={matches ? 1 : 3}
        />
        <Box
          bgcolor="error.light"
          color="white"
          borderRadius={3}
          py={0.25}
          px={0.8}
          fontSize={13}
          mr={2}
          textAlign="center"
          width="4.5ch"
        >
          {count}
        </Box>
        <Box mr={2} width={64}>
          <SeverityComponent severity={Severity[violations[0].severity]} />
        </Box>
        <Box>
          <Box display="inline" mr={2}>
            {violations[0].title}
          </Box>
        </Box>
        <Box ml="auto">
          {violations[0].compliance?.map((c, i) => (
            <Box key={i} display="inline-block" mr={0.5}>
              <Badge key={c} color="info">
                {c}
              </Badge>
            </Box>
          ))}
        </Box>
      </Box>
      <Collapse
        className={classes.collapse}
        mountOnEnter
        in={collapsed}
        unmountOnExit
      >
        <Table className={classes.tableRoot}>
          <TableHead>
            <TableRow>
              <TableCell>Resource</TableCell>
              <TableCell className={classes.textCenter}>Status</TableCell>
              <TableCell className={classes.textCenter}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {page.map((v, i) => {
              const cellClass = classNames(
                i + 1 === violations.length
                  ? classes.noBorderBottom
                  : undefined,
                classes.resourceCell,
              );

              return (
                <Box
                  style={{ overflow: "scroll" }}
                  className={tableClasses.rowHover}
                  component={TableRow}
                  key={i}
                  onClick={() => goToFinding(v.original)}
                >
                  <TableCell style={{ minWidth: 400 }} className={cellClass}>
                    <ViolationResource violation={v.original} />
                  </TableCell>
                  <TableCell
                    className={classNames(classes.textCenter, cellClass)}
                  >
                    <Chip
                      size="small"
                      style={{ width: 90 }}
                      color={
                        v.original.status === "Open" ? "default" : "secondary"
                      }
                      label={v.original.status}
                    />
                  </TableCell>
                  <TableCell
                    className={classNames(classes.textCenter, cellClass)}
                  >
                    <DropdownActions items={dropdownActions(v.original)} />
                  </TableCell>
                </Box>
              );
            })}
          </TableBody>
        </Table>
        {violations.length > 25 && (
          <DefaultTablePagination
            count={violations.length}
            onChangePage={handlePageChange}
            pageIndex={state.pageIndex}
            pageSize={state.pageSize}
            onChangeRowsPerPage={(e) => setPageSize(Number(e.target.value))}
            pageSizeOptions={[25, 50, 75, 100]}
          />
        )}
        <Box display="none">
          <SuppressionModal
            hideButton
            gitRepo={processingFinding?.gitRepo}
            ref={suppressRef}
            finding={processingFinding}
            onClose={clearFinding}
            onMutate={optimisticSuppress}
          />
          <DeleteSuppressionModal
            hideButton
            gitRepo={processingFinding?.gitRepo}
            ref={deleteSuppressionRef}
            finding={processingFinding}
            onClose={clearFinding}
            onMutate={optimisticDeleteSuppression}
          />
        </Box>
      </Collapse>
    </Fragment>
  );
};

export default ViolationRow;
