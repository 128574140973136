import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import { Row } from "react-table";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import { AssessmentDetailsContext } from "../context/AssessmentDetailsContext";
import FindingDetailActions from "./FindingDetailActions";
import FindingDetailActivity from "./FindingDetailActivity";
import FindingDetailControls from "./FindingDetailControls";
import BootstrapBadge from "components/BootstrapBadge/BootstrapBadge";
import { DataTableRef } from "components/DataTable/DataTable";
import MountedTabPanel from "components/MountedTabPanel/MountedTabPanel";
import ReactMarkdown from "components/ReactMarkdown/ReactMarkdown";
import SeverityComponent, {
  Severity,
} from "components/SeverityComponent/SeverityComponent";
import { ArrayQueryKey } from "helpers/queryHelpers";
import assessmentService from "services/assessmentService";
import { Finding } from "types/assessments";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      height: "100%",
      overflowY: "auto",
    },
    card: { height: "100%" },
    tab: {
      "&:hover": {
        textDecoration: "none",
      },
    },
  }),
);

enum ATabs {
  Summary = "summary",
  Guidelines = "guidelines",
  Activity = "activity",
  Default = "",
}

interface FindingDetailProps {
  finding?: Finding;
  tableRefs: {
    findingsTableRef: React.RefObject<DataTableRef<Finding>>;
    suppressedTableRef: React.RefObject<DataTableRef<Finding>>;
  };
  changeFinding(row: Row<Finding>): void;
  queryKey: ArrayQueryKey;
}

const FindingDetail: FC<FindingDetailProps> = ({
  finding,
  tableRefs,
  changeFinding,
  queryKey,
}) => {
  const { search } = useLocation();
  const { assessmentDetail, refetch } = useContext(AssessmentDetailsContext);
  const { tab = ATabs.Default } = useParams<{
    tab: string;
  }>();
  const location = useLocation();
  const classes = useStyles();
  const orgId = useCurrentOrgId();
  const url = location.pathname.slice(0, location.pathname.lastIndexOf("/"));

  const goPrev = useRef<HTMLButtonElement>(null);
  const goNext = useRef<HTMLButtonElement>(null);

  const isIaC = finding?.category
    ? finding.category === "iac"
    : assessmentDetail?.category === "iac";

  const {
    data: guidelines,
    isLoading: isGuidelinesLoading,
    isError,
  } = useQuery(
    [
      `finding-detail-guidelines-${finding?.sid}`,
      orgId,
      finding?.sid,
      assessmentDetail?.target,
    ],
    () =>
      assessmentService.getAssessmentFindingGuidelines(
        orgId,
        finding?.sid ?? "",
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      enabled: isIaC && !!finding?.sid,
    },
  );

  const mGuidelines = useMemo(() => {
    const guidelinesBlocks = (guidelines + "").split("```") ?? [];
    const codeContainingIndices: number[] = [];
    for (let index = 0; index < guidelinesBlocks.length; index++) {
      if (index % 2 !== 0) codeContainingIndices.push(index);
    }

    codeContainingIndices.forEach((i) => {
      const codeBlockLines = guidelinesBlocks[i].split("\n");
      const changedLines: { index: number; add: boolean }[] = [];
      for (let j = 0; j < codeBlockLines.length; j++) {
        const codeBlockLine = codeBlockLines[j];
        const toAdd =
          codeBlockLine[0] === "+"
            ? true
            : // PREVENTS HIGLIGHTS OF DOUBLE DASH --
            codeBlockLine[0] === "-" && codeBlockLine[1] !== "-"
            ? false
            : null;
        if (toAdd !== null) {
          changedLines.push({ index: j, add: toAdd });
        }
      }
      const changedLinesIndices = changedLines.map((f) => f.index);
      changedLines.forEach((line) => {
        const hasPreviousElement = changedLinesIndices.includes(line.index - 1);
        const hasFollowingElement = changedLinesIndices.includes(
          line.index + 1,
        );
        return (codeBlockLines[line.index] = `${
          hasPreviousElement ? "" : "\n ```\n"
        }<pre class="${line.add ? "add" : "remove"}-line ${
          hasPreviousElement ? "" : "first"
        } ${!hasFollowingElement ? "last" : ""}"><code>${
          codeBlockLines[line.index]
        }</code></pre>\n ${hasFollowingElement ? "" : "``` \n"}`);
      });
      guidelinesBlocks[i] = codeBlockLines.join("\n");
    });

    return guidelinesBlocks.join("```");
  }, [guidelines]);

  const hasGuidelines = !!guidelines && isIaC;

  const onSuppress = () => {
    if (goPrev.current?.disabled) {
      if (goNext.current?.disabled) {
        return;
      } else {
        goNext.current?.click();
        return;
      }
    }
    goPrev.current?.click();
  };

  const onDeleteSuppression = () => {
    if (goPrev.current?.disabled) {
      if (goNext.current?.disabled) {
        return;
      } else {
        goNext.current?.click();
        return;
      }
    }
    goPrev.current?.click();
  };

  const isTabValid = useMemo(
    () =>
      tab === ATabs.Summary ||
      tab === ATabs.Activity ||
      isGuidelinesLoading ||
      (hasGuidelines && tab === ATabs.Guidelines),
    [tab, isGuidelinesLoading, hasGuidelines],
  );

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    switch (event.key) {
      case "ArrowRight":
        if (!goNext.current?.disabled) goNext.current?.click();
        break;
      case "ArrowLeft":
        if (!goPrev.current?.disabled) goPrev.current?.click();
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  if (!isTabValid) {
    return <Navigate to={`summary`} />;
  }

  return (
    <Card className={classes.card}>
      <Box pt={2} px={3} bgcolor="lightBackground.main">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography variant="h5" display="block" gutterBottom>
              {finding?.title}
            </Typography>
            <Box display="flex">
              {finding?.severity && (
                <Fragment>
                  <Box display="inline" fontWeight="500" mr={1}>
                    Severity
                  </Box>
                  <SeverityComponent severity={Severity[finding.severity]} />
                </Fragment>
              )}
              {finding?.compliance &&
                finding.compliance.length > 0 &&
                !finding.compliance.includes("No Compliance") && (
                  <Fragment>
                    <Box display="inline" fontWeight="500" ml={2} mr={1}>
                      Compliance
                    </Box>
                    {finding.compliance.map((c, i) => (
                      <Box key={`${c}-${i}`} display="inline-block" mr={1}>
                        <BootstrapBadge color="info">{c}</BootstrapBadge>
                      </Box>
                    ))}
                  </Fragment>
                )}
              <Box display="inline" fontWeight="500" ml={2} mr={1}>
                SID
              </Box>
              <div>{finding?.sid}</div>
            </Box>
          </Box>
          <FindingDetailActions
            queryKey={queryKey}
            refetch={refetch}
            assessmentDetail={assessmentDetail}
            finding={finding}
            onDeleteSuppression={onDeleteSuppression}
            onSuppress={onSuppress}
          />
        </Box>
        <Box component="hr" mb={1} />
        <Box
          borderRadius="inherit"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Tabs value={tab} indicatorColor="primary" textColor="primary">
            <Tab
              className={classes.tab}
              component={Link}
              to={`${url}/summary${search}`}
              value="summary"
              label="SUMMARY"
              replace
            />
            <Tab
              className={classes.tab}
              component={Link}
              to={`${url}/activity${search}`}
              value="activity"
              label="ACTIVITY"
              replace
            />
            {hasGuidelines && (
              <Tab
                className={classes.tab}
                component={Link}
                to={`${url}/guidelines${search}`}
                value="guidelines"
                label="GUIDELINES"
                replace
              />
            )}
          </Tabs>
          <FindingDetailControls
            tableRefs={tableRefs}
            changeFinding={changeFinding}
            suppressed={!!finding?.suppressedTs}
            actionsRef={{ goPrev, goNext }}
          />
        </Box>
      </Box>
      <Box p={2} className={classes.body}>
        <MountedTabPanel value={tab} index={"summary"}>
          <Box py={1} height="100%" overflow="auto">
            <ReactMarkdown linkTarget="_blank">
              {finding?.markdown}
            </ReactMarkdown>
          </Box>
        </MountedTabPanel>
        {hasGuidelines && (
          <MountedTabPanel value={tab} index={"guidelines"}>
            <Box py={1} height="100%" overflow="auto">
              {isGuidelinesLoading ? (
                <Box lineHeight={2}>
                  <Skeleton count={20} />
                </Box>
              ) : isError ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography variant="h1" color="textSecondary" gutterBottom>
                    <FontAwesomeIcon icon={faCogs} />
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    There are no guidelines available for this finding.
                  </Typography>
                </Box>
              ) : (
                <ReactMarkdown
                  unescapeHtml
                  sanitize={false}
                  linkTarget="_blank"
                >
                  {mGuidelines}
                </ReactMarkdown>
              )}
            </Box>
          </MountedTabPanel>
        )}
        <MountedTabPanel value={tab} index={"activity"}>
          <Box py={1} height="100%" overflow="auto">
            <FindingDetailActivity
              findingId={finding?.id ?? ""}
              assessmentId={assessmentDetail?.id ?? finding?.assessmentId ?? ""}
              isActive={tab === ATabs.Activity}
            />
          </Box>
        </MountedTabPanel>
      </Box>
    </Card>
  );
};

export default FindingDetail;
