import React, { FC, Fragment, useRef } from "react";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { Box, Card } from "@material-ui/core";

import { useCurrentOrgId } from "providers";

import DataTable from "components/DataTable";
import { DataTableRef } from "components/DataTable/DataTable";
import FiltersModal from "components/FiltersModal/FiltersModal";
import NoDataComponent from "components/NoDataComponent";
import PageHeader from "components/PageHeader";
import RelativeTimeFormatter from "components/RelativeTimeFormatter";
import { errorToastHandler } from "helpers/queryHelpers";
import eventService from "services/eventService";
import { Event } from "types";

const columns: Column<Event>[] = [
  {
    Header: "User",
    accessor: "userDisplayName",
    Cell: ({ row: { original: row } }) =>
      row.userDisplayName ?? row.userId ?? "-",
  },
  {
    Header: "Summary",
    accessor: "message",
  },
  {
    Header: "Type",
    accessor: "eventType",
  },
  {
    Header: "Timestamp",
    accessor: "eventTs",
    Cell: ({ row: { original: row } }) => (
      <RelativeTimeFormatter dateTs={row.eventTs}></RelativeTimeFormatter>
    ),
  },
];

const Events: FC = () => {
  const orgId = useCurrentOrgId();
  const tableRef = useRef<DataTableRef<Event>>(null);
  const { data: events = [], isLoading } = useQuery(
    ["events", orgId],
    () => eventService.getEvents(orgId),
    {
      onError: errorToastHandler(),
    },
  );

  return (
    <Fragment>
      <PageHeader>Events</PageHeader>
      <Card>
        <Box p={2}>
          <DataTable
            columns={columns}
            data={events}
            isLoading={isLoading}
            pagination
            search
            defaultSortField="eventTs"
            sortByDesc
            autoResetFilters={false}
            ref={tableRef}
            searchActions={
              <FiltersModal
                fields={[
                  {
                    displayName: "Type",
                    type: "select",
                    key: "eventType",
                    values: [
                      { displayName: "USER_LOGIN", value: "USER_LOGIN" },
                      { displayName: "USER_LOGOUT", value: "USER_LOGOUT" },
                    ],
                  },
                  {
                    displayName: "Summary",
                    type: "textfield",
                    key: "message",
                  },
                ]}
                title="Filter Events"
                tableRef={tableRef}
              />
            }
            noDataComponent={
              <NoDataComponent>There are no events</NoDataComponent>
            }
            compact
          />
        </Box>
      </Card>
    </Fragment>
  );
};

export default Events;
