import React, { FC, Fragment, useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Box, Button, CircularProgress, Tooltip } from "@material-ui/core";

import { useCurrentOrgId, useHasScope } from "providers";

import ConfirmationModal, {
  ConfirmationModalRef,
} from "components/ConfirmationModal/ConfirmationModal";
import zodiacService from "services/zodiacService";
import { Permissions, Resources } from "types/auth-roles";

const RestartZodiacButton: FC = () => {
  const orgId = useCurrentOrgId();
  const hasOrgWriteScope = useHasScope(
    Resources.Organization,
    Permissions.Write,
  );
  const [isRestarting, setIsRestarting] = useState(false);
  const confirmationModal = useRef<ConfirmationModalRef>(null);
  const restartZodiac = useCallback(async () => {
    try {
      setIsRestarting(true);
      const response = await zodiacService.restartZodiac(orgId);
      if (response)
        toast.success(
          "We successfully queued your request for restart of Zodiac.",
        );
      confirmationModal.current?.closeModal();
    } catch ({ response: { status } }) {
      if (status === 409)
        toast.error(
          "There is an existing request for restarting the Zodiac. Please wait a few minutes before restarting.",
        );
      else
        toast.error(
          "Unable to restart Zodiac at this time. Please try again after some time.",
        );
    } finally {
      setIsRestarting(false);
    }
  }, [orgId]);

  const openRestartZodiacModal = () =>
    confirmationModal.current?.openModal({
      action: restartZodiac,
      cancel: "Cancel",
      confirm: "Restart",
      description: isRestarting ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        "Are you sure you want to restart Zodiac for this organization?"
      ),
      title: "Restart Zodiac",
      disabled: isRestarting,
    });

  return (
    <Fragment>
      <Tooltip
        arrow
        title={!hasOrgWriteScope ? "Insufficient permissions." : ""}
      >
        <span>
          <Button
            disabled={!hasOrgWriteScope}
            onClick={openRestartZodiacModal}
            color="primary"
            variant="outlined"
          >
            Restart Zodiac
          </Button>
        </span>
      </Tooltip>
      <ConfirmationModal ref={confirmationModal} />
    </Fragment>
  );
};

export default RestartZodiacButton;
