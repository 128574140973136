import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Card,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import useCommonStyles from "theme/useCommonStyles";

import SeverityComponent from "components/SeverityComponent/SeverityComponent";
import { ZodiacAssessmentDiffEntry } from "types/zodiac";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textDecoration: "none",
      "&:hover": {
        background: theme.palette.lightBackground.main,
        cursor: "pointer",
        textDecoration: "none",
      },
      transition: "background-color 0.3s",
    },
    dList: {
      margin: 0,
    },
    dTitle: {
      margin: 0,
      marginRight: theme.spacing(1),
    },
  }),
);

interface ZodiacBuildDiffEntryItemProps {
  diff: ZodiacAssessmentDiffEntry;
}

const ZodiacBuildDiffEntryItem: FC<ZodiacBuildDiffEntryItemProps> = ({
  diff,
}) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const { ciBuildId } = useParams<{ ciBuildId: string }>();
  return (
    <Link
      className={classes.root}
      to={`/zodiac/jobs/${ciBuildId}/assessments/${diff.id}`}
    >
      <Card variant="outlined" className={classes.root}>
        <Box px={2} py={1}>
          <Box>{diff.title}</Box>
          <Box className={classes.dList} component="dl">
            <Box display="flex">
              <Typography
                className={classes.dTitle}
                variant="h6"
                component="dt"
              >
                Resource
              </Typography>
              <Typography
                className={commonStyles.wordBreakAll}
                variant="body2"
                component="dd"
              >
                {diff.resource}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography
                className={classes.dTitle}
                variant="h6"
                component="dt"
              >
                Severity
              </Typography>
              <Typography
                className={commonStyles.wordBreakAll}
                variant="body2"
                component="dd"
              >
                <SeverityComponent severity={diff.severity} />
              </Typography>
            </Box>
            {(diff.filePath || diff.fileName) && (
              <Box display="flex" alignItems="flex-end">
                <Box className={classes.dTitle} component="dt">
                  <DescriptionOutlined fontSize="small" />
                </Box>
                <Typography
                  className={commonStyles.wordBreakAll}
                  variant="body2"
                  component="dd"
                >
                  {`Found in ${diff.filePath ?? diff.fileName}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </Link>
  );
};

export default ZodiacBuildDiffEntryItem;
