import React, { FC, Fragment, useState } from "react";
import DiffViewer, { ReactDiffViewerProps } from "react-diff-viewer";
import Skeleton from "react-loading-skeleton";
import { Box, BoxProps, Grid, useTheme } from "@material-ui/core";
import { ChromeReaderModeOutlined } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

interface CustomDiffComponentProps extends ReactDiffViewerProps {
  wrapperProps?: BoxProps;
  isLoading?: boolean;
}

const CustomDiffComponent: FC<CustomDiffComponentProps> = ({
  isLoading,
  wrapperProps,
  ...props
}) => {
  const theme = useTheme();
  const [options, setOptions] = useState<string[]>([]);
  const isSplit = options.includes("split");

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[],
  ) => {
    setOptions(newFormats);
  };

  return isLoading ? (
    <Grid data-testid="skeleton" container spacing={3}>
      <Grid item xs={12}>
        <Skeleton count={8} />
      </Grid>
    </Grid>
  ) : (
    <Fragment>
      <Box
        display="flex"
        position="absolute"
        right={0}
        marginRight={5}
        style={{ opacity: 0.8 }}
        marginTop={1}
        zIndex={theme.zIndex.modal + 1}
      >
        <ToggleButtonGroup
          size="small"
          value={options}
          onChange={handleFormat}
          aria-label="diff-viewer options"
        >
          <ToggleButton value="split" aria-label="split files">
            <ChromeReaderModeOutlined fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box {...wrapperProps}>
        <DiffViewer splitView={isSplit} {...props} />
      </Box>
    </Fragment>
  );
};

export default CustomDiffComponent;
